const UrlLinks = {
  baseUrl: "https://qaapieflex.okaygo.in/",
  // baseUrl:'https://apieflex.okaygo.in/',
  webBaseUrl:"https://qa.okaygo.in/",
  // webBaseUrl: "https://okaygo.in/",
  taskExecutionUrl: "https://task.okaygodev.in/org/process_okaygo",
  workerExecutionUrl: "https://task.okaygodev.in/org/worker_view",
  checkAdmin: "user/checkAdminUser",
  wiproReprt: "ezeDox/wipro/report",
  treeboReprt: "ezeDox/treebo/report",
  amazonReport: "ezeDox/amazom/report",
  clientReport: "ezeDox/clients/report",
  checkAdminUserName: "user/checkAdminUserName",
  saveAdminUserName: "user/saveAdminUserName",
  getEmail: "user/getEmailHRBP?email=",
  sendOtp: "/user/sendOTP",
  verifyOtp: "/user/verifyOTP",
  verifyHubName: "/user/isHubValid",
  registerHubLocation: "/user/registerHubLocation",
  messageTemplates: "popups/getTemplates",
  getMessageBody: "popups/getMessageBody",
  sendMsg: "/popups/sendMessages",
  sendCustomMsg: "/popups/sendCustomNotification",
  sendMsgToAll: "/popups/sendMessage/all",
  getPartner: "/configmaster/getPartners",
  getZonalManager: "/configmaster/getZonalManager",
  aproveReward: "https://apieflex.okaygo.in/rewards/saveToWorkerInvoiceMaster",
  rewardPaymentData: "/AATestController/rewardPaymentDetailsManak",
  BGVListing: "bgv/listing",
  BGVDownloadReport: "bgv/getBgvReport?userId=",
  assignVerifier: {
    listing: "verifier/assignmentListing",
    assign: "verifier/update",
  },
  verificationAPIs: {
    listing: "docsVerification/getVerificationListing",
    ajWorkerListing: "docsVerification/getAjWorkersListing",
    verificationProfile: "docsVerification/getVerificationDataByUserId",
    updateVerification: "docsVerification/manualDocsVerification",
  },
  workerUrls: {
    workerLedgerListing: "ledger/getWorkerLedger",
    particularWorkerLedgerListing: "ledger/getWorkerLedgerInvoiceList",
    updatePennyTestingStatus: "worker/recheckBankDetail",
    lastAppliedHistory: "workerdata/getJobAppliedHistory?userId=",
    bgvStatusList: "bgv/getStatus?userId=",
    triggerBGV: "bgv/createProfile?userId=",
    bgvReverify: `bgv/reverify?insertedBy=${localStorage.getItem(
      "userID"
    )}&userId=`,
    asaanJobWorkerListing: "workerdata/getVacoWorkerListing",
    asaanJobPaymentListing: "workerdata/paymentDataListing",
    leadCities: "leads/getLeadCities?key=",
    reteriveLastSms: "worker/getLastSmsOfUser",
    editEntity: "workerdata/editEntities?",
    Invoicedetails: "/uploadData/uploadWorkerInvoiceDetailsV3?insertedBy=",
    uploadPayoutAutomation: "/uploadData/uploadPayoutAutomation?insertedBy=",
    workerListing: "worker/listing",
    workerListingV3: "/worker/listing/v3",
    workerDataListing: "/workerdata/listing",
    leadDataListing: "/workerdata/getLeadDataListing",
    workerDataListingv3: "/workerdata/listingV2",
    leadDataListingv3: "/workerdata/getLeadDataListingV2",
    workerView: "worker/listing?user_id=",
    workerViewV3: "workerdata/listing?user_id=",
    allWorkerListing: "worker/allRecords",
    editWorkerDetails: "worker/addEditUserDetail",
    editWorkerHRBPDetails: "workerdata/editHRBPSection",
    editWorkerBankDetails: "worker/addEditBankDetails",
    docList: "user/getUserDoc?user_id=",
    activateWorker: "worker/activateWorker",
    hubBasedFacilityList: "user/getHubs?userId=",
    facilityList: "worker/getFacilityByKey?key=",
    rateCardList: "worker/getDistinctRateCardByZone?zone=",
    uploadVacoOnboardingExcel: "/uploadData/uploadVacoWorker?insertedBy=",
    uploadVacoWorkerInvoice: "/uploadData/uploadVacoWorkerInvoice?insertedBy=",
    uploadOnboardingExcel:
      "uploadData/uploadExcelForOkaygoIdCreation?insertedBy=",
    uploadOnboardingExcelV2:
      "uploadData/uploadExcelForOkaygoIdCreation/V2?insertedBy=",
    uploadOffboardingExcel: "uploadData/offBoardWorkersV2?userId=",
    uploadOffboardingSwiggyExcel: "upload/offBoardSwiggyWorkersV2?userId=",
    updateLeader: "worker/leaderfunctionality",
    generateWeeklyInvoice: "/worker/generateWeeklyInvoice",
    uploadDocLink: "user/getDocLink?user_id=",
    uploadDocument: "user/uploadDocument?inserted_by=",
    // new added
    uploadExcelForMultiplePartner:
      "/upload/uploadExcelForMultiplePartner?insertedBy=",
    offBoardWorkers: "uploadData/V3/offBoardWorkers?userId=",
    uploadMettlAssessor: "workermaster/uploadMettlWorker?",
    //integrate these
    uploadZomatoIds: "/upload/zomatoIds?insertedBy=",
    uploadJiomartIds: "/upload/uploadJiomartIds?insertedBy=",
    uploadDelhiveryIds: "/upload/uploadDelhiveryIds?insertedBy=",
    uploadSwiggyIdsV2: "/upload/swiggyIds/v2?insertedBy=",
    getExistingLeadDetails: "workerdata/getLeadDataListing?user_id=",
    uploadHubs: "/hub/add",
    // here
    uploadGroupFlexExcel:
      "/uploadData/uploadExcelForOkaygoIdCreationWithGroupflexId?insertedBy=",
    bankDetails: "worker/getBankDetails?user_id=",
    editContact: "worker/editWorkerContacts",
    workerReactivation: "worker/changeWorkerStatusFromAdmin",
    workerWithoutCasperId: "worker/allWorkerCasper",
    workerDataPopupSwiggy: "/worker/V2/notFilledSwiggyIds",
    workerWithoutDelhiveryId: "/worker/notFilledDelhiveryIds",
    workerWithoutJiomartId: "/worker/notFilledJiomartIds",
    workerDataPopupZomato: "/worker/notFilledZomatoIds",
    uploadCasperOnboardingData: "upload/uploadWorkerCasperId",
    downloadAgreementOnResponse: "worker/createWorkerAgreementCopy?batchNo=",
    swiggyIds: "/upload/swiggyIds",
    swiggyShipmentIds: "/upload/swiggyShipmentData",
    swiggyOffboarding: "",
    swiggyListing: "/worker/swiggyListing",
    swiggyListingView: "/worker/swiggyListing?user_id=",
    emailAgreements: `worker/sendAgreementEmail?inserted_by=${localStorage.getItem(
      "userID"
    )}&user_ids=`,
    allSwiggyList: "worker/swiggyAllRecords",
    workerDataHubs: "/configmaster/getActiveHubs",
    workerDataHubCity: "/configmaster/getFacilityCity",
    leadSourceList: "leads/getLeadSourceList",
    genderList: "configmaster/?category_type=gender&type_key=gender",
    languagesList: "configmaster/?category_type=language&type_key=language",
    assetsList: "configmaster/?category_type=ownership&type_key=ownership",
    qualificationList:
      "configmaster/?category_type=qualification_type&type_key=qualification_type",
    verticalList:
      "configmaster/?category_sub_type=lead_category&category_type=user_category",
    getAreaByPinCode: "worker/getAreaByPinCode?key=",
    workerDataHubState: "/configmaster/getFacilityStates",
    recruiterList: "/recruiter/getAllRecruiter?noPaging=1",
    workerWithoutClientCreatedIds: `workerdata/notFilledClientWorkerDataIds?partnerName=`,
    getInsuranceTagsExport: `workermaster/getInsuranceTagsExport?partnerId=`,
    uploadExcelForMultiplePartner2:
      "/uploadData/uploadExcelForMultiplePartner?insertedBy=",
    uploadCasperOnboardingData2:
      "uploadData/uploadClientWorkerDataIds?partnerName=",
    uploadInsuranceTags: "workermaster/uploadInsuranceTags?insertedBy=",
    uploadOnboardingExcelV22:
      "uploadData/uploadExcelForOkaygoIdCreation/V2?insertedBy=",
    uploadOnboardingExcel2:
      "uploadData/uploadExcelForOkaygoIdCreation?insertedBy=",
    hubListing: "/hub/getHubsByClient",
    uploadExcelForLMD: "/uploadData/uploadExcelForMultiplePartner?insertedBy=",
    uploadExcelForNonLMD: "/uploadData/uploadNonLmdWorker?insertedBy=",
    getAllAgreedProjectByUserId: "/task-model/getAllAgreedProjectByUserId",
    getAgreementByUserId: "/task-model/getAgreementByUserId",
  },
  clients: {
    addNewCompany: "clients/saveNewClient",
  },
  paymentUrls: {
    paymentListing: "payments/listing",
    paymentListingMultiClient: "workerdata/paymentDataListing",
    paymentListingMultiClientV2: "workerdata/paymentDataListingV2",
    triggerPayment: "payments/triggerBulkPayments",
    financeAdmin: "user/checkFinanceUser?user_id=",
    paymentOnHold: "payments/holdPayments",
    paymentunHold: "payments/undoHoldPayments",
    updatePaymentStage: "payments/updateStage",
    updateRetriggerPaymentStage: "payments/retriggerInvoicePayments",
  },
  jobs: {
    jobsListing: "job/jobListing/v2",
    jobsdbListing: "job/jobListing/admin",
    jobsdbListingNew: "task-job/",
    jobCampaignStats: "job/jobCampaignStats",
    jobslocation: "task-job/getJobLocations",
    jobProject: "verticals/getAllProjects",
    jobsVerticals: "verticals/getAllVerticals",
    jobCities: "worker/getDistrictByKey/v2?key=",
    jobAreas: "job/areas",
    workerApplied: "job/workerApplied",
    jobUpload: "upload/uploadJobs/v2",
    jobEditSave: "job/updateJobFields",
    jobEditSave2: "job/updateJobFields/v2",
    setDemandJob: "task-job/setDemandJob",
    updateJobSelection: "job/updateSelectionStatus",
  },
  retentionCalls: {
    retentionCallListing: "workerdata/retentionCall/listing",
    statusUpdate: "workerdata/retentionCall/update",
    AssignRecruter: "workerdata/retentionCall/assign",
  },
  referral: {
    referralListing: "referral/dashboard",
    openClaimsListing: "referral/dashboard/openClaims",
    processedClaimsListing: "/referral/dashboard/ProcessedClaims",
    updateReferralFields: "/referral/updateFields",
  },
  bankVerification: {
    listing: "worker/bankDetailsVarification/listing",
    DetailsVerificationInitial: "worker/getBankDetailsForVerification?userId=",
    DetailsVerificationNext:
      "worker/getBankDetailsForVerification?currentUserId=",
    DetailsVerificationNextV2:
      "worker/getBankDetailsForVerificationv2?currentUserId=",
    submitDetails: "worker/verifiyBankDetails",
    editBankAccountDetails: "bankaccountdetail/editBankAccountDetails",
  },
  shifts: {
    shiftPlanning: "workershift/getWorkerShiftPlanning",
    shiftData: "workershift/listing",
    workerPartners: "workershift/getWorkerPartners",
    uploadingShiftingPlan: "upload/uploadWorkersShiftDetails?insertedBy=",
    shiftStatusList: "configmaster/?category_sub_type=worker_shift_status",
    shiftDetailExcel:
      "user/getSignedUrlForS3Upload?filePath=document/Templates/Shift_Planner_Template.xlsx",
    newOnboardingExcel:
      "user/getSignedUrlForS3Upload?filePath=document/templates/Onboarding_Multiple_Client_Template.xlsx",
    newOffboardingExcel:
      "user/getSignedUrlForS3Upload?filePath=document/templates/Offboarding_Multiple_Client_Template.xlsx",
    updateShiftDetails: "workershift/addEditWorkerShift",
    updateEffectiveDate: "workershift/editEffectiveDate",
    uploadEarningFlipkart: "upload/flipkartShipmentData?insertedBy=",
    uploadEarningSwiggy: "upload/swiggyShipmentData?insertedBy=",
    uploadEarningZomato: "upload/zomatoShipmentData?insertedBy=",
    uploadEarningDelhivery: "upload/delhiveryShipmentData?insertedBy=",
    uploadEarningJiomart: "upload/jiomartShipmentData?insertedBy=",
    uploadEarningOther: "uploadData/newClientEarningUpload?insertedBy=",
    uploadEarningZepto: "uploadData/newZeptoEarningUpload?insertedBy=",
  },
  recruiter: {
    dashboard: "recruiter/getCountCallRecord",
    assignToRecruiter: "recruiter/assignWorkerToRecruiter",
    assignToRecruiterLead: "recruiter/assignLeadToRecruiterV2",
    activeAndBlockAPI: "recruiter/setActiveAndBlock",
  },
  verificationCases: {
    listing: "verifier/listing",
    assingedVerifier: "verifier/assignedVerifier",
    getCasesPincodeAndCity: "verifier/getCasesPincodeAndCity?",
    uploadVerificationCases:
      "uploadData/uploadExcelForVerificationCases?insertedBy=",
  },
  onHoldList:
    "configmaster/?category_sub_type=Onhold_remark&category_type=Worker_invoice",
  stateList:
    "configmaster/?category_sub_type=state_list&category_type=state_list&type_key=state_list",
  roleType: {
    leadCategory:
      "configmaster/?category_sub_type=lead_category&category_type=user_category",
    recruiterCategory:
      "configmaster/?category_sub_type=lead_category&category_type=user_category",
  },
  documentTypeList: "configmaster/?category_type=document_upload",
  entityList: "configmaster/entityList",
  onBoardingExcel:
    "user/getSignedUrlForS3Upload?filePath=document/templates/Onboarding_data.xlsx",
  newOnboardingExcel:
    "user/getSignedUrlForS3Upload?filePath=document/templates/Onboarding_Multiple_Client_Template.xlsx",
  onBoardingExcelV2:
    "user/getSignedUrlForS3Upload?filePath=document/templates/Onboarding_Data_Admin.xlsx",
  offBoardingExcel:
    "user/getSignedUrlForS3Upload?filePath=document/Templates/Updated_Offboarding_file_template.xlsx",
  //add here
  groupFlexExcel:
    "user/getSignedUrlForS3Upload?filePath=document/templates/Groupflex_Onboarding_Template.xlsx",
  uploadSwiggyIdExcel:
    "user/getSignedUrlForS3Upload?filePath=document/templates/upload_swiggy_id_template.xlsx",
  offBoardingSwiggyExcel:
    "user/getSignedUrlForS3Upload?filePath=document/templates/offboarding_swiggy_template.xlsx",
  earningSwiggyExcel:
    "user/getSignedUrlForS3Upload?filePath=document/templates/swiggy_shipment_data_template.xlsx",
  earningFlipkartExcel:
    "user/getSignedUrlForS3Upload?filePath=document/templates/flipkart_shipment_data_template.xlsx",
  earningZomatoExcel:
    "user/getSignedUrlForS3Upload?filePath=document/templates/zomato_shipment_data_template.xlsx",
  earningZeptoExcel:
    "user/getSignedUrlForS3Upload?filePath=document/templates/zepto_shipment_data_template.xlsx",
  jobdbExcel:
    "user/getSignedUrlForS3Upload?filePath=document/templates/upload_job_template.xlsx",
  projectListing: {
    mainListing: "verticals/getAllProjects",
    client: "verticals/getDataListForProject?param=1",
    vertical: "verticals/getDataListForProject?param=2",
    projectOwner: "verticals/getDataListForProject?param=3",
    projectById: "/verticals/getProjectsById",
    addNewProject: "/verticals/addNewProject",
    editProject: "/verticals/editProjectById",
    editTat: "task-model/saveTatConfig",
    getAttendanceByProjectId: "verticals/getAttendanceByProjectId?projectId=",
    getTatConfig: "task-model/getTatConfig",
    formDataList: "task-model/getALlFormDataList",
    formDataById: "task-model/getFormDataById",
    saveFormData: "/task-model/saveFormData",
  },
  driveListing: {
    mainListing: "workerdata/getDriveData",
    mainInvigilatorListing: "workerdata/getInvigilatorData",
    getFilterValues: "workerdata/driveData/getFilterValues?val=",
    getInvigilatorFilterValues:
      "workerdata/invigilatorData/getFilterValues?val=",
    saveDriveData: "workerdata/saveDriveData?projectId=",
    saveInvigilatorData: "workerdata/saveInvigilatorData",
    saveShiftUploadData: "workerdata/saveShiftUploadData",
    getInvigilatorHistory:
      "workerdata/invigilatorData/getHistory?contact_number=",
  },
  mpaListing: {
    mainListing: "task-model/mpaListing",
    AddMpaUser: "task-model/AddMpaUser",
    getAllVerticals: "verticals/getAllVerticals",
  },
  leadData: {
    applicationHistory: "leaddata/getLeadApplicationHistory",
    assignAppliedLeadToRecruiter: "leaddata/assignAppliedLeadToRecruiter",
    assignLeadToOps: "leaddata/assignLeadToOps",
    assignToRecruiter: "leaddata/assignToRecruiter",
    getCampaignListing: "leaddata/getCampaignListing",
    getJobCampaignTemplates: "leaddata/getJobCampaignTemplates",
    getLeadApplicationStatus: "leaddata/getLeadApplicationStatus",
    getLeadApplicationStatusExcel: "leaddata/getLeadApplicationStatusExcel",
    getOpsListing: "leaddata/getOpsListing",
    getUserNotification: "leaddata/getUserNotification",
    getWorkerHistory: "leaddata/getWorkerHistory",
    mainListing: "leaddata/getLeadData",
    setLeadApplicationStatusInBulk: "leaddata/setLeadApplicationStatusInBulk",
    setRemark: "leaddata/setRemarks",
    startJobCampaign: "leaddata/startJobCampaign",
    updateLeads: "upload/updateLeads",
    uploadLeads: "upload/uploadLeads",
    uploadJobApplication: "job/uploadJobApplication",
  },
  deeplink: {
    validateDeepLink: "deeplink/validateDeepLink",
    getDailyWhatsAppSentCount: "deeplink/getDailyWhatsAppSentCount",
  },
  payments: {
    getFinancialYearListingAmount:
      "payments/getFinancialYearListingAmount?workerId=",
  },
};

export default UrlLinks;
