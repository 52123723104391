import React, {
  useState,
  useEffect,
  useMemo,
  useContext,
  forwardRef,
  useRef,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../store/actions/index";
import "./indeterminateCheckbox.css";
import styled from "styled-components";
import { useTable, useRowSelect, useSortBy, useFilters } from "react-table";
import urlLinks from "../../UrlLinks";
import Axios from "axios";
import styles from "./index.module.scss";
import Pagination from "../../Utils/Pagination";
import * as moment from "moment";
import NoRecord from "./Assets/noRecord.svg";
import ScaleLoader from "react-spinners/ScaleLoader";
import { AuthContext } from "../../services/AuthProvider";
import UrlLinks from "../../UrlLinks";
import ExportButton from "../../Utils/functions/ExportButton";
import RemoveExtraSpaces from "../../Utils/functions/RemoveExtraSpaces";
import SearchBar from "../../Utils/functions/SearchBar";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Checkbox, Drawer, Button, IconButton } from "@material-ui/core";
import JobFilter from "../../Utils/FilterComponents/JobFilter";
import filterIcon from "../../assets/filterIconWhite.svg";
import FilterIconMarked from "../../assets/FilterIconMarked.svg";
import FilterDrawer from "./components/FilterDrawer";
import DocumentDownload from "./components/downloadDocuments";
import ActionsDropdown from "../../Utils/functions/ActionsDropdown";
import SortUpArrow from "./Assets/Polygon1.svg";
import SortDownArrow from "./Assets/Polygon2.svg";
import ExportModal from "./components/ExportModal";
import BulkUpdateModal from "../../Utils/dialogs/BulkUpdateModal";
import OnboardingModal from "../../Utils/dialogs/OnboardingModal";
import HistoryModal from "../../Utils/dialogs/HistoryModal";

const Styles = styled.div`
  padding: 1rem;
  .tableContainer {
    overflow-x: scroll;
    border: 1px solid #cfcfcf;
    max-height: 74vh;
  }
  .tableContainer::-webkit-scrollbar {
    width: 0.75em;
  }

  .tableContainer::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  .tableContainer::-webkit-scrollbar-thumb {
    background-color: darkgrey;
  }
  table {
    border-spacing: 0;
    width: max-content;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    th {
      :last-child {
        border-left: 0;
      }
      background-color: #1c75bc;
      color: #ffffff;
    }
    th,
    td {
      padding: 5px 15px;
    }

    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid #cfcfcf;
      border-right: 1px solid #cfcfcf;
      background-color: white;
      nth-last-child(2) {
        border-right: 0;
      }
      :last-child {
        border-right: 0;
      }

      input {
        font-size: 1rem;
        padding: 0;
        margin: 0;
        border: 0;
      }
    }
  }

  .pagination {
    padding: 0.5rem;
  }
`;

const useStyles = makeStyles((theme) => ({}));

const DriveDatabase = (props) => {
  /** accessing states from redux store **/

  const { users, driveDb } = useSelector((state) => state);
  const {
    loader,
    modalLoader,
    currentPage,
    perPage,
    totalRows,
    totalPages,
    openFilterDrawer,
    isFilter,
    allRecord,
  } = users;
  const {
    driveTab,
    multipleCityName,
    multipleExamCity,
    multipleDriveRole,
    multipleInvigilatorRole,
  } = driveDb;

  /** useDispatch is used to update states to redux store **/

  const dispatch = useDispatch();
  const {
    setLoader,
    setModalLoader,
    setCurrentPage,
    setPerPage,
    setDriveTab,
    setTotalRows,
    setTotalPages,
    setOpenFilterDrawer,
    setIsFilter,
    setAllRecord,
  } = bindActionCreators(actionCreators, dispatch);

  /** other states **/

  const classes = useStyles();
  const { showToast } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [allRecordLoading, setAllRecordLoading] = useState(false);
  const [searchByAuto, setSearchByAuto] = useState("");
  const [searchBy, setSearchBy] = useState("");
  const [filterUrl, setFilterUrl] = useState("");
  const [searchError, setSearchError] = useState(false);
  const [projectList, setProjectList] = useState([]);
  const [actions, setActions] = useState(-1);
  const [exportModal, setExportModal] = useState(false);
  const [openBulkUpdateModal, setOpenBulkUpdateModal] = useState(false);
  const [addShiftModal, setAddShiftModal] = useState(false);
  const [openNameHistoryModal, setOpenNameHistoryModal] = useState(false);
  const [historyColumns, setHistoryColumns] = useState([]);
  const [historyList, setHistoryList] = useState([]);
  const [examCityId, setExamCityId] = useState([]);
  const [cityId, setCityId] = useState([]);
  const [driveRole, setDriveRole] = useState([]);
  const [invigilatorRole, setInvigilatorRole] = useState([]);
  const [selectedOption, setSelectedOption] = useState({
    id: "-1",
    name: "Select Project",
  });
  const [filters, setFilters] = useState({
    aadharValidation: "",
    city: "",
    examCity: "",
    examDate: null,
    examName: "",
    month: "",
    nameAgency: "",
    panValidation: "",
    projectId: "",
    role: "",
    roleType: "",
    searchBy: "",
    state: "",
    tenthValidation: "",
    twelfthValidation: "",
    venueName: "",
    zone: "",
  });

  const actionList = [
    {
      value: "updateData",
      label: "Update Data",
    },
    driveTab.driveDashboard
      ? {
          value: "addShiftData",
          label: "Add Shift Data",
        }
      : {},
  ];

  /* The column names here should be same as the 'selectedColumns' state in ExportModal.js */
  const driveColumnsList = [
    "Month",
    "Exam Timing",
    "Zone",
    "Role Type",
    "State",
    "Shift 1",
    "Exam City",
    "Shift 2",
    "TC code",
    "Shift 3",
    "Venue Name",
    "Shift 4",
    "Exam Name",
    "Total no. shifts Utilised",
    "Exam Date",
  ];

  /* The column names here should be same as the 'selectedColumns' state in ExportModal.js */
  const workerColumnsList = [
    "Zone",
    "Workforce Father’s Name",
    "12th Pass Certificate",
    "State",
    "Workforce Mother’s Name",
    "12th Validation",
    "City Name",
    "DOB",
    "10th Pass Certificate",
    "OkayGo ID",
    "Complete permanent address",
    "10th Validation",
    "Client Resource ID",
    "Aadhaar Card",
    "BGV Status",
    "Name",
    "Aadhaar Number",
    "If Yes, BGV Date",
    "Role",
    "Aadhaar Validation",
    "BGV Validation",
    "Contact Number",
    "PAN Card",
    "Email ID",
    "PAN Number",
    "Name of the Agency/Vendor",
    "PAN Validation",
  ];

  const resetFiltersWithoutSearch = (type) => {
    setIsFilter(false);
    const filterList = {
      aadharValidation: "",
      city: "",
      examCity: "",
      examDate: null,
      examName: "",
      month: "",
      nameAgency: "",
      panValidation: "",
      projectId: filters.projectId,
      role: "",
      roleType: "",
      searchBy: type === "resetSearch" ? "" : filters.searchBy,
      state: "",
      tenthValidation: "",
      twelfthValidation: "",
      venueName: "",
      zone: "",
    };

    if (openFilterDrawer && type !== "resetSearch") {
      setFilterUrl(
        `${filters.searchBy ? "&searchBy=" + filters.searchBy : ""}${
          filters.projectId ? "&projectId=" + filters.projectId : ""
        }`
      );
    } else {
      if (type === "resetSearch") {
        setFilterUrl(
          `${filters.projectId ? "&projectId=" + filters.projectId : ""}`
        );
        setSearchBy("");
        setSearchByAuto("");
      }
    }

    setFilters(filterList);
    setExamCityId([]);
    setCityId([]);
    setDriveRole([]);
    setInvigilatorRole([]);
    setCurrentPage(1);
    return filterList;
  };

  const driveColumns = useMemo(
    () => [
      {
        Header: "Month",
        columns: [
          {
            id: "monthId",
            accessor: "month",
            defaultCanFilter: false,
            defaultCanSort: false,
          },
        ],
      },
      {
        Header: "Zone",
        columns: [
          {
            id: "zoneId",
            accessor: "zone",
            defaultCanFilter: false,
            defaultCanSort: false,
          },
        ],
      },
      {
        Header: "State",
        columns: [
          {
            id: "stateId",
            accessor: "state",
            defaultCanFilter: false,
            defaultCanSort: false,
          },
        ],
      },
      {
        Header: "Exam City",
        columns: [
          {
            id: "examCityId",
            accessor: "examCity",
            defaultCanFilter: false,
            defaultCanSort: false,
          },
        ],
      },
      {
        Header: "Client Code",
        columns: [
          {
            id: "clientCodeId",
            accessor: "tcCode",
            defaultCanFilter: false,
            defaultCanSort: false,
          },
        ],
      },
      {
        Header: "Venue Name",
        columns: [
          {
            id: "venueNameId",
            accessor: "venueName",
            Cell: (row) => {
              return (
                <div style={{ width: "250px" }}>
                  {row.row.original.venueName}
                </div>
              );
            },
            defaultCanFilter: false,
            defaultCanSort: false,
          },
        ],
      },
      {
        Header: "Exam Name",
        columns: [
          {
            id: "examNameId",
            accessor: "examName",
            defaultCanFilter: false,
            defaultCanSort: false,
          },
        ],
      },
      {
        Header: "Exam Date",
        columns: [
          {
            id: "examDateId",
            accessor: "examDate",
            Cell: (row) => {
              return (
                <div style={{ width: "min-content" }}>
                  {row.row.original.examDate
                    ? moment(row.row.original.examDate).format("DD/MM/YYYY")
                    : "-"}
                </div>
              );
            },
            sortType: (a, b) => {
              const dateA = new Date(a.original.examDate);
              const dateB = new Date(b.original.examDate);
              return dateA - dateB;
            },
            canSort: true,
          },
        ],
      },
      {
        Header: "Exam Timing",
        columns: [
          {
            id: "examTimingId",
            accessor: "examTiming",
            Cell: (row) => {
              return (
                <div>
                  {row.row.original.examTiming
                    ? moment(row.row.original.examTiming, "HH:mm:ss").format(
                        "h:mm A"
                      )
                    : "-"}
                </div>
              );
            },
            sortType: (a, b) => {
              const timeA = moment(a.original.examTiming, "HH:mm:ss");
              const timeB = moment(b.original.examTiming, "HH:mm:ss");
              if (timeA.isBefore(timeB)) return -1;
              if (timeA.isAfter(timeB)) return 1;
              return 0;
            },
            defaultCanFilter: false,
            defaultCanSort: false,
          },
        ],
      },
      {
        Header: "Role Type",
        columns: [
          {
            id: "roleTypeId",
            accessor: "roleType",
            defaultCanFilter: false,
            defaultCanSort: false,
          },
        ],
      },
      {
        Header: "Shift 1",
        columns: [
          {
            id: "shift1Requirement",
            Header: "Requirement No.",
            accessor: "requirementNumberShift1",
          },
          {
            id: "shift1Fulfilled",
            Header: "Fulfilled No.",
            accessor: "fulfilledNumberShift1",
          },
        ],
      },
      {
        Header: "Shift 2",
        columns: [
          {
            id: "shift2Requirement",
            Header: "Requirement No.",
            accessor: "requirementNumberShift2",
          },
          {
            id: "shift2Fulfilled",
            Header: "Fulfilled No.",
            accessor: "fulfilledNumberShift2",
          },
        ],
      },
      {
        Header: "Shift 3",
        columns: [
          {
            id: "shift3Requirement",
            Header: "Requirement No.",
            accessor: "requirementNumberShift3",
          },
          {
            id: "shift3Fulfilled",
            Header: "Fulfilled No.",
            accessor: "fulfilledNumberShift3",
          },
        ],
      },
      {
        Header: "Shift 4",
        columns: [
          {
            id: "shift4Requirement",
            Header: "Requirement No.",
            accessor: "requirementNumberShift4",
          },
          {
            id: "shift4Fulfilled",
            Header: "Fulfilled No.",
            accessor: "fulfilledNumberShift4",
          },
        ],
      },
      {
        Header: "Total Shifts Utilised",
        columns: [
          {
            id: "totalShiftId",
            accessor: "totalNoOfShiftsUtilized",
            defaultCanFilter: false,
            defaultCanSort: false,
          },
        ],
      },
    ],
    []
  );

  const workerColumns = useMemo(
    () => [
      {
        Header: "Zone",
        accessor: "zone",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "State",
        accessor: "state",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "City Name",
        accessor: "cityName",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "OkayGo ID",
        accessor: "ogId",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Client Resource ID",
        accessor: "clientResourceId",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Name",
        accessor: "name",
        Cell: (row) => {
          return (
            <div
              style={{ width: "250px" }}
              className="flexDisplay justifyContentSpaceBetween"
            >
              <span style={{ width: "190px" }}>
                {row.row.original.name || "-"}
              </span>
              <button
                className={styles.workerName}
                type="button"
                onClick={(e) =>
                  handleUserModal(e, row.row.original, "nameHistory")
                }
              >
                View
              </button>
            </div>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Role",
        accessor: "role",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Contact Number",
        accessor: "contactNumber",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Email ID",
        accessor: "emailId",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Name of the Agency/Vendor",
        accessor: "agencyVendorName",
        Cell: (row) => {
          return (
            <div style={{ width: "250px" }}>
              {row.row.original.agencyVendorName}
            </div>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Workforce Father’s Name",
        accessor: "workforceFathersName",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Workforce Mother’s Name",
        accessor: "workforceMothersName",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "DOB (DD/MM/YYYY)",
        accessor: "dob",
        Cell: (row) => {
          return (
            <div style={{ width: "min-content" }}>
              {row.row.original.dob
                ? moment(row.row.original.dob).format("DD/MM/YYYY")
                : "-"}
            </div>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Complete permanent address",
        accessor: "completePermAddress",
        Cell: (row) => {
          return (
            <div style={{ width: "250px" }}>
              {row.row.original.completePermAddress}
            </div>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Aadhaar Card",
        accessor: "aadhaarCard",
        Cell: (row) => {
          return (
            <DocumentDownload row={row.row.original} columnName="aadhaarCard" />
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Aadhaar No.",
        accessor: "aadhaarNo",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Aadhaar Validation",
        accessor: "aadhaarValidation",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "PAN Card",
        accessor: "panCard",
        Cell: (row) => {
          return (
            <DocumentDownload row={row.row.original} columnName="panCard" />
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "PAN No.",
        accessor: "panNo",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "PAN Validation",
        accessor: "panValidation",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "12th Pass Certificate",
        accessor: "twelfthPassCertificate",
        Cell: (row) => {
          return (
            <DocumentDownload
              row={row.row.original}
              columnName="twelfthPassCertificate"
            />
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "12th Validation",
        accessor: "twelfthPassCertificateValidation",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "10th Pass Certificate",
        accessor: "tenthPassCertificate",
        Cell: (row) => {
          return (
            <DocumentDownload
              row={row.row.original}
              columnName="tenthPassCertificate"
            />
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "10th Validation",
        accessor: "tenthPassCertificateValidation",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "BGV Status",
        accessor: "bgvStatus",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "If Yes, BGV Date",
        accessor: "bgvDate",
        Cell: (row) => {
          return (
            <div style={{ width: "min-content" }}>
              {row.row.original.bgvDate
                ? moment(row.row.original.bgvDate).format("DD/MM/YYYY")
                : "-"}
            </div>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "BGV Validation",
        accessor: "bgvValidation",
        Cell: (row) => {
          return (
            <DocumentDownload
              row={row.row.original}
              columnName="bgvValidation"
            />
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
    ],
    []
  );

  const columns = driveTab.workerDashboard ? workerColumns : driveColumns;

  // Function to update the Table Data
  const updateMyData = () => {
    getExternalWorkerList(perPage, currentPage);
  };

  const handleSearch = () => {
    setFilters({ ...filters, searchBy: searchBy });
    setSearchByAuto(searchBy);
  };

  const handleSearchClose = () => {
    setFilters({ ...filters, searchBy: "" });
    setSearchBy("");
    setFilterUrl("");
    setSearchByAuto("");
  };

  const updateFilterUrl = () => {
    let url = "";

    // Search By
    if (filters["searchBy"] !== "") {
      url = `${url}&searchBy=${filters["searchBy"]}`;
    }

    // Aadhar Validation
    if (filters["aadharValidation"]) {
      url = `${url}&aadhaarValidation=${filters["aadharValidation"].id}`;
    }

    // City name
    if (filters["city"]) {
      url = `${url}&cityName=${filters["city"]}`;
    }

    // Exam city
    if (filters["examCity"]) {
      url = `${url}&exam_city=${filters["examCity"]}`;
    }

    // Exam date
    if (filters["examDate"]) {
      url = `${url}&exam_date=${moment(filters["examDate"]).format(
        "YYYY-MM-DD"
      )}`;
    }

    // Exam name
    if (filters["examName"]) {
      url = `${url}&exam_name=${filters["examName"].id}`;
    }

    // Month
    if (filters["month"]) {
      url = `${url}&month=${filters["month"].id}`;
    }

    // Agency Name
    if (filters["nameAgency"]) {
      url = `${url}&agencyName=${filters["nameAgency"].id}`;
    }

    // PAN Validation
    if (filters["panValidation"]) {
      url = `${url}&panValidation=${filters["panValidation"].id}`;
    }

    // Project Id
    if (filters["projectId"]) {
      url = `${url}&projectId=${filters["projectId"]}`;
    }

    // Role
    if (filters["role"]) {
      url = `${url}&role=${filters["role"]}`;
    }

    // Role type
    if (filters["roleType"]) {
      url = `${url}&role_type=${filters["roleType"]}`;
    }

    // State
    if (filters["state"]) {
      url = `${url}&state=${filters["state"].id}`;
    }

    // 10th Validation
    if (filters["tenthValidation"]) {
      url = `${url}&tenthValidation=${filters["tenthValidation"].id}`;
    }

    // 12th Validation
    if (filters["twelfthValidation"]) {
      url = `${url}&twelfthValidation=${filters["twelfthValidation"].id}`;
    }

    // Venue name
    if (filters["venueName"]) {
      url = `${url}&venue_name=${filters["venueName"].id}`;
    }

    // Zone
    if (filters["zone"]) {
      url = `${url}&zone=${filters["zone"].id}`;
    }

    return url;
  };

  const ApplyFilters = () => {
    let url = updateFilterUrl();
    setFilterUrl(url);
    setFilters(filters);
    setCurrentPage(1);
  };

  //Function to create drive export data as per the main listing order
  const makeDriveExportData = async (response, selectedColumns) => {
    let extractedData = [];
    response.map((el) => {
      if (driveTab.workerDashboard) {
        let trueColumns = Object.keys(selectedColumns.workerDashboard)
          .map((key) => {
            if (!selectedColumns.workerDashboard[key].val) return;
            selectedColumns.workerDashboard[key].name = key;
            return selectedColumns.workerDashboard[key];
          })
          .filter((el) => el !== undefined);

        let list = {};
        trueColumns.forEach((ele) => {
          list[ele.name] =
            ele.id === "bgvDate"
              ? moment(el[ele.id]).format("DD-MM-YYYY") || ""
              : ele.id === "dob"
              ? moment(el[ele.id]).format("DD-MM-YYYY") || ""
              : el[ele.id] || "";
        });

        extractedData.push(list);
      } else {
        let trueColumns = Object.keys(selectedColumns.driveDashboard)
          .map((key) => {
            if (!selectedColumns.driveDashboard[key].val) return;
            selectedColumns.driveDashboard[key].name = key;
            return selectedColumns.driveDashboard[key];
          })
          .filter((el) => el !== undefined);

        let list = {};
        trueColumns.forEach((ele) => {
          if (typeof ele.id === "string") {
            list[ele.name] =
              ele.id === "examDate"
                ? moment(el[ele.id]).format("DD-MM-YYYY") || ""
                : ele.id === "examTiming"
                ? moment(el[ele.id], "HH:mm:ss").format("h:mm A") || ""
                : el[ele.id] || "";
          } else {
            list[`${ele.name} Requirement Number`] = el[ele.id[1]];
            list[`${ele.name} Fulfilled Number`] = el[ele.id[0]];
          }
        });

        extractedData.push(list);
      }
    });
    return extractedData;
  };

  //Function to call export data
  const createAllRecordExcel = async (selectedColumns) => {
    let excelExported = `&export_excel=1`;
    let mainUrl = driveTab.workerDashboard
      ? `${urlLinks.baseUrl}${urlLinks.driveListing.mainInvigilatorListing}?${excelExported}${filterUrl}`
      : `${urlLinks.baseUrl}${urlLinks.driveListing.mainListing}?${excelExported}${filterUrl}`;

    let excelData = await Axios.get(mainUrl)
      .then(async (response) => {
        if (response) {
          if (openBulkUpdateModal) {
            return response.data.response;
          } else {
            return await makeDriveExportData(
              response.data.response,
              selectedColumns
            );
          }
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
    return excelData;
  };

  //Function to call main listing.
  const getExternalWorkerList = async (rows, pageNo) => {
    setAllRecord([]);
    if (localStorage.getItem("roleType")) {
      setLoader(true);

      let mainUrl = driveTab.workerDashboard
        ? `${urlLinks.baseUrl}${
            urlLinks.driveListing.mainInvigilatorListing
          }?page_no=${pageNo - 1}&rows=${rows}${filterUrl}`
        : `${urlLinks.baseUrl}${urlLinks.driveListing.mainListing}?page_no=${
            pageNo - 1
          }&rows=${rows}${filterUrl}`;

      try {
        const response = await Axios.get(mainUrl);
        if (response) {
          setTotalRows(response.data.response?.totalElements);
          setTotalPages(response.data.response?.totalPages);
          if (currentPage > response.data.response?.totalPages) {
            setCurrentPage(1);
          }
          setData(response.data.response ? response.data.response.content : []);
        }
        setLoader(false);
      } catch (error) {
        if (Axios.isCancel(error)) {
          console.log("Request canceled:", error.message);
        } else {
          console.error("Error:", error);
          setLoader(false);
        }
      }
    }
  };

  const handlePageChange = (page) => {
    getExternalWorkerList(perPage, page);
    setCurrentPage(page);
  };

  const handlePerRowsChange = (newPerPage) => {
    getExternalWorkerList(newPerPage, 1);
    setPerPage(newPerPage);
    setCurrentPage(1);
  };

  const makeProjectList = (data) => {
    let list = [];
    data.map((el) => {
      list.push({
        id: el.projects_id,
        name: el.project_name,
      });
    });
    return list;
  };

  const handleActions = (event, type) => {
    // actions validations
    if (event.target.value === "updateData") {
      setOpenBulkUpdateModal(true);
    } else if (event.target.value === "addShiftData") {
      setAddShiftModal(true);
    }
  };

  const handleTab = (event, type) => {
    // RESET filters except search bar
    resetFiltersWithoutSearch("resetSearch");

    if (type === "1") {
      setDriveTab({
        ...driveTab,
        driveDashboard: true,
        workerDashboard: false,
      });
      setCurrentPage(1);
    } else if (type === "2") {
      setDriveTab({
        ...driveTab,
        driveDashboard: false,
        workerDashboard: true,
      });
      setCurrentPage(1);
    }
  };

  const handleOpenExportModal = () => {
    setExportModal(true);
  };

  // Function to open a specific user data
  const handleUserModal = async (event, row, type) => {
    switch (type) {
      case "nameHistory": {
        setModalLoader(true);
        setOpenNameHistoryModal(true);
        setHistoryColumns([
          {
            key: "zone",
            label: "Zone",
          },
          {
            key: "examDate",
            label: "Test date",
          },
          {
            key: "city",
            label: "Test city",
          },
          {
            key: "venueName",
            label: "Center",
          },
          {
            key: "shift",
            label: "Shifts",
          },
        ]);
        await Axios.get(
          `${urlLinks.baseUrl}${urlLinks.driveListing.getInvigilatorHistory}${row.contactNumber}`
        )
          .then((res) => {
            setHistoryList(res.data.response);
            setModalLoader(false);
          })
          .catch((err) => {
            console.log("err", err);
            showToast(
              "error",
              "Error",
              "Something went wrong, please try after sometime."
            );
          });
        return;
      }
      default:
        return;
    }
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    toggleAllRowsSelected,
    setSortBy,
    state: { selectedRowIds, sortBy },
  } = useTable(
    {
      columns,
      data,
      initialState: {},
      updateMyData,
      showToast,
      setLoader,
      filters,
      setFilters,
      // manualSortBy: true,
      defaultCanSort: false,
    },
    useFilters,
    useSortBy,
    useRowSelect
  );

  const tableDrive = (
    <div className={`tableContainer ${styles.tableContainer}`}>
      <table {...getTableProps()}>
        <thead className="thead">
          {headerGroups.map((headerGroup, rowIndex) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={rowIndex}>
              {headerGroup.headers.map((column, columnIndex) => {
                return (
                  <th
                    {...column.getHeaderProps()}
                    key={columnIndex}
                    className={
                      column.render("Header") === "Shift 1" ||
                      column.render("Header") === "Shift 2" ||
                      column.render("Header") === "Shift 3" ||
                      column.render("Header") === "Shift 4"
                        ? `header textAlignCenter`
                        : column.render("Header") === "Requirement No." ||
                          column.render("Header") === "Fulfilled No."
                        ? `header2 row2HeaderShift`
                        : rowIndex === 0
                        ? "header"
                        : "header2"
                    }
                  >
                    {column.id === "examDateId" ? (
                      <div className="flexDisplay justifyContentSpaceBetween">
                        <div
                          style={{ minWidth: "max-content", marginRight: "5%" }}
                        >
                          {column.render("Header")}
                        </div>
                        <span
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          className={"sortHeaderDiv"}
                          style={{
                            marginTop: "-107px",
                          }}
                        >
                          {column.isSortedDesc !== false && (
                            <IconButton
                              style={{
                                padding: "0",
                                marginTop: "2px",
                              }}
                            >
                              <img src={SortUpArrow} alt="Increasing" />
                            </IconButton>
                          )}
                          {!column.isSortedDesc && (
                            <IconButton
                              style={{
                                padding: "0",
                                marginTop: "2px",
                              }}
                            >
                              <img src={SortDownArrow} alt="Decreasing" />
                            </IconButton>
                          )}
                        </span>
                      </div>
                    ) : (
                      <span
                        style={{
                          paddingRight:
                            column.render("Header") === "Exam Date"
                              ? "16px"
                              : "",
                        }}
                      >
                        {column.render("Header")}
                      </span>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        {
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={i}>
                  {row.cells.map((cell, j) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        key={j}
                        className={`${"cell"}`}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        }
      </table>
    </div>
  );

  const tableWorker = (
    <div className={`tableContainer ${styles.tableContainer}`}>
      <table {...getTableProps()}>
        <thead className="thead">
          {headerGroups.map((headerGroup, rowIndex) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, columnIndex) => (
                <th
                  {...column.getHeaderProps()}
                  className={
                    column.render("Header") === "OkayGo ID"
                      ? "zIndex1080 stickCheckbox2 borderCheckbox header"
                      : column.render("Header") === "Name"
                      ? "header zIndex1080 stickInvigilatorName borderCheckbox"
                      : "header"
                  }
                >
                  <span>{column.render("Header")}</span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell, j) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        className={`${
                          cell.column.Header === "OkayGo ID"
                            ? "stickCheckbox2 borderCheckbox zIndex540"
                            : cell.column.Header === "Name"
                            ? "stickInvigilatorName borderCheckbox zIndex540"
                            : "cell"
                        }`}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        }
      </table>
    </div>
  );

  /* Useffects */

  useEffect(() => {
    setAllRecordLoading(false);
  }, [allRecord]);

  useEffect(() => {
    if (
      filters.aadharValidation !== "" ||
      filters.city !== "" ||
      filters.examCity !== "" ||
      filters.examDate !== null ||
      filters.examName !== "" ||
      filters.month !== "" ||
      filters.nameAgency !== "" ||
      filters.panValidation !== "" ||
      filters.role !== "" ||
      filters.roleType !== "" ||
      filters.state !== "" ||
      filters.tenthValidation !== "" ||
      filters.twelfthValidation !== "" ||
      filters.venueName !== "" ||
      filters.zone !== ""
    ) {
      setIsFilter(true);
    } else {
      setIsFilter(false);
    }
  }, [filterUrl]);

  useEffect(() => {
    let searchByVar = RemoveExtraSpaces(searchBy);
    let count = searchByVar.split(/[ ,]+/).length;
    if (count > 100) {
      setSearchError(true);
    } else {
      setSearchError(false);
    }
  }, [searchBy]);

  useEffect(() => {
    ApplyFilters(filters);
  }, [searchByAuto, selectedOption]);

  useEffect(() => {
    getExternalWorkerList(perPage, 1);
    setCurrentPage(1);
  }, [filterUrl, localStorage.getItem("roleType"), selectedOption, driveTab]);

  useEffect(() => {
    setDriveTab({
      driveDashboard: true,
      workerDashboard: false,
    });

    // API for Project List
    Axios.get(
      `${UrlLinks.baseUrl}${UrlLinks.projectListing.mainListing}?category=4`
    )
      .then((response) => {
        setProjectList(makeProjectList(response.data.response));
      })
      .catch((err) => {
        showToast("error", "Could not able to fetch job listing");
      });
  }, []);

  const loaderBar = (
    <div style={{ margin: "130px auto" }}>
      <ScaleLoader height={70} width={10} color={"#1c75bc"} />
    </div>
  );

  return (
    <Styles>
      <Grid container className="padding2percent">
        <Grid item xs={12}>
          <Button
            onClick={(event) => {
              if (driveTab.workerDashboard === true) {
                handleTab(event, "1");
              }
            }}
            className={`statusDriveButtons marginRight20 ${
              driveTab.driveDashboard ? "blueColorBorder" : "greyColorBorder"
            }`}
          >
            Drive Dashboard
          </Button>
          <Button
            onClick={(event) => {
              if (driveTab.driveDashboard === true) {
                handleTab(event, "2");
              }
            }}
            className={`statusDriveButtons marginRight20 ${
              driveTab.workerDashboard ? "blueColorBorder" : "greyColorBorder"
            }`}
          >
            Worker Dashboard
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
          className="flexDisplay marginBottom8 marginTop16 justifyContentSpaceBetween"
        >
          <JobFilter
            uniqueKey="driveProject"
            jobFilterOption={""}
            setJobFilterOption={null}
            jobList={projectList}
            disabled={false}
            filters={filters}
            setFilters={setFilters}
            id="projectId"
            statusId="jobStatus"
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            jobFilterOptionList={[]}
            placeholder="Type or search the jobs"
          />
          <Button
            variant="outlined"
            className={`dataButtonOutline2 ${styles.filterButton}`}
            onClick={() => setOpenFilterDrawer(true)}
          >
            {!isFilter ? (
              <img src={filterIcon} alt="filterIcon" />
            ) : (
              <img src={FilterIconMarked} alt="filterIcon" />
            )}
            Filters
          </Button>
        </Grid>
        <Grid item xs={12} className="flexDisplay">
          <Grid
            item
            xs={6}
            className="flexDisplay padding0 paddingTop8 justifySpaceBetween"
          >
            <SearchBar
              uniqueId="driveConfig"
              searchBy={searchBy}
              setSearchBy={setSearchBy}
              searchError={searchError}
              filters={filters}
              setFilters={setFilters}
              handleSearch={handleSearch}
              handleSearchClose={handleSearchClose}
              ApplyFilters={ApplyFilters}
              placeholder={`${
                driveTab.workerDashboard
                  ? "Search by Contact Number, OkayGo ID & Client Code"
                  : "Search by Client Code"
              }`}
              buttonName="Search"
              searchInstructions="Use comma or space as delimiter to search multiple values accross attributes. Max limit 100."
              warningMessage=" Max limit reached."
            />
          </Grid>
          <Grid item xs={6} className={`${styles.itemRowReverse} flexDisplay`}>
            <ActionsDropdown
              uniqueKey="driveAction"
              actions={actions}
              handleActions={handleActions}
              actionList={actionList}
              isRowSelected={selectedOption.id < 0}
              dropdownName="Actions"
            />
            <div style={{ marginRight: "16px" }}>
              <ExportButton
                uniqueKey="driveConfig"
                allRecordLoading={allRecordLoading}
                filename="Attendance Management.csv"
                buttonName="Export Data"
                loadingMessage="Please wait ... it might take few minutes."
                downloadButton="Download now"
                handleOpenExportModal={handleOpenExportModal}
                exportDrive={selectedOption.id < 0 || data.length === 0}
              />
            </div>
          </Grid>
        </Grid>
        {loader ? (
          loaderBar
        ) : data.length === 0 || selectedOption.id < 0 ? (
          <div className={styles.noRecord}>
            <img src={NoRecord} alt="noRecord" />
            <span>No records found</span>
          </div>
        ) : (
          <Grid item xs={12} className="paddingTop16">
            {driveTab.workerDashboard ? tableWorker : tableDrive}
            <Pagination
              rowsperpage={perPage}
              pageNo={currentPage}
              totalElements={totalRows}
              totalPages={totalPages}
              handlePageChange={handlePageChange}
              handlePerRowsChange={handlePerRowsChange}
            />
          </Grid>
        )}
        {openFilterDrawer && (
          <Drawer
            anchor="right"
            open={openFilterDrawer}
            onClose={() => {
              setCityId(multipleCityName);
              setDriveRole(multipleDriveRole);
              setExamCityId(multipleExamCity);
              setInvigilatorRole(multipleInvigilatorRole);
              setOpenFilterDrawer(false);
            }}
          >
            <FilterDrawer
              handleClose={() => setOpenFilterDrawer(false)}
              filters={filters}
              setFilters={setFilters}
              ApplyFilters={ApplyFilters}
              resetFilters={resetFiltersWithoutSearch}
              examCityId={examCityId}
              setExamCityId={setExamCityId}
              cityId={cityId}
              setCityId={setCityId}
              driveRole={driveRole}
              setDriveRole={setDriveRole}
              invigilatorRole={invigilatorRole}
              setInvigilatorRole={setInvigilatorRole}
            ></FilterDrawer>
          </Drawer>
        )}
        {exportModal && (
          <ExportModal
            openCasperOnboardingModal={exportModal}
            setOpenCasperOnboardingModal={setExportModal}
            statusValues={
              driveTab.workerDashboard ? workerColumnsList : driveColumnsList
            }
            showToast={showToast}
            updateMyData={updateMyData}
            createAllRecordExcel={createAllRecordExcel}
            title="Select Columns for Export CSV file"
            uniqueKey="driveExport"
            buttonName="Export"
          />
        )}
        {openBulkUpdateModal && (
          <BulkUpdateModal
            openCasperOnboardingModal={openBulkUpdateModal}
            setOpenCasperOnboardingModal={setOpenBulkUpdateModal}
            showToast={showToast}
            updateMyData={updateMyData}
            createAllRecordExcel={createAllRecordExcel}
            title="Update data"
            uniqueKey="driveBulkUpdate"
            url={
              driveTab.workerDashboard
                ? `${UrlLinks.baseUrl}${
                    UrlLinks.driveListing.saveInvigilatorData
                  }?insertedBy=${localStorage.getItem("userID")}`
                : `${UrlLinks.baseUrl}${UrlLinks.driveListing.saveDriveData}${
                    selectedOption.id
                  }&insertedBy=${localStorage.getItem("userID")}`
            }
            fileName={
              driveTab.workerDashboard
                ? "Invalid invigilator data"
                : "Invalid drive data"
            }
            templateButton="Download File Template"
          />
        )}
        {addShiftModal && (
          <OnboardingModal
            openOnboardingModal={addShiftModal}
            setOpenOnboardingModal={setAddShiftModal}
            showToast={showToast}
            updateMyData={updateMyData}
            title="Add Shift Data"
            uniqueKey="addShift"
            templateButton="Download File Template"
            url={`${UrlLinks.baseUrl}${
              UrlLinks.driveListing.saveShiftUploadData
            }?insertedBy=${localStorage.getItem("userID")}`}
            fileName="Invalid shift data"
          />
        )}
        {openNameHistoryModal && (
          <HistoryModal
            uniqueKey="driveHistory"
            open={openNameHistoryModal}
            setOpen={setOpenNameHistoryModal}
            title={`History`}
            historyList={historyList}
            historyColumns={historyColumns}
          />
        )}
      </Grid>
    </Styles>
  );
};

export default DriveDatabase;
