import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Axios from "axios";
import document from "../document.svg";
import ResponseModal from "./ResponseModal";
import Typography from "@material-ui/core/Typography";
import * as moment from "moment";
import { Grid } from "@material-ui/core";
import Styles from "../index.module.scss";
import UrlLinks from "../../../UrlLinks";
import Download from "../Group 3238.svg";
import { ScaleLoader } from "react-spinners";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    borderBottom: "unset",
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function CustomizedDialogs(props) {
  const {
    openSwiggyOnboardingModal,
    setOpenSwiggyOnboardingModal,
    showToast,
    updateMyData,
  } = props;
  const [excel, setExcel] = useState(null);
  const [openResponseModal, setOpenResponseModal] = useState(false);
  const [name, setName] = useState("");
  const [swiggyLoading, setSwiggyLoading] = useState(false);
  const [excelError, setExcelError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [invalidData, setInvalidData] = useState();
  const [fileLink, setFileLink] = useState(null);
  const [responseData, setResponseData] = useState();

  const handleClose = () => {
    setOpenSwiggyOnboardingModal(false);
  };

  const handleUpload = (event) => {
    const fileName = event.target.files[0].name.split(".").slice(-1)[0];
    if (fileName.toLowerCase() === "xlsx" || fileName.toLowerCase() === "xls") {
      setExcel(event.target.files[0]);
      setName(event.target.files[0].name);
      setExcelError(false);
    } else {
      setExcelError(true);
      setExcel(null);
      setName("");
    }
    setResponseData();
  };

  let uploadSwiggyIdExcel =
    "https://www.okaygo.in/upload_swiggy_id_template.xlsx";
  const createInvalidData = (records) => {
    if (records.length > 0) {
      let list = [];
      records.map((record) => {
        list.push({
          OkayGoId: record.okaygoId || "",
          SwiggyId: record.swiggyId || "",
          SwiggyJoiningDate:
            moment(record.swiggyJoiningDate).format("DD-MM-YYYY") || "",
          "Reason for failure": record.reasonOfFailure || "",
        });
        return true;
      });
      return list.length > 0 ? list : null;
    }
  };

  const handleExcel = () => {
    setIsLoading(true);
    let formData = new FormData();
    formData.append("file", excel);

    Axios.post(
      `${UrlLinks.baseUrl}${UrlLinks.workerUrls.swiggyIds}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
      .then((response) => {
        if (response.data.response) {
          setInvalidData(
            createInvalidData(response.data.response.invalidExcel)
          );
          setResponseData({
            rejected: response.data.response.rejected,
            uploaded: response.data.response.uploaded,
            total:
              response.data.response.rejected + response.data.response.uploaded,
          });
          updateMyData();
          setIsLoading(false);
        } else {
          setResponseData([]);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        showToast("error", error.message);
      });
  };

  useEffect(() => {
    if (fileLink === "") {
      setSwiggyLoading(false);
    } else if (fileLink !== null) {
      setSwiggyLoading(false);
    }
  }, [fileLink]);

  const loader = (
    <div style={{ margin: "130px auto", width: "fit-content" }}>
      <ScaleLoader height={70} width={10} color={"#1c75bc"} />
    </div>
  );

  useEffect(() => {
    if (responseData) {
      setOpenResponseModal(true);
    }
  }, [responseData]);

  let dialogData = (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={openSwiggyOnboardingModal}
      className={Styles.onboardingDialog}
      classes={{ paper: Styles.paper }}
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        <span>Upload Swiggy IDs</span>
        <a
          href={uploadSwiggyIdExcel}
          target="_blank"
          download
          className={Styles.downloadTemplate}
        >
          <span>Download File Template</span>
          <img src={Download} alt="download" />
        </a>
      </DialogTitle>
      <DialogContent dividers>
        {swiggyLoading ? (
          loader
        ) : (
          <Grid container>
            <Grid item xs={12} className="padding0">
              <input
                style={{ display: "none" }}
                id="contained-button-file"
                type="file"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                onChange={handleUpload}
              />
              <label htmlFor="contained-button-file">
                <Button
                  variant="outlined"
                  className={Styles.uploadButton}
                  component="span"
                  startIcon={<img src={document} alt={"upload"} />}
                >
                  Browse File
                </Button>
              </label>
              {name && (
                <span className={Styles.fileName}>
                  <span>File name: </span>
                  {`${name}`}
                </span>
              )}
            </Grid>
            <Grid item xs={12} className="padding0">
              <span className={`fontSize13 ${excelError ? "redColor" : ""}`}>
                only .xls and .xlsx are allowed
              </span>
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        {isLoading ? (
          <span>Please wait... it may take few minutes</span>
        ) : (
          <Button
            variant="contained"
            onClick={handleExcel}
            className={`${!excel ? "disabledButton" : "blueButtonContained"}`}
            disabled={!excel}
          >
            Upload
          </Button>
        )}
      </DialogActions>
      {openResponseModal && (
        <ResponseModal
          openResponseModal={openResponseModal}
          setOpenResponseModal={setOpenResponseModal}
          responseData={responseData}
          updateMyData={updateMyData}
          setOpenOnboardingModal={setOpenSwiggyOnboardingModal}
          showToast={showToast}
          invalidData={invalidData}
          type="OnboardingSwiggy"
        />
      )}
    </Dialog>
  );

  return dialogData;
}
