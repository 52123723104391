import React, { useContext, useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { AuthContext } from '../../../services/AuthProvider';
import Axios from 'axios';
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import Styles from '../index.module.scss'
import UrlLinks from "../../../UrlLinks";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    color: "#455a64"
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    borderBottom: "unset",

  }
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    justifyContent: "center",
    paddingBottom: 30
  }
}))(MuiDialogActions);

export default function CustomizedDialogs(props) {
  const {openForgotPasswordModal, setOpenForgotPasswordModal } = props
  const [email, setEmail] = useState('');
  const [otpSent, setOtpSent] = useState(false)
  const { forgotPassword} = useContext(AuthContext);
  const [unknownEmailError, setUnknownEmailError] = useState("")
  const handleEmail =(event) =>{
    setEmail(event.target.value)
    setUnknownEmailError(false)
  }
  const handleClose = () => {
    setOpenForgotPasswordModal(false);
  };
  const handleSubmit = async () =>{
    Axios.post(`${UrlLinks.baseUrl}${UrlLinks.checkAdminUserName}`, {user_name:email})
      .then(async () =>{
        setUnknownEmailError(false);
        await forgotPassword(email, setOtpSent , true)
        

      })
      .catch(err =>{

        setUnknownEmailError(true)
      });
  }
  useEffect(() => {
    if(otpSent){
      window.open(`/resetpassword/${email}`, "_self");
    }
  }, [otpSent])

  let dialogData=
        <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={openForgotPasswordModal}
            className={Styles.onboardingDialog}
            classes={{paper: Styles.paperForgotPassword}}
        >
            <DialogTitle id="customized-dialog-title" onClose={handleClose} >
              Forgot Password
            </DialogTitle>
            <DialogContent dividers>
              <Grid item xs={12} className={Styles.forgotPasswordContainer}>
                <span className={Styles.heading}>Enter your Username</span>
                <input
                    id="emailId"
                    placeholder="Username"
                    value={email}
                    onChange={handleEmail}
                />
                {unknownEmailError && <div>
                    <span style={{color: "red"}}>Username does not exist.</span>
                  </div>
                }
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" onClick={handleSubmit} className={`${!email?"disabledButton":"blueButtonContained"}`} disabled={!email}>
                Submit
              </Button>
            </DialogActions>
        </Dialog>
  
  

  return dialogData; 
  
}
