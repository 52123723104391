import JSZipUtils from "jszip-utils";

export const numberFormat = (value) => {
  return new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }).format(value);
};

export const FirstUpperCase = (str) => {
  let word = str;
  if (word.length > 1) {
    let sub = word.substring(0, 1).toUpperCase();
    let remaining = word.substring(1, word.length);
    return sub + remaining;
  }
};

export const urlToPromise = (url) => {
  return new Promise(function (resolve, reject) {
    JSZipUtils.getBinaryContent(url, function (err, data) {
      if (err) {
        return reject(err);
      } else {
        resolve(data);
      }
    });
  });
};

export const copyToClipboard = (text) => {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      alert("Copied to clipboard")
    })
    .catch((error) => console.error("Failed to copy:", error));
};