import React from 'react'
import EditableDropdownSubComponent from './EditableDropdownSubComponent'
const EditableDropdown =( props) =>{
    const {row, column, updateMyData, showToast, setLoading}= props
    console.log(row.original)
    // console.log(row.original[column.id])
    const dropdown= 
        <EditableDropdownSubComponent 
            row={row.original}
            columnId={column.id}
            // disable={row.original.disable}
            // jobId={jobId}
            showToast={showToast}
            initialValue={row.original.selectionStatus}
            // initialValue={row.original[column.id]?row.original[column.id]:column.id}
            updateMyData={updateMyData}
            setLoading={setLoading}
            // interviewAddress={interviewAddress}
        />
    return (
        dropdown
    )
}

export default EditableDropdown;