import * as actionTypes from "../actionTypes";
import { setLoader } from "../Config/Users/users";

export const setCurId = (
  multipleExamCity,
  multipleCityName,
  multipleDriveRole,
  multipleInvigilatorRole
) => {
  return (dispatch) => {
    setLoader(true);
    dispatch({
      type: actionTypes.CUR_ID,
      multipleExamCity: multipleExamCity,
      multipleDriveRole: multipleDriveRole,
      multipleCityName: multipleCityName,
      multipleInvigilatorRole: multipleInvigilatorRole,
    });
    setLoader(false);
  };
};

export const setDriveTab = (tab) => {
  return (dispatch) => {
    setLoader(true);
    dispatch({
      type: actionTypes.DRIVE_TAB,
      driveTab: tab,
    });
    setLoader(false);
  };
};
