import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Axios from "axios";
import document from "../document.svg";
import ResponseModal from "./ResponseModal";
import * as moment from "moment";
import Typography from "@material-ui/core/Typography";
import Download from "../Group 3238.svg";
import { Grid } from "@material-ui/core";
import Styles from "../index.module.scss";
import UrlLinks from "../../../UrlLinks";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    borderBottom: "unset",
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function CustomizedDialogs(props) {
  const {
    openOffboardingModal,
    setOpenOffboardingModal,
    showToast,
    updateMyData,
  } = props;
  const [excel, setExcel] = useState(null);
  const [name, setName] = useState("");
  const [responseData, setResponseData] = useState();
  const [invalidData, setInvalidData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [fileLink, setFileLink] = useState(null);
  const [excelError, setExcelError] = useState(false);
  const [openResponseModal, setOpenResponseModal] = useState(false);
  const [closeFile, setCloseFile] = useState(true);

  const handleClose = () => {
    setOpenOffboardingModal(false);
  };

  const handleCloseFile = () => {
    setName("");
    setCloseFile(true);
  };

  const handleUpload = (event) => {
    const fileExtension = event.target.files[0].name.split(".").slice(-1)[0];
    const fileName = event.target.files[0].name;

    if (
      fileExtension.toLowerCase() === "xlsx" ||
      fileExtension.toLowerCase() === "xls"
    ) {
      setName(fileName);
      setExcel(event.target.files[0]);
      setExcelError(false);
      setCloseFile(false);
    } else {
      setExcelError(true);
      setExcel(null);
      setName("");
    }
    setResponseData();
    event.target.value = null;
  };

  useEffect(() => {
    Axios.post(`${UrlLinks.baseUrl}${UrlLinks.jobdbExcel}`, null, {
      timeout: 60 * 5 * 1000,
    }).then((response) => {
      setFileLink(response.data);
    });
  }, []);

  const createInvalidData = (records) => {
    if (records.length > 0) {
      let list = [];
      records.map((record) => {
        list.push({
          PartnerName: record.partnerName,
          "Hub Name/clustername/zone name": record.hub_name,
          "Hub area": record.hubAddress,
          "Job Role": record.jobRole,
          "No of Openings": record.noOfOpenings,
          "Shift start time": record.shiftStartTime
            ? moment(record.shiftStartTime, ["HH:mm"]).format("hh:mm A")
            : "",
          "Shift end time": record.shiftEndTime
            ? moment(record.shiftEndTime, ["HH:mm"]).format("hh:mm A")
            : "",
          "Job Closing Date": record.jobClosingDate
            ? moment(record.jobClosingDate).format("DD/MM/YYYY")
            : "",
          "Single Day Date": record.SingleDayDate
            ? moment(record.SingleDayDate).format("DD/MM/YYYY")
            : "",
          "Job Type": record.jobType
            ? record.jobType === 1
              ? "Single"
              : "Weekend"
            : "Daily",
          "Earning Per Hour": record.earningPerHour
            ? record.earningPerHour
            : "",
          "Earning Per Day": record.earning_per_day
            ? record.earning_per_day
            : "",
          "Per Packet Earning": record.per_packet_earning
            ? record.per_packet_earning
            : "",
          "Minimum Gaurantee": record.minimum_guarantee
            ? record.minimum_guarantee
            : "",
          reason: record.reason,
        });
        return true;
      });
      return list.length > 0 ? list : null;
    }
  };

  const handleExcel = () => {
    setIsLoading(true);
    let formData = new FormData();
    formData.append("file", excel);

    Axios.post(
      `${UrlLinks.baseUrl}${
        UrlLinks.jobs.jobUpload
      }?insertedBy=${localStorage.getItem("userID")}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
      .then((response) => {
        if (response.data.response) {
          setInvalidData(
            createInvalidData(response.data.response.invalidExcel)
          );
          let invalid = response.data.response.rejected;
          let uploaded = response.data.response.uploaded;
          let total = invalid + uploaded;
          const summary = {
            "Total records attempted": total,
            "Invalid record": invalid,
            "No. of workers offboarded": uploaded,
          };
          setResponseData({
            total: summary["Total records attempted"],
            uploaded: summary["No. of workers offboarded"],
            rejected: summary["Invalid record"],
          });
          updateMyData();
          setIsLoading(false);
        } else {
          setResponseData([]);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        showToast("error", error.message);
      });
  };

  useEffect(() => {
    if (responseData) {
      setOpenResponseModal(true);
    }
  }, [responseData]);

  let dialogData = (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={openOffboardingModal}
      className={Styles.onboardingDialog}
      classes={{ paper: Styles.paper }}
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        <span>Post a Job</span>
        <a
          href={"/new_job_upload_template_updated_June_2023.xlsx"}
          target="_blank"
          download
          className={Styles.downloadTemplate}
          style={{ marginRight: "20px" }}
        >
          <span
            style={{
              marginRight: "10px",
              marginLeft: "30px",
              color: "#1c75bc",
              textDecoration: "underline",
            }}
          >
            Download File Template
          </span>
          <img src={Download} style={{ marginRight: "30px" }} alt="download" />
        </a>
      </DialogTitle>
      <DialogContent dividers>
        <Grid item xs className="padding0">
          <input
            style={{ display: "none" }}
            id="contained-button-file"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            type="file"
            onChange={handleUpload}
          />
          <label htmlFor="contained-button-file">
            <Button
              variant="outlined"
              className={Styles.uploadButton}
              component="span"
              startIcon={<img src={document} alt={"upload"} />}
            >
              Browse File
            </Button>
          </label>
        </Grid>
        <Grid item xs={12} className="padding0">
          <span className={`fontSize13 ${excelError ? "redColor" : ""}`}>
            only .xls and .xlsx are allowed
          </span>
        </Grid>
        <Grid item xs={12} className="padding0" style={{ marginTop: "10px" }}>
          {name && (
            <span className={Styles.fileName}>
              {name.length > 70 ? `${name.substring(0, 69)}...` : `${name}`}
              <span>
                <IconButton
                  aria-label="close"
                  style={{ color: "white", paddingRight: "0" }}
                  onClick={handleCloseFile}
                >
                  <CloseIcon />
                </IconButton>
              </span>
            </span>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        {isLoading ? (
          <span>Please wait... it may take few minutes</span>
        ) : (
          <Button
            variant="contained"
            onClick={handleExcel}
            className={`${!excel ? "disabledButton" : "blueButtonContained"}`}
            disabled={!excel}
          >
            Upload
          </Button>
        )}
      </DialogActions>
      {openResponseModal && (
        <ResponseModal
          openResponseModal={openResponseModal}
          setOpenResponseModal={setOpenResponseModal}
          responseData={responseData}
          setOpenOnboardingModal={setOpenOffboardingModal}
          showToast={showToast}
          invalidData={invalidData}
          type="Offboarding"
        />
      )}
    </Dialog>
  );

  return dialogData;
}
