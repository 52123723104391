import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { Button } from '@material-ui/core';
import { useState } from 'react';

function getModalStyle() {
  const top = 50 ;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}


const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function SimpleModal(props) {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const {open, setOpen, closeBulkJob} = props;
  const [disableYesButton,setDisableYesButton] = useState(false)

  const handleClose = () => {
    setOpen(false);
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <h2 id="simple-modal-title">Are you sure you want to close the selected jobs?</h2>
      <div align="right" > 
        <Button disabled={disableYesButton} variant="outlined"  component="span" style={{  color:'#1c75bc',borderColor: '#1c75bc',marginTop: '10px' ,marginRight: '10px'}} onClick={()=>{closeBulkJob('closeJob')}}>YES</Button> 
        <Button  variant="outlined"  component="span" style={{  color:'#1c75bc',borderColor: '#1c75bc',marginTop: '10px' }} onClick={()=>{handleClose()}} >No </Button>
      </div> 
    </div>
  );
  
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
}