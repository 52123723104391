import React, { useState, useEffect, useMemo, useContext } from "react";
import styled from "styled-components";
import { Grid, Checkbox, IconButton } from "@material-ui/core";
import { useTable, useRowSelect, useSortBy, useFilters } from "react-table";
import urlLinks from "../../UrlLinks";
import Axios from "axios";
import Pagination from "../../Utils/Pagination";
import { makeStyles } from "@material-ui/core/styles";
import * as moment from "moment";
import { AuthContext } from "../../services/AuthProvider";
import NoRecord from "./Assets/noRecord.svg";
import styles from "./index.module.scss";
import ScaleLoader from "react-spinners/ScaleLoader";
import "./indeterminateCheckbox.css";
import SortUpArrow from "./Assets/Polygon1.svg";
import SortDownArrow from "./Assets/Polygon2.svg";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../store/actions/index";

const Styles = styled.div`
  padding: 1rem;
  .tableContainer {
    overflow-x: scroll;
    border: 1px solid #cfcfcf;
    max-height: 74vh;
  }
  .tableContainer::-webkit-scrollbar {
    width: 0.75em;
  }

  .tableContainer::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  .tableContainer::-webkit-scrollbar-thumb {
    background-color: darkgrey;
  }
  table {
    border-spacing: 0;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    th {
      :last-child {
        border-left: 0;
      }
      background-color: #1c75bc !important;
      color: #ffffff;
    }
    th,
    td {
      padding: 5px 15px;
    }

    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid #cfcfcf !important;
      border-right: 1px solid #cfcfcf !important;
      background-color: white;
      nth-last-child(2) {
        border-right: 0;
      }
      :last-child {
        border-right: 0;
      }

      input {
        font-size: 1rem;
        padding: 0;
        margin: 0;
        border: 0;
      }
    }
  }

  .pagination {
    padding: 0.5rem;
  }
`;

const CampaignTracker = (props) => {
  /** accessing states from redux store **/

  const { users } = useSelector((state) => state);
  const { loader, currentPage, perPage, totalRows, totalPages, isFilter } =
    users;

  /** useDispatch is used to update states to redux store **/

  const dispatch = useDispatch();
  const {
    setLoader,
    setCurrentPage,
    setPerPage,
    setTotalRows,
    setTotalPages,
    setIsFilter,
  } = bindActionCreators(actionCreators, dispatch);

  /** other states **/

  const [data, setData] = useState([]);
  const { showToast } = useContext(AuthContext);
  const [filterUrl, setFilterUrl] = useState("");
  const [filters, setFilters] = useState({});

  //columns list
  const columns = useMemo(
    () => [
      {
        Header: "S. No.",
        accessor: "serialNumber",
        // defaultCanFilter: false,
        // defaultCanSort: false,
      },
      {
        Header: "Campaign Name",
        accessor: "campaign",
        defaultCanFilter: false,
        defaultCanSort: false,
        canSort: true,
      },
      {
        Header: "Job Link",
        accessor: "deeplink",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Date of Sharing Link",
        accessor: "insertedOn",
        Cell: (row) => {
          return (
            <div style={{ width: "min-content" }}>
              {row.row.original.insertedOn
                ? moment(row.row.original.insertedOn).format(
                    "DD/MM/YYYY hh:mm A"
                  )
                : "-"}
            </div>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Medium of Communication",
        accessor: "channel",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Link Shared",
        accessor: "userCount",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Link Clicked",
        accessor: "totalClick",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Total Installed",
        accessor: "totalInstall",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Total Open",
        accessor: "totalOpen",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Application",
        accessor: "application",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Additional Tags",
        accessor: "tags",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
    ],
    [filterUrl, filters, perPage, currentPage]
  );

  //Function to create All record export data
  const updateMyData = () => {
    getTableListing(perPage, currentPage);
  };

  //Function to call main listing.
  const getTableListing = (rows, pageNo) => {
    setLoader(true);
    let requestedBy = "";
    if (localStorage.getItem("roleType")) {
      Axios.get(
        `${urlLinks.baseUrl}${urlLinks.jobs.jobCampaignStats}?${filterUrl}`
      )
        .then((response) => {
          if (response) {
            let contentData = response.data.response;
            contentData.forEach((item, index) => {
              item.serialNumber = index + 1;
            });
            setTotalRows(response.data.response.totalElements);
            setTotalPages(response.data.response.totalPages);
            if (currentPage > response.data.response.totalPages)
              setCurrentPage(1);
            setData(contentData);
            setLoader(false);
          }
        })
        .catch((error) => {
          setLoader(false);
        });
    }
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    isAllRowsSelected,
    state: { selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [{ id: "serialNumber", desc: false }], // Specify initial sorting
      },
      updateMyData,
      showToast,
      setLoader,
      filters,
      setFilters,
      //   manualSortBy: true,
      defaultCanSort: false,
    },
    useFilters,
    useSortBy,
    useRowSelect
  );

  const table = (
    <div className={`tableContainer ${styles.tableContainer}`}>
      <table {...getTableProps()}>
        <thead className="thead">
          {headerGroups.map((headerGroup, index) => (
            <tr
              key={index}
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column, id) => {
                return (
                  <th
                    key={`${index}_${id}`}
                    className={
                      column.render("Header") === "S. No."
                        ? "stickSNo zIndex1080 header"
                        : "header"
                    }
                  >
                    <div className="sortHeader">
                      <div
                        style={{ minWidth: "max-content", marginRight: "5%" }}
                      >
                        {column.render("Header")}
                      </div>
                      {column.render("Header") === "S. No." ||
                      column.render("Header") === "Campaign Name" ||
                      column.render("Header") === "Job Link" ||
                      column.render("Header") === "Medium of Communication" ||
                      column.render("Header") === "Additional Tags" ? (
                        ""
                      ) : (
                        <span
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          className={"sortHeaderDiv"}
                        >
                          {column.isSortedDesc !== false && (
                            <IconButton
                              style={{
                                padding: "0",
                                marginBottom: "2px",
                              }}
                            >
                              <img src={SortUpArrow} alt="Increasing" />
                            </IconButton>
                          )}
                          {!column.isSortedDesc && (
                            <IconButton
                              style={{
                                padding: "0",
                                marginTop: "2px",
                              }}
                            >
                              <img src={SortDownArrow} alt="Decreasing" />
                            </IconButton>
                          )}
                        </span>
                      )}
                    </div>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        {rows.length === 0 ? (
          ""
        ) : (
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} className="row">
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        className={
                          cell.column.Header === "S. No."
                            ? "stickSNo zIndex540"
                            : cell.column.Header === "Name"
                            ? "stickWorkerNameMatchNew zIndex540"
                            : "cell"
                        }
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        )}
      </table>
      {rows.length === 0 ? (
        <div className={styles.noRecord}>
          <img src={NoRecord} alt="noRecord" />
          <span>No records found</span>
        </div>
      ) : (
        ""
      )}
    </div>
  );

  /* Useffects */

  useEffect(() => {
    getTableListing(perPage, 1);
  }, [filterUrl, localStorage.getItem("roleType")]);

  useEffect(() => {
    if (
      (filters.projectId !== null && filters.projectId !== "") ||
      filters.facilityCity !== "" ||
      (filters.verticalId !== "" && filters.verticalId !== null) ||
      (filters.jobStatus !== "" && filters.jobStatus !== null)
    ) {
      setIsFilter(true);
    } else {
      setIsFilter(false);
    }
  }, [filters]);

  const loaderBar = (
    <div style={{ margin: "130px auto" }}>
      <ScaleLoader height={70} width={10} color={"#1c75bc"} />
    </div>
  );

  return (
    <Styles>
      <Grid container className="padding2percent">
        <Grid style={{ justifyContent: "space-between" }} container spacing={2}>
          <Grid item xs={6}>
            <span className="fontSize24 fontWeightBold">Campaign Tracker</span>
          </Grid>
        </Grid>
        {loader ? (
          loaderBar
        ) : (
          <Grid item xs={12} className="paddingTop16">
            {table}
          </Grid>
        )}
      </Grid>
    </Styles>
  );
};

export default CampaignTracker;
