import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../../store/actions/index";
import styles from "./Components.module.scss";
import Axios from "axios";
import { Button, Grid } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ScaleLoader from "react-spinners/ScaleLoader";
import AutoSelectFilter from "../../../Utils/FilterComponents/AutoSelectFilter";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import UrlLinks from "../../../UrlLinks";
import SearchSelectFilter from "../../../Utils/FilterComponents/SearchSelectFilter";
import MultipleSelectFilter from "../../../Utils/FilterComponents/MultipleSelectFilter";

const FilterDrawer = (props) => {
  const {
    handleClose,
    resetFilters,
    ApplyFilters,
    filters,
    setFilters,
    examCityId,
    setExamCityId,
    cityId,
    setCityId,
    driveRole,
    setDriveRole,
    invigilatorRole,
    setInvigilatorRole,
  } = props;
  const { users, driveDb } = useSelector((state) => state);
  const { modalLoader } = users;
  const {
    driveTab,
    multipleCityName,
    multipleExamCity,
    multipleDriveRole,
    multipleInvigilatorRole,
  } = driveDb;

  const dispatch = useDispatch();
  const { setModalLoader, setCurId } = bindActionCreators(
    actionCreators,
    dispatch
  );

  const [monthList, setMonthList] = useState([]);
  const [zoneList, setZoneList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [venueNameList, setVenueNameList] = useState([]);
  const [examNameList, setExamNameList] = useState([]);
  const [roleTypeList, setRoleTypeList] = useState([]);
  const [invigilatorZoneList, setInvigilatorZoneList] = useState([]);
  const [invigilatorStateList, setInvigilatorStateList] = useState([]);
  const [invigilatorAgencyList, setInvigilatorAgencyList] = useState([]);
  const [invigilatorRoleList, setInvigilatorRoleList] = useState([]);

  const yesNo = [
    {
      id: "Yes",
      name: "Yes",
    },
    {
      id: "No",
      name: "No",
    },
  ];

  const handleCancelFilters = () => {
    setCityId(multipleCityName);
    setDriveRole(multipleDriveRole);
    setInvigilatorRole(multipleInvigilatorRole);
    setExamCityId(multipleExamCity);
    handleClose();
  };

  const handleApplyFilters = () => {
    setCurId(examCityId, cityId, driveRole, invigilatorRole);
    ApplyFilters();
    handleClose();
  };

  const handleDateChange = (date, pos) => {
    let dateData;
    if (pos === 1) dateData = date;

    setFilters({ ...filters, examDate: dateData });
  };

  const makeMonthList = (data) => {
    let list = [];
    data.map((element, index) => {
      list.push({
        id: element,
        name: element,
      });
    });
    return list;
  };

  const makeZoneList = (data) => {
    let list = [];
    data.map((element, index) => {
      list.push({
        id: element,
        name: element,
      });
    });
    return list;
  };

  const makeStateList = (data) => {
    let list = [];
    data.map((element, index) => {
      list.push({
        id: element,
        name: element,
      });
    });
    return list;
  };

  const makeExamCityList = (data) => {
    let list = [];
    data.map((element, index) => {
      list.push({
        id: element,
        name: element,
      });
    });
    return list;
  };

  const makeVenueNameList = (data) => {
    let list = [];
    data.map((element, index) => {
      list.push({
        id: element,
        name: element,
      });
    });
    return list;
  };

  const makeExamNameList = (data) => {
    let list = [];
    data.map((element, index) => {
      list.push({
        id: element,
        name: element,
      });
    });
    return list;
  };

  const makeRoleTypeList = (data) => {
    let list = [];
    data.map((element, index) => {
      list.push({
        id: element,
        name: element,
        label: element,
      });
    });
    return list;
  };

  const makeInvigilatorZoneList = (data) => {
    let list = [];
    data.map((element, index) => {
      list.push({
        id: element,
        name: element,
      });
    });
    return list;
  };

  const makeInvigilatorStateList = (data) => {
    let list = [];
    data.map((element, index) => {
      list.push({
        id: element,
        name: element,
      });
    });
    return list;
  };

  const makeInvigilatorAgencyList = (data) => {
    let list = [];
    data.map((element, index) => {
      list.push({
        id: element,
        name: element,
      });
    });
    return list;
  };

  const makeInvigilatorRoleList = (data) => {
    let list = [];
    data.map((element, index) => {
      list.push({
        id: element,
        name: element,
        label: element,
      });
    });
    return list;
  };

  useEffect(() => {
    if (driveTab.workerDashboard) {
      // API for zone filter
      Axios.get(
        `${UrlLinks.baseUrl}${UrlLinks.driveListing.getInvigilatorFilterValues}zone`
      ).then((response) => {
        setInvigilatorZoneList(makeInvigilatorZoneList(response.data.response));
      });

      // API for state filter
      Axios.get(
        `${UrlLinks.baseUrl}${UrlLinks.driveListing.getInvigilatorFilterValues}state`
      ).then((response) => {
        setInvigilatorStateList(
          makeInvigilatorStateList(response.data.response)
        );
      });

      // API for agency name filter
      Axios.get(
        `${UrlLinks.baseUrl}${UrlLinks.driveListing.getInvigilatorFilterValues}agency_vendor_name`
      ).then((response) => {
        setInvigilatorAgencyList(
          makeInvigilatorAgencyList(response.data.response)
        );
      });

      // API for role filter
      Axios.get(
        `${UrlLinks.baseUrl}${UrlLinks.driveListing.getInvigilatorFilterValues}role`
      ).then((response) => {
        setInvigilatorRoleList(makeInvigilatorRoleList(response.data.response));
      });
    } else {
      // API for month filter
      Axios.get(
        `${UrlLinks.baseUrl}${UrlLinks.driveListing.getFilterValues}month`
      ).then((response) => {
        setMonthList(makeMonthList(response.data.response));
      });

      // API for zone filter
      Axios.get(
        `${UrlLinks.baseUrl}${UrlLinks.driveListing.getFilterValues}zone`
      ).then((response) => {
        setZoneList(makeZoneList(response.data.response));
      });

      // API for state filter
      Axios.get(
        `${UrlLinks.baseUrl}${UrlLinks.driveListing.getFilterValues}state`
      ).then((response) => {
        setStateList(makeStateList(response.data.response));
      });

      // API for venue name filter
      Axios.get(
        `${UrlLinks.baseUrl}${UrlLinks.driveListing.getFilterValues}venue_name`
      ).then((response) => {
        setVenueNameList(makeVenueNameList(response.data.response));
      });

      // API for exam name filter
      Axios.get(
        `${UrlLinks.baseUrl}${UrlLinks.driveListing.getFilterValues}exam_name`
      ).then((response) => {
        setExamNameList(makeExamNameList(response.data.response));
      });

      // API for role type filter
      Axios.get(
        `${UrlLinks.baseUrl}${UrlLinks.driveListing.getFilterValues}role_type`
      ).then((response) => {
        setRoleTypeList(makeRoleTypeList(response.data.response));
      });
    }
  }, []);

  const loaderBar = (
    <div style={{ margin: "130px auto" }}>
      <ScaleLoader height={70} width={10} color={"#1c75bc"} />
    </div>
  );

  const output = modalLoader ? (
    <Grid container style={{ width: "520px", marginTop: "20px" }}>
      {loaderBar}
    </Grid>
  ) : (
    <Grid
      container
      style={{ width: "520px", marginTop: "20px" }}
      className={`${styles.filterContainerDrawer}`}
    >
      <Grid container className={`${styles.FilterHeadingContainer}`}>
        <div>
          <Button
            onClick={handleCancelFilters}
            className={styles.filterCloseArrow}
          >
            <ArrowForwardIcon />
          </Button>
          <span className={styles.filterHeading}>Filters</span>
        </div>
        <div>
          <Button onClick={resetFilters} className={styles.resetFiltersButton}>
            Reset
          </Button>
        </div>
      </Grid>
      <div className={styles.filterHeadingDivider}></div>
      <Grid container className={styles.filtersContainer}>
        {driveTab.driveDashboard && (
          <Grid item xs={12} className={`${styles.singleFilterContainer}`}>
            <span className={styles.filterTitle}>Month</span>
            <AutoSelectFilter
              filters={filters}
              setFilters={setFilters}
              multipleFlag={false}
              id="month"
              options={monthList}
            />
          </Grid>
        )}
        <Grid item xs={12} className={`${styles.singleFilterContainer}`}>
          <span className={styles.filterTitle}>Zone</span>
          <AutoSelectFilter
            filters={filters}
            setFilters={setFilters}
            multipleFlag={false}
            id="zone"
            options={driveTab.workerDashboard ? invigilatorZoneList : zoneList}
          />
        </Grid>
        <Grid item xs={12} className={`${styles.singleFilterContainer}`}>
          <span className={styles.filterTitle}>State</span>
          <AutoSelectFilter
            filters={filters}
            setFilters={setFilters}
            multipleFlag={false}
            id="state"
            options={
              driveTab.workerDashboard ? invigilatorStateList : stateList
            }
          />
        </Grid>
        {driveTab.driveDashboard && (
          <Grid item xs={12} className={`${styles.singleFilterContainer}`}>
            <span className={styles.filterTitle}>Exam city</span>
            <SearchSelectFilter
              filters={filters}
              setFilters={setFilters}
              id="examCity"
              sourceId={examCityId}
              setSourceId={setExamCityId}
              module="driveExamCityListing"
              multiple={true}
              placeholder="Search and select"
              url={`${UrlLinks.baseUrl}${UrlLinks.workerUrls.leadCities}`}
            />
          </Grid>
        )}
        {driveTab.driveDashboard && (
          <Grid item xs={12} className={`${styles.singleFilterContainer}`}>
            <span className={styles.filterTitle}>Venue name</span>
            <AutoSelectFilter
              filters={filters}
              setFilters={setFilters}
              multipleFlag={false}
              id="venueName"
              options={venueNameList}
            />
          </Grid>
        )}
        {driveTab.driveDashboard && (
          <Grid item xs={12} className={`${styles.singleFilterContainer}`}>
            <span className={styles.filterTitle}>Exam name</span>
            <AutoSelectFilter
              filters={filters}
              setFilters={setFilters}
              multipleFlag={false}
              id="examName"
              options={examNameList}
            />
          </Grid>
        )}
        {driveTab.driveDashboard && (
          <Grid container className={`${styles.singleFilterContainer}`}>
            <Grid item xs={5}>
              <span className={styles.filterTitle}>Exam date</span>
            </Grid>
            <Grid item xs={7}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  id={`{id}To`}
                  placeholder="Choose date"
                  label="Choose date"
                  format="dd-MM-yyyy"
                  clearable
                  className="fromDate"
                  value={filters["examDate"]}
                  onChange={(date) => handleDateChange(date, 1)}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        )}
        {driveTab.driveDashboard && (
          <Grid item xs={12} className={`${styles.singleFilterContainer}`}>
            <span className={styles.filterTitle}>Role type</span>
            <MultipleSelectFilter
              filters={filters}
              setFilters={setFilters}
              id="roleType"
              options={roleTypeList ? roleTypeList : ""}
              multipleFlag={true}
              sourceId={driveRole}
              setSourceId={setDriveRole}
            />
          </Grid>
        )}
        {driveTab.workerDashboard && (
          <Grid item xs={12} className={`${styles.singleFilterContainer}`}>
            <span className={styles.filterTitle}>City name</span>
            <SearchSelectFilter
              filters={filters}
              setFilters={setFilters}
              id="city"
              sourceId={cityId}
              setSourceId={setCityId}
              module="invigilatorCityNameListing"
              multiple={true}
              placeholder="Search and select"
              url={`${UrlLinks.baseUrl}${UrlLinks.workerUrls.leadCities}`}
            />
          </Grid>
        )}
        {driveTab.workerDashboard && (
          <Grid item xs={12} className={`${styles.singleFilterContainer}`}>
            <span className={styles.filterTitle}>Name of the Agency</span>
            <AutoSelectFilter
              filters={filters}
              setFilters={setFilters}
              multipleFlag={false}
              id="nameAgency"
              options={invigilatorAgencyList}
            />
          </Grid>
        )}
        {driveTab.workerDashboard && (
          <Grid item xs={12} className={`${styles.singleFilterContainer}`}>
            <span className={styles.filterTitle}>Role</span>
            <MultipleSelectFilter
              filters={filters}
              setFilters={setFilters}
              id="role"
              options={invigilatorRoleList ? invigilatorRoleList : ""}
              multipleFlag={true}
              sourceId={invigilatorRole}
              setSourceId={setInvigilatorRole}
            />
          </Grid>
        )}
        {driveTab.workerDashboard && (
          <Grid item xs={12} className={`${styles.singleFilterContainer}`}>
            <span className={styles.filterTitle}>10th Validation</span>
            <AutoSelectFilter
              filters={filters}
              setFilters={setFilters}
              multipleFlag={false}
              id="tenthValidation"
              options={yesNo}
            />
          </Grid>
        )}
        {driveTab.workerDashboard && (
          <Grid item xs={12} className={`${styles.singleFilterContainer}`}>
            <span className={styles.filterTitle}>12th Validation</span>
            <AutoSelectFilter
              filters={filters}
              setFilters={setFilters}
              multipleFlag={false}
              id="twelfthValidation"
              options={yesNo}
            />
          </Grid>
        )}
        {driveTab.workerDashboard && (
          <Grid item xs={12} className={`${styles.singleFilterContainer}`}>
            <span className={styles.filterTitle}>PAN Validation</span>
            <AutoSelectFilter
              filters={filters}
              setFilters={setFilters}
              multipleFlag={false}
              id="panValidation"
              options={yesNo}
            />
          </Grid>
        )}
        {driveTab.workerDashboard && (
          <Grid item xs={12} className={`${styles.singleFilterContainer}`}>
            <span className={styles.filterTitle}>Aadhar Validation</span>
            <AutoSelectFilter
              filters={filters}
              setFilters={setFilters}
              multipleFlag={false}
              id="aadharValidation"
              options={yesNo}
            />
          </Grid>
        )}
      </Grid>
      <Grid container className={styles.filterButtonDividerContainer}>
        <div className={styles.filterHeadingDivider}></div>
        <Grid container style={{ marginTop: "30px" }} className="padding0">
          <div
            style={{
              paddingRight: "16px",
              paddingLeft: "300px",
            }}
          >
            <Button
              variant="outlined"
              onClick={handleCancelFilters}
              size="medium"
              style={{ color: "#1C75BC", borderColor: "#1C75BC" }}
            >
              Cancel
            </Button>
          </div>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            onClick={handleApplyFilters}
            style={{ backgroundColor: "#1C75BC" }}
          >
            Apply
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );

  return output;
};

export default FilterDrawer;
