import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { Divider, Grid, TextField, Button } from "@material-ui/core";
import Styles from "../index.module.scss";
import Autocomplete from "@material-ui/lab/Autocomplete";
import AutoSelectFilter from "../../WorkerDataV2/FilterComponents/AutoSelectFilter";
import Axios from "axios";
import UrlLinks from "../../../UrlLinks";
import qs from "querystring";
import Information_red from "../Information_red.svg";
import exclamation_mark from "../exclamation-mark.svg";
import { ScaleLoader } from "react-spinners";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(3),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    borderBottom: "unset",
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function CustomizedDialogs(props) {
  const {
    open,
    close,
    filters,
    setFilters,
    resetFilters,
    title,
    workflowId,
    showToast,
  } = props;
  const [recruiterAssigned, setRecruiterAssigned] = useState("");
  const [formDataList, setFormDataList] = useState([]);
  const [formDataId, setFormDataId] = useState([]);
  const [selectedFormFields, setSelectedFormFields] = useState([]);
  const [validationErr, setValidationErr] = useState({
    sameField: false,
    mandatoryField: false,
    noTaskField: false,
  });
  const [apiLoading, setApiLoading] = useState(true);

  const makeFormDataList = (data) => {
    let list = [];

    Object.keys(data).map((e, i) => {
      list.push({
        id: e,
        name: data[e],
      });
    });
    return list;
  };

  useEffect(() => {
    let fieldList = [];
    let selectedFormFieldsArray = [];

    Object.keys(filters).map((e) => {
      if (
        e === "field1" ||
        e === "field2" ||
        e === "field3" ||
        e === "field4" ||
        e === "field5" ||
        e === "reworkReason" ||
        e === "rejectReason" ||
        e === "qcStatus"
      ) {
        if (filters[e].id) {
          fieldList.push(filters[e].id);
          selectedFormFieldsArray.push({ [filters[e].id]: filters[e].name });
          setSelectedFormFields(selectedFormFieldsArray);
        }
      }
    });

    if (fieldList.some((val, i) => fieldList.indexOf(val) !== i)) {
      setValidationErr({
        ...validationErr,
        sameField: true,
        mandatoryField: false,
      });
    } else {
      setValidationErr({
        ...validationErr,
        sameField: false,
        mandatoryField: filters.qcStatus !== "" ? false : "",
        noTaskField:
          filters.field1 === "" &&
          filters.field2 === "" &&
          filters.field3 === "" &&
          filters.field4 === "" &&
          filters.field5 === "" &&
          filters.rejectReason === "" &&
          filters.reworkReason === ""
            ? false
            : "",
      });
    }
  }, [filters]);

  useEffect(() => {
    setApiLoading(true);

    Axios.get(
      `${UrlLinks.baseUrl}${
        UrlLinks.projectListing.formDataList
      }?workflowId=${workflowId}&userId=${localStorage.getItem("userID")}`
    )
      .then((response) => {
        setFormDataList(makeFormDataList(response.data.response));
      })
      .catch((err) => {
        console.log("err", err);
      });

    Axios.post(
      `${UrlLinks.baseUrl}${
        UrlLinks.projectListing.formDataById
      }?id=${workflowId}&userId=${localStorage.getItem("userID")}`
    )
      .then((response) => {
        setFormDataId(response.data.response.id);
        let retainedFields = response.data.response.selected_form_fields;
        let val = [];
        let rejectReason = {};
        let reworkReason = {};

        for (let i = 0; i < retainedFields.length; i++) {
          if (Object.keys(retainedFields[i])[0] === "reject_reason") {
            rejectReason = {
              id: Object.keys(retainedFields[i])[0],
              name: Object.values(retainedFields[i])[0],
            };
          } else if (Object.keys(retainedFields[i])[0] === "qcRemarks") {
            reworkReason = {
              id: Object.keys(retainedFields[i])[0],
              name: Object.values(retainedFields[i])[0],
            };
          } else {
            val.push({
              id: Object.keys(retainedFields[i])[0],
              name: Object.values(retainedFields[i])[0],
            });
          }
        }
        setFilters({
          ...filters,
          field1: val[0] && 0 !== val.length - 1 ? val[0] : "",
          field2: val[1] && 1 !== val.length - 1 ? val[1] : "",
          field3: val[2] && 2 !== val.length - 1 ? val[2] : "",
          field4: val[3] && 3 !== val.length - 1 ? val[3] : "",
          field5: val[4] && 4 !== val.length - 1 ? val[4] : "",
          reworkReason: reworkReason ? reworkReason : "",
          rejectReason: rejectReason ? rejectReason : "",
          qcStatus: val[val.length - 1] ? val[val.length - 1] : "",
        });
        setApiLoading(false);
      })
      .catch((err) => {
        console.log("err1", err);
        setApiLoading(false);
      });
  }, []);

  const handleSubmit = () => {
    if (workflowId === null || workflowId === undefined || workflowId === "") {
      close(false);
      resetFilters();
    } else if (filters.qcStatus === "") {
      setValidationErr({ ...validationErr, mandatoryField: true });
    } else if (
      filters.field1 === "" &&
      filters.field2 === "" &&
      filters.field3 === "" &&
      filters.field4 === "" &&
      filters.field5 === "" &&
      filters.rejectReason === "" &&
      filters.reworkReason === ""
    ) {
      setValidationErr({ ...validationErr, noTaskField: true });
    } else {
      setValidationErr({ ...validationErr, mandatoryField: false });
      const postData = { selected_form_fields: selectedFormFields };

      Axios.post(
        `${UrlLinks.baseUrl}${
          UrlLinks.projectListing.saveFormData
        }?workflow_id=${workflowId}&userId=${localStorage.getItem("userID")}`,
        postData
      )
        .then((response) => {
          showToast("success", response.data.response, "success1");
        })
        .catch((err) => {
          showToast("error", err);
        });

      close(false);
      resetFilters();
    }
  };

  let dialogData = (
    <Dialog
      aria-labelledby="status-update-dialog"
      open={open}
      className={Styles.reassignDialog}
      classes={{ paper: Styles.paperReassign }}
      onClose={() => {
        close(false);
        resetFilters();
      }}
    >
      <DialogTitle id="customized-dialog-title" onClose={() => close(false)}>
        {title}
      </DialogTitle>
      {workflowId === null || workflowId === undefined || workflowId === "" ? (
        <DialogContent>
          <Grid
            container
            style={{
              fontFamily: "Nunito",
              fontWeight: "500",
              fontSize: "18px",
            }}
          >
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                paddingBottom: "20px",
              }}
            >
              <img src={Information_red} alt="Warning" />
            </Grid>
            <Grid
              item
              xs={12}
              style={{ width: "300px", paddingBottom: "20px" }}
            >
              The project needs to be mapped to a process to enable Worker task
              card fields configuration.
            </Grid>
          </Grid>
        </DialogContent>
      ) : apiLoading ? (
        <div style={{ margin: "130px" }}>
          <ScaleLoader height={70} width={10} color={"#1c75bc"} />
        </div>
      ) : (
        <DialogContent style={{ paddingTop: "0px" }}>
          {validationErr.mandatoryField ? (
            <div style={{ color: "red", display: "flex", marginBottom: "5%" }}>
              <img src={exclamation_mark} style={{ marginRight: "10px" }} />
              Field is mandatory to filled
            </div>
          ) : validationErr.sameField ? (
            <div style={{ color: "red", display: "flex", marginBottom: "5%" }}>
              <img src={exclamation_mark} style={{ marginRight: "10px" }} />
              No two task fields have same field configured
            </div>
          ) : validationErr.noTaskField ? (
            <div style={{ color: "red", display: "flex", marginBottom: "5%" }}>
              <img src={exclamation_mark} style={{ marginRight: "10px" }} />
              Atleast one task field should be selected
            </div>
          ) : (
            ""
          )}
          <Grid
            container
            style={{
              fontFamily: "Roboto, Helvetica, Arial, sans-serif",
              fontSize: "18px",
            }}
          >
            <Grid
              item
              xs={12}
              style={{ width: "300px", paddingBottom: "20px" }}
            >
              <div style={{ paddingBottom: "10px" }}>Field 1</div>
              <AutoSelectFilter
                filters={filters}
                setFilters={setFilters}
                multipleFlag={false}
                id="field1"
                options={formDataList}
                title={title}
                selectedFormFields={selectedFormFields}
                setSelectedFormFields={setSelectedFormFields}
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{ width: "300px", paddingBottom: "20px" }}
            >
              <div style={{ paddingBottom: "10px" }}>Field 2</div>
              <AutoSelectFilter
                filters={filters}
                setFilters={setFilters}
                multipleFlag={false}
                id="field2"
                options={formDataList}
                title={title}
                selectedFormFields={selectedFormFields}
                setSelectedFormFields={setSelectedFormFields}
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{ width: "300px", paddingBottom: "20px" }}
            >
              <div style={{ paddingBottom: "10px" }}>Field 3</div>
              <AutoSelectFilter
                filters={filters}
                setFilters={setFilters}
                multipleFlag={false}
                id="field3"
                options={formDataList}
                title={title}
                selectedFormFields={selectedFormFields}
                setSelectedFormFields={setSelectedFormFields}
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{ width: "300px", paddingBottom: "20px" }}
            >
              <div style={{ paddingBottom: "10px" }}>Field 4</div>
              <AutoSelectFilter
                filters={filters}
                setFilters={setFilters}
                multipleFlag={false}
                id="field4"
                options={formDataList}
                title={title}
                selectedFormFields={selectedFormFields}
                setSelectedFormFields={setSelectedFormFields}
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{ width: "300px", paddingBottom: "20px" }}
            >
              <div style={{ paddingBottom: "10px" }}>Field 5</div>
              <AutoSelectFilter
                filters={filters}
                setFilters={setFilters}
                multipleFlag={false}
                id="field5"
                options={formDataList}
                title={title}
                selectedFormFields={selectedFormFields}
                setSelectedFormFields={setSelectedFormFields}
              />
            </Grid>
          </Grid>
          <Divider style={{ margin: "10px 0 20px 0" }} />
          <Grid
            container
            style={{
              fontFamily: "Roboto, Helvetica, Arial, sans-serif",
              fontSize: "18px",
            }}
          >
            <Grid
              item
              xs={12}
              style={{ width: "300px", paddingBottom: "20px" }}
            >
              <div
                style={{ paddingBottom: "10px" }}
              >
                Rework Reason
              </div>
              <AutoSelectFilter
                filters={filters}
                setFilters={setFilters}
                multipleFlag={false}
                id="reworkReason"
                options={formDataList}
                title={title}
                selectedFormFields={selectedFormFields}
                setSelectedFormFields={setSelectedFormFields}
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{ width: "300px", paddingBottom: "20px" }}
            >
              <div
                style={{ paddingBottom: "10px" }}
                // className={`${Styles.formFont} ${Styles.requiredField}`}
              >
                Reject Reason
              </div>
              <AutoSelectFilter
                filters={filters}
                setFilters={setFilters}
                multipleFlag={false}
                id="rejectReason"
                options={formDataList}
                title={title}
                selectedFormFields={selectedFormFields}
                setSelectedFormFields={setSelectedFormFields}
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{ width: "300px", paddingBottom: "20px" }}
            >
              <div
                style={{ paddingBottom: "10px" }}
                className={`${Styles.formFont} ${Styles.requiredField}`}
              >
                QC Status Field
              </div>
              <AutoSelectFilter
                filters={filters}
                setFilters={setFilters}
                multipleFlag={false}
                id="qcStatus"
                options={formDataList}
                title={title}
                selectedFormFields={selectedFormFields}
                setSelectedFormFields={setSelectedFormFields}
              />
            </Grid>
          </Grid>
        </DialogContent>
      )}
      <DialogActions className={Styles.reassignButtonContainer}>
        {workflowId === null ||
        workflowId === undefined ||
        workflowId === "" ? (
          <button onClick={handleSubmit}>Okay</button>
        ) : (
          <button onClick={handleSubmit}>Submit</button>
        )}
      </DialogActions>
    </Dialog>
  );

  return dialogData;
}
