import axios from 'axios'
import React, { useState, } from 'react'
import UrlLinks from '../../../UrlLinks'
import * as moment from 'moment'
const LastSentMsgs = (props) => {
    const { row, } = props
    const [viewMore, setViewMore] = useState(false)
    const [msgsList, setMsgsList] = useState(<></>)

    const handleViewMore = () => {
        if(viewMore===false){
            bringMoreSentSms();
        }
        setViewMore(!viewMore)
    }

    const bringMoreSentSms=()=>{
        axios.get(`${UrlLinks.baseUrl}${UrlLinks.workerUrls.reteriveLastSms}?userId=${row.original.userId}`)
        .then(response => {
          if(response.data.response.content.length>0){
            let a=null;
            let aList=[]
            let date=null
            response.data.response.content.map(e=>{
                date=e.insertedOn?(moment(e.insertedOn).format("hh:mm a, DD/MM/YYYY")):""
               a= <div style={{ fontSize: "12px", fontWeight: "400" }}>
                <b>[</b>{date}<b>]</b>
                <br />
                {e.smsMessage} <br /><br /></div>
                
            aList.push(a)
            })
            setMsgsList(aList)
          }
        })
    }
    return (
        <div>
            {!viewMore ? <div>{row.original.lastSmsMessage && <div><div style={{ fontSize: "12px", fontWeight: "400" }}>
            <b>[</b>{row.original.lastSmsTime}<b>]</b>
                <br />
                {row.original.lastSmsMessage}
                <br />
             
            </div>
            <a style={{    color: "#1c75bc",
    textDecoration: "underline",
    cursor: "pointer",}} onClick={handleViewMore}>View More</a>
            </div>}</div> :<><a style={{    color: "#1c75bc",textDecoration: "underline",cursor: "pointer"}} onClick={handleViewMore}>View Less</a> <div style={{overflowY: "auto",maxHeight: "220px"}} >   
            {msgsList}
            </div></>}
        </div>
    )
}

export default LastSentMsgs;