import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import Skeleton from "@material-ui/lab/Skeleton";
import { Grid } from "@material-ui/core";
import Axios from "axios";
import styles from "./index.module.scss";
import { dateFormat } from "../../services/CommonServices";
import ReactPanZoom from "react-image-pan-zoom-rotate";
import * as moment from "moment";
import { AuthContext } from "../../services/AuthProvider";
import UrlLinks from "../../UrlLinks";
import PennyTestingVerification from "./components/PennyTestingDrawer";

const BankDetailsList = (props) => {
  const params = useParams();
  const { userId, pennyStatus } = params;
  const { showToast } = useContext(AuthContext);
  const [currentUserId, setCurrentUserId] = useState(userId);
  const [basicPennyStatus, setpennyStatus] = useState(pennyStatus);
  const [data, setData] = useState({});
  const [showNextButton, setShowNextButton] = useState(true);
  const [submissionError, setSubmissionError] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [editChecks, setEditChecks] = useState({
    nameDisable: true,
    ifscDisable: true,
    imageDisable: true,
    numberDisable: true,
    submitDisable: true,
    editDisable: true,
    updateDisable: true,
    submitHide: true,
    editHide: true,
    mismatchHide: true,
    updateHide: true,
  });
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);

  const makeData = (response) => {
    setData({
      name: `${response.firstName ? response.firstName : ""} ${
        response.lastName ? response.lastName : ""
      }`,
      accountHolderNameFromPennyTesting:
        response.accountHolderNameFromPennyTesting
          ? response.accountHolderNameFromPennyTesting
          : "",
      pennyTestingStatus: response.pennyTestingStatus
        ? response.pennyTestingStatus
        : null,
      phoneNumber: response.phoneNumber || "",
      okaygoId: response.okaygoId || "",
      casperId: response.casperId || "",
      reason: response.reason || null,
      verifiedBy: response.verifiedBy || "",
      verifiedOn: dateFormat(response.verifiedOn),
      confirmedBy: response.confirmedBy || "",
      confirmedOn: dateFormat(response.confirmedOn),
      distortImage: response.distortImage === 1 ? true : false,
      accountHolderName: response.account_holder || "",
      firstName: response.firstName ? response.firstName : "",
      lastName: response.lastName ? response.lastName : "",
      ifscCode: response.ifsc_code || "",
      accountNumber: response.account_number || "",
      workerInput: response.account_number || "",
      adminInput: response.accountNumberByAdmin || "",
      accountProof: response.file_path
        ? `${response.file_path.replace("https://", "http://")}`
        : "",
      accountHolderNameMismatch: response.isAccHolderMatch === 1 ? false : true,
      ifscCodeMismatch: response.isIfscMatch === 1 ? false : true,
      accountNumberMismatch: response.isAccNumberMatch === 1 ? false : true,
      verification: response.verification,
      userId: response.user_id,
      tag: response.tag,
      pennyTestingDate:
        moment(response.penny_testing_date).format("DD MMM, YYYY") || "",
      verifiedByName: response.verified_by_name || "",
      lastUpdatedOn: response.lastUpdatedOn ? response.lastUpdatedOn : "",
    });
  };
  
  useEffect(() => {
    if (data.verification >= 0) {
      const temp = {
        nameDisable: false,
        ifscDisable: false,
        numberDisable: false,
        imageDisable: false,
        submitDisable: false,
        editDisable: false,
        updateDisable: false,
        submitHide: false,
        editHide: true,
      };

      switch (data.verification) {
        case 0:
          if (data.distortImage) {
            temp.nameDisable = true;
            temp.ifscDisable = true;
            temp.numberDisable = true;
            temp.submitDisable = false;
            temp.updateDisable = false;
          } else if (!data.distortImage) {
            temp.nameDisable = false;
            temp.ifscDisable = false;
            temp.numberDisable = false;
            if (data.adminInput) {
              temp.submitDisable = false;
              temp.updateDisable = false;
            } else {
              temp.submitDisable = true;
              temp.updateDisable = true;
            }
          }
          setEditChecks({
            ...editChecks,
            nameDisable: temp.nameDisable,
            ifscDisable: temp.ifscDisable,
            numberDisable: temp.numberDisable,
            imageDisable: false,
            submitDisable: temp.submitDisable,
            editDisable: true,
            updateDisable: temp.updateDisable,
            submitHide: false,
            editHide: true,
            mismatchHide: true,
            updateHide: true,
          });
          break;
        case 1:
          if (edit) {
            if (data.distortImage) {
              temp.nameDisable = true;
              temp.ifscDisable = true;
              temp.numberDisable = true;
              temp.submitDisable = false;
              temp.updateDisable = false;
            } else if (!data.distortImage) {
              temp.nameDisable = false;
              temp.ifscDisable = false;
              temp.numberDisable = false;
              if (data.adminInput) {
                temp.submitDisable = false;
                temp.updateDisable = false;
              } else {
                temp.submitDisable = true;
                temp.updateDisable = true;
              }
            }
          } else {
            temp.nameDisable = true;
            temp.ifscDisable = true;
            temp.numberDisable = true;
            temp.imageDisable = true;
            temp.submitDisable = false;
            temp.updateDisable = false;
          }
          setEditChecks({
            ...editChecks,
            nameDisable: temp.nameDisable,
            ifscDisable: temp.ifscDisable,
            numberDisable: temp.numberDisable,
            imageDisable: temp.imageDisable,
            submitDisable: temp.submitDisable,
            editDisable: false,
            updateDisable: temp.updateDisable,
            submitHide: true,
            editHide: edit ? true : false,
            mismatchHide: edit ? true : false,
            updateHide: edit ? false : true,
          });
          break;
        case 2:
          if (edit) {
            if (data.distortImage) {
              temp.nameDisable = true;
              temp.ifscDisable = true;
              temp.numberDisable = true;
              temp.submitDisable = false;
              temp.updateDisable = false;
            } else if (!data.distortImage) {
              temp.nameDisable = false;
              temp.ifscDisable = false;
              temp.numberDisable = false;
              if (data.adminInput) {
                temp.submitDisable = false;
                temp.updateDisable = false;
              } else {
                temp.submitDisable = true;
                temp.updateDisable = true;
              }
            }
          } else {
            temp.nameDisable = true;
            temp.ifscDisable = true;
            temp.numberDisable = true;
            temp.imageDisable = true;
            temp.submitDisable = false;
            temp.updateDisable = false;
          }
          if (localStorage.getItem("roleType") === 6) {
            setEditChecks({
              ...editChecks,
              nameDisable: temp.nameDisable,
              ifscDisable: temp.ifscDisable,
              numberDisable: temp.numberDisable,
              imageDisable: temp.imageDisable,
              submitDisable: temp.submitDisable,
              editDisable: false,
              updateDisable: temp.updateDisable,
              submitHide: false,
              editHide: edit ? true : false,
              mismatchHide: edit ? true : false,
              updateHide: edit ? false : true,
            });
          } else {
            setEditChecks({
              ...editChecks,
              nameDisable: temp.nameDisable,
              ifscDisable: temp.ifscDisable,
              numberDisable: temp.numberDisable,
              imageDisable: temp.imageDisable,
              submitDisable: temp.submitDisable,
              editDisable: false,
              updateDisable: temp.updateDisable,
              submitHide: true,
              editHide: edit ? true : false,
              mismatchHide: false,
              updateHide: edit ? false : true,
            });
          }
          break;
        case 3:
          setEditChecks({
            ...editChecks,
            nameDisable: true,
            ifscDisable: true,
            numberDisable: true,
            imageDisable: true,
            submitDisable: true,
            editDisable: false,
            updateDisable: !data.distortImage || !data.adminInput,
            submitHide: true,
            editHide: true,
            mismatchHide: true,
            updateHide: true,
          });
          break;
        default:
          break;
      }
    }
  }, [data, edit]);

  const handleNextRecord = () => {
    setLoading(true);
    Axios.get(
      `${UrlLinks.baseUrl}${UrlLinks.bankVerification.DetailsVerificationNextV2}${currentUserId}&pennyTestingStatus=${basicPennyStatus}`
    )
      .then((response) => {
        if (response) {
          if (response.data.response.user_id !== userId) {
            makeData(response.data.response);
            setCurrentUserId(response.data.response.user_id);
            setEdit(false);
            setIsSubmitted(false);
            setLoading(false);
          } else {
            showToast("error", "No more records to be verified");
            setShowNextButton(false);
            setLoading(false);
          }
        }
      })
      .catch((error) => {
        showToast("error", "Error loading next record");
      });
  };

  const getRecord = () => {
    setLoading(true);
    if (currentUserId) {
      Axios.get(
        `${UrlLinks.baseUrl}${UrlLinks.bankVerification.DetailsVerificationInitial}${currentUserId}`
      ).then((response) => {
        if (response) {
          makeData(response.data.response);
          setLoading(false);
        }
      });
    }
  };

  useEffect(() => {
    if (data.distortImage) {
      setData({
        ...data,
        accountHolderNameMismatch: false,
        ifscCodeMismatch: false,
        adminInput: "",
      });
    }
  }, [data.distortImage]);

  useEffect(() => {
    if (userId) {
      getRecord();
    } else {
      props.history.push("/bankverificationlisting");
    }
  }, []);

  return loading ? (
    <Grid container style={{ margin: "130px 0 0 40px" }}>
      <Grid item xs={5}>
        <Grid item xs={12}>
          <Skeleton variant="rect" width="90%" height={200} />
        </Grid>
        <Grid item xs={12} style={{ marginTop: "30px" }}>
          <Skeleton variant="rect" width="90%" height={400} />
        </Grid>
      </Grid>
      <Grid item xs={7}>
        <Skeleton variant="rect" width="90%" height="100%" />
      </Grid>
    </Grid>
  ) : (
    <Grid container className={styles.container}>
      <Grid item xs={5}>
        <PennyTestingVerification
          data={data}
          handleNextRecord={handleNextRecord}
          getRecord={getRecord}
        ></PennyTestingVerification>
      </Grid>
      <Grid item xs={7} className={styles.imageContainer}>
        <Grid container className={styles.imageInnerContainer}>
          <Grid item xs={12} className={styles.displayRow}>
            <span className={styles.titleText}>Account Proof</span>
            {showNextButton && (
              <button
                className={styles.nextRecordButton}
                onClick={handleNextRecord}
              >
                {`Next Record >`}
              </button>
            )}
          </Grid>
          <Grid item xs={12} className={styles.imageMagnifier}>
            <ReactPanZoom
              image={data.accountProof}
              alt="account_proof"
              crossOrigin="anonymous"
              className={styles.imagePan}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BankDetailsList;
