import { useEffect, useState } from "react";
import "./QuestionForm.css";
import CopyQuestion from "./CopyQuestion.svg";
import Delete from "./Delete.svg";
import plusSvg from "./plusSvg.svg";
import Close from "./Close.svg";

export default function QuestionForm(props) {
  const {
    index,
    id,
    questionText,
    marks: initialMarks,
    isMandatory: initialIsMandatory,
    answer: initialAnswer,
    answerId: initialAnswerId,
    answerList: initialAnswerList,
    setQuestionArray,
    handleDelete,
    handleCopy, // Receive handleCopy as a prop
    errArray,
    setErrArray,
    setTotalMarks,
  } = props;

  // UI States
  const [answerListShow, setAnswerListShow] = useState(false);
  const [marksListShow, setMarksListShow] = useState(false);
  const [questionInputErr, setQuestionInputErr] = useState("");
  const [answerInputErr, setAnswerInputErr] = useState("");
  const [marksInputErr, setMarksInputError] = useState("");

  useEffect(() => {
    const error = errArray.find((error) => error.questionIndex === index);
    if (error) {
      setQuestionInputErr(error.questionErr || "");
      setAnswerInputErr(error.answerErr || "");
      setMarksInputError(error.marksErr || "");
    }
  }, [errArray, index]);

  const [QuestionText, setQuestionText] = useState(questionText || "");
  const [answerList, setAnswerList] = useState(initialAnswerList || []);
  const [answer, setAnswer] = useState(initialAnswer || "");
  const [answerId, setAnswerId] = useState(initialAnswerId || 0);
  const [isMandatory, setIsMandatory] = useState(initialIsMandatory || false);
  const [tempIdCounter, setTempIdCounter] = useState(
    id && id.toString().startsWith("temp") ? parseInt(id.split("-")[1]) : 0
  );
  const [marks, setMarks] = useState(initialMarks || 0);
  const marksList = [1, 2, 3, 4, 5];

  const [formData, setFormData] = useState({
    marks: initialMarks || 0,
    questionId: id || 0,
    questionText: questionText || "",
    answerList: initialAnswerList || [],
    answer: initialAnswer || "",
    answerId: initialAnswerId || 0,
    isMandatory: initialIsMandatory ? 1 : 0, // Convert initialIsMandatory to 1 or 0
  });

  useEffect(() => {
    const updatedAnswerList = answerList
      .filter((option) => option.option_text !== "")
      .map((option) => ({
        ...option,
        option_id: option.option_id.toString().startsWith("temp")
          ? 0
          : option.option_id,
      }));

    if (QuestionText !== "") setQuestionInputErr("");
    if (answer !== "") setAnswerInputErr("");
    if (marks !== 0) setMarksInputError("");

    setFormData({
      questionId: id || 0,
      questionText: QuestionText,
      answerList: updatedAnswerList,
      answer: answer,
      marks: marks,
      answerId: answerId.toString().startsWith("temp") ? 0 : answerId,
      isMandatory: isMandatory ? 1 : 0, // Update isMandatory in formData
    });
  }, [QuestionText, answerList, answer, answerId, id, isMandatory, marks]);

  useEffect(() => {
    const sendFormDataToApi = () => {
      // Prepare formData to send to API
      const formDataToSend = {
        ...formData,
        isMandatory: isMandatory ? 1 : 0, // Ensure isMandatory is converted to 1 or 0
      };

      // Update question in setQuestionArray with formDataToSend
      setQuestionArray((prevQuestionArray) =>
        prevQuestionArray.map((question) =>
          question.questionId === formDataToSend.questionId
            ? formDataToSend
            : question
        )
      );
    };
    sendFormDataToApi();
  }, [formData]);

  const handleOptionTextChange = (optionId, newText) => {
    setAnswerList((prevAnswerList) =>
      prevAnswerList.map((option) =>
        option.option_id === optionId
          ? { ...option, option_text: newText }
          : option
      )
    );
  };

  const handleAddOptionToAnswerList = () => {
    const newOption = {
      option_id: `temp-${tempIdCounter.toString().padStart(2, "0")}`, // Two-digit temp ID
      option_text: "",
    };
    setAnswerList((prevAnswerList) => [...prevAnswerList, newOption]);
    setTempIdCounter((prevCounter) => prevCounter + 1);
  };

  const handleAnswerChange = (optionId, optionText) => {
    setAnswer(optionText);
    setAnswerId(optionId);
    setAnswerListShow((prev) => !prev);
  };

  const handleMandatoryClick = () => {
    setIsMandatory(!isMandatory); // Toggle isMandatory state
  };

  const handleMarksChange = (newMarks) => {
    setTotalMarks((prev) => {
      if (prev === "") {
        return newMarks;
      }
      return prev - marks + newMarks;
    });
    setMarks(newMarks);
    setMarksListShow((prev) => !prev);
  };

  const handleDeleteClick = () => {
    handleDelete(id, marks); // Call the handleDelete function with the question id
  };

  const handleCopyClick = () => {
    const newAnswerList = answerList.map((option) => ({
      ...option,
      option_id: `temp-${Date.now()}-${Math.random()
        .toString(36)
        .substr(2, 9)}`, // Unique temporary ID
    }));

    const newAnswerId = `temp-${Date.now()}-${Math.random()
      .toString(36)
      .substr(2, 9)}`;

    handleCopy({
      questionId: `temp-${Date.now()}`, // Unique temporary ID
      questionText: QuestionText,
      marks,
      isMandatory,
      answer,
      answerId: newAnswerId,
      answerList: newAnswerList,
    });
  };

  const deleteOption = (value) => {
    const newAnswerList = answerList.filter((i) => i !== value);
    setAnswerList(newAnswerList);
  };

  return (
    <div className="screeningQuestionForm">
      {id.toString().startsWith("temp") && (
        <div className="questionInput">
          <input
            type="text"
            value={QuestionText}
            placeholder="Write Your Question"
            onChange={(e) => setQuestionText(e.target.value)}
            className={`questionInputTag ${
              questionText.length > 50
                ? "w1200"
                : questionText.length > 38
                ? "w640"
                : questionText.length > 19
                ? "w320"
                : "w200"
            } underline`}
          />{" "}
        </div>
      )}
      {!id.toString().startsWith("temp") && (
        <input
          type="text"
          value={QuestionText}
          placeholder="Write Your Question"
          className="questionInput"
          readOnly
        />
      )}
      {questionInputErr && (
        <div className="validationScreeningErr">{questionInputErr}</div>
      )}
      {answerList.length > 0 &&
        answerList.map((option, index) => (
          <div key={option.option_id} className="option">
            <div className="optionCircle"></div>
            {!id.toString().startsWith("temp") && (
              <input
                type="text"
                placeholder={`Option ${index + 1}`}
                className="optionInput"
                value={option.option_text}
                readOnly
              />
            )}
            {id.toString().startsWith("temp") && (
              <input
                type="text"
                placeholder={`Option ${index + 1}`}
                className={`optionInput ${
                  option.option_text.length > 30
                    ? "w640"
                    : option.option_text.length > 16
                    ? "w320"
                    : option.option_text.length > 8
                    ? "w200"
                    : "w120"
                } underline`}
                value={option.option_text}
                onChange={(e) =>
                  handleOptionTextChange(option.option_id, e.target.value)
                }
              />
            )}
            {id.toString().startsWith("temp") && (
              <div className="closeBtn" onClick={() => deleteOption(option)}>
                {option.option_text === "" ? "" : <img src={Close} alt="" />}
              </div>
            )}
          </div>
        ))}
      {id.toString().startsWith("temp") && (
        <div onClick={handleAddOptionToAnswerList} className="addOptionBtn">
          <div className="addOptionBtnText">
            Add Option <img src={plusSvg} alt="" />
          </div>
        </div>
      )}
      <div className="questionHr"></div>
      <div className="otherFieldsInputs">
        <div className="fieldsInputs">
          {id.toString().startsWith("temp") && (
            <div className="answerContainer">
              <input
                type="text"
                className={`answerInput ${answer === "" ? "" : "bge6"}`}
                value={answer || ""}
                placeholder="Add Correct Response"
                onClick={() => setAnswerListShow((prev) => !prev)}
                readOnly
              />
              <span className="dropdownScreenIcon">&#9660;</span>
              <div className={`answersList ${answerListShow ? "" : "hidden"}`}>
                {answerList.map(
                  (option) =>
                    option.option_text !== "" && (
                      <div
                        className="answersListOption"
                        onClick={() =>
                          handleAnswerChange(
                            option.option_id,
                            option.option_text
                          )
                        }
                        key={option.option_id}
                      >
                        {option.option_text}
                      </div>
                    )
                )}
              </div>
            </div>
          )}
          {!id.toString().startsWith("temp") && (
            <div className="answerContainer">
              <input
                type="text"
                className={`answerInput disableBG ${
                  answer === "" ? "" : "bge6"
                }`}
                value={answer || ""}
                placeholder="Add Correct Response"
                readOnly
              />
              <span className="dropdownScreenIcon">&#9660;</span>
            </div>
          )}
          {answerInputErr && (
            <div className="validationScreeningErr">{answerInputErr}</div>
          )}
          {id.toString().startsWith("temp") && (
            <div className="marksContainer">
              <input
                type="text"
                value={marks ? `${marks} marks` : ""}
                placeholder="Type & Select Marks"
                onClick={() => setMarksListShow((prev) => !prev)}
                className="marksInput"
                readOnly
              />
              <span className="dropdownScreenIconMarks">&#9660;</span>
              <div className={`marksList ${marksListShow ? "" : "hidden"}`}>
                {marksList.map((i) => (
                  <div
                    onClick={() => handleMarksChange(i)}
                    className="marksListOption"
                    key={i}
                  >
                    {`${i} marks`}
                  </div>
                ))}
              </div>
            </div>
          )}
          {!id.toString().startsWith("temp") && (
            <div className="marksContainer">
              <input
                type="text"
                value={marks ? `${marks} marks` : ""}
                placeholder="Type & Select Marks"
                className="marksInput disableBG"
                readOnly
              />
              <span className="dropdownScreenIconMarks">&#9660;</span>
            </div>
          )}
          {marksInputErr && (
            <div className="validationScreeningErr">{marksInputErr}</div>
          )}
        </div>
        <div className="screeningBtns">
          <div>
            <div onClick={handleCopyClick}>
              <img src={CopyQuestion} alt="" />
            </div>
          </div>
          <div>
            <div onClick={handleDeleteClick}>
              <img src={Delete} alt="" />
            </div>
          </div>
          <div className="verticalHr"></div>
          <div className="mandatorySection">
            Mandatory :{" "}
            <div
              onClick={handleMandatoryClick}
              className={`mandatory ${isMandatory ? "mandatoryActive" : ""}`}
            >
              <div
                className={`mandatoryTrue ${
                  isMandatory ? "mandatoryFalse" : ""
                }`}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
