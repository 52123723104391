import React, { useState, useEffect } from "react";
import Axios from "axios";
import styles from "./Components.module.scss";
import { Button, Grid } from "@material-ui/core";
import urlLinks from "../../../UrlLinks";
import AutoSelectFilter from "../../../Utils/FilterComponents/AutoSelectFilter";
import DateFilter from "../../../Utils/FilterComponents/DateFilter";
import { useSelector } from "react-redux";
import { ScaleLoader } from "react-spinners";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

export default function FilterDrawer(props) {
  const { handleClose, filters, setFilters, applyFilters, resetFilters } =
    props;
  const { users } = useSelector((state) => state);
  const { modalLoader } = users;

  const [hubsList, setHubsList] = useState([]);
  const [facilityStateList, setFacilityStateList] = useState([]);
  const [verificationType, setVerificationType] = useState([]);
  const [partnerList, setPartnerList] = useState([]);

  const zoneList = [
    {
      id: "North",
      name: "North",
    },
    {
      id: "South",
      name: "South",
    },
    {
      id: "East",
      name: "East",
    },
    {
      id: "West",
      name: "West",
    },
  ];

  const paymentStatusList = [
    {
      id: "queued",
      name: "Queued",
    },
    {
      id: "processing",
      name: "Processing",
    },
    {
      id: "processed",
      name: "Processed",
    },
    {
      id: "Account_Details_not_valid",
      name: "Account details not valid",
    },
    {
      id: "on hold",
      name: "On hold",
    },
    {
      id: "failed",
      name: "Failed",
    },
    {
      id: "null",
      name: "Without payment status",
    },
  ];

  const workerStatusList = [
    {
      id: 100,
      name: "Not Onboarded",
    },
    {
      id: 1,
      name: "Active",
    },
    {
      id: 2,
      name: "Partially Onboarded",
    },
    {
      id: 3,
      name: "Inactive",
    },
    {
      id: 4,
      name: "Offboarding Initiated",
    },
    {
      id: 5,
      name: "Onboarding Completed",
    },
    {
      id: 6,
      name: "Temporarily Inactive",
    },
    {
      id: 7,
      name: "Temporary Inactivation Initiated",
    },
    {
      id: 8,
      name: "Reactivation Initiated",
    },
  ];

  const workerTypeList = [
    {
      id: 2,
      name: "Super",
    },
    {
      id: 1,
      name: "Gold",
    },
    {
      id: 0,
      name: "None",
    },
  ];

  const paymentTypeList = [
    {
      id: 3,
      name: "Advance Payout",
    },
    {
      id: 2,
      name: "15 Days",
    },
    {
      id: 1,
      name: "Weekly",
    },
  ];

  const advanceTakenList = [
    {
      id: 1,
      name: "Advance Taken",
    },
    {
      id: 0,
      name: "Not Taken",
    },
  ];

  const modalFlagList = [
    {
      id: 0,
      name: "Eflex",
    },
    {
      id: 1,
      name: "LMA",
    },
  ];

  const handleCancelFilters = () => {
    handleClose();
  };

  const handleApplyFilters = () => {
    applyFilters();
    handleClose();
  };

  const makeHubList = (data) => {
    let list = [];
    let count = 0;
    if (data?.length > 0) {
      data.map((skill) => {
        count++;

        if (skill !== null) {
          let list2 = {
            name: skill.facilityName,
            id: count,
          };
          list.push(list2);
        }
      });
    }
    return list;
  };

  const makeFacilityStateList = (data) => {
    let list = [];
    let count = 0;
    if (data?.length > 0) {
      data.map((skill) => {
        count++;

        if (skill !== null) {
          let list2 = {
            name: skill,
            id: count,
          };
          list.push(list2);
        }
      });
    }
    return list;
  };

  const makeVerificationType = (data) => {
    let list = [];
    if (data?.length > 0) {
      data.map((skill) => {
        if (skill !== null) {
          let list2 = {
            id: skill.typeKey,
            name: skill.typeValue,
          };
          list.push(list2);
        }
      });
    }
    return list;
  };

  const makeNonAJPartnerList = (data) => {
    let list = [];
    if (data?.length > 0) {
      data.map((skill) => {
        if (
          skill !== null &&
          (skill.is_aj_client === 0 || skill.is_aj_client === null)
        ) {
          let list2 = {
            id: skill.partner_id,
            name: skill.partner_name,
          };
          list.push(list2);
        }
      });
    }
    return list;
  };

  useEffect(() => {
    Axios.get(`${urlLinks.baseUrl}configmaster/getActiveHubs`).then(
      (response) => {
        setHubsList(makeHubList(response.data.response));
      }
    );

    Axios.get(`${urlLinks.baseUrl}/configmaster/getFacilityStates`).then(
      (response) => {
        setFacilityStateList(makeFacilityStateList(response.data.response));
      }
    );

    Axios.get(`${urlLinks.baseUrl}/configmaster/getPartners`).then(
      (response) => {
        setPartnerList(makeNonAJPartnerList(response.data.response));
      }
    );

    Axios.get(
      `${urlLinks.baseUrl}/configmaster/?category_sub_type=penny_testing_stage&category_type=penny_testing`
    ).then((response) => {
      setVerificationType(makeVerificationType(response.data.response.content));
    });

    setFilters(props.filters);
  }, []);

  const loaderBar = (
    <div style={{ margin: "130px auto" }}>
      <ScaleLoader height={70} width={10} color={"#1c75bc"} />
    </div>
  );

  const output = modalLoader ? (
    <Grid container style={{ width: "520px", marginTop: "20px" }}>
      {loaderBar}
    </Grid>
  ) : (
    <Grid
      container
      style={{ width: "520px", marginTop: "20px" }}
      className={`${styles.filterContainerDrawer}`}
    >
      <Grid container className={`${styles.FilterHeadingContainer}`}>
        <div>
          <Button
            onClick={handleCancelFilters}
            className={styles.filterCloseArrow}
          >
            <ArrowForwardIcon />
          </Button>
          <span className={styles.filterHeading}>Filters</span>
        </div>
        <div>
          <Button onClick={resetFilters} className={styles.resetFiltersButton}>
            Reset
          </Button>
        </div>
      </Grid>
      <div className={styles.filterHeadingDivider}></div>
      <Grid container className={styles.filtersContainer}>
        <Grid item xs={12} className={`${styles.singleFilterContainer}`}>
          <span className={styles.filterTitle}>Zone</span>
          <AutoSelectFilter
            filters={filters}
            setFilters={setFilters}
            multipleFlag={false}
            id="zone"
            options={zoneList}
          />
        </Grid>
        <Grid item xs={12} className={`${styles.singleFilterContainer}`}>
          <span className={styles.filterTitle}>Facility State</span>
          <AutoSelectFilter
            filters={filters}
            setFilters={setFilters}
            multipleFlag={false}
            id="facility_state"
            options={facilityStateList}
          />
        </Grid>
        <Grid item xs={12} className={`${styles.singleFilterContainer}`}>
          <span className={styles.filterTitle}>Facility Hubs</span>
          <AutoSelectFilter
            filters={filters}
            setFilters={setFilters}
            multipleFlag={false}
            id="facility_name"
            options={hubsList}
          />
        </Grid>
        <Grid item xs={12} className={`${styles.singleFilterContainer}`}>
          <span className={styles.filterTitle}>Payment Status</span>
          <AutoSelectFilter
            filters={filters}
            setFilters={setFilters}
            multipleFlag={false}
            id="payment_status"
            options={paymentStatusList}
          />
        </Grid>
        <Grid item xs={12} className={`${styles.singleFilterContainer}`}>
          <span className={styles.filterTitle}>Worker Status</span>
          <AutoSelectFilter
            filters={filters}
            setFilters={setFilters}
            multipleFlag={false}
            id="worker_status"
            options={workerStatusList}
          />
        </Grid>
        <Grid item xs={12} className={`${styles.singleFilterContainer}`}>
          <span className={styles.filterTitle}>Worker type</span>
          <AutoSelectFilter
            filters={filters}
            setFilters={setFilters}
            multipleFlag={false}
            id="worker_type"
            options={workerTypeList}
          />
        </Grid>
        <Grid item xs={12} className={`${styles.singleFilterContainer}`}>
          <span className={styles.filterTitle}>Payment type</span>
          <AutoSelectFilter
            filters={filters}
            setFilters={setFilters}
            multipleFlag={false}
            id="payment_type"
            options={paymentTypeList}
          />
        </Grid>
        <Grid item xs={12} className={`${styles.singleFilterContainer}`}>
          <span className={styles.filterTitle}>Advance Taken</span>
          <AutoSelectFilter
            filters={filters}
            setFilters={setFilters}
            multipleFlag={false}
            id="advanceTaken"
            options={advanceTakenList}
          />
        </Grid>
        <Grid item xs={12} className={`${styles.singleFilterContainer}`}>
          <span className={styles.filterTitle}>Modal Flag</span>
          <AutoSelectFilter
            filters={filters}
            setFilters={setFilters}
            multipleFlag={false}
            id="modalFlag"
            options={modalFlagList}
          />
        </Grid>
        <Grid item xs={12} className={`${styles.singleFilterContainer}`}>
          <span className={styles.filterTitle}>Client</span>
          <AutoSelectFilter
            filters={filters}
            setFilters={setFilters}
            multipleFlag={false}
            id="clientId"
            options={partnerList ? partnerList : []}
          />
        </Grid>
        <Grid item xs={12} className={`${styles.singleFilterContainer}`}>
          <span className={styles.filterTitle}>Verification Type</span>
          <AutoSelectFilter
            filters={filters}
            setFilters={setFilters}
            multipleFlag={false}
            id="verification_type"
            options={verificationType ? verificationType : []}
          />
        </Grid>
        <Grid container className={`${styles.singleFilterContainer}`}>
          <Grid item xs={5}>
            <span className={styles.filterTitle}>Payment Date</span>
          </Grid>
          <Grid item xs={7}>
            <DateFilter
              filters={filters}
              setFilters={setFilters}
              labelTo="To"
              labelFrom="From"
              id="paymentDate"
            />
          </Grid>
        </Grid>
        <Grid container className={`${styles.singleFilterContainer}`}>
          <Grid item xs={5}>
            <span className={styles.filterTitle}>Invoice Date</span>
          </Grid>
          <Grid item xs={7}>
            <DateFilter
              filters={filters}
              setFilters={setFilters}
              labelTo="To"
              labelFrom="From"
              id="invoiceDate"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container className={styles.filterButtonDividerContainer}>
        <div className={styles.filterHeadingDivider}></div>
        <Grid container style={{ marginTop: "30px" }} className="padding0">
          <div
            style={{
              paddingRight: "16px",
              paddingLeft: "300px",
            }}
          >
            <Button
              variant="outlined"
              onClick={handleCancelFilters}
              size="medium"
              style={{ color: "#1C75BC", borderColor: "#1C75BC" }}
            >
              Cancel
            </Button>
          </div>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            onClick={handleApplyFilters}
            style={{ backgroundColor: "#1C75BC" }}
          >
            Apply
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );

  return output;
}
