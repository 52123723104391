import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import Axios from "axios";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { Button } from "@material-ui/core";
import style from "./index.module.scss";
import AutoSelectFilter from "./FilterComponents/AutoSelectFilter";
import ScaleLoader from "react-spinners/ScaleLoader";
import SelectFilter from "./FilterComponents/SelectFilter";
import urlLinks from "../../UrlLinks";
import FacilitySearch from "./FilterComponents/FacilitySearch";

const FilterDrawer = (props) => {
  const { handleClose, resetFilters, applyFilters, filters, setFilters } =
    props;
  const [workerPartners, setWorkerPartners] = useState([]);
  const [shiftStatusList, setShiftStatusList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const handleCancelFilters = () => {
    resetFilters();
    handleClose();
  };

  const workerStatusData = [
    {
      id: 10,
      name: "All",
    },
    {
      id: 0,
      name: "Not Onboarded",
    },
    {
      id: 2,
      name: "Partially Onboarded",
    },
    {
      id: 5,
      name: "Onboarding Completed",
    },
    {
      id: 1,
      name: "Active",
    },
    {
      id: 6,
      name: "Temporarily Inactive",
    },
    {
      id: 3,
      name: "Inactive",
    },
  ];

  const loader = (
    <div style={{ margin: "130px auto" }}>
      <ScaleLoader height={70} width={10} color={"#1c75bc"} />
    </div>
  );

  const makeWorkerPartnerData = (data) => {
    let list = [];
    data.map((el) => {
      list.push({
        id: el.partner_id,
        name: el.company_name,
      });
      return true;
    });
    return list;
  };

  const makeShiftStatusData = (data) => {
    let list = [];
    data.map((el) => {
      list.push({
        id: el.typeValue,
        name: el.typeDesc,
      });
      return true;
    });
    return list;
  };

  useEffect(() => {
    if (workerPartners.length > 0 && shiftStatusList.length > 0) {
      setIsLoading(false);
    }
  }, [workerPartners, shiftStatusList]);

  useEffect(() => {
    Axios.get(`${urlLinks.baseUrl}${urlLinks.shifts.workerPartners}`).then(
      (response) => {
        setWorkerPartners(makeWorkerPartnerData(response.data.response));
      }
    );

    Axios.get(`${urlLinks.baseUrl}${urlLinks.shifts.shiftStatusList}`).then(
      (response) => {
        setShiftStatusList(makeShiftStatusData(response.data.response.content));
      }
    );
  }, []);

  const output = isLoading ? (
    <Grid container style={{ width: "520px", marginTop: "20px" }}>
      {loader}
    </Grid>
  ) : (
    <Grid
      container
      style={{ width: "520px", marginTop: "20px" }}
      className={style.FilterContainer}
    >
      <Grid container className={`${style.FilterHeadingContainer}`}>
        <div className={style.flexBaseLine}>
          <Button onClick={handleClose} className={style.filterCloseArrow}>
            <ArrowForwardIcon />
          </Button>
          <span className={style.filterHeading}>Filters</span>
        </div>
        <div>
          <Button onClick={resetFilters} className={style.resetFiltersButton}>
            Reset
          </Button>
        </div>
      </Grid>
      <div className={style.filterHeadingDivider}></div>
      <Grid container className={style.filtersContainer}>
        <Grid item xs={12} className={`${style.singleFilterContainer}`}>
          <span className={style.filterTitle}>Source</span>
          <AutoSelectFilter
            filters={filters}
            setFilters={setFilters}
            multipleFlag={false}
            id="workerPartner"
            options={workerPartners}
          />
        </Grid>
        <Grid
          item
          xs={12}
          className={`padding0 ${style.singleFilterContainer}`}
        >
          <span className={style.filterTitle}>Hub</span>
          <FacilitySearch filters={filters} setFilters={setFilters} id="hub" />
        </Grid>
        <Grid item xs={12} className={style.singleFilterContainer}>
          <span className={style.filterTitle}>Worker status</span>
          <SelectFilter
            filters={filters}
            setFilters={setFilters}
            multiple={false}
            id="workerStatus"
            optionList={workerStatusData}
          />
        </Grid>
      </Grid>
      <Grid container className={style.filterButtonDividerContainer}>
        <div className={style.filterHeadingDivider}></div>
        <Grid container style={{ marginTop: "30px" }} className="padding0">
          <div
            style={{
              paddingRight: "16px",
              paddingLeft: "300px",
            }}
          >
            <Button
              variant="outlined"
              onClick={handleCancelFilters}
              size="medium"
              color="primary"
            >
              Cancel
            </Button>
          </div>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            className={style.PrimaryColorButton}
            onClick={() => {
              applyFilters();
              handleClose();
            }}
          >
            Apply
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );

  return output;
};

export default FilterDrawer;
