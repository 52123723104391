import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../../store/actions/index";
import { Stack } from "@mui/material";
import { makeStyles } from "@material-ui/styles";
import styles from "./Components.module.scss";
import { Close } from "@material-ui/icons";
import {
  Box,
  FormControl,
  IconButton,
  MenuItem,
  Modal,
  Select,
} from "@material-ui/core";
import { CurrencyRupeeOutlined } from "@mui/icons-material";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { ScaleLoader } from "react-spinners";
import NoRecord from "../../../assets/noRecord.svg";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 556,
  minHeight: "180px",
  padding: "32px 40px",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  filter: "drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.15))",
};

const usestyles = makeStyles({
  earningSelectMui: {
    margin: 0,
    "& .MuiInput-underline:before, & .MuiInput-underline:after": {
      borderBottom: "1px solid #1c75bc",
      width: "80%",
    },
  },
});

export default function EarningModal(props) {
  const {
    open,
    setOpen,
    title,
    uniqueKey,
    selectedOption,
    setSelectedOption,
    financialYearList,
  } = props;

  /** accessing states from redux store **/

  const { users } = useSelector((state) => state);
  const { modalLoader } = users;

  /** other states **/

  const classes = usestyles();

  const handleClose = () => setOpen(false);

  const handleSelect = (e) => {
    const selectedId = e.target.value;
    const selectedEarning = financialYearList.find(
      (option) => option.financialYear === selectedId
    ).totalEarning;

    setSelectedOption({
      id: selectedId,
      name: selectedId,
      val: selectedEarning,
    });
  };

  const loaderBar = (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <ScaleLoader height={20} width={10} color={"#1c75bc"} />
    </div>
  );

  return (
    <div>
      <Modal
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        className={styles.earningModal}
      >
        <Box sx={style} className={styles.earningBox}>
          <p className={styles.header}>
            {title}
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </p>
          {modalLoader ? (
            loaderBar
          ) : financialYearList.length === 0 ? (
            <div className={styles.noRecord}>
              <img src={NoRecord} alt="noRecord" style={{ height: "90px" }} />
              <span>No data</span>
            </div>
          ) : (
            <Stack spacing={3} className={styles.earningStack}>
              <div className={styles.financialYear}>
                <CurrencyRupeeOutlined />
                <span>Total Earning</span>
                <FormControl className={classes.earningSelectMui}>
                  <Select
                    id="search-select"
                    MenuProps={{
                      autoFocus: false,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                    className={`${styles.earningSelect}`}
                    value={selectedOption.id}
                    onChange={(e) => handleSelect(e)}
                    IconComponent={ExpandMoreIcon}
                    renderValue={() =>
                      uniqueKey === "earningModal"
                        ? `(${selectedOption.name})`
                        : ""
                    }
                  >
                    {financialYearList.map((option) => (
                      <MenuItem
                        key={option.financialYear}
                        value={option.financialYear}
                      >
                        {option.financialYear}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className={styles.financialEarning}>
                {selectedOption.id === "-1"
                  ? "Select Year"
                  : `₹ ${selectedOption.val}`}
              </div>
            </Stack>
          )}
        </Box>
      </Modal>
    </div>
  );
}
