import React, { useState, useEffect } from "react";
import Axios from "axios";
import {
  Popover,
  Button,
  Divider,
  Grid,
  IconButton,
  Badge,
} from "@material-ui/core";
import SelectFilter from "../../Utils/FilterComponents/SelectFilter";
import FilterIcon from "../../assets/filter.svg";
import AutoSelectFilter from "./components/AutoSelectFilter";
import urlLinks from "../../UrlLinks";

export default function SimplePopover(props) {
  const { isFilterApplied, setIsFilterApplied } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [filters, setFilters] = useState(props.filters);
  const [hubsList, setHubsList] = useState([]);
  const [facilityStateList, setFacilityStateList] = useState([]);

  const makeHubList = (data) => {
    let list = [];
    let count = 0;
    if (data?.length > 0) {
      data.map((skill) => {
        count++;
        if (skill !== null) {
          let list2 = {
            name: skill.facilityName,
            id: count,
          };
          list.push(list2);
        }
      });
    }
    return list;
  };

  const makeFacilityStateList = (data) => {
    let list = [];
    let count = 0;
    if (data?.length > 0) {
      data.map((skill) => {
        count++;
        if (skill !== null) {
          let list2 = {
            name: skill,
            id: count,
          };
          list.push(list2);
        }
      });
    }
    return list;
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleResetFilters = () => {
    const newFilters = props.resetFilters();
    setFilters(newFilters);
    setIsFilterApplied(false);
  };

  const handleApplyFilters = () => {
    props.applyFilters(filters);
    handleClose();
  };

  const handleCancelFilters = () => {
    setFilters(props.filters);
    handleClose();
  };

  useEffect(() => {
    Axios.get(`${urlLinks.baseUrl}configmaster/getActiveHubs`).then(
      (response) => {
        setHubsList(makeHubList(response.data.response));
      }
    );

    Axios.get(`${urlLinks.baseUrl}/configmaster/getFacilityStates`).then(
      (response) => {
        setFacilityStateList(makeFacilityStateList(response.data.response));
      }
    );
  }, []);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  let popoverData = (
    <Grid container style={{ width: "600px" }}>
      <Grid item xs={5} className="padding20 margin20">
        <SelectFilter
          filters={filters}
          setFilters={setFilters}
          label="Zone"
          id="zone"
        />
      </Grid>
      <Grid item xs={5} className="padding20 margin20">
        <SelectFilter
          filters={filters}
          setFilters={setFilters}
          label="Partner"
          id="partnerId"
        />
      </Grid>

      <Grid item xs={5} className="padding20 margin20">
        <AutoSelectFilter
          filters={filters}
          setFilters={setFilters}
          multipleFlag={false}
          placeholder="Facility Hubs"
          id="facilityName"
          options={hubsList}
        />
      </Grid>
      <Grid item xs={5} className="padding20 margin20">
        <AutoSelectFilter
          filters={filters}
          setFilters={setFilters}
          multipleFlag={false}
          placeholder="Facility State"
          id="facilityState"
          options={facilityStateList}
        />
      </Grid>
    </Grid>
  );

  return (
    <div>
      <Badge color="secondary" variant="dot" invisible={!isFilterApplied}>
        <IconButton
          className="filterButton marginLight20"
          onClick={handleClick}
        >
          <img src={FilterIcon} alt="img" />
        </IconButton>
      </Badge>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Grid container className="buttonList">
          <Grid item xs={4} style={{ padding: "10px" }}>
            <span className="FilterHeading">All worker filters</span>
          </Grid>
          <Grid
            item
            xs={8}
            style={{ padding: "20px" }}
            className="flexRowReverse"
          >
            <Button
              variant="contained"
              color="primary"
              style={{ marginLeft: "30px", backgroundColor: "#1c75bc" }}
              onClick={handleResetFilters}
            >
              Reset
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{ marginLeft: "30px", backgroundColor: "#1c75bc" }}
              onClick={handleApplyFilters}
            >
              Apply
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{ marginLeft: "30px", backgroundColor: "#1c75bc" }}
              onClick={handleCancelFilters}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
        <Divider />
        {popoverData}
      </Popover>
    </div>
  );
}
