import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Styles from "./Components.module.scss";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Axios from "axios";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import moment from "moment";
import Calendar from "../Assets/calendar.svg";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import TimeFilter from "../../../Utils/FilterComponents/TimeFilter";
import UrlLinks from "../../../UrlLinks";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    borderBottom: "unset",
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    "& .MuiDialog-paperWidthSm": {
      width: "600px !important",
    },
  },
}));

export default function FollowUp(props) {
  const {
    openOnboardingModal,
    setOpenOnboardingModal,
    showToast,
    updateMyData,
    title,
    uniqueKey,
    assignIds,
    searchActions,
  } = props;
  const classes = useStyles();
  const [followUpDetails, setFollowUpDetails] = useState({
    date: [null],
    time: null,
  });

  const handleClose = () => {
    setOpenOnboardingModal(false);
  };

  const handleStatusUpdate = async() => {
    let data = {};
    if (searchActions === 18) {
      data = {
        assignIds: assignIds,
        follow_up_date_one: moment(followUpDetails.date[0]).format(
          "YYYY-MM-DD"
        ),
        follow_up_time_one: moment(followUpDetails.time).format("HH:mm:ss"),
        statusId: searchActions,
        userId: localStorage.getItem("userID"),
      };
    } else if (searchActions === 19) {
      data = {
        assignIds: assignIds,
        follow_up_date_two: moment(followUpDetails.date[0]).format(
          "YYYY-MM-DD"
        ),
        follow_up_time_two: moment(followUpDetails.time).format("HH:mm:ss"),
        statusId: searchActions,
        userId: localStorage.getItem("userID"),
      };
    }

    await Axios.post(
      `${UrlLinks.baseUrl}${
        UrlLinks.leadData.setLeadApplicationStatusInBulk
      }?assignIds=${data.assignIds}&statusId=${data.statusId}${
        searchActions === 18
          ? `&follow_up_date_one=${data.follow_up_date_one}&follow_up_time_one=${data.follow_up_time_one}`
          : searchActions === 19
          ? `&follow_up_date_two=${data.follow_up_date_two}&follow_up_time_two=${data.follow_up_time_two}`
          : ""
      }&userId=${data.userId}`
    )
      .then((response) => {
        showToast("success", "Success", "Status updated successfully.");
      })
      .catch((err) => {
        console.log("err", err);
        showToast("error", "Something went wrong, please try after sometime.");
      });
    setOpenOnboardingModal(false);
    updateMyData();
  };

  const handleFollowUpDetails = (event, index, type) => {
    if (type === "date") {
      let dates = followUpDetails.date;
      dates[index] = event;
      setFollowUpDetails({ ...followUpDetails, [type]: dates });
    } else if (type === "time") {
      setFollowUpDetails({ ...followUpDetails, [type]: event });
    }
  };

  let dialogData = (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={openOnboardingModal}
      className={classes.root}
      classes={{ paper: Styles.paperFollowUp }}
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        <span>{title}</span>
      </DialogTitle>
      <DialogContent dividers className={`${Styles.FollowUpDialogContent}`}>
        <Grid item xs={12} className={`${Styles.FollowUpDate}`}>
          <span>Follow Up Date &nbsp; :</span>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              id="billDateFrom"
              placeholder="Select Date"
              format="dd/MM/yyyy"
              inputVariant="outlined"
              className={Styles.datePicker}
              clearable
              value={followUpDetails.date[0]}
              onChange={(date) => handleFollowUpDetails(date, 0, "date")}
              keyboardIcon={
                <img src={Calendar} alt="calendar" width="18px" height="18px" />
              }
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12} className={`${Styles.FollowUpTime}`}>
          <span>Follow Up Time &nbsp; :</span>
          <TimeFilter
            uniqueKey="followUpTime"
            timeRange={false}
            disabled={false}
            ampm={true}
            val={followUpDetails.time}
            handleChange={handleFollowUpDetails}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={handleStatusUpdate}
          className={`${
            followUpDetails.date.length === 0 || followUpDetails.time === null
              ? "disabledButton"
              : "blueButtonContained"
          }`}
          disabled={
            followUpDetails.date.length === 0 || followUpDetails.time === null
          }
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );

  return dialogData;
}
