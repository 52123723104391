import { createContext, useState } from "react";

export const AppData = createContext(null);

function Context({ children }) {
  const [formMode, setFormMode] = useState("create");
  const [mpaFormMode, setMpaFormMode] = useState("create");
  const [jobConfigMode,setJobConfigMode] = useState("create")
  const [eligibilityMode,setEligibilityMode] = useState("create")
  const [eligibilityJobId,setEligibilityJobId] = useState(null)
  

  return (
    <AppData.Provider value={{ formMode, setFormMode, mpaFormMode, setMpaFormMode, jobConfigMode, setJobConfigMode, eligibilityMode,setEligibilityMode,eligibilityJobId,setEligibilityJobId}}>
      {children}
    </AppData.Provider>
  );
}

export default Context;