import React, { useState, useEffect, useMemo, useContext } from "react";
import styled from "styled-components";
import {
  Button,
  Grid,
  IconButton,
  Checkbox,
  TextField,
  InputAdornment,
  Drawer,
} from "@material-ui/core";
import { useTable, useRowSelect, useSortBy, useFilters } from "react-table";
import urlLinks from "../../UrlLinks";
import { Link } from "react-router-dom";
import Axios from "axios";
import SearchIcon from "@material-ui/icons/Search";
import CloseSharpIcon from "@material-ui/icons/CloseSharp";
import DownloadReport from "./components/DownloadReport";
import Pagination from "../../Utils/Pagination";
import { makeStyles } from "@material-ui/core/styles";
import BGVStatus from "./components/BGVDocumentStatus";
import BGVVerificationModal from "./components/BGVVerificationModal";
import Filters from "./filters";
import NoRecord from "./noRecord.svg";
import styles from "./index.module.scss";
import ScaleLoader from "react-spinners/ScaleLoader";
import { AuthContext } from "../../services/AuthProvider";
import "./indeterminateCheckbox.css";

const Styles = styled.div`
  padding: 1rem;
  .tableContainer {
    overflow-x: scroll;
    border: 1px solid #cfcfcf;
    max-height: 74vh;
  }
  .tableContainer::-webkit-scrollbar {
    width: 0.75em;
  }

  .tableContainer::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  .tableContainer::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    //   border-radius: 8px;
  }
  table {
    border-spacing: 0;
    width: max-content;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    th {
      :last-child {
        border-left: 0;
      }
      background-color: #1c75bc !important;
      color: #ffffff;
    }
    th,
    td {
      padding: 5px 15px;
    }

    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid #cfcfcf;
      border-right: 1px solid #cfcfcf;
      background-color: white;
      nth-last-child(2) {
        border-right: 0;
      }
      :last-child {
        border-right: 0;
      }

      input {
        font-size: 1rem;
        padding: 0;
        margin: 0;
        border: 0;
      }
    }
  }

  .pagination {
    padding: 0.5rem;
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-root": {
      marginLeft: "0",
      "&.Mui-focused fieldset": {
        border: "1px solid grey",
      },
    },
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  input: {
    '& [class*="MuiOutlinedInput-input"]': {
      padding: "8px 135px 8px 8px",
      borderLeft: "1px solid grey",
      borderRadius: "0px",
    },
  },
  select: {
    "& .MuiOutlinedInput-inputMarginDense": {
      padding: "8px 10px",
    },
  },
}));

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <React.Fragment>
        <Checkbox ref={resolvedRef} {...rest} />
      </React.Fragment>
    );
  }
);

const ExternalWorker = () => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const { showToast } = useContext(AuthContext);
  const [bgvFinalStatus, setBgvFinalStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [openBGVTriggerModal, setOpenBGVTriggerModal] = useState(false);
  const [searchBy, setSearchBy] = useState("");
  const [searchByUrl, setSearchByUrl] = useState("");
  const [BGVResponse, setBGVResponse] = useState(null);
  const [filtersUrl, setFiltersUrl] = useState("");
  const [filters, setFilters] = useState({
    adhaar: "",
    pan: "",
    dl: "",
    permanentAddress: "",
    currentAddress: "",
    criminal: "",
    court: "",
  });

  const handleSearch = () => {
    setSearchByUrl(`&searchBy=${searchBy}`);
    setCurrentPage(1);
  };

  const handleSearchClose = () => {
    setSearchBy("");
    setSearchByUrl("");
  };

  const ApplyFilters = () => {
    let url = "";

    if (filters["adhaar"]) {
      url = `${url}&AadhaarStatus=${filters["adhaar"].id}`;
    }
    if (filters["pan"]) {
      url = `${url}&panStatus=${filters["pan"].id}`;
    }
    if (filters["dl"]) {
      url = `${url}&dlStatus=${filters["dl"].id}`;
    }
    if (filters["permanentAddress"]) {
      url = `${url}&permanentAddress=${filters["permanentAddress"].id}`;
    }
    if (filters["currentAddress"]) {
      url = `${url}&currentAddress=${filters["currentAddress"].id}`;
    }
    if (filters["criminal"]) {
      url = `${url}&criminalVerification=${filters["criminal"].id}`;
    }
    if (filters["court"]) {
      url = `${url}&courtVerification=${filters["court"].id}`;
    }
    setFiltersUrl(url);
    setCurrentPage(1);
  };

  const resetFilters = () => {
    const filterList = {
      billDate: [null, null],
      zone: "",
      facility_state: "",
      facility_Name: "",
      payment_status: "",
      worker_status: "",
      worker_type: "",
      payment_type: "",
      advanceTaken: "",
      bankDetails: "",
    };
    setFilters(filterList);
    setFiltersUrl("");
    setCurrentPage(1);
    return filterList;
  };

  const columns = useMemo(
    () => [
      {
        id: "selection",
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <div>
            <IndeterminateCheckbox
              {...getToggleAllRowsSelectedProps()}
              className="overrideCheckbox"
              color="white"
            />
          </div>
        ),
        Cell: ({ row }) => (
          <div>
            <IndeterminateCheckbox
              {...row.getToggleRowSelectedProps()}
              style={{ display: "flex", justifyItems: "center" }}
            />
          </div>
        ),
      },
      {
        Header: "OkayGo id",
        accessor: "okaygoId",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Name",
        accessor: "name",
        Cell: (row) => {
          return (
            <Link
              to={{ pathname: `/workerview/v2/${row.row.original.userId}` }}
              target="_blank"
              className="nameLink"
            >
              {row.row.original.name}
            </Link>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Contact No.",
        accessor: "contactNo",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Aadhaar No.",
        accessor: "aadhaarNo",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "BGV Document Status",
        accessor: "overallStatus",
        Cell: ({ row }) => {
          return (
            <BGVStatus status={row.original.overallStatus} isListing={true} />
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Driving License",
        accessor: "dlStatus",
        Cell: ({ row }) => {
          return <BGVStatus status={row.original.dlStatus} />;
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Aadhaar Card",
        accessor: "aadhaarStatus",
        Cell: ({ row }) => {
          return <BGVStatus status={row.original.aadhaarStatus} />;
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "PAN Card",
        accessor: "panStatus",
        Cell: ({ row }) => {
          return <BGVStatus status={row.original.panStatus} />;
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Permanent Address",
        accessor: "addressStatus",
        Cell: ({ row }) => {
          return <BGVStatus status={row.original.addressStatus} />;
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Current Address",
        accessor: "currentAddressStatus",
        Cell: ({ row }) => {
          return <BGVStatus status={row.original.currentAddressStatus} />;
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Court Permanent Address",
        accessor: "criminalStatus",
        Cell: ({ row }) => {
          return <BGVStatus status={row.original.criminalStatus} />;
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Court Current Address",
        accessor: "courtStatus",
        Cell: ({ row }) => {
          return <BGVStatus status={row.original.courtStatus} />;
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "BGV Report",
        Cell: DownloadReport,
        defaultCanFilter: false,
        defaultCanSort: false,
      },
    ],
    []
  );

  //Function to create table data
  const makeData = (response) => {
    let extractedData = [];
    response.content.map((el) => {
      extractedData.push({
        okaygoId: el.okaygoId || "",
        name: `${el.firstName ? el.firstName : ""} ${
          el.lastName ? el.lastName : ""
        }`,
        contactNo: el.phoneNumber || "",
        aadhaarNo: el.aadharNumber,
        overallStatus: el.bgvFinalStatus,
        dlStatus: el.drivingLicense,
        aadhaarStatus: el.aadharCard,
        panStatus: el.panCard,
        addressStatus: el.permanentAddress,
        currentAddressStatus: el.currentAddress,
        criminalStatus: el.criminal,
        courtStatus: el.court,
        userId: el.userId,
        workerId: el.workerId,
      });
      return true;
    });

    return extractedData;
  };

  const updateMyData = () => {
    getPaymentListing(perPage, currentPage);
  };

  const getPaymentListing = (rows, pageNo) => {
    setLoading(true);
    let requestedBy = "";

    Axios.get(
      `${urlLinks.baseUrl}${urlLinks.BGVListing}?page_no=${
        pageNo - 1
      }${requestedBy}&rows=${rows}${filtersUrl}${bgvFinalStatus}${searchByUrl}`
    )
      .then((response) => {
        if (response) {
          setTotalRows(response.data.response.totalElements);
          setTotalPages(response.data.response.totalPages);
          if (currentPage > response.data.response.totalPages)
            setCurrentPage(1);
          setData(makeData(response.data.response));
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getPaymentListing(perPage, 1);
    setCurrentPage(1);
  }, [filtersUrl, searchByUrl, bgvFinalStatus]);

  useEffect(() => {
    if (BGVResponse) {
      setOpenBGVTriggerModal(true);
    }
  }, [BGVResponse]);
  
  const handleCloseBGVModal = () => {
    setBGVResponse(null);
    setOpenBGVTriggerModal(false);
    updateMyData();
  };

  const handlePageChange = (page) => {
    getPaymentListing(perPage, page);
    setCurrentPage(page);
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      updateMyData,
      showToast,
      setLoading,
      filters,
      setFilters,
      manualSortBy: true,
    },
    useFilters,
    useSortBy,
    useRowSelect
  );

  const handlePerRowsChange = (newPerPage) => {
    getPaymentListing(newPerPage, 1);
    setPerPage(newPerPage);
    setCurrentPage(1);
  };

  const HandleReverify = () => {
    const indexes = Object.entries(selectedRowIds);
    let idList = [];

    indexes.map((id, index) => {
      idList.push(data[parseFloat(id[0])].userId);
      return true;
    });
    Axios.post(
      `${urlLinks.baseUrl}${
        urlLinks.workerUrls.bgvReverify
      }${idList.toString()}`
    )
      .then((response) => {
        let errors = [];
        response.data.response.invalidRecords.map((error) => {
          errors.push({
            okaygoId: error.okaygoId,
            reason: error.issue,
          });
          return true;
        });
        setBGVResponse({
          errorList: errors,
          success: response.data.response.totalUploaded,
          fail: response.data.response.invalidCount,
          total: response.data.response.totalAttempted,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const loader = (
    <div style={{ margin: "130px auto" }}>
      <ScaleLoader height={70} width={10} color={"#1c75bc"} />
    </div>
  );

  const table = (
    <div className="tableContainer">
      <table {...getTableProps()} className="table">
        <thead className="thead">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps()}
                  className={
                    column.render("Header") === "Worker Id"
                      ? "stickWorkerIdnewMatch zIndex1080 header"
                      : column.render("Header") === "Name"
                      ? "stickWorkerNameV2 zIndex1080 header"
                      : column.id === "selection"
                      ? "stickCheckbox zIndex1080 header"
                      : column.render("Header") === "Last SMS sent"
                      ? "width200"
                      : column.id === "Mark onHold"
                      ? "displayNone"
                      : "header"
                  }
                >
                  <span {...column.getSortByToggleProps()}>
                    {column.render("Header")}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} className="row">
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        className={
                          cell.column.Header === "Worker Id"
                            ? "stickWorkerIdnewMatch  zIndex540"
                            : cell.column.Header === "Name"
                            ? "stickWorkerNameV2 zIndex540"
                            : cell.column.id === "selection"
                            ? "stickCheckbox zIndex540"
                            : cell.column.Header === "Mark onHold"
                            ? "displayNone"
                            : "cell"
                        }
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        }
      </table>
    </div>
  );

  return (
    <Styles>
      <Grid item xs={12} className="flexDisplay" alignItems="flex-start">
        <span className={styles.headingBGV}>Worker BGV</span>
      </Grid>
      <Grid item xs={12} className="flexDisplay" alignItems="flex-start">
        <Grid
          item
          sm={7}
          className="flexDisplay padding0 paddingTop8"
          direction="row"
          justify="space-between"
        >
          <TextField
            className={`${classes.root} marginLeft0`}
            placeholder={"Contact No / OkayGo ID / Aadhaar No"}
            fullWidth
            value={searchBy}
            onChange={(event) => setSearchBy(event.target.value)}
            variant="outlined"
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleSearch();
              }
            }}
            InputProps={{
              className: classes.input,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <div className={styles.searchButtonDiv}>
            <IconButton
              className={`${styles.closeButton} ${
                searchBy ? "" : styles.noVisibility
              }`}
              onClick={handleSearchClose}
            >
              <CloseSharpIcon />
            </IconButton>
            <button onClick={handleSearch} className={styles.searchButton}>
              Search
            </button>
          </div>
        </Grid>
        <Grid
          item
          xs={5}
          className="flexDisplay paddingTop8"
          direction="row"
          justifyContent="flex-end"
        >
          <button
            className={styles.blueFilledButton}
            style={{ marginLeft: "16px" }}
            onClick={HandleReverify}
            disabled={Object.keys(selectedRowIds).length === 0}
          >
            Reverify
          </button>
          <button
            className={styles.blueOutlinedButton}
            style={{ marginLeft: "16px" }}
            onClick={() => setOpenFilterDrawer(true)}
          >
            filters
          </button>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        className="flexDisplay"
        alignItems="flex-start"
        style={{ marginTop: "20px" }}
      >
        <Button
          onClick={() => {
            setBgvFinalStatus("");
          }}
          className={`statusButtons marginRight20 ${
            bgvFinalStatus === "" ? "blueColorBorder" : "greyColorBorder"
          }`}
        >
          All
        </Button>
        <Button
          onClick={() => {
            setBgvFinalStatus("&bgvFinalStatus=1");
          }}
          className={`statusButtons marginRight20 ${
            bgvFinalStatus === "&bgvFinalStatus=1"
              ? "blueColorBorder"
              : "greyColorBorder"
          }`}
        >
          In Process
        </Button>
        <Button
          onClick={() => {
            setBgvFinalStatus("&bgvFinalStatus=2");
          }}
          className={`statusButtons marginRight20 ${
            bgvFinalStatus === "&bgvFinalStatus=2"
              ? "blueColorBorder"
              : "greyColorBorder"
          }`}
        >
          Verified
        </Button>
        <Button
          onClick={() => {
            setBgvFinalStatus("&bgvFinalStatus=3");
          }}
          className={`statusButtons marginRight20 ${
            bgvFinalStatus === "&bgvFinalStatus=3"
              ? "blueColorBorder"
              : "greyColorBorder"
          }`}
        >
          Failed
        </Button>
      </Grid>
      {loading ? (
        loader
      ) : data.length === 0 ? (
        <div className={styles.noRecord}>
          <img src={NoRecord} alt="noRecord" />
          <span>No records found</span>
        </div>
      ) : (
        <Grid item xs={12} className="paddingTop16">
          {table}
          <Pagination
            rowsperpage={perPage}
            pageNo={currentPage}
            totalElements={totalRows}
            totalPages={totalPages}
            handlePageChange={handlePageChange}
            handlePerRowsChange={handlePerRowsChange}
          />
        </Grid>
      )}

      {openBGVTriggerModal && (
        <BGVVerificationModal
          response={BGVResponse}
          openBGVTriggerModal={openBGVTriggerModal}
          handleClose={handleCloseBGVModal}
        />
      )}
      <Drawer
        anchor="right"
        open={openFilterDrawer}
        onClose={() => setOpenFilterDrawer(false)}
      >
        <Filters
          handleClose={() => setOpenFilterDrawer(false)}
          filters={filters}
          setFilters={setFilters}
          applyFilters={ApplyFilters}
          resetFilters={resetFilters}
        ></Filters>
      </Drawer>
    </Styles>
  );
};

export default ExternalWorker;
