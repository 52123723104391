import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Calendar from '../../../assets/calendar.svg'
// import * as moment from 'moment';
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import Styles from '../index.module.scss'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    borderBottom: "unset",

  }
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  }
}))(MuiDialogActions);

export default function CustomizedDialogs(props) {
    const {open, handleClose , handleReactivationDate, setReactivationData} = props
    // console.log({open})
    const handleDate =(date) =>{
      // console.log(moment(date).format("DD/MM/YYYY"))

      setReactivationData({
        ...open,
        date: date
      })
    }
    // console.log({open})
  
    let dialogData=
        <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open.open}
            className={Styles.onboardingDialog}
            classes={{paper: Styles.paper}}
        >
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
              <span>Activate Worker</span> 
            </DialogTitle>
            <DialogContent dividers>
              <Grid item xs style={{padding:"40px"}}>
                <span className={"fontSize18 fontWeight600"} style={{fontFamily: "Nunito"}}>Enter activation date</span>
                <MuiPickersUtilsProvider utils={DateFnsUtils} style={{marginTop:"12px"}}>
                  <KeyboardDatePicker
                      id="billDateFrom"
                      placeholder="Activation date"
                      format="dd/MM/yyyy"
                      inputVariant="outlined"
                      className={styles.datePicker}
                      style={{marginTop:"20px"}}
                      clearable
                      fullWidth
                      value={open.date}
                      onChange={handleDate}
                      keyboardIcon={<img src={Calendar} alt="calendar" width="18px" height="18px"/>}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" disabled={!open.date} onClick={handleReactivationDate} className={"blueButtonContained"} >
                  Done
              </Button>
            </DialogActions>
        </Dialog>
    return dialogData; 
  
}
