var crypto = require('crypto');

// module.exports.Decrypt =  decrypt=(messagebase64)=> {
//     var keyBase64 = "T2theUdvRWZsZXg2NzUhIQ==";
//     var ivBase64  = 'T2theUdvRWZsZXg2NzUhIQ==';
    
//     const key = Buffer.from(keyBase64, 'base64');
//     const iv  = Buffer.from(ivBase64, 'base64');

//     const decipher = crypto.createDecipheriv(getAlgorithm(keyBase64), key, iv.slice(0, 16));
//     let decrypted  = decipher.update(messagebase64, 'base64');
//     decrypted += decipher.final();
//     return decrypted;
// }

var keyBase64 = "T2theUdvRWZsZXg2NzUhIQ==";
var ivBase64  = 'T2theUdvRWZsZXg2NzUhIQ==';
module.exports.Decrypt=(messagebase64)=> {
  try{
    const key = Buffer.from(keyBase64, 'base64');
    const iv  = Buffer.from(ivBase64, 'base64');

    const decipher = crypto.createDecipheriv(getAlgorithm(keyBase64), key, iv.slice(0, 16));
    let decrypted  = decipher.update(messagebase64, 'base64');
    decrypted += decipher.final();
    // console.log(decrypted)
    return decrypted;}catch(e){
        return"";
    }
}
function getAlgorithm(keyBase64) {

    var key = Buffer.from(keyBase64, 'base64');
    switch (key.length) {
        case 16:
            return 'aes-128-cbc';
        case 32:
            return 'aes-256-cbc';

    }

    throw new Error('Invalid key length: ' + key.length);
}


module.exports.Encrypt=(plainText) =>{

    const key = Buffer.from(keyBase64, 'base64');
    const iv  = Buffer.from(ivBase64, 'base64');

    const cipher  = crypto.createCipheriv(getAlgorithm(keyBase64), key, iv.slice(0, 16));
    let encrypted = cipher.update(plainText, 'utf8', 'base64');
    encrypted += cipher.final('base64');
    return encrypted;
}




// var plainText = '7440906895';
// kPMsaVWiTK65owhQ5S0LiA==
// var cipherText          = "kPMsaVWiTK65owhQ5S0LiA=="
// var decryptedCipherText = Decrypt(cipherText, keyBase64, ivBase64);

// console.log('Algorithm: ' + getAlgorithm(keyBase64));
// console.log('Plaintext: ' + plainText);
// console.log('Ciphertext: ' + cipherText);
// console.log('Decoded Ciphertext: ' + decryptedCipherText);
// Decrypt;