import React from "react";
import MenuItem from "@mui/material/MenuItem";
import {
  Select,
  FormControl,
  Checkbox,
  ListItemText,
  ListItemIcon,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    "&.MuiSelect-select": {
      padding: "10px",
      width: "93%",
    },
  },
  disabled: {
    background: "#F5F5F5",
    color: "#959595 !important",
  },
}));

export default function MultipleSelectFilter(props) {
  const classes = useStyles(200);
  const {
    options,
    id,
    disabled,
    alreadySelectedValue,
    setAlreadySelectedValue,
  } = props;

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    setAlreadySelectedValue([...value.sort((a, b) => a.id - b.id)]);
  };

  return (
    <FormControl variant="outlined" style={{ width: "613px" }}>
      <Select
        multiple={true}
        id={id}
        options={options}
        value={alreadySelectedValue}
        onChange={handleChange}
        style={{ height: "40px" }}
        classes={{
          root: classes.root,
          disabled: classes.disabled,
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          getContentAnchorEl: null,
          style: {
            marginTop: "45px",
            height: "290px",
          },
        }}
        renderValue={(selected) => selected.map((e) => e.name).join(",")}
        disabled={disabled}
      >
        {options.map((option) => (
          <MenuItem key={option.id} value={option}>
            <ListItemIcon>
              <Checkbox
                name="select-checkbox"
                checked={alreadySelectedValue
                  .map((e) => e.id)
                  .includes(option.id)}
              />
            </ListItemIcon>
            <ListItemText primary={option.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
