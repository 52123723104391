// show loader
export const LOADER = "LOADER";
export const MODAL_LOADER = "MODAL_LOADER";

// handle pagination
export const CURRENT_PAGE = "CURRENT_PAGE";
export const PER_PAGE = "PER_PAGE";
export const TOTAL_ROWS = "TOTAL_ROWS";
export const TOTAL_PAGES = "TOTAL_PAGES";

// handle filters
export const IS_FILTER = "IS_FILTER";
export const SELECTED_ASSIGN_IDS = "SELECTED_ASSIGN_IDS";
export const SELECTED_USER_IDS = "SELECTED_USER_IDS";
export const OPEN_FILTER_DRAWER = "OPEN_FILTER_DRAWER";
export const CUR_LEAD_ID = "CUR_LEAD_ID";
export const CUR_ID = "CUR_ID";
export const TAB_VALUE = "TAB_VALUE";
export const DRIVE_TAB = "DRIVE_TAB";

// handle cancel tokens
// export const CANCEL_PREVIOUS_REQUEST = 'CANCEL_PREVIOUS_REQUEST';
// export const SET_CANCEL_TOKEN = 'SET_CANCEL_TOKEN';


// get user data in the table
// export const USER_DATA = "USER_DATA";
export const ROW_DATA = "ROW_DATA";
export const ALL_RECORD = "ALL_RECORD";