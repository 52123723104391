import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../../store/actions/index";
import Axios from "axios";
import { Stack } from "@mui/material";
import UrlLinks from "../../../UrlLinks";
import { makeStyles } from "@material-ui/styles";
import styles from "./Components.module.scss";
import { Close } from "@material-ui/icons";
import { Box, Button, IconButton, Modal, TextField } from "@material-ui/core";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 439,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  filter: "drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.15))",
};

const usestyles = makeStyles({
  remarkTextField: {
    "& .MuiFormHelperText-root": {
      textAlign: "end",
    },
    "& .MuiInputBase-input": {
      height: "80px !important",
    },
  },
  input: {
    padding: "9px",
  },
});

export default function RemarkOkayGo(props) {
  const {
    open,
    setOpen,
    setRemarkChanged,
    record,
    showToast,
    updateMyData,
    title,
    placeholder,
    submitButton,
    cancelButton,
  } = props;

  /** useDispatch is used to update states to redux store **/

  const dispatch = useDispatch();
  const { setLoader } = bindActionCreators(actionCreators, dispatch);

  /** other states **/

  const classes = usestyles();
  const [remarkWritten, setRemarkWritten] = useState(
    record?.remarks ? record?.remarks : ""
  );

  const handleClose = () => setOpen(false);

  const onChange = (e) => {
    setRemarkWritten(e.target.value);
  };

  const handleOk = async () => {
    setLoader(true);
    let data = {
      assignId: record.assignId,
      remarks: remarkWritten,
    };
    Axios.post(
      `${UrlLinks.baseUrl}${UrlLinks.leadData.setRemark}?assignId=${data.assignId}&remarks=${data.remarks}`
    )
      .then((res) => {
        showToast("success", "Success", "Remark updated successfully.");
      })
      .catch((err) => {
        showToast(
          "error",
          "Error",
          "Something went wrong, please try after sometime."
        );
      })
      .finally(() => {
        setLoader(false);
      });
    setLoader(false);
    setOpen(false);
    updateMyData();
    setRemarkChanged(remarkWritten);
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        className={styles.remarkModal}
      >
        <Box sx={style} className={styles.remarkBox}>
          <p className={styles.header}>
            {title}
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </p>
          <Stack spacing={3}>
            <TextField
              variant="outlined"
              value={remarkWritten}
              inputProps={{ maxLength: 50 }}
              placeholder={placeholder}
              multiline
              fullWidth
              onChange={onChange}
              helperText={`${remarkWritten.length}/50`}
              className={classes.remarkTextField}
              InputProps={{
                className: classes.input,
              }}
            />
          </Stack>
          <div className={styles.footer}>
            <Button className="blueColorOutlined" onClick={handleClose}>
              {cancelButton}
            </Button>
            <Button
              variant="contained"
              className="dataButton"
              onClick={handleOk}
            >
              {submitButton}
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
