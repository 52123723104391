import React, { createContext, useCallback, useState } from "react";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import Pool from "./UserPool";
import UserService from "./UserService";
import { toast, ToastContainer } from "react-toastify";
import Axios from "axios";
import { Encrypt } from "./AESHandler";

export const AuthContext = createContext({});

const AuthProvider = (props) => {
  const [session, setSession] = useState(null);
  const [userDetails, setUserDetails] = useState(null);

  const showToast = (type, message, id) => {
    if (id) {
      if (type === "success") toast.success(message, { toastId: id });
      else if (type === "error") toast.error(message);
    } else {
      if (type === "success") toast.success(message);
      else if (type === "error") toast.error(message);
    }
  };

  const initSession = useCallback(
    () =>
      new Promise((resolve, reject) => {
        const user = Pool.getCurrentUser();

        if (user) {
          user.getSession((err, userSession) => {
            setSession(err ? null : userSession);
            resolve();
          });
        } else {
          setSession(null);
          resolve();
        }
      }),
    []
  );

  const logout = useCallback(() => {
    const user = Pool.getCurrentUser();
    if (user) {
      user.signOut();
      localStorage.clear();
      sessionStorage.clear();
      setSession(null);
    }
  }, []);

  const forgotPassword = useCallback(
    async (Username, setOtpSent) =>
      await new Promise((resolve, reject) => {
        const user = new CognitoUser({ Username, Pool });
        user.forgotPassword({
          onSuccess: function (data) {
            if (setOtpSent) {
              setOtpSent(true);
            }
            showToast("success", "Verification code sent successfully");
          },

          onFailure: function (err) {
            showToast("error", err.message);
            if (setOtpSent) {
              setOtpSent(false);
            }
          },
        });
      }),
    []
  );

  const validateResetPasswordCode = useCallback(
    async (Username, newPassword, verificationCode, setWrongCode) =>
      await new Promise((resolve, reject) => {
        const user = new CognitoUser({ Username, Pool });
        user.confirmPassword(verificationCode, newPassword, {
          onSuccess() {
            showToast("success", "Password updated successfully");
            logout();
            if (setWrongCode) {
              setWrongCode("");
            }
            window.location.replace("/");
          },
          onFailure(err) {
            if (
              err.message ===
              "PostConfirmation failed with error HandlerDemo.ResponseFound:Resource is Redirecting to qa-eflex.okaygo.in."
            ) {
              showToast("success", "Password updated successfully");
              if (setWrongCode) {
                setWrongCode("");
                window.location.replace("/");
              }
              logout();
            } else if (
              err.message ===
              "Invalid verification code provided, please try again."
            ) {
              if (setWrongCode) {
                setWrongCode(
                  "Entered verification code is incorrect. Please check and enter again"
                );
              } else {
                showToast(
                  "error",
                  "Entered verification code is incorrect. Please check and enter again"
                );
              }
            } else {
              showToast("error", err.message);
            }
          },
        });
      }),
    []
  );

  const login = useCallback(
    async (Username, Password) =>
      await new Promise((resolve, reject) => {
        const user = new CognitoUser({ Username, Pool });
        const authDetails = new AuthenticationDetails({ Username, Password });
        const userService = new UserService();
        Axios.defaults.headers.common["Authorization"] =
          localStorage.getItem("accessToken");
        user.authenticateUser(authDetails, {
          onSuccess: (data) => {
            localStorage.setItem("accessToken", data.accessToken.jwtToken);
            userService
              .loginToPortal(Username, data.accessToken.jwtToken)
              .then(
                (response) => {
                  if (response.ok) {
                    let data = response.json();
                    setUser(data);
                    initSession().then(resolve);
                  } else {
                    logout();
                    reject(
                      "AuthProvider:login:onSuccess: loginToPortal API Failure"
                    );
                    logout();
                  }
                },
                (err) => {
                  logout();
                  reject(err);
                }
              )
              .catch((error) => {
                logout();
                reject(error);
              });
          },

          onFailure: (err) => {
            reject(err);
          },

          newPasswordRequired: (data) => {
            resolve();
          },
        });
      }),
    [initSession]
  );

  const setUser = useCallback((response) => {
    response.then((data) => {
      localStorage.setItem("userName", data.userName);
      localStorage.setItem("fullName", data.fullName);
      localStorage.setItem("userID", data.userID);
      localStorage.setItem("roleType", data.roleType);
      localStorage.setItem("projectId", data.projectId);
      localStorage.setItem("emailId", Encrypt(data.emailId));
      localStorage.setItem("password", Encrypt(data.password));
      localStorage.setItem(
        "asaanRole",
        data.roleType === 25 || data.roleType === 26 || data.roleType === 30
      );
      setUserDetails({
        fullName: data.fullName,
        userID: data.userID,
        roleType: data.roleType,
        userName: data.userName,
      });
    });
  }, []);

  return (
    <AuthContext.Provider
      value={{
        initSession,
        login,
        logout,
        session,
        setUser,
        userDetails,
        forgotPassword,
        validateResetPasswordCode,
        showToast,
      }}
    >
      <div>
        <ToastContainer
          position="top-right"
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          autoClose={5000}
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        ></ToastContainer>
        {props.children}
      </div>
    </AuthContext.Provider>
  );
};

export default AuthProvider;
