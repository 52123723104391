import React,  {Fragment, useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { AuthContext } from './AuthProvider';
import Header from '../containers/Header';
const AuthenticatedRoute = ({ component: Component, ...rest }) => {
  const { session } = useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={(props) =>
        session ? (
          <Fragment>
            <Header />
            <Component {...props} />
          </Fragment>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
};

export default AuthenticatedRoute;
