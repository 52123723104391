import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Axios from "axios";
import document from "../document.svg";
import ResponseModal from "./ResponseModal";
import * as moment from "moment";
import Typography from "@material-ui/core/Typography";
import Download from "../Group 3238.svg";
import { Grid } from "@material-ui/core";
import Styles from "../index.module.scss";
import UrlLinks from "../../../UrlLinks";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    borderBottom: "unset",
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function CustomizedDialogs(props) {
  const {
    openOffboardingModal,
    setOpenOffboardingModal,
    showToast,
    updateMyData,
  } = props;
  const [excel, setExcel] = useState(null);
  const [name, setName] = useState("");
  const [responseData, setResponseData] = useState();
  const [invalidData, setInvalidData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [fileLink, setFileLink] = useState(null);
  const [excelError, setExcelError] = useState(false);
  const [openResponseModal, setOpenResponseModal] = useState(false);
  const handleClose = () => {
    setOpenOffboardingModal(false);
  };
  const handleUpload = (event) => {
    const fileName = event.target.files[0].name.split(".").slice(-1)[0];
    if (fileName.toLowerCase() === "xlsx" || fileName.toLowerCase() === "xls") {
      setExcel(event.target.files[0]);
      setName(event.target.files[0].name);
      setExcelError(false);
    } else {
      setExcelError(true);
      setExcel(null);
      setName("");
    }
    setResponseData();
  };

  let newOffboardingExcel =
    "https://www.okaygo.in/Offboarding_Multiple_Client_Template.xlsx";
  const createInvalidData = (records) => {
    if (records.length > 1) {
      let list = [];
      records.map((record) => {
        if (record.hasOwnProperty("WorkerDetail")) {
          if (
            record.WorkerDetail.isWorkerOffboarded === 0 &&
            "uploadStatus" !== "Offboarding initiated for this worker"
          ) {
            list.push({
              "Client ID": record.WorkerDetail.casperId,
              "OkayGo ID": record.WorkerDetail.okaygoId,
              "Exit date": record.WorkerDetail.exitDate
                ? moment(record.WorkerDetail.exitDate).format("DD/MM/YYYY")
                : "",
              "Exit Type": record.WorkerDetail.exitType,
              "Exit reason": record.WorkerDetail.exitReason,
              "Profile Blacklisted":
                record.WorkerDetail.isProfileBlacklisted === 1
                  ? "Yes"
                  : record.WorkerDetail.isProfileBlacklisted === 0
                  ? "No"
                  : "",
              Client: record.WorkerDetail.partner || "",
              "Reason for failure": record.WorkerDetail.uploadStatus,
            });
          }
        }
        return true;
      });
      return list.length > 0 ? list : null;
    }
  };

  const handleExcel = () => {
    setIsLoading(true);
    let formData = new FormData();
    formData.append("file", excel);

    Axios.post(
      `${UrlLinks.baseUrl}${
        UrlLinks.workerUrls.offBoardWorkers
      }${localStorage.getItem("userID")}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
      .then((response) => {
        if (response.data.response) {
          setInvalidData(createInvalidData(response.data.response));
          const summary = response.data.response.slice(-1)[0];
          setResponseData(summary);
          updateMyData();
          setIsLoading(false);
        } else {
          setResponseData([]);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        showToast("error", error.message);
      });
  };

  useEffect(() => {
    if (responseData) {
      setOpenResponseModal(true);
    }
  }, [responseData]);

  let dialogData = (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={openOffboardingModal}
      className={Styles.onboardingDialog}
      classes={{ paper: Styles.paper }}
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        <span>Upload Offboarding Data</span>
        <a
          href={newOffboardingExcel}
          target="_blank"
          download
          className={Styles.downloadTemplate}
        >
          <span>Download File Template</span>
          <img src={Download} alt="download" />
        </a>
      </DialogTitle>
      <DialogContent dividers>
        <Grid item xs className="padding0">
          <input
            style={{ display: "none" }}
            id="contained-button-file"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            type="file"
            onChange={handleUpload}
          />
          <label htmlFor="contained-button-file">
            <Button
              variant="outlined"
              className={Styles.uploadButton}
              component="span"
              startIcon={<img src={document} alt={"upload"} />}
            >
              Browse File
            </Button>
          </label>
          {name && (
            <span className={Styles.fileName}>
              <span>File name: </span>
              {`${name}`}
            </span>
          )}
        </Grid>
        <Grid item xs={12} className="padding0">
          <span className={`fontSize13 ${excelError ? "redColor" : ""}`}>
            only .xls and .xlsx are allowed
          </span>
        </Grid>
      </DialogContent>
      <DialogActions>
        {isLoading ? (
          <span>Please wait... it may take few minutes</span>
        ) : (
          <Button
            variant="contained"
            onClick={handleExcel}
            className={`${!excel ? "disabledButton" : "blueButtonContained"}`}
            disabled={!excel}
          >
            Upload
          </Button>
        )}
      </DialogActions>
      {openResponseModal && (
        <ResponseModal
          openResponseModal={openResponseModal}
          setOpenResponseModal={setOpenResponseModal}
          responseData={responseData}
          setOpenOnboardingModal={setOpenOffboardingModal}
          showToast={showToast}
          invalidData={invalidData}
          type="Offboarding"
        />
      )}
    </Dialog>
  );

  return dialogData;
}
