import React, { useEffect, useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import { Box, IconButton, Modal } from "@material-ui/core";
import ResponseTable from "./ResponseTable";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function AssignToRecruiterRes(props) {
  const { open, handleClose, resData, uniqueKey, userIds, unapplied } = props;
  const selectedIds = userIds;
  // const [selectedIds, setSelectedIds] = useState(userIds);
  const [counts, setCounts] = useState({
    assigned: null,
    unAssigned: null,
  });

  useEffect(() => {
    let assigned = 0,
      unAssigned = 0;

    if (typeof resData !== "string") {
      resData?.map((e) => {
        if (unapplied) {
          e.isAssigned === 1 ? assigned++ : unAssigned++;
        } else {
          e[0].isAssigned === 1 ? assigned++ : unAssigned++;
        }
        return true;
      });
    }

    setCounts({
      assigned: assigned,
      unAssigned: unAssigned,
    });
  }, [resData]);

  return (
    <div>
      <Modal
        open={open}
        onClose={() => {
          handleClose();
        }}
      >
        <Box sx={style}>
          <h3>
            Total Assigned :&nbsp;
            {uniqueKey === "recruiterAssign"
              ? counts.assigned
              : selectedIds?.split(",").length}
            <span>
              {" "}
              <IconButton
                onClick={handleClose}
                style={{ left: "400px", padding: "0px", position: "relative" }}
              >
                <CancelIcon />
              </IconButton>
            </span>
          </h3>
          {uniqueKey === "recruiterAssign" ? (
            <>
              <h3>Total Unassigned :&nbsp;{counts.unAssigned}</h3>
              {counts.unAssigned !== 0 && <ResponseTable resData={resData} />}
            </>
          ) : null}
        </Box>
      </Modal>
    </div>
  );
}
