import React, { useEffect, useState } from "react";
import { Grid, Modal, TextField, InputAdornment } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Styles from "./index.module.scss";
import urlLinks from "../../UrlLinks";
import Axios from "axios";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import CloseSharpIcon from "@material-ui/icons/CloseSharp";
import { ScaleLoader } from "react-spinners";

const flexDisplay = {
  marginBottom: "20px",
  display: "flex",
  justifyContent: "space-between",
};

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: "681px",
    height: "168px",
  },
  input: {
    "& .MuiInputBase-input": {
      borderLeft: "1px solid #E3E3E3",
      padding: "3% 4%",
    },
  },
}));

const LocationModal = (props) => {
  const classes = useStyles();
  const {
    openAdminNotes,
    setOpenAdminNotes,
    recruiterMasterId,
    inForm,
    modelList,
  } = props;
  const [searchBy, setSearchBy] = useState("");
  const [locationData, setLocationData] = useState([]);
  const [rows, setRows] = useState(locationData);
  const [isLoading, setIsLoading] = useState(false);

  const Loader = () => (
    <div style={{ margin: "40px auto", width: "fit-content" }}>
      <ScaleLoader height={70} width={10} color={"#1c75bc"} />
    </div>
  );

  let caption = "Locations";

  const handleSearchClose = () => {
    setSearchBy("");
    setRows(locationData);
  };

  useEffect(() => {
    if (inForm) {
      setLocationData([...modelList]);
    } else {
      setIsLoading(true);
      Axios.get(
        `${urlLinks.baseUrl}${urlLinks.jobs.jobslocation}?jobId=${recruiterMasterId}`
      )
        .then((response) => {
          setLocationData(response.data.response);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log("err", err);
        });
    }
  }, []);

  useEffect(() => {
    setRows(locationData);
  }, [locationData]);

  const handleSearch = (e) => {
    setSearchBy(e.target.value);
    const filteredRows = locationData.filter((row) => {
      if (typeof row.name === "number") {
        let searchStr = row.name.toString();
        return searchStr.includes(e.target.value);
      } else {
        return row.name.toLowerCase().includes(e.target.value.toLowerCase());
      }
    });
    setRows(filteredRows);
  };

  return (
    <Modal
      className={classes.modal}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openAdminNotes}
      onClose={() => setOpenAdminNotes(false)}
      closeAfterTransition
    >
      {isLoading ? (
        <Loader />
      ) : (
        <div
          className={classes.paper}
          style={{ width: "25%", minHeight: "49%", height: "fit-content" }}
        >
          <Grid container>
            <Grid
              className="padding0"
              item
              xs={12}
              style={flexDisplay}
              direction="row"
            >
              <span className={Styles.summaryText}>{caption}</span>
              <IconButton
                style={{ padding: "0" }}
                aria-label="close"
                onClick={() => setOpenAdminNotes(false)}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
            <Grid item xs={12} className="padding0" style={{ display: "flex" }}>
              <TextField
                className={`${classes.root}`}
                placeholder="Search"
                fullWidth
                value={searchBy}
                onChange={(event) => handleSearch(event)}
                variant="outlined"
                InputProps={{
                  className: classes.input,
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon style={{ color: "#757575" }} />
                    </InputAdornment>
                  ),
                }}
              />
              <div className={Styles.searchButtonDiv1}>
                <IconButton
                  className={`${Styles.closeButton} ${
                    searchBy ? "" : Styles.noVisibility
                  }`}
                  onClick={handleSearchClose}
                >
                  <CloseSharpIcon />
                </IconButton>
              </div>
            </Grid>
            <Grid item xs={12} className={Styles.locationGrid}>
              {rows.map((loc) => (
                <div key={loc.id}>{loc.name}</div>
              ))}
            </Grid>
          </Grid>
        </div>
      )}
    </Modal>
  );
};

export default LocationModal;
