import * as actionTypes from "../../../actions/actionTypes";
import { updateObject } from "../../utility";

const initialState = {
  loader: false,
  modalLoader: false,
  totalPages: 1,
  totalRows: 0,
  currentPage: 1,
  perPage: 10,
  isFilter: false,
  selectedAssignIds: "",
  selectedUserIds: "",
  openFilterDrawer: false,
  allRecord: [],
  // userData: [],
};

const setLoader = (state, action) => {
  return updateObject(state, {
    loader: action.loader,
  });
};

const setModalLoader = (state, action) => {
  return updateObject(state, {
    modalLoader: action.loader,
  });
};

const setCurrentPage = (state, action) => {
  return updateObject(state, {
    currentPage: action.currentPage,
  });
};

const setPerPage = (state, action) => {
  return updateObject(state, {
    perPage: action.perPage,
  });
};

const setTotalRows = (state, action) => {
  return updateObject(state, {
    totalRows: action.totalRows,
  });
};

const setTotalPages = (state, action) => {
  return updateObject(state, {
    totalPages: action.totalPages,
  });
};

const setIsFilter = (state, action) => {
  return updateObject(state, {
    isFilter: action.isFilter,
  });
};

const setSelectedAssignIds = (state, action) => {
  return updateObject(state, {
    selectedAssignIds: action.selectedAssignIds,
  });
};

const setSelectedUserIds = (state, action) => {
  return updateObject(state, {
    selectedUserIds: action.selectedUserIds,
  });
};

const setOpenFilterDrawer = (state, action) => {
  return updateObject(state, {
    openFilterDrawer: action.openFilterDrawer,
  });
};

const setAllRecord = (state, action) => {
  return updateObject(state, {
    allRecord: action.allRecord,
  });
};

// const getUserData = (state, action) => {
//   return updateObject(state, {
//     userData: action.userData,
//     totalPages: action.totalPages,
//     totalRows: action.totalRows,
//   });
// };

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOADER:
      return setLoader(state, action);
    case actionTypes.MODAL_LOADER:
      return setModalLoader(state, action);
    case actionTypes.CURRENT_PAGE:
      return setCurrentPage(state, action);
    case actionTypes.PER_PAGE:
      return setPerPage(state, action);
    case actionTypes.TOTAL_ROWS:
      return setTotalRows(state, action);
    case actionTypes.TOTAL_PAGES:
      return setTotalPages(state, action);
    case actionTypes.IS_FILTER:
      return setIsFilter(state, action);
    case actionTypes.SELECTED_ASSIGN_IDS:
      return setSelectedAssignIds(state, action);
    case actionTypes.SELECTED_USER_IDS:
      return setSelectedUserIds(state, action);
    case actionTypes.OPEN_FILTER_DRAWER:
      return setOpenFilterDrawer(state, action);
    case actionTypes.ALL_RECORD:
      return setAllRecord(state, action);
    // case actionTypes.USER_DATA:
    //   return getUserData(state, action);
    default:
      return state;
  }
};

export default userReducer;
