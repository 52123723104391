import React, { useState, useEffect } from "react";
import { Grid, Select, MenuItem, Button } from "@material-ui/core";
import * as Axios from "axios";
import urlLinks from "../../../UrlLinks";
import { Field } from "formik";
import * as moment from "moment";
import qs from "querystring";
import FacilitySearch from "./FacilitySearch";
import WorkerApproveConfirmation from "../../../Utils/dialogs/WorkerApprovalConfirmation";

function HRBPedit(props) {
  const { data, getWorkerData } = props;
  const { values, handleChange, handleBlur, setFieldValue, setValues } =
    props.formikProps;
  const [entityList, setEntityList] = useState();
  const [rateCardList, setRateCardList] = useState();
  const [facilityData, setFacilityData] = useState();
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [disableApprove, setDisableApprove] = useState(false);
  const sourceBy = data.sourceByName ? `, ${data.sourceByName}` : "";

  const getRateCardList = (zone) => {
    Axios.get(
      `${urlLinks.baseUrl}${urlLinks.workerUrls.rateCardList}${zone}`
    ).then((response) => {
      setRateCardList(Object.entries(response.data.response));
    });
  };

  useEffect(() => {
    if (data.facilityName) {
      Axios.get(
        `${urlLinks.baseUrl}worker/getFacilityByKey?key=${data.facilityName}`
      ).then((response) => {
        setFacilityData(response.data.response[0]);
        getRateCardList(response.data.response[0].zone);
      });
    }
  }, []);

  useEffect(() => {
    if (facilityData) {
      getRateCardList(facilityData.zone);
      setValues({
        ...values,
        facilityCity: facilityData.facilityCity,
        facilityState: facilityData.facilityState,
        facilityName: facilityData.facilityName,
        zone: facilityData.zone,
        facilityType: facilityData.facilityType,
      });
    } else if (!facilityData) {
      setRateCardList();
      setValues({
        ...values,
        facilityCity: "",
        facilityState: "",
        facilityName: "",
        zone: "",
        facilityType: "",
      });
    }
  }, [facilityData]);

  const getEntityList = () => {
    Axios.get(`${urlLinks.baseUrl}${urlLinks.entityList}`).then((response) => {
      let dropdownList = {};
      const list = Object.entries(response.data.response.content);
      list.map((entity) => {
        const dropDown = Object.entries(entity[1]);
        dropdownList[entity[0]] = dropDown;
      });
      setEntityList(dropdownList);
    });
  };

  useEffect(() => {
    if (values.sourceBy === "VENDOR")
      setValues({ ...values, sourceByName: "OkayGo" });
    else if (
      (values.sourceBy === "TA" || values.sourceBy === "EREF") &&
      data.sourceByName
    ) {
      setValues({ ...values, sourceByName: data.sourceByName });
    } else if (
      ((values.sourceBy === "TA" || values.sourceBy === "EREF") &&
        !data.sourceByName) ||
      data.sourceBy !== values.sourceBy
    ) {
      setValues({ ...values, sourceByName: "" });
    }
  }, [values.sourceBy]);

  useEffect(() => {
    getEntityList();
  }, []);

  const handleApprovalDisabled = () => {
    if (
      data.workerStatus !== 5 ||
      !data.casperId ||
      !data.criminalRecord ||
      !data.sourceBy ||
      !data.sourceByName ||
      !data.facilityName ||
      !data.facilityCity ||
      !data.facilityState ||
      !data.rateCard ||
      !data.facilityType ||
      !data.designation ||
      !data.edab ||
      !data.date_of_joining
    ) {
      setDisableApprove(true);
    } else {
      setDisableApprove(false);
    }
  };

  useEffect(() => {
    handleApprovalDisabled();
  }, [data]);

  const handleSave = (values) => {
    let postData = {
      casper_id: values.casperId,
      source_by: values.sourceBy,
      source_by_name: values.sourceByName,
      facility_name: values.facilityName,
      facility_city: values.facilityCity,
      facility_state: values.facilityState,
      zone: values.zone,
      facility_type: values.facilityType,
      designation: values.designation,
      rate_card: values.rateCard,
      criminal_record: values.criminalRecord === "Yes" ? 1 : 0,
      physically_disabled: values.edab === "No" ? 0 : 1,
      updated_by: parseInt(localStorage.getItem("userID")),
      user_id: data.userId,
    };
    if (values.date_of_joining) {
      postData["date_of_joining"] = moment(values.date_of_joining).format(
        "YYYY-MM-DD 00:00:00"
      );
    }
    Axios.post(
      `${urlLinks.baseUrl}${urlLinks.workerUrls.editWorkerHRBPDetails}`,
      qs.stringify(postData)
    )
      .then((response) => {
        if (response.data.code === 1000) {
          getWorkerData();
          props.showToast("success", "Worker details saved successfully");
        } else {
          props.showToast("error", "Worker details not saved");
        }
      })
      .catch((error) => {
        props.showToast("error", "Worker not activated");
      });
  };

  const handleApprove = () => {
    Axios.post(
      `${urlLinks.baseUrl}${
        urlLinks.workerUrls.activateWorker
      }?inserted_by=${parseInt(localStorage.getItem("userID"))}&user_id=${
        data.userId
      }`
    )
      .then((response) => {
        if (response.data.code === 1000) {
          getWorkerData();
          props.showToast("success", "Worker activated successfully");
        } else {
          props.showToast("error", "Worker not activated");
        }
      })
      .catch((error) => {
        props.showToast("error", "Worker not activated");
      });
  };

  return (
    <Grid container>
      {entityList && (
        <Grid container className="flexDisplay" direction="column">
          <Grid item xs={12} className="marginBottom40">
            <span className="fontSize1_5rem fontWeightBold">
              Contract Details
            </span>
          </Grid>
          <Grid item xs={12} className="flexDisplay height40" direction="row">
            <Grid item xs={4}>
              <span className="fontWeightBold">Casper ID</span>
            </Grid>
            <Grid item xs={8}>
              <span>{data.casperId}</span>
            </Grid>
          </Grid>
          <Grid item xs={12} className="flexDisplay height40" direction="row">
            <Grid item xs={4}>
              <span className="fontWeightBold">Source by</span>
            </Grid>
            <Grid item xs={8}>
              <span>{`${data.sourceByView}${sourceBy}`}</span>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            className="flexDisplay height40 marginTop16"
            direction="row"
          >
            <Grid item xs={4}>
              <span className="fontWeightBold">Facility name</span>
            </Grid>
            <Grid item xs={8}>
              <FacilitySearch
                facilityData={facilityData}
                setFacilityData={setFacilityData}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} className="flexDisplay height40" direction="row">
            <Grid item xs={4}>
              <span className="fontWeightBold">Facility city</span>
            </Grid>
            <Grid item xs={8}>
              <input
                id="facilityCity"
                placeholder="Facility city"
                type="text"
                className="hrbpEditInput"
                value={values.facilityCity}
                disabled={true}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} className="flexDisplay height40" direction="row">
            <Grid item xs={4}>
              <span className="fontWeightBold">Facility state</span>
            </Grid>
            <Grid item xs={8}>
              <input
                id="facilityState"
                placeholder="Facility state"
                type="text"
                className="hrbpEditInput"
                value={values.facilityState}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={true}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} className="flexDisplay height40" direction="row">
            <Grid item xs={4}>
              <span className="fontWeightBold">Zone</span>
            </Grid>
            <Grid item xs={8}>
              <input
                id="zone"
                placeholder="Zone"
                type="text"
                className="hrbpEditInput"
                value={values.zone}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={true}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} className="flexDisplay height40" direction="row">
            <Grid item xs={4}>
              <span className="fontWeightBold">Facility type</span>
            </Grid>
            <Grid item xs={8}>
              <input
                id="facilityType"
                placeholder="Facility type"
                type="text"
                className="hrbpEditInput"
                value={values.facilityType}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={true}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} className="flexDisplay height40" direction="row">
            <Grid item xs={4}>
              <span className="fontWeightBold">Designation</span>
            </Grid>
            <Grid item xs={8}>
              <Select
                value={values.designation}
                onChange={(e) => setFieldValue("designation", e.target.value)}
                variant="outlined"
                className="hrbpEditSelect"
                classes={{ root: { border: "1px solid black" } }}
                inputProps={{
                  padding: 5,
                }}
              >
                {values.facilityType === "DELIVERY_HUB" &&
                  entityList.delivery_hub.map((rate) => {
                    return (
                      <MenuItem key={rate[0]} value={rate[1]}>
                        {rate[1]}
                      </MenuItem>
                    );
                  })}
                {values.facilityType === "PICKUP_HUB" &&
                  entityList.pickup_hub.map((rate) => {
                    return (
                      <MenuItem key={rate[0]} value={rate[1]}>
                        {rate[1]}
                      </MenuItem>
                    );
                  })}
              </Select>
            </Grid>
          </Grid>
          {rateCardList?.length > 0 && (
            <Grid item xs={12} className="flexDisplay height40" direction="row">
              <Grid item xs={4}>
                <span className="fontWeightBold">Rate card</span>
              </Grid>
              <Grid item xs={8}>
                <Select
                  value={values.rateCard}
                  onChange={(e) => setFieldValue("rateCard", e.target.value)}
                  variant="outlined"
                  className="hrbpEditSelect"
                >
                  {rateCardList.map((rate) => {
                    return (
                      <MenuItem key={rate[0]} value={rate[1]}>
                        {rate[1]}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
            </Grid>
          )}
          <Grid
            item
            xs={12}
            className="flexDisplay"
            direction="row"
            style={{ height: "34px" }}
          >
            <Grid item xs={4}>
              <span className="fontWeightBold">Criminal record</span>
            </Grid>
            <Grid item xs={8}>
              <div role="group">
                <label>
                  <Field
                    type="radio"
                    id="criminalRecord1"
                    name="criminalRecord"
                    value="Yes"
                  />
                  Yes
                </label>
                <label>
                  <Field
                    type="radio"
                    id="criminalRecord2"
                    name="criminalRecord"
                    value="No"
                  />
                  No
                </label>
              </div>
            </Grid>
          </Grid>
          <Grid item xs={12} className="flexDisplay height40" direction="row">
            <Grid item xs={4}>
              <span className="fontWeightBold">Date of joining</span>
            </Grid>
            <Grid item xs={8}>
              <span>
                {data.date_of_joining
                  ? moment(data.date_of_joining).format("DD/MM/YYYY")
                  : ""}
              </span>
            </Grid>
          </Grid>
          <Grid item xs={12} className="flexDisplay " direction="row">
            <Grid item xs={4}>
              <span className="fontWeightBold ">
                EDAB (physically disabled)
              </span>
            </Grid>
            <Grid item xs={8}>
              <span>{data.edab}</span>
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid
        item
        xs={12}
        className="flexDisplay paddingTop16"
        direction="row"
        justify="space-between"
      >
        <Button
          variant="outlined"
          className="blueButtonOutlined"
          onClick={() => handleSave(values)}
        >
          Save Details
        </Button>
        {data.workerStatus === 5 && data.casperId && (
          <Button
            variant="contained"
            className={`${
              disableApprove ? "disabledButton" : "blueButtonContained"
            }`}
            onClick={() => setOpenConfirmationDialog(true)}
            disabled={disableApprove}
          >
            Activate
          </Button>
        )}
      </Grid>
      {openConfirmationDialog && (
        <WorkerApproveConfirmation
          openConfirmationDialog={openConfirmationDialog}
          setOpenConfirmationDialog={setOpenConfirmationDialog}
          handleApprove={handleApprove}
        />
      )}
    </Grid>
  );
}

export default HRBPedit;
