import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../../store/actions/index";
import Axios from "axios";
import { Stack } from "@mui/material";
import { Autocomplete } from "@material-ui/lab";
import styles from "./Components.module.scss";
import { Close } from "@material-ui/icons";
import { Box, Button, IconButton, Modal, TextField } from "@material-ui/core";
import AssignToRecruiterRes from "./AssignToRecruiterRes";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 439,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  filter: "drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.15))",
};

export default function AssignToRecruiter(props) {
  const {
    open,
    setOpen,
    showToast,
    updateMyData,
    userIds,
    title,
    uniqueKey,
    placeholder,
    buttonName,
    optionList,
    url,
    unapplied,
  } = props;

  /** accessing states from redux store **/

  const { users } = useSelector((state) => state);
  const { loader } = users;

  /** useDispatch is used to update states to redux store **/

  const dispatch = useDispatch();
  const { setLoader } = bindActionCreators(actionCreators, dispatch);

  /** other states **/
  const [recruiterAssigned, setRecruiterAssigned] = useState("");
  const [resData, setResData] = useState([]);
  const [openRes, setOpenRes] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const assinAPI = () => {
    let url_main;
    if (uniqueKey === "recruiterAssign") {
      let data = {
        insertedBy: localStorage.getItem("userID"),
        recruiterId: recruiterAssigned.id,
        userIds: userIds,
      };
      unapplied
        ? (url_main = `${url}?insertedBy=${data.insertedBy}&recruiterId=${data.recruiterId}&userIds=${data.userIds}`)
        : (url_main = `${url}?insertedBy=${data.insertedBy}&recruiterId=${data.recruiterId}&assignIds=${data.userIds}`);
    } else if (uniqueKey === "opsAssign") {
      let data = {
        userId: recruiterAssigned.id,
        assignIds: userIds,
      };
      url_main = `${url}?assignIds=${data.assignIds}&userId=${data.userId}`;
    }

    setLoader(true);
    Axios.post(url_main)
      .then((response) => {
        setRecruiterAssigned("");
        setResData(
          unapplied ? response.data.response.content : response.data.response
        );
        setOpenRes(true);
        updateMyData();
      })
      .catch((err) => {
        showToast(
          "error",
          "Error",
          "Something went wrong, please try after sometime."
        );
      });
    setLoader(false);
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        className={styles.assignRecruiterModal}
      >
        <Box sx={style} className={styles.assignRecruiterBox}>
          <p className={styles.header}>
            {title}
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </p>
          <Stack spacing={3}>
            <Autocomplete
              id="ReAssign"
              value={recruiterAssigned}
              onChange={(e, newValue) => setRecruiterAssigned(newValue)}
              fullWidth
              options={optionList}
              getOptionLabel={(option) => option.name}
              renderOption={(option) => (
                <>
                  {option.id} - {option.name}{" "}
                  {option.phoneNumber ? " - " + option.phoneNumber : ""}
                </>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder={placeholder}
                  size="small"
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
            />
          </Stack>
          <div className={styles.footer}>
            <Button
              variant="contained"
              className="dataButton"
              onClick={assinAPI}
            >
              {loader ? "Please wait..." : buttonName}
            </Button>
          </div>
        </Box>
      </Modal>
      <AssignToRecruiterRes
        open={openRes}
        resData={resData}
        handleClose={handleClose}
        uniqueKey={uniqueKey}
        userIds={userIds}
        unapplied={unapplied}
      ></AssignToRecruiterRes>
    </div>
  );
}
