import React, { useState, useEffect, useMemo, useContext } from "react";
import styled from "styled-components";
import { Grid, Checkbox } from "@material-ui/core";
import {
  useTable,
  useRowSelect,
  useSortBy,
  useFilters,
  useExpanded,
} from "react-table";
import urlLinks from "../../UrlLinks";
import InnerTable from "./InnerTable";
import UpperTable from "./UpperTable";
import Axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import * as moment from "moment";
import { numberFormat } from "../../Utils/CommonFunctions";
import NoRecord from "./noRecord.svg";
import styles from "./index.module.scss";
import ScaleLoader from "react-spinners/ScaleLoader";
import { AuthContext } from "../../services/AuthProvider";
import { useHistory, useParams } from "react-router-dom";

const Styles = styled.div`
  padding: 1rem;
  .tableContainer {
    overflow-x: scroll;
    border: 1px solid #cfcfcf;
    max-height: 74vh;
  }
  .tableContainer::-webkit-scrollbar {
    width: 0.75em;
  }

  .tableContainer::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  .tableContainer::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    //   border-radius: 8px;
  }
  table {
    border-spacing: 0;
    width: max-content;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    th {
      :last-child {
        border-left: 0;
      }
      background-color: #1c75bc !important;
      color: #ffffff;
    }
    th,
    td {
      padding: 5px 15px;
    }
    td {
      margin: 0;
      padding: 1rem;
      border-bottom: 1px solid #cfcfcf;
      border-right: 1px solid #cfcfcf;
      background-color: white;
      nth-last-child(2) {
        border-right: 0;
      }
      :last-child {
        border-right: 0;
      }

      input {
        font-size: 1rem;
        padding: 0;
        margin: 0;
        border: 0;
      }
    }
  }
  .pagination {
    padding: 0.5rem;
  }
`;

const Styles2 = styled.div`
        padding: 1rem;
        .tableContainer{
            overFlow-x: scroll;
            max-height : 74vh;
           
        }
        .tableContainer::-webkit-scrollbar {
            width: 0.75em;
        }
         
        .tableContainer::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        }
         
        .tableContainer::-webkit-scrollbar-thumb {
          background-color: darkgrey;
        //   border-radius: 8px;
        }
        table {
            
       
            width: max-content;
            tr {
            :last-child {
                td {
                // border-bottom: 0;
                }
            }
            }
            th {
                :last-child{
                        // border-left: 0;
                }
             
                background: #E1E1E1;!important;
                
                color: black;

            }
            th,td{
                padding:5px 15px;
            }
            
            td {
            margin: 0;
            padding: 1rem;
            border: none; 
            // border-collapse: collapse;
            // border-left: thin solid; border-top: thin solid; border-bottom: thin solid;
            // border-bottom: 0px solid #cfcfcf;
            // border-right: 0px solid #cfcfcf;
            background-color: white;
            nth-last-child(2) {
                // border-right: 0;
            } 
            :last-child {
                // border-right: 0;
            }

            input {
                font-size: 1rem;
                padding: 0;
                margin: 0;
                border: 0;
            }
            }
        }

        .pagination {
            padding: 0.5rem;
        }
`;

const useStyles = makeStyles((theme) => ({}));

const ExternalWorker = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const { showToast } = useContext(AuthContext);
  const [allRecordLoading, setAllRecordLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allRecord, setAllRecord] = useState([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [sortBy, setSortBy] = useState(0);
  const [sortDirection, setSortDirection] = useState(false);
  const [sortDirectionUrl, setSortDirectionUrl] = useState("");
  const [searchByUrl, setSearchByUrl] = useState("");
  const [sortUrl, setSortUrl] = useState("");
  const [filtersUrl, setFiltersUrl] = useState("");
  const [searchByAuto, setsearchByAuto] = useState("");
  const [rowData, setRowData] = useState("");
  const params = useParams();

  useEffect(() => {
    ApplyFilters(filters);
  }, [searchByAuto]);

  const [filters, setFilters] = useState({
    searchBy: "",
    billDate: [null, null],
    zone: "",
    facility_state: "",
    facility_name: "",
    payment_status: "",
    worker_status: "",
    worker_type: "",
    payment_type: "",
    advanceTaken: "",
    bankDetails: "",
    bankDetailsVerified: "",
    panStatus: "",
    modalFlag: "",
  });

  const ApplySort = () => {
    if (sortBy !== 0) setSortUrl("&partnerId=" + sortBy);
  };

  useEffect(() => {
    ApplySort();
    setSortDirection(false);
    setSortDirectionUrl("");
  }, [sortBy]);

  const ApplyFilters = () => {
    let url = "";
    if (filters["searchBy"]) url = `${url}&searchBy=${filters["searchBy"]}`;
    if (filters["billDate"][0] && !filters["billDate"][1])
      url = `${url}&billFrom=${moment(filters["billDate"][0]).format(
        "YYYY-MM-DD"
      )}&billTo=${moment(new Date()).format("YYYY-MM-DD")} 23:59:59`;
    if (!filters["billDate"][0] && filters["billDate"][1])
      url = `${url}&billFrom=2019-01-01&billTo=${moment(
        filters["billDate"][1]
      ).format("YYYY-MM-DD")} 23:59:59`;
    if (filters["billDate"][0] && filters["billDate"][1])
      url = `${url}&billFrom=${moment(filters["billDate"][0]).format(
        "YYYY-MM-DD"
      )}&billTo=${moment(filters["billDate"][1]).format(
        "YYYY-MM-DD"
      )} 23:59:59`;
    if (filters["zone"]) {
      url = `${url}&zone=${filters["zone"]}`;
    }
    if (filters["facility_state"]) {
      url = `${url}&facility_state=${filters["facility_state"].name}`;
    }
    if (filters["facility_name"]) {
      url = `${url}&facility_name=${filters["facility_name"].name}`;
    }
    if (filters["payment_status"]) {
      url = `${url}&payment_status=${filters["payment_status"]}`;
    }
    if (filters["worker_status"]) {
      url = `${url}&worker_status=${filters["worker_status"]}`;
    }
    if (filters["worker_type"] || filters["worker_type"] === 0) {
      url = `${url}&isGold=${filters["worker_type"]}`;
    }
    if (filters["payment_type"]) {
      url = `${url}&paymentType=${filters["payment_type"]}`;
    }
    if (filters["advanceTaken"] || filters["advanceTaken"] === 0) {
      url = `${url}&advanceTaken=${filters["advanceTaken"]}`;
    }
    if (filters["bankDetails"] || filters["bankDetails"] === 0) {
      url = `${url}&bank_details=${filters["bankDetails"]}`;
    }
    if (
      filters["bankDetailsVerified"] ||
      filters["bankDetailsVerified"] === 0
    ) {
      url = `${url}&verification_status=${filters["bankDetailsVerified"]}`;
    }
    if (filters["panStatus"] || filters["panStatus"] === 0) {
      url = `${url}&isPanVerified=${filters["panStatus"]}`;
    }
    if (filters["modalFlag"] || filters["modalFlag"] === 0) {
      url = `${url}&is_lma_worker=${filters["modalFlag"]}`;
    }
    setFiltersUrl(url);
    if (url) {
      setIsFilterApplied(true);
    }
    setCurrentPage(1);
  };

  useEffect(() => {
    ApplyFilters();
  }, [filters]);

  const workerLedgerListingCols = useMemo(
    () => [
      {
        Header: "OkayGo ID",
        accessor: "okaygoId",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Name",
        accessor: "name",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Bank Verification",
        accessor: "bankVerification",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Invoices",
        Cell: (row) => {
          return <div>{row.row.original.invoices}</div>;
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Credit",
        accessor: "totalCredit",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Debit",
        accessor: "totalDebit",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Net Balance",
        accessor: "netBalance",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
    ],
    [filters, filtersUrl, sortBy, sortUrl, perPage, currentPage]
  );

  const invoiceListCols = useMemo(
    () => [
      {
        Header: "Inovice date",
        Cell: ({ row }) => (
          <div>
            <div>{row.original.invoiceDate}</div>
            {row.original.invoiceDetails.slice(1).map((el) => {
              return (
                <>
                  {el.advance ? (
                    <div className="advanceStyle UpperXMargin"> Advance</div>
                  ) : (
                    <div className="noAdvanceStyle UpperXMargin"></div>
                  )}
                </>
              );
            })}
          </div>
        ),
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Invoice#",
        Cell: ({ row }) => (
          <div>
            <div>
              <span>
                {row.original.invoiceFilePath !== "" ? (
                  <a
                    style={{ color: "#1C75BC" }}
                    href={row.original.invoiceFilePath}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {row.original.invoiceNumber}
                  </a>
                ) : (
                  row.original.invoiceNumber
                )}
              </span>
            </div>
            {row.original.invoiceDetails.slice(1).map((el) => {
              return (
                <div className="Upper10Margin">Paid on : {el.payment_date}</div>
              );
            })}
          </div>
        ),
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Bill From",
        Cell: ({ row }) => (
          <div>
            <div>{row.original.billFrom}</div>
            {row.original.invoiceDetails.slice(1).map((el) => {
              return (
                <div className="Upper10Margin">
                  UTR : <span>{el.transaction_id}</span>
                </div>
              );
            })}
          </div>
        ),
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Bill To",
        accessor: "billTo",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Client",
        accessor: "client",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Credit",
        Cell: ({ row }) => (
          <div>
            {row.original.invoiceDetails.slice(0, 1).map((el) => {
              return <div className=""> {el.credit}</div>;
            })}
          </div>
        ),
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Debit",
        Cell: ({ row }) => (
          <div>
            <p style={{ marginTop: "32px" }}></p>

            {row.original.invoiceDetails.slice(1).map((el) => {
              return <div className="Upper10Margin"> {el.debit}</div>;
            })}
          </div>
        ),
        defaultCanFilter: false,
        defaultCanSort: false,
      },
    ],
    [filters, filtersUrl, sortBy, sortUrl, perPage, currentPage]
  );

  //Function to create table data
  const makeData = (rowData) => {
    let extractedData = [];
    extractedData.push({
      workerId: rowData.workerId || "",
      okaygoId: rowData.okaygoId || "",
      name: `${rowData.firstName ? rowData.firstName : ""} ${
        rowData.lastName ? rowData.lastName : ""
      }`,
      bankVerification:
        rowData.bankVerification != null
          ? rowData.bankVerification === 1
            ? "Yes"
            : "No"
          : "-",
      invoices: rowData.invoices ? rowData.invoices + " Invoices" : "-",
      totalCredit: rowData.totalCredit ? numberFormat(rowData.totalCredit) : "",
      totalDebit: rowData.totalDebit ? numberFormat(rowData.totalDebit) : "",
      netBalance: rowData.netBalance ? numberFormat(rowData.netBalance) : "",
    });
    return extractedData;
  };

  const makeData2 = (response) => {
    let extractedData = [];
    response.map((el) => {
      extractedData.push({
        invoiceDate: el.billDate
          ? moment(el.billDate).format("DD-MMM-YYYY")
          : "",
        invoiceNumber: el.billNumber || "",
        invoiceFilePath: el.invoiceFilePath
          ? el.invoiceFilePath.replace("http", "https")
          : "",
        billFrom: el.billFrom ? moment(el.billFrom).format("DD-MMM-YYYY") : "",
        billTo: el.billto ? moment(el.billto).format("DD-MMM-YYYY") : "",
        client: el.partnerName || "",
        invoiceDetails: el.invoiceDataList.map((e) => {
          return {
            credit: e.credit ? numberFormat(e.credit) : "",
            debit: e.debit ? numberFormat(e.debit) : "",
            insertedOn: e.insertedOn,
            inserted_by: e.inserted_by,
            invoice_id: e.invoice_id,
            payment_date: e.payment_date
              ? moment(e.payment_date).format("DD-MMM-YYYY")
              : "",
            transaction_id: e.transaction_id,
            updatedOn: e.updatedOn,
            worker_id: e.worker_id,
            worker_ledger_id: e.worker_ledger_id,
            advance: e.advance ? e.advance : "",
          };
        }),
      });
    });
    return extractedData;
  };

  //Function to create All record export data
  const columns = workerLedgerListingCols;
  const updateMyData = () => {
    getWorkerLedgerListing(perPage, currentPage);
  };

  useEffect(() => {
    setAllRecordLoading(false);
  }, [allRecord]);

  const getWorkerRowData = (id) => {
    setLoading(true);
    Axios.get(
      `${urlLinks.baseUrl}${urlLinks.workerUrls.workerLedgerListing}?searchBy=${id}`
    )
      .then((response) => {
        if (response) {
          setRowData(response.data.response.content[0]);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log("Error while fetching rowData is", error);
        setLoading(false);
      });
  };
  
  useEffect(() => {
    const okaygoId = params.okaygoId;
    getWorkerRowData(okaygoId);
  }, [
    filtersUrl,
    sortUrl,
    localStorage.getItem("roleType"),
    searchByUrl,
    params,
  ]);

  const getWorkerLedgerListing = (rows, pageNo) => {
    setLoading(true);
    if (rowData === undefined) {
      history.push("/workerLedger");
      return;
    }
    if (rowData.workerId) {
      Axios.get(
        `${urlLinks.baseUrl}${urlLinks.workerUrls.particularWorkerLedgerListing}?worker_id=${rowData.workerId}`
      )
        .then((response) => {
          if (response) {
            setData2(makeData2(response.data.response));
          }
        })
        .catch((error) => {
          setLoading(false);
        });
      setData(makeData(rowData));
      setLoading(false);
    }
  };

  useEffect(() => {
    getWorkerLedgerListing(perPage, 1);
  }, [
    filtersUrl,
    sortUrl,
    localStorage.getItem("roleType"),
    searchByUrl,
    params,
    rowData,
  ]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    isAllRowsSelected,
    visibleColumns,
    state: { selectedRowIds, expanded },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        hiddenColumns: [""],
      },
      updateMyData,
      showToast,
      setLoading,
      filters,
      setFilters,
      manualSortBy: true,
    },
    useFilters,
    useSortBy,
    useExpanded,
    useRowSelect
  );

  useEffect(() => {
    rows.map((record, index) => {
      if (record.original.queued !== 0 || !record.original.isInvoiceCreated) {
        selectedRowIds[index] = false;
      }
    });
  }, [isAllRowsSelected]);

  useEffect(() => {
    if (selectedRowIds) {
      const indexes = Object.values(selectedRowIds);
      let disableButton = true;
      indexes.map((id) => {
        if (id) {
          disableButton = false;
          return true;
        }
        return true;
      });
    }
  }, [selectedRowIds]);

  const loader = (
    <div style={{ margin: "130px auto" }}>
      <ScaleLoader height={70} width={10} color={"#1c75bc"} />
    </div>
  );

  return (
    <Grid container className="padding2percent">
      {loading ? (
        loader
      ) : data.length === 0 ? (
        <div className={styles.noRecord}>
          <img src={NoRecord} alt="noRecord" />
          <span>No records found</span>
        </div>
      ) : (
        <Grid item xs={12} className="paddingTop16">
          <Styles>
            <UpperTable data={data} columns={columns} />
          </Styles>
          <Styles2>
            <InnerTable data={data2} columns={invoiceListCols} />
          </Styles2>
        </Grid>
      )}
    </Grid>
  );
};

export default ExternalWorker;
