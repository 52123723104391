import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Warning from '../warning.svg'
import Typography from "@material-ui/core/Typography";
import { FormControlLabel, Grid, Radio, RadioGroup } from "@material-ui/core";
import Styles from '../index.module.scss'

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    borderBottom: "unset",
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(4),
    justifyContent: "center",
  },
}))(MuiDialogActions);

export default function CustomizedDialogs(props) {
  const { criminalRecordData, setCriminalRecordData } = props;

  const handleClose = () => {
    setCriminalRecordData({ userId: null, open: false });
  };

  const handleYes = () => {
    window.open(`/workerview/${criminalRecordData.userId}`, "_blank");
    handleClose();
  };
  
  let dialogData = (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={criminalRecordData.open}
      className={Styles.onboardingDialog}
      classes={{ paper: Styles.paperCriminalRecord }}
    >
      <DialogTitle
        id="customized-dialog-title"
        onClose={handleClose}
      ></DialogTitle>
      <DialogContent>
        <Grid item xs className={Styles.criminalRecordContainer}>
          <img src={Warning} alt="warning" style={{ height: "50px" }} />
          <span>
            The selected candidate holds a criminal record. Do you want to
            continue with this candidate
          </span>
        </Grid>
      </DialogContent>
      <DialogActions className={Styles.criminalRecordActions}>
        <Button variant="contained" onClick={handleClose} className={Styles.no}>
          No
        </Button>
        <Button variant="outlined" onClick={handleYes} className={Styles.yes}>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
  return dialogData;
}
