import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid,TextField,MenuItem } from '@material-ui/core'
import axios from 'axios';
import UrlLinks from '../../UrlLinks';
import { AuthContext } from '../../services/AuthProvider';

const statuses = [
  {
    value: 'verify',
    label: 'Verify',
  },
  {
    value: 'qc',
    label: 'QC',
  },
  {
    value: 'verify_qc',
    label: 'Verify QC',
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  mainContainer: {
    marginTop: '75px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  button: {
    backgroundColor: '#1c75bc !important',
    width: '150px',
    height : '50px',
    borderRadius: '5px',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#115293 !important',
    },
  },
}));

const WiproReport =()=> {
  const { showToast } = useContext(AuthContext);
  const classes = useStyles();
  const [values, setValues] = useState({
    fromDate: '',
    toDate: '',
    status: '',
  });

  const handleChange = (event) => {
    const name = event.target.name;
    setValues({
      ...values,
      [name]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    axios.get(`${UrlLinks.baseUrl}${UrlLinks.wiproReprt}?fromDate=${values.fromDate}&toDate=${values.toDate}&status=${values.status}`)
    .then(response=>{
        showToast("success", "Report sent successfully")
    })
    setValues({
        fromDate: '',
        toDate: '',
        status: '',
      });
  };

  return (
    <form className={classes.root} onSubmit={handleSubmit} noValidate autoComplete="off">
      
      <Grid className={classes.mainContainer} container spacing={3}>
      <Grid className={classes.mainContainer} container spacing={3}>
        <h1>Wipro Report Generator</h1>
      </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="fromDate"
            name="fromDate"
            label="From Date"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            value={values.fromDate}
            onChange={handleChange}
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="toDate"
            name="toDate"
            label="To Date"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            value={values.toDate}
            onChange={handleChange}
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="status"
            name="status"
            select
            label="Status"
            value={values.status}
            onChange={handleChange}
            helperText="Please select status"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            size="small"
          >
            {statuses.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12}><button className={classes.button} type="submit">Submit</button></Grid>
      </Grid>
      
    </form>
  );
}


export default WiproReport