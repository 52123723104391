import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { CSVLink } from "react-csv";
import Download from "../Group 3238.svg";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import Styles from "../index.module.scss";
import { ScaleLoader } from "react-spinners";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    border: "unset",
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    display: "flex",
    flexDirection: "row-reverse",
    justifyContent: "space-between",
  },
}))(MuiDialogActions);

export default function CustomizedDialogs(props) {
  const {response, openBGVTriggerModal, handleClose } = props;
  
  let dialogData = (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={openBGVTriggerModal}
      classes={{ paper: Styles.paperResponse }}
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        <Grid item xs={12}>
          <span className={Styles.summaryText}>Summary of BGV</span>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <Grid item xs={12} className="padding0">
          <span
            className={Styles.mainRecord}
          >{`# Worker records attempted for BGV : ${response.total}`}</span>
        </Grid>
        <Grid item xs={12} className="padding0 marginTop16">
          <Grid container className="padding0 marginTop16">
            <table className="tableContainer">
              <tr>
                <td># BGV In Queue</td>
                <td>{response.success}</td>
              </tr>
              <tr>
                <td># Incomplete details  </td>
                <td>{response.fail}</td>
              </tr>
            </table>
          </Grid>
          <div>
            <div>
              {response.errorList.length>0 && (
                <Grid item xs={12} style={{ marginTop: "20px" }}>
                  <CSVLink
                    data={response.errorList}
                    filename="BGV Incomplete data"
                    
                    className="btn btn-primary blueColor downloadExcelButton"
                    target="_blank"
                    style={{
                      color: "inherit",
                      textDecoration: "none",
                      marginTop: "20px",
                      fontWeight: "500",
                    }}
                  >
                    <span style={{ marginRight: "10px" }}>
                      Download Incomplete data
                    </span>
                    <img src={Download} alt="download" />
                  </CSVLink>
                </Grid>
              )}
            </div>
          </div>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          onClick={handleClose}
          className={"blueButtonContained"}
        >
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );

  return dialogData;
}
