import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  TimePicker,
} from "@material-ui/pickers";
import "./filter.css";
import { AccessTimeOutlined } from "@material-ui/icons";

const useStyles = makeStyles({
  shiftStart: {
    verticalAlign: "initial",
  },
  followUpStart: {
    verticalAlign: "initial",
    marginLeft: "18px",
    width: "182px",
  },
  shiftInput: {
    height: "50px",
    cursor: "pointer",
    "& fieldset": {
      top: "-7px",
    },
  },
  followUpInput: {
    height: "43px",
    cursor: "pointer",
  },
  attendanceFilter: {
    "& .MuiInputLabel-outlined": {
      transform: "translate(14px, 15px) scale(1)",
      zIndex: 0,
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
    },
  },
  attendanceInput: {
    height: "44px",
    marginBottom: "10px",
  },
});

export default function TimeFilter(props) {
  const classes = useStyles();
  const {
    uniqueKey,
    timeRange,
    disabled,
    labelFrom,
    labelTo,
    formMode,
    ampm,
    val,
    handleChange,
    type,
  } = props;

  return (
    <>
      {/* timeRange defines whether there is a range of time (from & to) or just a time setter. */}
      {timeRange ? (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          {/* <TimePicker autoOk label={label} value={filters[id].id} onChange={time => handleDateChange(time)} /> */}
          <TimePicker
            className={`${
              uniqueKey === "attendanceTime" ? classes.attendanceFilter : ""
            }`}
            InputProps={{
              className:
                uniqueKey === "attendanceTime" ? classes.attendanceInput : "",
              endAdornment: (
                <>
                  <AccessTimeOutlined
                    style={{
                      color:
                        uniqueKey === "projectShiftTime" &&
                        disabled.attendanceRequired
                          ? "#1C75BC"
                          : "",
                    }}
                  />
                </>
              ),
            }}
            variant="dialog"
            inputVariant="outlined"
            fullWidth
            disabled={
              uniqueKey === "projectShiftTime"
                ? formMode === "view" || !disabled.attendanceRequired
                : disabled
            }
            label={labelFrom}
            ampm={ampm}
            value={val[0]}
            onChange={(e) => handleChange(e, 0, "attendanceTimeFrom")}
          />
          <TimePicker
            className={`${
              uniqueKey === "attendanceTime" ? classes.attendanceFilter : ""
            }`}
            InputProps={{
              className:
                uniqueKey === "attendanceTime" ? classes.attendanceInput : "",
              endAdornment: (
                <>
                  <AccessTimeOutlined />
                </>
              ),
            }}
            variant="dialog"
            inputVariant="outlined"
            fullWidth
            disabled={
              uniqueKey === "projectShiftTime"
                ? formMode === "view" || !disabled.attendanceRequired
                : disabled
            }
            label={labelTo}
            ampm={ampm}
            value={val[1]}
            onChange={(e) => handleChange(e, 1, "attendanceTimeTo")}
          />
        </MuiPickersUtilsProvider>
      ) : (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <TimePicker
            className={`${
              uniqueKey === "projectShiftTime"
                ? classes.shiftStart
                : classes.followUpStart
            }`}
            InputProps={{
              className:
                uniqueKey === "projectShiftTime"
                  ? classes.shiftInput
                  : classes.followUpInput,
              endAdornment: (
                <>
                  <AccessTimeOutlined />
                </>
              ),
            }}
            variant="dialog"
            inputVariant="outlined"
            disabled={
              uniqueKey === "projectShiftTime"
                ? formMode === "view" || !disabled.attendanceRequired
                : disabled
            }
            ampm={ampm}
            value={val}
            onChange={(e) =>
              uniqueKey === "followUpTime"
                ? handleChange(e, 1, "time")
                : handleChange(e, type)
            }
          />
        </MuiPickersUtilsProvider>
      )}
    </>
  );
}
