import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import { Select, FormControl } from '@material-ui/core';

export default function MultipleSelectFilter(props) {
  const { options, filters, setFilters, id, sourceId, setSourceId }= props  
  const handleChange = (event) => {
    const {
        target: { value },
    } = event;

    let a= value === 'string' ? value.split(',') : value;
    setFilters({...filters, [id] : a.toString()});
    
    setSourceId(
    // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value
    );
  };

  return (
      <FormControl variant="outlined" style={{width:"250px"}}>
        <Select
          multiple={true}
          id={id}
          options={options}
          value={sourceId}
          onChange={handleChange}
          style={{height:"40px"}}
        >
          {options.map((option) => (
            <MenuItem
              key={option.id}
              value={option.name}
              style={{width:'250px', display:'block', padding:'6px 16px'}}
            >
              {option.name}
            </MenuItem>
          ))}
        </Select>
    </FormControl>
  );
}