import { combineReducers } from "redux";
import userReducer from "./Config/Users/users";
import applicationDbReducer from "./ApplicationDB/applicationDB";
import driveDbReducer from "./DriveDB/driveDB";
import workerDataReducer from "./WorkerData/workerData";

const rootReducer = combineReducers({
  applicationDb: applicationDbReducer,
  driveDb: driveDbReducer,
  workerData: workerDataReducer,
  users: userReducer,
});

export default rootReducer;
