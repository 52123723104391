import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import { Button, Grid, FormControl, Select, MenuItem } from "@material-ui/core";
import { useTable, useRowSelect, useSortBy, useFilters } from "react-table";
import urlLinks from "../../UrlLinks";
import Axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import NoRecord from "./noRecord.svg";
import styles from "./index.module.scss";
import ScaleLoader from "react-spinners/ScaleLoader";
import { Link } from "react-router-dom";
import AddIcon from "../Assets/Add.svg";
import { AppData } from "../../context/context";
import { useContext } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { AuthContext } from "../../services/AuthProvider";

const Styles = styled.div`
  padding: 1rem;
  .tableContainer {
    overflow-x: scroll;
    border: 1px solid #cfcfcf;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: 94%;
    position: absolute;
    /* left: 315px; */
    max-height: 74vh;
  }
  .tableContainer::-webkit-scrollbar {
    width: 0.75em;
  }

  .tableContainer::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  .tableContainer::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    //   border-radius: 8px;
  }
  table {
    border-spacing: 0;
    width: 100%;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    th {
      :last-child {
        border-left: 0;
      }
      background-color: #1c75bc !important;
      color: #ffffff;
    }
    th,
    td {
      padding: 5px 15px;
    }

    td {
      margin: 0;
      //   padding: 0.5rem;
      border-bottom: 1px solid #cfcfcf;
      border-right: 1px solid #cfcfcf;
      background-color: white;
      nth-last-child(2) {
        border-right: 0;
      }

      :last-child {
        border-right: 0;
      }

      input {
        font-size: 1rem;
        padding: 0;
        margin: 0;
        border: 0;
      }
    }

    thead {
      th {
        // border:2px solid red;
        padding: 15px 17px;
      }
    }

    tbody {
      td {
        max-width: 300px;
        padding: 12px 15px;
      }
    }
  }

  .pagination {
    padding: 0.5rem;
  }
`;

const useStyles = makeStyles((theme) => ({
  actionButton: {
    "& .MuiSelect-selectMenu": {
      border: "1px solid #1C75BC",
      color: "#1C75BC",
    },
    "& .MuiSelect-icon": {
      top: "calc(50% - 10px)",
    },
    "& .MuiSelect-iconOutlined": {
      right: 0,
      color: "#1C75BC",
    },
    "& .css-i4bv87-MuiSvgIcon-root": {
      fontSize: "20px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#1C75BC",
    },
  },
}));

const MpaConfig = (props) => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const { showToast } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const { mpaFormMode, setMpaFormMode } = useContext(AppData);
  const [actions, setActions] = useState(0);

  const handleActions = (event, workflow_id) => {
    if (event.target.value === "ViewEditProject") {
      setMpaFormMode("view");
    }
  };

  const columnsOkayGo = useMemo(
    () => [
      {
        Header: "MPA Name",
        accessor: "mpa_name",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "SPOC Name",
        accessor: "spoc_name",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Email ID",
        accessor: "spoc_email_id",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Phone No",
        accessor: "phone_number",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Action",
        Cell: (row) => {
          return (
            <FormControl
              variant="outlined"
              className={`margin0 ${classes.actionButton}`}
            >
              <Select
                id="actions"
                value={actions}
                classes={{ outlined: styles.actionSelect }}
                onChange={(e) => handleActions(e, row.row.original.workflowId)}
                IconComponent={MoreVertIcon}
              >
                <MenuItem value={0} disabled={true}>
                  {" "}
                  Actions
                </MenuItem>
                <MenuItem value="ViewEditProject">
                  <Link
                    to={{
                      pathname: `/mpaConfig/mpa/${row.row.original.mpa_id}`,
                    }}
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    View/Edit
                  </Link>
                </MenuItem>
              </Select>
            </FormControl>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
    ],
    []
  );

  //Function to create table data
  const makeData = (response) => {
    let extractedData = [];
    response.map((el) => {
      extractedData.push({
        is_company: el.is_company || "",
        mpa_id: el.mpa_id || "",
        mpa_name: el.is_company === 0 ? el.spoc_name || "" : el.mpa_name || "",
        spoc_name: el.spoc_name || "",
        spoc_email_id: el.spoc_email_id || "",
        phone_number: el.phone_number || "",
      });
      return true;
    });
    return extractedData;
  };

  const columns = columnsOkayGo;
  const updateMyData = () => {
    getPaymentListing(perPage, currentPage);
  };

  const getPaymentListing = (rows, pageNo) => {
    setLoading(true);
    let requestedBy = "";
    if (localStorage.getItem("roleType")) {
      Axios.get(
        `${urlLinks.baseUrl}${urlLinks.mpaListing.mainListing}?page_no=${
          pageNo - 1
        }${requestedBy}&rows=${rows}`
      )
        .then((response) => {
          if (response) {
            setData(makeData(response.data.response));
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    getPaymentListing(perPage, 1);
  }, [localStorage.getItem("roleType")]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        initialState: {
          hiddenColumns:[""],
        },
        updateMyData,
        showToast,
        setLoading,
        manualSortBy: true,
      },
      useFilters,
      useSortBy,
      useRowSelect
    );

  const loader = (
    <div style={{ margin: "130px auto" }}>
      <ScaleLoader height={70} width={10} color={"#1c75bc"} />
    </div>
  );
  const table = (
    <div className={`tableContainer ${styles.tableContainer}`}>
      <table {...getTableProps()} className="table">
        <thead className="thead">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps()}
                  className={
                    column.render("Header") === "Worker Id"
                      ? "stickWorkerIdnewMatch zIndex1080 header"
                      : column.render("Header") === "Name"
                      ? "stickWorkerNameMatchNew zIndex1080 header"
                      : column.id === "selection"
                      ? "stickCheckbox zIndex1080 header"
                      : "header"
                  }
                >
                  <span {...column.getSortByToggleProps()}>
                    {column.render("Header")}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} className="row">
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        className={
                          cell.column.Header === "Worker Id"
                            ? "stickWorkerIdnewMatch  zIndex540"
                            : cell.column.Header === "Name"
                            ? "stickWorkerNameMatchNew zIndex540"
                            : cell.column.id === "selection"
                            ? "stickCheckbox zIndex540"
                            : "cell"
                        }
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        }
      </table>
    </div>
  );

  return (
    <Styles>
      <Grid container className="padding2percent">
        <Grid style={{ justifyContent: "space-between" }} container spacing={2}>
          <Grid item xs={6}>
            <span className="fontSize24 fontWeightBold">MPA List</span>
          </Grid>
          <Grid
            style={{
              marginLeft: "54px",
              display: "flex",
              justifyContent: "end",
            }}
            item
            xs={4}
          >
            <Link to="/mpaConfig/mpa/add" style={{ textDecoration: "none" }}>
              <Button
                style={{
                  textTransform: "unset",
                  color: "#1C75BC",
                  border: "1px solid #1C75BC",
                }}
                variant="outlined"
                startIcon={
                  <img
                    alt="/"
                    src={AddIcon}
                    onClick={() => {
                      setMpaFormMode("create");
                    }}
                  />
                }
              >
                Add MPA
              </Button>
            </Link>
          </Grid>
        </Grid>
        {loading ? (
          loader
        ) : data.length === 0 ? (
          <div className={styles.noRecord}>
            <img src={NoRecord} alt="noRecord" />
            <span>No records found</span>
          </div>
        ) : (
          <Grid item xs={12} className="paddingTop16">
            {table}
          </Grid>
        )}
      </Grid>
    </Styles>
  );
};
export default MpaConfig;
