import React, { useState } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Grid } from "@material-ui/core";
import {
  Backdrop,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import swal from "sweetalert";
import "./index.css";
import axios from "axios";
import UrlLinks from "../../../UrlLinks";
import { Link, useHistory } from "react-router-dom";

const AddCompany = (data) => {
  const [companyLogo, setCompanyLogo] = useState(null);
  const history = useHistory();
  const [companyName, setCompanyName] = useState("");
  const [paymentConfiguration, setPaymentConfiguration] = useState(null);
  const [laoding, setLoading] = useState(false);
  const [document, setDocument] = useState({
    adhr: false,
    rc: false,
    pan: false,
    dl: false,
    address: false,
  });
  const [noUsedocument, setNoUseDocument] = useState({
    adhr: false,
    rc: false,
    pan: false,
    dl: false,
    address: false,
  });

  function checkObjEqual(obj1, obj2) {
    for (let key in obj1) {
      if (!(key in obj2)) return false;
      if (obj1[key] !== obj2[key]) return false;
    }
    return true;
  }

  const handleLogoUpload = (event) => {
    setCompanyLogo(event.target.files[0]);
  };

  const handleDocumentRequired = (event) => {
    if (event.target.value === "1") {
      setDocument({ ...document, pan: event.target.checked });
    }
    if (event.target.value === "2") {
      setDocument({ ...document, adhr: event.target.checked });
    }
    if (event.target.value === "3") {
      setDocument({ ...document, rc: event.target.checked });
    }
    if (event.target.value === "4") {
      setDocument({ ...document, dl: event.target.checked });
    }
    if (event.target.value === "5") {
      setDocument({ ...document, address: event.target.checked });
    }
  };

  const addClient = () => {
    setLoading(true);
    let formData = new FormData();
    formData.append("file", companyLogo);
    let docQueryUrl = `&mandatoryAadharBack=${
      document.adhr ? "1" : "0"
    }&mandatoryAadharCard=${
      document.adhr ? "1" : "0"
    }&mandatoryCurrentAddressProof=${
      document.address ? "1" : "0"
    }&mandatoryDrivingLicense=${document.dl ? "1" : "0"}&mandatoryPanCard=${
      document.pan ? "1" : "0"
    }&mandatoryRc=${document.rc ? "1" : "0"}&groupId=1`;
    let paymentConfigurationUrl = "";
    if (paymentConfiguration) {
      paymentConfigurationUrl = `&paymentConfiguration=${paymentConfiguration}`;
    }
    axios
      .post(
        `${UrlLinks.baseUrl}${
          UrlLinks.clients.addNewCompany
        }?partnerName=${companyName}${docQueryUrl}&isActive=1&insertedBy=${localStorage.getItem(
          "userID"
        )}${paymentConfigurationUrl}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        setLoading(false);
        if (response.data.code === 1000) {
          swal("Company Added", "", "success");
          history.push("/employer-data");
        } else {
          swal("Failed!", "", "error");
        }
      });
  };

  const handlePaymentConfig = (e) => {
    if (e.target.value) {
      setPaymentConfiguration(e.target.value);
    }
  };

  return (
    <>
      <div>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            "& > :not(style)": {
              m: 1,
              height: "600px",
              marginLeft: "130px",
              marginTop: "55px",
            },
          }}
        >
          <Paper sx={{ p: 2, margin: "40", maxWidth: 1200, flexGrow: 1 }}>
            <div>
              <Link to="/employer-data" style={{ textDecoration: "none" }}>
                {" "}
                <Button>
                  <ArrowBackIcon></ArrowBackIcon>
                </Button>
              </Link>{" "}
              &nbsp;&nbsp;&nbsp;{" "}
              <span className="heading-nw"> Add New Company</span>
            </div>
            <br></br>
            <Grid container spacing={2}>
              <Grid
                item
                style={{ paddingLeft: "96px", paddingTop: "24px" }}
                xs={4}
              >
                <div className="level">
                  <span className="red">*</span> Company Name
                </div>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  id="outlined-basic"
                  label="Company Name"
                  style={{ width: "300px" }}
                  variant="outlined"
                  onChange={(e) => {
                    setCompanyName(e.target.value);
                  }}
                />
              </Grid>
              <Grid
                item
                style={{ paddingLeft: "96px", paddingTop: "24px" }}
                xs={4}
              >
                <div className="level">
                  <span className="red">*</span> Company Logo
                </div>
              </Grid>
              <Grid item xs={8}>
                <Grid container spacing={2}>
                  <Grid item xs={2}>
                    <div>
                      {" "}
                      {companyLogo == null ? (
                        <div className="square">
                          <CameraAltIcon
                            style={{ margin: "36px" }}
                          ></CameraAltIcon>
                        </div>
                      ) : (
                        <div className="square">
                          <img
                            className="hw100"
                            alt="not found"
                            src={URL.createObjectURL(companyLogo)}
                          ></img>
                        </div>
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={7}>
                    <div className="m30">
                      <input
                        style={{ display: "none" }}
                        id="contained-button-file"
                        type="file"
                        accept="image/x-png,image/jpeg"
                        onChange={handleLogoUpload}
                      />
                      <label htmlFor="contained-button-file">
                        <Button component="span">
                          {companyLogo
                            ? "Update Company Logo"
                            : "Upload Company Logo"}
                        </Button>
                      </label>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                style={{ paddingLeft: "96px", paddingTop: "24px" }}
                xs={4}
              >
                <div className="level">
                  {" "}
                  <span className="red">*</span> Documents required
                </div>
              </Grid>
              <Grid item xs={8}>
                <FormGroup onChange={handleDocumentRequired}>
                  <FormControlLabel
                    control={
                      <Checkbox value={1} defaultChecked={document.pan} />
                    }
                    label="PAN card"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox value={2} defaultChecked={document.adhr} />
                    }
                    label="Aadhaar card"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox value={3} defaultChecked={document.rc} />
                    }
                    label="Vehicle RC"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox value={4} defaultChecked={document.dl} />
                    }
                    label="Driving License"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox value={5} defaultChecked={document.address} />
                    }
                    label="Address proof"
                  />
                </FormGroup>
              </Grid>
              <Grid
                item
                style={{ paddingLeft: "96px", paddingTop: "24px" }}
                xs={4}
              >
                <div className="level">Payment configuration</div>
              </Grid>
              <Grid item xs={8}>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    onClick={handlePaymentConfig}
                    defaultValue={paymentConfiguration}
                  >
                    <FormControlLabel
                      value={1}
                      control={<Radio value={1} />}
                      label="Shipment basis"
                    />
                    <FormControlLabel
                      value={2}
                      control={<Radio value={2} />}
                      label="Hourly basis"
                    />
                    <FormControlLabel
                      value={3}
                      control={<Radio value={3} />}
                      label="Daily basis"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid
                item
                style={{ paddingLeft: "96px", paddingTop: "24px" }}
                xs={4}
              ></Grid>
              <Grid item xs={8} style={{ textAlign: "right" }}>
                <div className="mr85">
                  <Link to="/employer-data" style={{ textDecoration: "none" }}>
                    {" "}
                    <Button variant="outlined">Cancel</Button>{" "}
                  </Link>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <Button
                    disabled={
                      !companyLogo ||
                      !companyName ||
                      checkObjEqual(document, noUsedocument)
                    }
                    onClick={addClient}
                    variant="contained"
                  >
                    Save
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Paper>
        </Box>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={laoding}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    </>
  );
};

export default AddCompany;
