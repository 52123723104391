import React, { useEffect, useState } from "react";
import MyStatefulEditor from "./rte_test";

export default function TextEditor({ data, setData, id }) {
  // Initialize the state with the initial value from data
  const [val, setVal] = useState(data[id] || "");

  useEffect(() => {
    // Update the value whenever data[id] changes
    setVal(data[id] || "");
  }, [data[id]]);

  const onChange = (value) => {
    // Update the value in the state and data
    setVal(value);
    setData((prev) => ({ ...prev, [id]: value }));
  };

  return (
    <div className="App">
      <MyStatefulEditor markup={val} style={{ height: "200px" }} onChange={onChange} value={val} />
    </div>
  );
}
