import React, { useState, useEffect, useMemo, useContext } from "react";
import styled from "styled-components";
import {
  Button,
  Grid,
  IconButton,
  Checkbox,
  TextField,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { useTable, useRowSelect, useSortBy, useFilters } from "react-table";
import urlLinks from "../../UrlLinks";
import Axios from "axios";
import styles from "./index.module.scss";
import { CSVLink } from "react-csv";
import ArrowUpwardSharpIcon from "@material-ui/icons/ArrowUpwardSharp";
import ArrowDownwardSharpIcon from "@material-ui/icons/ArrowDownwardSharp";
import UploadAJWorkerModal from "../WorkerDataV3/components/UploadAJEmployeeIdModal";
import SearchIcon from "@material-ui/icons/Search";
import OnboardingModal from "./components/onboardingModal";
import OnboardingModalV2 from "./components/onboardingModalV2";
import OnboardingModalAJV2 from "../WorkerDataV3/components/onboardingModalV2";
import GroupFlexModal from "./components/GroupFlexModal";
import CasperOnboardingModal from "./components/CasperOnboardingModal";
import OffboardingModal from "./components/offboardingModal";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CloseSharpIcon from "@material-ui/icons/CloseSharp";
import Pagination from "../../Utils/Pagination";
import { makeStyles } from "@material-ui/core/styles";
import * as moment from "moment";
import NoRecord from "./noRecord.svg";
import EditContactModal from "./components/EditPhoneNumber";
import ReactivationModal from "./components/ReactivtionDialog";
import ToggleButton from "@material-ui/lab/ToggleButton";
import CriminalRecord from "./components/CriminalRecord";
import Filters from "./filterModal";
import ScaleLoader from "react-spinners/ScaleLoader";
import JSZip from "jszip";
import JSZipUtils from "jszip-utils";
import { AuthContext } from "../../services/AuthProvider";
import EditableTextInput from "../../Utils/functions/EditableTextInput";
import { saveAs } from "file-saver";
import { Link } from "react-router-dom";
import UrlLinks from "../../UrlLinks";
import { ClockLoader } from "react-spinners";
import EditableDropdown from "./Functions/EditableDropdown";
import "./indeterminateCheckbox.css";

const Styles = styled.div`
  padding: 1rem;
  .tableContainer {
    overflow-x: scroll;
    border: 1px solid #cfcfcf;
    max-height: 74vh;
  }
  .tableContainer::-webkit-scrollbar {
    width: 0.75em;
  }

  .tableContainer::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  .tableContainer::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    //   border-radius: 8px;
  }
  table {
    border-spacing: 0;
    width: max-content;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    th {
      :last-child {
        border-left: 0;
      }
      background-color: #1c75bc !important;
      color: #ffffff;
    }
    th,
    td {
      padding: 5px 15px;
    }
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid #cfcfcf;
      border-right: 1px solid #cfcfcf;
      background-color: white;
      nth-last-child(2) {
        border-right: 0;
      }
      :last-child {
        border-right: 0;
      }

      input {
        font-size: 1rem;
        padding: 0;
        margin: 0;
        border: 0;
      }
    }
  }

  .pagination {
    padding: 0.5rem;
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-root": {
      marginLeft: "0",
      "&.Mui-focused fieldset": {
        border: "1px solid grey",
      },
    },
  },
  input: {
    '& [class*="MuiOutlinedInput-input"]': {
      padding: "8px 135px 8px 8px",
      borderLeft: "1px solid grey",
      borderRadius: "0px",
    },
  },
  select: {
    "& .MuiOutlinedInput-inputMarginDense": {
      padding: "8px 10px",
    },
  },
}));

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <React.Fragment>
        <Checkbox ref={resolvedRef} {...rest} />
      </React.Fragment>
    );
  }
);

const ExternalWorker = (props) => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [allRecordLoading, setAllRecordLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allRecord, setAllRecord] = useState([]);
  const [agreementDownloading, setAgreementDownloading] = useState(false);
  const [emailAgreement, setEmailAgreement] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [allDocumentDownload, setAllDocumentDownload] = useState(null);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [openOffboardingModal, setOpenOffboardingModal] = useState(false);
  const [openOnboardingModal, setOpenOnboardingModal] = useState(false);
  const [openOnboardingModalV2, setOpenOnboardingModalV2] = useState(false);
  const [openOnboardingModalAJV2, setOpenOnboardingModalAJV2] = useState(false);
  const [openAJworkerUploadModal, setOpenAJworkerUploadModal] = useState(false);
  const [openCasperOnboardingModal, setOpenCasperOnboardingModal] =
    useState(false);
  const [openGroupflexModal, setOpenGroupflexModal] = useState(false);
  const [searchByAuto, setsearchByAuto] = useState("");
  const [partners, setPartners] = useState([]);
  const [editContactData, setEditContactData] = useState({
    userId: null,
    contact: null,
    open: false,
    error: "",
  });
  const [reactivationData, setReactivationData] = useState({
    open: false,
    userId: null,
    date: null,
  });
  const [actions, setActions] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [sortBy, setSortBy] = useState("");
  const [sortDirection, setSortDirection] = useState(false);
  const [sortDirectionUrl, setSortDirectionUrl] = useState("");
  const [searchBy, setSearchBy] = useState("");
  const [sortUrl, setSortUrl] = useState("");
  const [filterUrl, setFilterUrl] = useState("");
  const [groupflexAdmin, setGroupflexAdmin] = useState(false);
  const [criminalRecordData, setCriminalRecordData] = useState({
    open: false,
    userId: null,
  });
  const [filters, setFilters] = useState({
    searchBy: "",
    all: true,
    onboardingInitiated: false,
    active: false,
    inactive: false,
    criminalRecord: false,
    temporarilyInactive: false,
    offboardingInitiated: false,
    zone: "",
    facilityState: "",
    facilityName: "",
    partnerId: "",
    client: "",
    city: "",
    state: "",
    addedOn: [null, null],
    panStatus: "",
    vacoAppStatus: "",
  });

  useEffect(() => {
    Axios.get(`${UrlLinks.baseUrl}${UrlLinks.getPartner}`).then((response) => {
      setPartners(
        response.data.response.filter((el) => el.is_active && el.is_aj_client)
      );
    });
  }, []);

  useEffect(() => {
    ApplyFilters(filters);
  }, [searchByAuto]);

  const { showToast } = useContext(AuthContext);

  const sortOptions = [
    ["active_date", "Activation date"],
    ["offboarding_date", "offboarding initiate date"],
  ];

  const createDocumentList = (documents, profilePhoto) => {
    let list = [];
    let aadharBackError = "Aadhar Back,";
    let aadharFrontError = " Aadhar Front,";
    let profilePicError = " Profile pic";
    let errorText = " not Found!";
    documents.map((doc) => {
      if (doc.document_type_id === 1929 || doc.document_type_id === 1867) {
        if (doc.document_type_id === 1929) aadharBackError = "";
        if (doc.document_type_id === 1867) aadharFrontError = "";
        if (doc.file_path.substring(0, 5) === "http:") {
          // in case if it come https, link wont become httpss

          list.push("https" + doc.file_path.substring(4, doc.file_path.length));
        } else list.push(doc.file_path);
      }
    });
    if (profilePhoto !== null && profilePhoto !== "") {
      profilePicError = "";
      if (profilePhoto.substring(0, 5) === "http:") {
        // in case if it come https, link wont become httpss

        list.push("https" + profilePhoto.substring(4, profilePhoto.length));
      } else list.push(profilePhoto);
    }
    if (
      aadharBackError.length !== 0 ||
      aadharFrontError.length !== 0 ||
      profilePicError.length !== 0
    ) {
      errorText =
        aadharBackError + aadharFrontError + profilePicError + errorText;
      showToast("error", errorText);
    }

    return list.forEach((path) => {
      window.open(path, "_blank");
    });
  };

  const getDocumentList = (userId, profilePhoto) => {
    Axios.get(
      `${urlLinks.baseUrl}${urlLinks.workerUrls.docList}${parseInt(userId)}`
    ).then((response) => {
      setAllDocumentDownload(
        createDocumentList(response.data.response.content, profilePhoto)
      );
    });
  };

  const handleSearch = () => {
    setFilters({ ...filters, searchBy: searchBy });
    setsearchByAuto(searchBy);
  };

  const handleSearchClose = () => {
    setFilters({ ...filters, searchBy: "" });
    setSearchBy("");
  };

  const handleCriminalRecord = (event) => {
    setCriminalRecordData({ userId: event.target.id, open: true });
  };

  const ApplySort = () => {
    let url = "";
    switch (sortBy) {
      case "casper_id":
        url = "&orderBy=wm.casper_id";
        break;
      case "profile_id":
        url = "&orderBy=wm.worker_id";
        break;
      case "okaygo_id":
        url = "&orderBy=wm.okaygo_id";
        break;
      case "onboarding_date":
        url = "&orderBy=wm.onboarding_initiation_date";
        break;
      case "active_date":
        url = "&orderBy=wm.active_date";
        break;
      case "offboarding_date":
        url = "&orderBy=wm.offboarding_initiation_date";
        break;
      case "inactive_date":
        url = "&orderBy=wm.inactive_date";
        break;
      default:
        url = "";
        break;
    }
    setSortUrl(url);
  };

  const handleEditPhoneNumber = (row) => {
    setEditContactData({
      userId: row.row.original.userId,
      contact: row.row.original.contactNo,
      open: true,
      error: "",
    });
  };

  const handleEditContactClose = () => {
    setEditContactData({
      userId: null,
      contact: null,
      open: false,
      error: "",
    });
  };

  const handleReactivationDateClose = () => {
    setReactivationData({
      open: false,
      userId: null,
      date: null,
    });
  };

  const handleTemporaryInactive = (data) => {
    setReactivationData({
      open: true,
      userId: data.userId,
      date: data.reactivationDateTimestamp
        ? data.reactivationDateTimestamp
        : null,
    });
  };

  useEffect(() => {
    ApplySort();
    setSortDirection(false);
    setSortDirectionUrl("");
  }, [sortBy]);

  const handleStatuses = (event, name) => {
    if (filters.onboardingInitiated && filters.active && filters.inactive) {
      setFilters({
        ...filters,
        onboardingInitiated: false,
        active: false,
        offboardingInitiated: false,
        inactive: false,
        all: true,
      });
    } else if (name === "all") {
      if (!filters[name]) {
        setFilters({
          ...filters,
          onboardingInitiated: false,
          active: false,
          offboardingInitiated: false,
          inactive: false,
          temporarilyInactive: false,
          all: true,
        });
      }
    } else if (name === "onboardingInitiated") {
      if (!filters[name]) {
        setFilters({
          ...filters,
          onboardingInitiated: true,
          active: false,
          offboardingInitiated: false,
          inactive: false,
          temporarilyInactive: false,
          all: false,
        });
      }
    } else if (name === "active") {
      if (!filters[name]) {
        setFilters({
          ...filters,
          onboardingInitiated: false,
          active: true,
          offboardingInitiated: false,
          inactive: false,
          temporarilyInactive: false,
          all: false,
        });
      }
    } else if (name === "offboardingInitiated") {
      if (!filters[name]) {
        setFilters({
          ...filters,
          onboardingInitiated: false,
          active: false,
          offboardingInitiated: true,
          inactive: false,
          temporarilyInactive: false,
          all: false,
        });
      }
    } else if (name === "temporarilyInactive") {
      if (!filters[name]) {
        setFilters({
          ...filters,
          onboardingInitiated: false,
          active: false,
          offboardingInitiated: false,
          inactive: false,
          temporarilyInactive: true,
          all: false,
        });
      }
    } else if (name === "inactive") {
      if (!filters[name]) {
        setFilters({
          ...filters,
          onboardingInitiated: false,
          active: false,
          offboardingInitiated: false,
          inactive: true,
          temporarilyInactive: false,
          all: false,
        });
      }
    } else if (name === "criminalRecord") {
      setFilters({ ...filters, criminalRecord: !filters.criminalRecord });
    }
  };

  const ApplyFilters = (filters) => {
    let url = "";
    if (filters["searchBy"]) url = `${url}&searchBy=${filters["searchBy"]}`;
    if (filters["onboardingInitiated"]) {
      url = `${url}&workerStatus=2,5`;
    }
    if (filters["active"]) {
      url = `${url}&workerStatus=1,7`;
    }
    if (filters["inactive"]) {
      url = `${url}&workerStatus=3`;
    }
    if (filters["offboardingInitiated"]) {
      url = `${url}&workerStatus=4`;
    }
    if (filters["temporarilyInactive"]) {
      url = `${url}&workerStatus=6,8`;
    }
    if (filters["criminalRecord"]) {
      url = `${url}&criminal_record=1`;
    }
    if (filters["zone"]) {
      url = `${url}&zone=${filters["zone"]}`;
      setIsFilterApplied(true);
    }
    if (filters["panStatus"] === 0 || filters["panStatus"] === 1) {
      url = `${url}&isPanVerified=${filters["panStatus"]}`;
      setIsFilterApplied(true);
    }
    if (
      filters["vacoAppStatus"] === 0 ||
      filters["vacoAppStatus"] === 1 ||
      filters["vacoAppStatus"] === 2
    ) {
      url = `${url}&vacoAppStatus=${filters["vacoAppStatus"]}`;
      setIsFilterApplied(true);
    }
    if (filters["facilityState"]) {
      url = `${url}&facilityState=${filters["facilityState"].name}`;
      setIsFilterApplied(true);
    }
    if (filters["facilityName"]) {
      url = `${url}&facilityName=${filters["facilityName"].name}`;
      setIsFilterApplied(true);
    }
    if (filters["city"]) {
      url = `${url}&city=${filters["city"].id}`;
      setIsFilterApplied(true);
    }
    if (filters["client"]) {
      url = `${url}&client=${filters["client"].id}`;
      setIsFilterApplied(true);
    }
    if (filters["state"]) {
      url = `${url}&state=${filters["state"].id}`;
      setIsFilterApplied(true);
    }
    if (filters["addedOn"][0] && !filters["addedOn"][1])
      url = `${url}&addedFrom=${moment(filters["addedOn"][0]).format(
        "YYYY-MM-DD"
      )}&addedto=${moment(new Date()).format("YYYY-MM-DD")} 23:59:59`;
    if (!filters["addedOn"][0] && filters["addedOn"][1])
      url = `${url}&addedFrom=2019-01-01&addedto=${moment(
        filters["addedOn"][1]
      ).format("YYYY-MM-DD")} 23:59:59`;
    if (filters["addedOn"][0] && filters["addedOn"][1])
      url = `${url}&addedFrom=${moment(filters["addedOn"][0]).format(
        "YYYY-MM-DD"
      )}&addedto=${moment(filters["addedOn"][1]).format(
        "YYYY-MM-DD"
      )} 23:59:59`;

    if (filters["partnerId"]) {
      url = `${url}&partnerId=${filters["partnerId"]}`;
      setIsFilterApplied(true);
    }

    setFilterUrl(url);
    setFilters(filters);
    setCurrentPage(1);
  };

  const resetFilters = () => {
    const filterList = {
      searchBy: "",
      zone: "",
      facilityState: "",
      facilityName: "",
      partnerId: "",
      client: "",
      city: "",
      state: "",
      addedOn: [null, null],
      panStatus: "",
      vacoAppStatus: "",
    };
    setFilters(filterList);
    setFilterUrl("");
    setIsFilterApplied(true);
    setCurrentPage(1);
    return filterList;
  };

  useEffect(() => {
    ApplyFilters(filters);
  }, [filters]);

  const handleSortDirection = () => {
    if (!sortDirection) setSortDirectionUrl("&sortBy=ascending");
    else setSortDirectionUrl("");
    setSortDirection(!sortDirection);
  };

  const handleActionsAJ = (event) => {
    if (event.target.value === "uploadWorkerData") {
      setOpenOnboardingModalAJV2(true);
    } else if (event.target.value === "UploadCasperID") {
      setOpenAJworkerUploadModal(true);
    }
  };

  const handleActivateWorker = (userId) => {
    Axios.post(
      `${urlLinks.baseUrl}${
        urlLinks.workerUrls.editEntity
      }status=1&insertedBy=${localStorage.getItem("userID")}&userId=${userId}`
    ).then((response) => {
      if (response.data.code === 1000) {
        showToast("success", "Worker status changed to Active");
        updateMyData();
      }
    });
  };

  //columns list
  const columnsOkayGo = useMemo(
    () => [
      {
        id: "selection",
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <div>
            <IndeterminateCheckbox
              {...getToggleAllRowsSelectedProps()}
              className="overrideCheckbox"
              color="white"
            />
          </div>
        ),
        Cell: ({ row }) => (
          <div id="check1">
            <IndeterminateCheckbox
              {...row.getToggleRowSelectedProps()}
              style={{ display: "flex", justifyItems: "center" }}
            />
          </div>
        ),
      },
      {
        Header: "Casper id",
        accessor: "casperId",
        defaultCanFilter: false,
        defaultCanSort: false,
        Cell: (row) => {
          if (
            (localStorage.getItem("roleType") === "6" ||
              localStorage.getItem("roleType") === "31") &&
            row.row.original.casperId !== ""
          ) {
            return EditableTextInput(row);
          } else {
            return row.row.original.casperId;
          }
        },
      },
      {
        Header: "OkayGo id",
        accessor: "okaygoId",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Status",
        // accessor: "status",
        Cell: (row) => {
          if (
            (localStorage.getItem("roleType") === "6" ||
              localStorage.getItem("roleType") === "2" ||
              localStorage.getItem("roleType") === "31") &&
            row.row.original.status === "Inactive"
          ) {
            return (
              <div>
                {row.row.original.status}{" "}
                <span>
                  <Button
                    style={{
                      color: "mediumblue",
                      textDecoration: "underline",
                      textTransform: "none",
                    }}
                    variant="text"
                    onClick={() =>
                      handleActivateWorker(row.row.original.userId)
                    }
                  >
                    <i>Active</i>
                  </Button>
                </span>
              </div>
            );
          } else {
            return row.row.original.status;
          }
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Flipkart Earning Status",
        accessor: "flipkartEarningStatus",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Name",
        accessor: "name",
        Cell: (row) => {
          return row.row.original.criminalRecord === "Yes" ? (
            <button
              className={styles.workerName}
              type="button"
              id={row.row.original.userId}
              onClick={handleCriminalRecord}
            >
              {row.row.original.name}
            </button>
          ) : (
            <Link
              to={{ pathname: `/workerview/${row.row.original.userId}` }}
              target="_blank"
              className="nameLink"
            >
              {row.row.original.name}
            </Link>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Partner",
        accessor: "partnerName",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Download Agreement",
        accessor: "downloadAgreement",
        Cell: (row) => {
          return row.row.original.agreementPath ? (
            <a
              href={row.row.original.agreementPath}
              className={styles.downloadTemplate}
            >
              <span style={{ color: "#1c75bc" }}>Download Agreement</span>
            </a>
          ) : (
            <div></div>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Documents",
        accessor: "documents",
        Cell: (row) => {
          return (
            <button
              className={styles.workerName}
              type="button"
              style={{ fontSize: "16px !important" }}
              onClick={() =>
                getDocumentList(
                  row.row.original.userId,
                  row.row.original.profilePhoto
                )
              }
            >
              Download
            </button>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Father's Name",
        accessor: "fathersName",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Source",
        accessor: "Source",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Contact No",
        accessor: "contactNo",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Aadhar Number",
        accessor: "aadharNumber",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Gender",
        accessor: "gender",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Date of birth",
        accessor: "dob",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Marital status",
        accessor: "maritalStatus",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "EDAB",
        accessor: "edab",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Criminal record",
        accessor: "criminalRecord",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Present Address",
        accessor: "present_address",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Present City",
        accessor: "present_city",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Present State",
        accessor: "present_state",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Permanent address",
        accessor: "permanent_address",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Permanent city",
        accessor: "permanent_city",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Permanent state",
        accessor: "permanent_state",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Emergency Contact No",
        accessor: "emergencyContactNo",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Qualification",
        accessor: "qualification",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Bank details",
        accessor: "bankDetails",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Work Experience",
        accessor: "workExperience",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Groupflex/ Eflex",
        accessor: "groupflex",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Leader",
        accessor: "leader",
        Cell: EditableDropdown,
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Role preference",
        accessor: "rolePreference",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Sourced by",
        accessor: "sourcedBy",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Zone",
        accessor: "zone",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Facility name",
        accessor: "facility_name",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Facility city",
        accessor: "facility_city",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Facility state",
        accessor: "facility_state",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Rate Card",
        accessor: "rateCard",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Facility type",
        accessor: "facility_type",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Designation",
        accessor: "designation",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Date of joining",
        accessor: "date_of_joining",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Activation Date",
        accessor: "activationDate",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Exit Date",
        accessor: "exitDate",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Exit Type",
        accessor: "exitType",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Reason of leaving",
        accessor: "reasonOfLeaving",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Offboarding Initiate Date",
        accessor: "offboardingInitiateDate",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Offboarding Initiate By",
        accessor: "offboardingInitiateBy",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Blacklisted",
        accessor: "blacklisted",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Added by",
        accessor: "addedBy",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Added on",
        accessor: "addedOn",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
    ],
    []
  );

  const userIdAvailable = () => {
    if (localStorage.getItem("userID") !== null) {
      Axios.get(
        `${urlLinks.baseUrl}${
          urlLinks.paymentUrls.financeAdmin
        }${localStorage.getItem("userID")}`
      )
        .then((response) => {
          if (response.data?.groupflexAdmin === 1) {
            setGroupflexAdmin(true);
          } else {
            setGroupflexAdmin(false);
          }
        })
        .catch((error) => {
          showToast("error", error.message);
        });
    }
  };

  useEffect(() => {
    userIdAvailable();
  }, [localStorage.getItem("userID")]);

  const createWorkerStatus = (status) => {
    let text = "";
    switch (status) {
      case 0:
        text = "Not Onboarded";
        break;
      case 1:
        text = "Active";
        break;
      case 2:
        text = "Partially Onboarded";
        break;
      case 3:
        text = "Inactive";
        break;
      case 4:
        text = "Offboarding Initiated";
        break;
      case 5:
        text = "Onboarding Completed";
        break;
      case 6:
        text = "Temporarily Inactive";
        break;
      case 7:
        text = "Temporary Inactivation Initiated";
        break;
      case 8:
        text = "Reactivation Initiated";
        break;
      default:
        break;
    }
    return text;
  };

  //Function to create table data
  const makeData = (response) => {
    let extractedData = [];
    let lastupdatedon = [];

    response.content.map((el) => {
      const sourceByType =
        el.sourceBy === "VENDOR"
          ? "Vendor"
          : el.sourceBy === "TA"
          ? "HRBP/TA"
          : el.sourceBy === "EREF"
          ? "Employee reference"
          : "";
      const sourceByName = el.sourceByName ? ` - ${el.sourceByName}` : "";
      const addedEmail = el.workerInsertedByEmail
        ? `(${el.workerInsertedByEmail})`
        : "";
      extractedData.push({
        employeeId: el.clientId || "",
        flipkartEarningStatus:
          el.flipkartEarningStatus !== null
            ? el.flipkartEarningStatus
              ? "Active"
              : "Inactive"
            : null,
        casperId: el.casperId || "",
        okaygoId: el.okaygoId || "",
        smsCount: el.smsSentCount || "",
        client: el.client || "",
        clientEmpId: el.clientEmpId || "",
        tdsPercent: el.tds || "",
        smsDeliveryStatus: el.smsDeliveryStatus || "",
        LastSmsDeliveredOn: el.smsDeliveredOn
          ? moment(el.smsDeliveredOn).format("DD/MM/YYYY hh:mm a")
          : "",
        status: createWorkerStatus(el.workerStatus),
        name: `${el.firstName ? el.firstName : ""} ${
          el.lastName ? el.lastName : ""
        }`,
        fathersName: el.fatherName || "",
        contactNo: el.phoneNumber || "",
        aadharNumber: el.aadhaarNumber || "",
        panNumber: el.panNumber || "",
        panVerification: el.pan_status ? el.pan_status : "-",
        workerAppStatus:
          el.vacoAppStatus === 1
            ? "Registered"
            : el.vacoAppStatus === 2
            ? "Onboarded"
            : "",
        gender:
          el.gender === "26"
            ? "Male"
            : el.gender === "27"
            ? "Female"
            : el.gender === "30"
            ? "Other"
            : "",
        dob: el.dateOfBirth ? moment(el.dateOfBirth).format("DD/MM/YYYY") : "",
        maritalStatus:
          el.maritalStatus === 1871
            ? "Single"
            : el.maritalStatus === 1872
            ? "Married"
            : el.maritalStatus === 1873
            ? "Other"
            : "",
        edab:
          el.physicallyDisabled === 1
            ? "Yes"
            : el.physicallyDisabled === 0
            ? "No"
            : "",
        criminalRecord:
          el.criminalRecord === 0 ? "No" : el.criminalRecord === 1 ? "Yes" : "",
        present_address: el.curAddressLine1 || "",
        workerStatus: el.workerStatus,
        present_city: el.curCity || "",
        present_state: el.curStateName || "",
        permanent_address: el.perAddressLine1 || "",
        permanent_city: el.perCity || "",
        permanent_state: el.perStateName || "",
        emergencyContactNo: el.emergencyContact || "",
        qualification: el.qualification || "",
        bankDetails: el.bankDetailsFilled ? "Yes" : "No",
        workExperience:
          el.totalExpYear || el.totalExpMonth
            ? `${el.totalExpYear ? el.totalExpYear : 0} yrs ${
                el.totalExpMonth ? el.totalExpMonth : 0
              } months`
            : "",
        rolePreference: el.jobRolePreferenceName || "",
        sourcedBy: `${sourceByType}${sourceByName}`,
        zone: el.zone || "",
        facility_name: el.facilityName || "",
        facility_city: el.facilityCity || "",
        facility_state: el.facilityState || "",
        branch: el.facilityName || "",
        city: el.facilityCity || "",
        state: el.facilityState || "",
        rateCard: el.rateCardType || "",
        facility_type: el.facilityType ? el.facilityType : "",
        designation: el.designation || "",
        jobRole: el.designation || "",
        date_of_joining: el.dateOfJoining
          ? moment(el.dateOfJoining).format("DD/MM/YYYY")
          : "",
        activationDate: el.activeDate
          ? moment(el.activeDate).format("DD/MM/YYYY")
          : "",
        reactivationDate: el.reactivationDate
          ? moment(el.reactivationDate).format("DD/MM/YYYY")
          : null,
        reactivationDateTimestamp: el.reactivationDate
          ? el.reactivationDate
          : null,
        exitType: el.exitType || "",
        exitDate: el.exitDate ? moment(el.exitDate).format("DD/MM/YYYY") : "",
        deactivationDate: el.inactiveDate
          ? moment(el.inactiveDate).format("DD/MM/YYYY")
          : "",
        reasonOfLeaving: el.reasonOfLeaving || "",
        offboardingInitiateDate: el.offboardingInitiationDate
          ? moment(el.offboardingInitiationDate).format("DD/MM/YYYY")
          : "",
        offboardingInitiateBy: el.offboardingInitiateBy || "",
        blacklisted:
          el.blacklisted === 1 ? "Yes" : el.blacklisted === 0 ? "No " : "",
        userId: el.userId,
        downloadOfferAgreement: el.offerLetter || "",
        agreementPath: el.agreementFilePath,
        agreementName: el.agreementFileName,
        profilePhoto: el.profilePhoto,
        addedBy: `${
          el.workerInsertedByName ? el.workerInsertedByName : ""
        } ${addedEmail}`,
        addedOn: el.workerInsertedOn
          ? moment(el.workerInsertedOn).format("DD/MM/YYYY hh:mm a")
          : "",
        firstEarningDate: el.firstEarningDate
          ? moment(el.firstEarningDate).format("DD/MM/YYYY")
          : "",
        lastEarningDate: el.lastEarningDate
          ? moment(el.lastEarningDate).format("DD/MM/YYYY")
          : "",
        offboardedOn: el.offboardingOn
          ? moment(el.offboardingOn).format("DD/MM/YYYY")
          : "",
        is_document_detail_filled: el.is_document_detail_filled,
        flipkartRateCard: el.is_lma_worker ? el.lmaRateCard : el.rateCard,
        groupflex: el.is_lma_worker
          ? "LMA"
          : el.groupflexId
          ? el.groupflexId
          : "Eflex",
        partnerName: el.partnerName ? el.partnerName : "",
        leader: el.isLeader !== null ? (el.isLeader === 1 ? "YES" : "NO") : "-",
      });
      lastupdatedon.push(el.lastUpdatedOn);
      return true;
    });
    return extractedData;
  };

  //Function to create All record export data
  const makeAllRecordData = (response) => {
    let extractedData = [];
    response.map((el) => {
      const addedEmail = el.workerInsertedByEmail
        ? `(${el.workerInsertedByEmail})`
        : "";
      const temp = {
        casperId: el.casperId || "",
        okaygoId: el.okaygoId || "",
        smsCount: el.smsSentCount || "",
        smsDeliveryStatus: el.smsDeliveryStatus || "",
        LastSmsDeliveredOn: el.smsDeliveredOn
          ? moment(el.smsDeliveredOn).format("DD/MM/YYYY hh:mm a")
          : "",
        status: createWorkerStatus(el.workerStatus),
        flipkartEarningStatus:
          el.flipkartEarningStatus !== null
            ? el.flipkartEarningStatus
              ? "Active"
              : "Inactive"
            : null,
        name: `${el.firstName ? el.firstName : ""} ${
          el.lastName ? el.lastName : ""
        }`,
        fathersName: el.fatherName || "",
        contactNo: el.phoneNumber || "",
        aadharNumber: el.aadhaarNumber || "",
        gender:
          el.gender === "26"
            ? "Male"
            : el.gender === "27"
            ? "Female"
            : el.gender === "30"
            ? "Other"
            : "",
        dob: el.dateOfBirth ? moment(el.dateOfBirth).format("DD/MM/YYYY") : "",
        maritalStatus:
          el.maritalStatus === 1871
            ? "Single"
            : el.maritalStatus === 1872
            ? "Married"
            : el.maritalStatus === 1873
            ? "Other"
            : "",
        edab:
          el.physicallyDisabled === 1
            ? "Yes"
            : el.physicallyDisabled === 0
            ? "No"
            : "",
        criminalRecord:
          el.criminalRecord === 0 ? "No" : el.criminalRecord === 1 ? "Yes" : "",
        present_address: el.curAddressLine1 || "",
        present_city: el.curCity || "",
        present_state: el.curStateName || "",
        permanent_address: el.perAddressLine1 || "",
        permanent_city: el.perCity || "",
        permanent_state: el.perStateName || "",
        emergencyContactNo: el.emergencyContact || "",
        qualification: el.qualification || "",
        bankDetails: el.accountNumber ? "Yes" : "No",
        workExperience:
          el.totalExpYear || el.totalExpMonth
            ? `${el.totalExpYear ? el.totalExpYear : 0} yrs ${
                el.totalExpMonth ? el.totalExpMonth : 0
              } months`
            : "",
        groupflex: el.is_lma_worker
          ? "LMA"
          : el.groupflexId
          ? el.groupflexId
          : "Eflex",
        leader: el.isLeader !== null ? (el.isLeader === 1 ? "YES" : "NO") : "-",
        rolePreference: el.jobRolePreferenceName || "",
        sourcedBy:
          el.sourceBy === "VENDOR"
            ? "Vendor"
            : el.sourceBy === "TA"
            ? "HRBP/TA"
            : el.sourceBy === "EREF"
            ? "Employee reference"
            : "",
        zone: el.zone || "",
        facility_name: el.facilityName || "",
        facility_city: el.facilityCity || "",
        facility_state: el.facilityState || "",
        rateCard: el.is_lma_worker ? el.lmaRateCard : el.rateCard,
        facility_type: el.facilityType ? el.facilityType : "",
        designation: el.designation || "",
        date_of_joining: el.dateOfJoining
          ? moment(el.dateOfJoining).format("DD/MM/YYYY")
          : "",
        activationDate: el.activeDate
          ? moment(el.activeDate).format("DD/MM/YYYY")
          : "",
        reactivationDate: el.reactivationDate
          ? moment(el.reactivationDate).format("DD/MM/YYYY")
          : null,
        exitType: el.exitType || "",
        exitDate: el.exitDate ? moment(el.exitDate).format("DD/MM/YYYY") : "",
        deactivationDate: el.inactiveDate
          ? moment(el.inactiveDate).format("DD/MM/YYYY")
          : "",
        reasonOfLeaving: el.reasonOfLeaving || "",
        offboardingInitiateDate: el.offboardingInitiationDate
          ? moment(el.offboardingInitiationDate).format("DD/MM/YYYY")
          : "",
        offboardingInitiateBy: el.offboardingInitiateBy || "",
        blacklisted: el.blacklisted === 1 ? "Yes" : "No ",
        addedBy: `${
          el.workerInsertedByName ? el.workerInsertedByName : ""
        } ${addedEmail}`,
        addedOn: el.workerInsertedOn
          ? moment(el.workerInsertedOn).format("DD/MM/YYYY hh:mm a")
          : "",
        partner: el.partnerName ? el.partnerName : "",
      };

      extractedData.push(temp);

      return true;
    });

    return extractedData;
  };

  const updateMyData = () => {
    getExternalWorkerList(perPage, currentPage);
  };

  const handleEditPhoneNumberService = (contact, userId) => {
    Axios.post(
      `${urlLinks.baseUrl}${urlLinks.workerUrls.editContact}?userId=${userId}&value=${contact}`
    )
      .then((response) => {
        if (response.data.code === 1000) {
          showToast("success", "Contact number updated successfully");
          handleEditContactClose();
          updateMyData();
        } else {
          setEditContactData({
            ...editContactData,
            error: response.data.response.error,
          });
        }
      })
      .catch((error) => {
        setEditContactData({ ...editContactData, error: error.message });
      });
  };

  const createAllRecordExcel = () => {
    setAllRecordLoading(true);
    Axios.get(
      `${
        urlLinks.baseUrl
      }${"worker/allRecords"}?exportExcel=1${filterUrl}${sortUrl}${sortDirectionUrl}`
    ).then((response) => {
      if (response) {
        setAllRecord(makeAllRecordData(response.data.response));
      }
    });
  };

  useEffect(() => {
    setAllRecordLoading(false);
  }, [allRecord]);

  const getExternalWorkerList = (rows, pageNo) => {
    setLoading(true);
    if (localStorage.getItem("roleType")) {
      Axios.get(
        `${urlLinks.baseUrl}${urlLinks.workerUrls.workerListing}?page_no=${
          pageNo - 1
        }&rows=${rows}${filterUrl}${sortUrl}${sortDirectionUrl}`
      )
        .then((response) => {
          if (response) {
            setTotalRows(response.data.response.totalElements);
            setTotalPages(response.data.response.totalPages);
            if (currentPage > response.data.response.totalPages)
              setCurrentPage(1);
            setData(makeData(response.data.response));
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  const handleReactivationDate = () => {
    Axios.post(
      `${UrlLinks.baseUrl}${
        UrlLinks.workerUrls.workerReactivation
      }?reActivationDate=${moment(reactivationData.date).format(
        "YYYY-MM-DD 00:00:00"
      )}&userId=${reactivationData.userId}&updatedBy=${parseInt(
        localStorage.getItem("userID")
      )}`
    )
      .then((response) => {
        showToast("success", "Activation date set successfully");
        getExternalWorkerList(perPage, currentPage);
        setReactivationData({
          open: false,
          userId: null,
          date: null,
        });
      })
      .catch((error) => {
        showToast("error", error.message);
      });
  };

  useEffect(() => {
    getExternalWorkerList(perPage, 1);
  }, [filterUrl, sortUrl, sortDirectionUrl, localStorage.getItem("roleType")]);

  const columns = columnsOkayGo;

  const handlePageChange = (page) => {
    getExternalWorkerList(perPage, page);
    setCurrentPage(page);
  };

  const handleActions = (event) => {
    if (
      event.target.value === "uploadWorkerData" &&
      (localStorage.getItem("roleType") === "2" ||
        localStorage.getItem("roleType") === "6" ||
        localStorage.getItem("roleType") === "31")
    ) {
      // new onboarding Model v2
      setOpenOnboardingModalV2(true);
    } else if (event.target.value === "uploadWorkerData") {
      setOpenOnboardingModal(true);
    } else if (event.target.value === "uploadOffboardingData") {
      setOpenOffboardingModal(true);
    } else if (event.target.value === "UploadCasperID") {
      setOpenCasperOnboardingModal(true);
    } else if (event.target.value === "UploadGroupFlex") {
      setOpenGroupflexModal(true);
    }
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    toggleAllRowsSelected,
    state: { selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      updateMyData,
      showToast,
      setLoading,
      filters,
      setFilters,
      manualSortBy: true,
    },
    useFilters,
    useSortBy,
    useRowSelect
  );

  const handlePerRowsChange = (newPerPage) => {
    getExternalWorkerList(newPerPage, 1);
    setPerPage(newPerPage);
    setCurrentPage(1);
  };

  const createZipForDocuments = (urls) => {
    var zip = new JSZip();
    var count = 0;
    var zipFilename = "Agreements.zip";
    urls.forEach(async function (url, index) {
      const record = Object.entries(url);
      const filename = `${record[0][0]}`;
      const path = `${record[0][1]}`;
      try {
        await JSZipUtils.getBinaryContent(path, function (err, data) {
          if (err) {
            throw err; // or handle the error
          }
          zip.file(filename, data, { binary: true });
          count++;
          if (count === urls.length) {
            zip.generateAsync({ type: "blob" }).then(function (content) {
              saveAs(content, zipFilename);
              setAgreementDownloading(false);
            });
          }
        });
      } catch (err) {
        console.log(err);
      }
    });
  };

  const handleEmailAgreements = () => {
    setEmailAgreement(true);
    const indexes = Object.keys(selectedRowIds);
    let userIdList = [];
    indexes.map((id) => {
      userIdList.push(data[id].userId);
    });
    Axios.post(
      `${UrlLinks.baseUrl}${UrlLinks.workerUrls.emailAgreements}${userIdList}`
    )
      .then((response) => {
        if (response.data.code === 1000) {
          showToast("success", "Agreements sent to your registered email id");
        } else if (response.data.code === 1001) {
          showToast("error", response.data.message);
        }

        setEmailAgreement(false);
      })
      .catch((error) => {
        setEmailAgreement(false);
        showToast("error", error.message);
      });
  };

  const handleDownloadAgreements = () => {
    setAgreementDownloading(true);
    const rowIds = Object.keys(selectedRowIds);
    let agreementUrls = [];
    rowIds.map((row) => {
      if (data[row].agreementPath) {
        const fileName = `${data[row].contactNo}_${data[row].name}.pdf`;
        agreementUrls.push({
          [fileName]: data[row].agreementPath,
        });
      }
    });
    if (agreementUrls.length > 0) {
      createZipForDocuments(agreementUrls);
    } else {
      setAgreementDownloading(false);
      showToast("error", "It will take some time to generate agreement.");
    }
  };

  const loader = (
    <div style={{ margin: "130px auto" }}>
      <ScaleLoader height={70} width={10} color={"#1c75bc"} />
    </div>
  );

  const table = (
    <div className={`tableContainer ${styles.tableContainer}`}>
      <table {...getTableProps()} className="table">
        <thead className="thead">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps()}
                  className={
                    column.render("Header") === "Worker Id"
                      ? "stickWorkerIdnewMatch zIndex1080 header"
                      : column.render("Header") === "Name"
                      ? "stickWorkerNameDb zIndex1080 header"
                      : column.id === "selection"
                      ? "stickCheckbox zIndex1080 header"
                      : "header"
                  }
                >
                  <span {...column.getSortByToggleProps()}>
                    {column.render("Header")}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  className={`row ${
                    !row.original.criminalRecord ? "criminalRecord" : ""
                  }`}
                >
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        className={`${
                          cell.column.Header === "Worker Id"
                            ? "stickWorkerIdnewMatch  zIndex540"
                            : cell.column.Header === "Name"
                            ? "stickWorkerNameDb zIndex540"
                            : cell.column.id === "selection"
                            ? "stickCheckbox zIndex540"
                            : "cell"
                        } ${
                          row.original.criminalRecord === "Yes"
                            ? "criminalRecord"
                            : ""
                        }`}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        }
      </table>
    </div>
  );

  return (
    <Styles>
      <Grid container className="padding2percent">
        <Grid item xs={12}>
          <span className="fontSize24 fontWeightBold">Worker List</span>
        </Grid>
        <Grid item xs={12} className="flexDisplay alignItemsFlexStart">
          <Grid
            item
            xs={8}
            className="flexDisplay padding0 paddingTop8 justifySpaceBetween"
          >
            <TextField
              className={`${classes.root} marginLeft0`}
              placeholder={`Search by  Aadhar No / Phone No / OkayGo id / ${"Casper id / Facility state/ Hub"}`}
              fullWidth
              value={searchBy}
              onChange={(event) => setSearchBy(event.target.value)}
              variant="outlined"
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleSearch();
                }
              }}
              InputProps={{
                className: classes.input,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <div className={styles.searchButtonDiv}>
              <IconButton
                className={`${styles.closeButton} ${
                  searchBy ? "" : styles.noVisibility
                }`}
                onClick={handleSearchClose}
              >
                <CloseSharpIcon />
              </IconButton>
              <button onClick={handleSearch} className={styles.searchButton}>
                Search
              </button>
            </div>
          </Grid>
          <Grid
            item
            xs={1}
            className="flexDisplay padding0 justifySpaceBetween"
          ></Grid>
          <Grid
            item
            xs={3}
            className="flexDisplay padding0 justifySpaceBetween"
          >
            <FormControl variant="outlined" fullWidth margin="dense">
              <InputLabel htmlFor="sortBy">Sort by:</InputLabel>
              <Select
                id="sortBy"
                IconComponent={ExpandMoreIcon}
                value={sortBy}
                onChange={(event) => setSortBy(event.target.value)}
                className={classes.select}
                label="Sort by:"
                inputProps={{
                  name: "Sort by:",
                  id: "sortBy",
                }}
              >
                {sortOptions.map((option) => (
                  <MenuItem key={option[0]} value={option[0]}>
                    {option[1]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {sortBy && (
              <ToggleButton
                selected={sortDirection}
                className="recSortOrderButton"
                onChange={handleSortDirection}
              >
                {!sortDirection ? (
                  <ArrowDownwardSharpIcon />
                ) : (
                  <ArrowUpwardSharpIcon />
                )}
              </ToggleButton>
            )}
            <Grid
              item
              xs={1}
              className="buttonList padding8"
              style={{ paddingLeft: "50px" }}
              alignItems="flex-start"
            >
              <Filters
                filters={filters}
                applyFilters={ApplyFilters}
                resetFilters={resetFilters}
                isFilterApplied={isFilterApplied}
                setIsFilterApplied={setIsFilterApplied}
                pom={setFilters}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          className="flexDisplay paddingTop16 alignItemsFlexStart"
        >
          <Grid item xs={8} className=" alignItemsFlexStart">
            <Button
              variant="contained"
              className="dataButton"
              onClick={handleDownloadAgreements}
              disabled={
                Object.keys(selectedRowIds).length === 0 || agreementDownloading
              }
              startIcon={
                agreementDownloading && (
                  <ClockLoader size={20} color={"#1c75bc"} />
                )
              }
            >
              Download Agreements
            </Button>
            <Button
              variant="contained"
              className="dataButton"
              style={{ marginLeft: "20px" }}
              onClick={handleEmailAgreements}
              disabled={
                Object.keys(selectedRowIds).length === 0 || emailAgreement
              }
              startIcon={
                emailAgreement && <ClockLoader size={20} color={"#1c75bc"} />
              }
            >
              Email Agreements
            </Button>
            {(localStorage.getItem("roleType") === "2" ||
              localStorage.getItem("roleType") === "6" ||
              localStorage.getItem("roleType") === "31") && (
              <FormControl variant="outlined" className="margin0">
                <Select
                  id="actions"
                  value={actions}
                  style={{ marginLeft: "16px" }}
                  classes={{ outlined: styles.actionSelect }}
                  onChange={handleActions}
                >
                  <MenuItem value={0} disabled={true}>
                    Upload Actions
                  </MenuItem>
                  <MenuItem value="uploadWorkerData">
                    Upload Onboarding data
                  </MenuItem>
                  <MenuItem value="uploadOffboardingData">
                    Upload Offboarding data
                  </MenuItem>
                  <MenuItem value="UploadCasperID">Upload Casper IDs</MenuItem>
                  {groupflexAdmin && (
                    <MenuItem value="UploadGroupFlex">
                      Upload Groupflex data
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            )}
          </Grid>
          <Grid item xs={4} className={styles.itemRowReverse}>
            <Button
              variant="contained"
              className="dataButton"
              onClick={createAllRecordExcel}
              disabled={allRecordLoading}
            >
              Export to Excel
            </Button>
            {allRecordLoading ? (
              <span style={{ marginRight: "20px" }}>
                Please wait ... it might take few minutes.{" "}
              </span>
            ) : allRecord.length > 0 ? (
              <CSVLink
                data={allRecord}
                filename={`Worker List.csv`}
                className="btn btn-primary blueColor downloadExcelButton"
                target="_blank"
                style={{
                  color: "inherit",
                  textDecoration: "none",
                  marginRight: "20px",
                  marginLeft: "20px",
                  fontWeight: "500",
                }}
              >
                Download now
              </CSVLink>
            ) : null}
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          className="flexDisplay paddingTop16 justifyFlexStart"
        >
          <Button
            onClick={(event) => handleStatuses(event, "all")}
            className={`statusButtons marginRight20 ${
              filters.all ? "blueColorBorder" : "greyColorBorder"
            }`}
          >
            All
          </Button>
          <Button
            onClick={(event) => handleStatuses(event, "onboardingInitiated")}
            className={`statusButtons marginRight20 ${
              filters.onboardingInitiated
                ? "blueColorBorder"
                : "greyColorBorder"
            }`}
          >
            Onboarding stage
          </Button>
          <Button
            onClick={(event) => handleStatuses(event, "active")}
            className={`statusButtons marginRight20 ${
              filters.active ? "blueColorBorder" : "greyColorBorder"
            }`}
          >
            Active
          </Button>
          <Button
            onClick={(event) => handleStatuses(event, "offboardingInitiated")}
            className={`statusButtons marginRight20 ${
              filters.offboardingInitiated
                ? "blueColorBorder"
                : "greyColorBorder"
            }`}
          >
            Offboarding initiated
          </Button>
          <Button
            onClick={(event) => handleStatuses(event, "temporarilyInactive")}
            className={`statusButtons marginRight20 ${
              filters.temporarilyInactive
                ? "blueColorBorder"
                : "greyColorBorder"
            }`}
          >
            Temporarily inactive
          </Button>
          <Button
            onClick={(event) => handleStatuses(event, "inactive")}
            className={`statusButtons ${
              filters.inactive ? "blueColorBorder" : "greyColorBorder"
            }`}
          >
            Inactive
          </Button>
          <div className={styles.criminalCheckBox}>
            <div></div>
            <span>Candidate with Criminal record</span>
          </div>
        </Grid>

        {loading ? (
          loader
        ) : data.length === 0 ? (
          <div className={styles.noRecord}>
            <img src={NoRecord} alt="noRecord" />
            <span>No records found</span>
          </div>
        ) : (
          <Grid item xs={12} className="paddingTop16">
            {table}
            <Pagination
              rowsperpage={perPage}
              pageNo={currentPage}
              totalElements={totalRows}
              totalPages={totalPages}
              handlePageChange={handlePageChange}
              handlePerRowsChange={handlePerRowsChange}
            />
          </Grid>
        )}
        {openOnboardingModalV2 && (
          <OnboardingModalV2
            openOnboardingModal={openOnboardingModalV2}
            setOpenOnboardingModal={setOpenOnboardingModalV2}
            showToast={showToast}
            updateMyData={updateMyData}
          />
        )}
        {openOnboardingModalAJV2 && (
          <OnboardingModalAJV2
            openOnboardingModal={openOnboardingModalAJV2}
            setOpenOnboardingModal={setOpenOnboardingModalAJV2}
            showToast={showToast}
            updateMyData={updateMyData}
          />
        )}
        {openOnboardingModal && (
          <OnboardingModal
            openOnboardingModal={openOnboardingModal}
            setOpenOnboardingModal={setOpenOnboardingModal}
            showToast={showToast}
            updateMyData={updateMyData}
          />
        )}
        {openOffboardingModal && (
          <OffboardingModal
            openOffboardingModal={openOffboardingModal}
            setOpenOffboardingModal={setOpenOffboardingModal}
            showToast={showToast}
            updateMyData={updateMyData}
          />
        )}
        {openCasperOnboardingModal && (
          <CasperOnboardingModal
            openCasperOnboardingModal={openCasperOnboardingModal}
            setOpenCasperOnboardingModal={setOpenCasperOnboardingModal}
            showToast={showToast}
            updateMyData={updateMyData}
          />
        )}
        {openGroupflexModal && (
          <GroupFlexModal
            openOnboardingModal={openGroupflexModal}
            setOpenOnboardingModal={setOpenGroupflexModal}
            showToast={showToast}
            updateMyData={updateMyData}
          />
        )}
        {criminalRecordData.open && (
          <CriminalRecord
            criminalRecordData={criminalRecordData}
            setCriminalRecordData={setCriminalRecordData}
          />
        )}
        {editContactData.open && (
          <EditContactModal
            open={editContactData}
            handleClose={handleEditContactClose}
            handleEditPhoneNumberService={handleEditPhoneNumberService}
          />
        )}

        {openAJworkerUploadModal && (
          <UploadAJWorkerModal
            openCasperOnboardingModal={openAJworkerUploadModal}
            setOpenCasperOnboardingModal={setOpenAJworkerUploadModal}
            showToast={showToast}
            updateMyData={updateMyData}
            partners={partners}
          />
        )}

        {reactivationData.open && (
          <ReactivationModal
            open={reactivationData}
            handleClose={handleReactivationDateClose}
            handleReactivationDate={handleReactivationDate}
            setReactivationData={setReactivationData}
          />
        )}
      </Grid>
    </Styles>
  );
};

export default ExternalWorker;
