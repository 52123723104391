import * as actionTypes from "../../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  driveTab: {
    driveDashboard: true,
    workerDashboard: false,
  },
  multipleCityName: [],
  multipleDriveRole: [],
  multipleExamCity: [],
  multipleInvigilatorRole: [],
};

const setCurId = (state, action) => {
  return updateObject(state, {
    multipleCityName: action.multipleCityName,
    multipleDriveRole: action.multipleDriveRole,
    multipleExamCity: action.multipleExamCity,
    multipleInvigilatorRole: action.multipleInvigilatorRole,
  });
};

const setDriveTab = (state, action) => {
  return updateObject(state, {
    driveTab: action.driveTab,
  });
};

const driveDbReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CUR_ID:
      return setCurId(state, action);
    case actionTypes.DRIVE_TAB:
      return setDriveTab(state, action);
    default:
      return state;
  }
};
export default driveDbReducer;
