import React, {
  useState,
  useEffect,
  useMemo,
  useContext,
  forwardRef,
  useRef,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../store/actions/index";
import "./indeterminateCheckbox.css";
import styled from "styled-components";
import { useTable, useRowSelect, useSortBy, useFilters } from "react-table";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import urlLinks from "../../UrlLinks";
import Axios from "axios";
import styles from "./index.module.scss";
import BulkUpdateModal from "../../Utils/dialogs/BulkUpdateModal";
import OnboardingModal from "../../Utils/dialogs/OnboardingModal";
import Pagination from "../../Utils/Pagination";
import * as moment from "moment";
import NoRecord from "./Assets/noRecord.svg";
import ScaleLoader from "react-spinners/ScaleLoader";
import { AuthContext } from "../../services/AuthProvider";
import UrlLinks from "../../UrlLinks";
import JobFilter from "../../Utils/FilterComponents/JobFilter";
import SearchBar from "../../Utils/functions/LeadSearchBar";
import CustomStatusDropdown from "./Functions/CustomStatusDropdown";
import ExportButton from "../../Utils/functions/ExportButton";
import ActionsDropdown from "../../Utils/functions/ActionsDropdown";
import AssignToRecruiter from "./components/AssignToRecruiter";
import SendMsg from "./components/SendMsg";
import FilterDrawer from "./components/FilterDrawer";
import filterIcon from "../ProjectConfig/filterIcon.svg";
import filterIconMarked from "../ProjectConfig/filterIconMarked.svg";
import RemarkOkayGo from "./components/RemarkOkayGo";
import HistoryModal from "../../Utils/dialogs/HistoryModal";
import DownloadDocuments from "./components/DownloadDoc";
import RemoveExtraSpaces from "../../Utils/functions/RemoveExtraSpaces";
import {
  alpha,
  makeStyles,
  styled as muiStyled,
} from "@material-ui/core/styles";
import { AddCircleOutlined, Edit } from "@material-ui/icons";
import {
  Button,
  Grid,
  Checkbox,
  Drawer,
  Switch,
  Tabs,
  Tab,
} from "@material-ui/core";
import SendJobMsg from "./components/SendJobMsg";
import { urlToPromise } from "../../Utils/CommonFunctions";

const Styles = styled.div`
  padding: 1rem;
  .tableContainer {
    overflow-x: scroll;
    border: 1px solid #cfcfcf;
    border-radius: 5px;
    max-height: 74vh;
  }
  .tableContainer::-webkit-scrollbar {
    width: 0.75em;
  }

  .tableContainer::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  .tableContainer::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    //   border-radius: 8px;
  }
  table {
    border-spacing: 0;
    width: max-content;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    th {
      :last-child {
        border-left: 0;
      }
      background-color: #1c75bc !important;
      color: #ffffff;
    }
    th,
    td {
      padding: 5px 15px;
    }

    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid #cfcfcf;
      border-right: 1px solid #cfcfcf;
      background-color: white;
      nth-last-child(2) {
        border-right: 0;
      }
      :last-child {
        border-right: 0;
      }

      input {
        font-size: 1rem;
        padding: 0;
        margin: 0;
        border: 0;
      }
    }
  }

  .pagination {
    padding: 0.5rem;
  }
`;

const useStyles = makeStyles((theme) => ({
  tabs: {
    "& .MuiTab-root": {
      minWidth: "auto",
      margin: "0 10px",
      textTransform: "capitalize",
      fontSize: "16px",
      fontWeight: "600",
      "&:first-child": {
        margin: "0",
      },
    },
    "& .MuiTab-textColorPrimary.Mui-selected": {
      color: "#1C75BC",
    },
    "& .MuiTab-textColorPrimary.Mui-disabled": {
      color: "#CECECE",
    },
  },
  remarkButton: {
    textTransform: "capitalize",
    width: "129px",
    display: "flex",
    justifyContent: "space-between",
  },
}));

const IndeterminateCheckbox = forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <React.Fragment>
      <Checkbox ref={resolvedRef} {...rest} />
    </React.Fragment>
  );
});

const ExternalWorker = (props) => {
  /** accessing states from redux store **/

  const { users, applicationDb } = useSelector((state) => state);
  const {
    loader,
    currentPage,
    perPage,
    totalRows,
    totalPages,
    isFilter,
    selectedAssignIds,
    selectedUserIds,
    openFilterDrawer,
    allRecord,
  } = users;
  const {
    curLeadId,
    tabValue,
    multiplePreferredPincodes,
    multiplePreferredLanguages,
    multipleAssets,
  } = applicationDb;

  /** useDispatch is used to update states to redux store **/

  const dispatch = useDispatch();
  const {
    setLoader,
    setModalLoader,
    setCurrentPage,
    setPerPage,
    setTotalRows,
    setTotalPages,
    setIsFilter,
    setSelectedAssignIds,
    setSelectedUserIds,
    setOpenFilterDrawer,
    setCurLeadId,
    setTabValue,
    setAllRecord,
  } = bindActionCreators(actionCreators, dispatch);

  /** other states **/

  const classes = useStyles();
  const { showToast } = useContext(AuthContext);
  const cancelTokens = useRef({
    mainListingData: null,
    applicationStatus: null,
    userNotification: null,
  });
  const cancelTokenSource = Axios.CancelToken.source();
  const [data, setData] = useState([]);
  const [actions, setActions] = useState(-1);
  const [allRecordLoading, setAllRecordLoading] = useState(false);
  const [searchByAuto, setSearchByAuto] = useState("");
  const [searchKeyByAuto, setSearchKeyByAuto] = useState("");
  const [searchBy, setSearchBy] = useState("");
  const [filterUrl, setFilterUrl] = useState("");
  const [docListLoader, setDocListLoader] = useState(null);
  const [downloadDocError, setDownloadDocError] = useState(null);
  const [leadCityId, setLeadCityId] = useState([]);
  const [leadSourceId, setLeadSourceId] = useState([]);
  const [preferredPincodes, setPreferredPincodes] = useState([]);
  const [preferredLanguages, setPreferredLanguages] = useState([]);
  const [assets, setAssets] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [searchActions, setSearchActions] = useState("Search by");
  const [searchList, setSearchList] = useState([]);
  const [searchError, setSearchError] = useState(false);
  const [toggleStatusUpdated, setToggleStatusUpdated] = useState(false);
  const [recruiterList, setRecruiterList] = useState([]);
  const [opsList, setOpsList] = useState([]);
  const [jobFilterOption, setJobFilterOption] = useState("all");
  const [jobList, setJobList] = useState([]);
  const [unapplied, setUnapplied] = useState(false);
  const [isRowSelected, setIsRowSelected] = useState(false);
  const [openAssignRecruiterModal, setOpenAssignRecruiterModal] =
    useState(false);
  const [openAssignOpsModal, setOpenAssignOpsModal] = useState(false);
  const [openCommunicationModal, setOpenCommunicationModal] = useState(false);
  const [openJobCommunicationModal, setOpenJobCommunicationModal] =
    useState(false);
  const [openBulkUpdateModal, setOpenBulkUpdateModal] = useState(false);
  const [openUploadLeadModal, setOpenUploadLeadModal] = useState(false);
  const [remarkChanged, setRemarkChanged] = useState("");
  const [openRemarkModal, setOpenRemarkModal] = useState(false);
  const [remarkRecord, setRemarkRecord] = useState([]);
  const [openApplicationHistoryModal, setOpenApplicationHistoryModal] =
    useState(false);
  const [openWorkerHistoryModal, setOpenWorkerHistoryModal] = useState(false);
  const [historyColumns, setHistoryColumns] = useState([]);
  const [historyList, setHistoryList] = useState([]);
  const [selectedOption, setSelectedOption] = useState({
    id: "all",
    name: "All Jobs",
  });
  const [filters, setFilters] = useState({
    aadhaarUpload: "",
    appInstalled: "",
    appliedBetween: [null, null],
    assets: "",
    campaignId: "",
    gender: "",
    jobId: "",
    jobStatus: "",
    leadAddedOn: [null, null],
    leadCity: "",
    leadSource: "",
    leadState: "",
    panUpload: "",
    preferredLanguages: "",
    preferredPincodes: "",
    profileCompletion: "",
    qualifications: "",
    searchBy: "",
    searchKey: "",
    status: "",
    recruiterAssignedBetween: [null, null],
    recruiterId: "",
    recruiterStatus: "",
    tabs: {
      All: true,
      Applied: false,
      Screening: false,
      Document: false,
      Training: false,
      Work: false,
      Rejected: false,
    },
    tenthUpload: "",
    twelfthUpload: "",
    vertical: "",
  });

  const searchBarList = ["Lead ID", "Aadhar No", "Pincode", "Phone No"];
  const jobFilterOptionList = [
    {
      id: "all",
      name: "All",
    },
    {
      id: "active",
      name: "Active",
    },
    {
      id: "inactive",
      name: "In-Active",
    },
  ];

  const applicationStatusList = {
    1: "Applied",
    2: "Not eligible",
    3: "Backed out",
    4: "DNA 1",
    14: "DNA 2",
    15: "DNA 3",
    5: "Interview Pending",
    6: "Interview Done",
    7: "Training done",
    8: "Training pending",
    9: "Ready to work",
    10: "Started working",
    11: "No task available",
    20: "Document pending",
    21: "Document done",
    18: "Follow up 1",
    19: "Follow up 2",
    16: "Not sure",
    12: "Rejected (system)",
    13: "Not Eligible (system)",
    23: "Waitlist",
  };

  const tabsData = [
    { label: "All", value: "All" },
    { label: "Applied", value: "Applied" },
    { label: "Interview", value: "Screening" },
    { label: "Document", value: "Document" },
    { label: "Training", value: "Training" },
    { label: "Work Status", value: "Work" },
    { label: "Rejected", value: "Rejected" },
  ];

  const actionList = [
    {
      value: "assignRecruiter",
      label: "Assign to recruiter",
    },
    !unapplied
      ? {
          value: "assignOps",
          label: "Assign to Ops",
        }
      : {},
    {
      value: "sendCommunication",
      label: "Send communication",
    },
    {
      value: "sendJobCampaign",
      label: "Send job campaign",
    },
  ];

  const uploadDataList = [
    !unapplied && !filters.tabs.Rejected
      ? {
          value: "bulkUpdate",
          label: "Bulk update",
        }
      : {},
    {
      value: "uploadLeads",
      label: "Upload leads",
    },
  ];

  const columns = useMemo(
    () => [
      {
        id: "selection",
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <div>
            <IndeterminateCheckbox
              {...getToggleAllRowsSelectedProps()}
              className="overrideCheckbox"
              color="white"
            />
          </div>
        ),
        Cell: ({ row }) => (
          <div id="check1">
            <IndeterminateCheckbox
              {...row.getToggleRowSelectedProps()}
              style={{ display: "flex", justifyItems: "center" }}
            />
          </div>
        ),
      },
      {
        Header: "Lead ID",
        accessor: "leadId",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Created At",
        accessor: "createdAt",
        Cell: (row) => {
          return (
            <div style={{ width: "min-content" }}>
              {row.row.original.createdAt
                ? moment(row.row.original.createdAt).format(
                    "DD/MM/YYYY hh:mm A"
                  )
                : "-"}
            </div>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "App Installed",
        accessor: "appInstalled",
        Cell: (row) => {
          return (
            <div style={{ width: "min-content" }}>
              {row.row.original.appInstalled ? "Yes" : "No"}
            </div>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Name",
        accessor: "name",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Phone Number",
        accessor: "phoneNumber",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Pincode",
        accessor: "pincode",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Address",
        accessor: "address",
        Cell: (row) => {
          return (
            <div style={{ width: "250px" }}>{row.row.original.address}</div>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "City",
        accessor: "city",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "State",
        accessor: "state",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Aadhaar Number",
        accessor: "aadharNumber",
        Cell: (row) => {
          return (
            <div style={{ width: "min-content" }}>
              {row.row.original.aadharNumber
                ? row.row.original.aadharNumber
                : row.row.original.workerAadhaar}
            </div>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "PAN Number",
        accessor: "panNumber",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Documents",
        accessor: "docAvailable",
        Cell: DownloadDocuments,
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "DOB",
        accessor: "dateOfBirth",
        Cell: (row) => {
          return (
            <div style={{ width: "min-content" }}>
              {row.row.original.dateOfBirth
                ? moment(row.row.original.dateOfBirth).format("DD/MM/YYYY")
                : ""}
            </div>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Gender",
        accessor: "gender",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Preferred Pincodes",
        accessor: "preferredPincodes",
        Cell: (row) => {
          return (
            <div style={{ width: "250px", overflowWrap: "break-word" }}>
              {row.row.original?.preferredPincodes?.split(",").join(", ")}
            </div>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Preferred Languages",
        accessor: "languages",
        Cell: (row) => {
          return (
            <div style={{ width: "250px", overflowWrap: "break-word" }}>
              {row.row.original?.languages?.split(",").join(", ")}
            </div>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Assets",
        accessor: "assests",
        Cell: (row) => {
          return (
            <div style={{ width: "250px", overflowWrap: "break-word" }}>
              {row.row.original?.assests?.split(",").join(", ")}
            </div>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Qualifications",
        accessor: "qualification",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Job ID",
        accessor: "jobId",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Vertical",
        accessor: "vertical",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Job Role",
        accessor: "jobRole",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Job City",
        accessor: "jobCity",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Source",
        accessor: "source",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Applied on",
        accessor: "appliedOn",
        Cell: (row) => {
          return (
            <div style={{ width: "min-content" }}>
              {row.row.original.appliedOn
                ? moment(row.row.original.appliedOn).format(
                    "DD/MM/YYYY hh:mm A"
                  )
                : "-"}
            </div>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Application Status",
        accessor: "applicationStatus",
        Cell: (row) => {
          return (
            <div>
              {row.row.original.applicationStatus
                ? applicationStatusList[
                    Object.keys(applicationStatusList).find(
                      (statusKey) =>
                        statusKey == row.row.original.applicationStatus
                    )
                  ]
                : "-"}
            </div>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Assigned to operations",
        accessor: "opsAssigned",
        Cell: (row) => {
          return (
            <div>
              <div>
                {row.row.original.opsAssignedName
                  ? row.row.original.opsAssignedName
                  : "-"}
              </div>
              <div>
                {row.row.original.opsAssignedOn
                  ? moment(row.row.original.opsAssignedOn).format(
                      "DD/MM/YYYY hh:mm A"
                    )
                  : "-"}
              </div>
            </div>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Recruiter Assigned",
        accessor: "recruiterAssigned",
        Cell: (row) => {
          return (
            <div>
              <div>
                {row.row.original.recruiterAssigned
                  ? `${row.row.original.recruiterAssigned}${
                      row.row.original.recruiterAssignedNumber
                        ? `-` + row.row.original.recruiterAssignedNumber
                        : ""
                    }`
                  : "-"}
              </div>
              <div>
                {row.row.original.recruiterAssignedOn
                  ? moment(row.row.original.recruiterAssignedOn).format(
                      "DD/MM/YYYY"
                    )
                  : "-"}
              </div>
            </div>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Comm. Type",
        accessor: "commType",
        Cell: (row) => {
          return <div>{row.row.original.commType}</div>;
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Last Comm. Sent",
        accessor: "lastCommSent",
        Cell: (row) => {
          return (
            <div style={{ width: "250px" }}>
              {row.row.original.lastCommSent}
            </div>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Remarks",
        accessor: "remarks",
        Cell: (row) => {
          return (
            <div style={{ width: "250px" }}>
              {row.row.original.remarks ? (
                <div style={{ display: "flex" }}>
                  <span>{row.row.original.remarks}</span>
                  <button
                    className={styles.remarkIcon}
                    onClick={(e) =>
                      handleUserModal(e, row.row.original, "remark")
                    }
                    style={{ marginLeft: "10px", padding: "0" }}
                  >
                    <Edit />
                  </button>
                </div>
              ) : (
                <Button
                  className={`${classes.remarkButton} blueColorOutlined`}
                  onClick={(e) =>
                    handleUserModal(e, row.row.original, "remark")
                  }
                >
                  <AddCircleOutlined />
                  Add Remark
                </Button>
              )}
            </div>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Application history",
        Cell: (row) => {
          return row.row.original.assignId ? (
            <button
              className={styles.workerName}
              type="button"
              onClick={(e) =>
                handleUserModal(e, row.row.original, "applicationHistory")
              }
            >
              View history
            </button>
          ) : (
            "-"
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Worker history",
        Cell: (row) => {
          return row.row.original.assignId ? (
            <button
              className={styles.workerName}
              type="button"
              onClick={(e) =>
                handleUserModal(e, row.row.original, "workerHistory")
              }
            >
              View working history
            </button>
          ) : (
            "-"
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "% Profile Complete",
        accessor: "profilePercentage",
        Cell: (row) => {
          return <div>{row.row.original.profilePercentage}%</div>;
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Follow Up 1",
        Cell: (row) => {
          return row.row.original.follow_up_date_one ? (
            <div>
              <div>
                Date -{" "}
                {moment(row.row.original.follow_up_date_one).format(
                  "DD/MM/YYYY"
                )}
              </div>
              <div>Time - {row.row.original.follow_up_time_one}</div>
            </div>
          ) : (
            <div>-</div>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Follow Up 2",
        Cell: (row) => {
          return row.row.original.follow_up_date_two ? (
            <div>
              <div>
                Date -{" "}
                {moment(row.row.original.follow_up_date_two).format(
                  "DD/MM/YYYY"
                )}
              </div>
              <div>Time - {row.row.original.follow_up_time_two}</div>
            </div>
          ) : (
            <div>-</div>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Campaign",
        accessor: "campaign",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
    ],
    []
  );

  // Function to update the Table Data
  const updateMyData = () => {
    getExternalWorkerList(perPage, currentPage);
  };

  const resetFiltersWithoutSearch = () => {
    setIsFilter(false);
    const filterList = {
      aadhaarUpload: "",
      appInstalled: "",
      appliedBetween: [null, null],
      assets: "",
      campaignId: "",
      gender: "",
      jobId: filters.jobId,
      jobStatus: filters.jobId,
      leadAddedOn: [null, null],
      leadCity: "",
      leadSource: "",
      leadState: "",
      panUpload: "",
      preferredLanguages: "",
      preferredPincodes: "",
      profileCompletion: "",
      qualifications: "",
      searchBy: filters.searchBy,
      searchKey: filters.searchKey,
      status: "",
      recruiterAssignedBetween: [null, null],
      recruiterId: "",
      recruiterStatus: "",
      tabs: {
        All: filters.tabs.All,
        Applied: filters.tabs.Applied,
        Screening: filters.tabs.Screening,
        Document: filters.tabs.Document,
        Training: filters.tabs.Training,
        Work: filters.tabs.Work,
        Rejected: filters.tabs.Rejected,
      },
      tenthUpload: "",
      twelfthUpload: "",
      vertical: "",
    };

    if (openFilterDrawer) {
      setFilterUrl(
        filters.searchKey !== "" && filters.searchBy
          ? "&searchField=" +
              filters.searchKey +
              "&searchValue=" +
              filters.searchBy
          : ""
      );
    } else {
      setFilterUrl(
        filters.searchKey && filters.searchBy
          ? "&searchBy=" + filters.searchBy
          : ""
      );
    }

    setFilters(filterList);
    setLeadCityId([]);
    setLeadSourceId([]);
    setPreferredPincodes([]);
    setPreferredLanguages([]);
    setAssets([]);
    setCurLeadId([], [], [], [], []);
    setCurrentPage(1);
    return filterList;
  };

  const createDocumentZip = (documents, okaygoId, name) => {
    var zip = new JSZip();
    var fileUrl = "";
    var profilePhotoURL = "";
    var photoName = "";

    documents?.forEach(function (doc, index) {
      var filename = doc?.file_name;
      filename = filename.substring(0, filename.indexOf("?"));
      if (doc?.file_path.substring(0, 5) === "http:") {
        // in case if it comes https, link wont become httpss
        fileUrl = "https" + doc.file_path.substring(4, doc.file_path.length);
      } else {
        fileUrl = doc?.file_path;
      }
      zip.file(filename, urlToPromise(fileUrl), { binary: true });
      if (
        doc.profilePhoto !== null &&
        doc.profilePhoto !== "" &&
        !profilePhotoURL
      ) {
        if (doc.profilePhoto.substring(0, 5) === "http:") {
          profilePhotoURL =
            "https" + doc.profilePhoto.substring(4, doc.profilePhoto.length);
        } else {
          profilePhotoURL = doc.profilePhoto;
        }
        if (profilePhotoURL.includes(".jpg?")) {
          photoName = `${name.replaceAll(" ", "")}_Profile_Photo.jpg`;
        } else if (profilePhotoURL.includes(".png?")) {
          photoName = `${name.replaceAll(" ", "")}_Profile_Photo.png`;
        }
        zip.file(photoName, urlToPromise(profilePhotoURL), { binary: true });
      }
    });

    zip.generateAsync({ type: "blob" }).then(function (content) {
      saveAs(content, `${okaygoId}_${name}_Documents`);
      setDocListLoader(null);
    });
  };

  const getDocumentList = (userId, name) => {
    setDocListLoader(userId);
    Axios.get(
      `${urlLinks.baseUrl}${urlLinks.workerUrls.docList}${parseInt(userId)}`
    )
      .then((response) => {
        if (response?.data?.response?.content?.length > 0) {
          createDocumentZip(response.data.response.content, userId, name);
        } else {
          setDocListLoader(null);
          setDownloadDocError({
            err: <span>No Document Available</span>,
            userId: userId,
          });
        }
      })
      .catch((error) => {
        setDocListLoader(null);
        setDownloadDocError({
          err: <span style={{ color: "red" }}>Error while downloading</span>,
          userId: userId,
        });
      });
  };

  const handleSearch = () => {
    setFilters({ ...filters, searchBy: searchBy, searchKey: searchKey });
    setSearchByAuto(searchBy);
    setSearchKeyByAuto(searchKey);
  };

  const handleSearchClose = () => {
    setFilters({ ...filters, searchBy: "" });
    setSearchBy("");
    setFilterUrl("");
    setSearchByAuto("");
  };

  const updateFilterUrl = () => {
    let url = "";

    // Tab Change
    if (!unapplied && filters["tabs"]) {
      // Find the tab with a value of true
      for (const tab in filters.tabs) {
        if (!filters.tabs.All && filters.tabs[tab]) {
          url = `${url}&applicationStatus=${tab}`;
          break;
        }
      }
    }

    // Job Filters
    if (filters["jobId"]) {
      url = `${url}&jobId=${filters["jobId"]}`;
    }

    if (filters["jobStatus"] !== "") {
      url = `${url}&jobStatus=${filters["jobStatus"]}`;
    }

    // Search By
    if (filters["searchBy"] !== "" && filters["searchKey"] !== "") {
      url = `${url}&searchField=${filters["searchKey"]}&searchValue=${filters["searchBy"]}`;
    }

    // Aadhaar upload url
    if (filters["aadhaarUpload"] || filters["aadhaarUpload"] === 0) {
      url = `${url}&aadhaarCard=${filters["aadhaarUpload"].id}`;
    }

    // Applied on url
    if (filters["appliedBetween"][0] && !filters["appliedBetween"][1])
      url = `${url}&appliedOnFrom=${moment(filters["appliedBetween"][0]).format(
        "YYYY-MM-DD"
      )}&appliedOnTo=${moment(new Date()).format("YYYY-MM-DD")} 23:59:59`;
    if (!filters["appliedBetween"][0] && filters["appliedBetween"][1])
      url = `${url}&appliedOnFrom=2019-01-01&appliedOnTo=${moment(
        filters["appliedBetween"][1]
      ).format("YYYY-MM-DD")} 23:59:59`;
    if (filters["appliedBetween"][0] && filters["appliedBetween"][1])
      url = `${url}&appliedOnFrom=${moment(filters["appliedBetween"][0]).format(
        "YYYY-MM-DD"
      )}&appliedOnTo=${moment(filters["appliedBetween"][1]).format(
        "YYYY-MM-DD"
      )} 23:59:59`;

    // Campaign ID
    if (!unapplied && filters["campaignId"]) {
      const inputString = filters["campaignId"].name;
      // Encode the string for safe use in the URL
      const encodedString = encodeURIComponent(inputString);
      url = `${url}&campaign=${encodedString}`;
    }

    // Lead State
    if (filters["leadState"]) {
      url = `${url}&state=${filters["leadState"].name}`;
    }

    // Lead City
    if (filters["leadCity"]) {
      url = `${url}&city=${filters["leadCity"]}`;
    }

    // Lead Source
    if (filters["leadSource"]) {
      url = `${url}&source=${filters["leadSource"]}`;
    }

    // lead Added On url
    if (filters["leadAddedOn"][0] && !filters["leadAddedOn"][1])
      url = `${url}&leadAddedFrom=${moment(filters["leadAddedOn"][0]).format(
        "YYYY-MM-DD"
      )}&leadAddedTo=${moment(new Date()).format("YYYY-MM-DD")} 23:59:59`;
    if (!filters["leadAddedOn"][0] && filters["leadAddedOn"][1])
      url = `${url}&leadAddedFrom=2019-01-01&leadAddedTo=${moment(
        filters["leadAddedOn"][1]
      ).format("YYYY-MM-DD")} 23:59:59`;
    if (filters["leadAddedOn"][0] && filters["leadAddedOn"][1])
      url = `${url}&leadAddedFrom=${moment(filters["leadAddedOn"][0]).format(
        "YYYY-MM-DD"
      )}&leadAddedTo=${moment(filters["leadAddedOn"][1]).format(
        "YYYY-MM-DD"
      )} 23:59:59`;

    // App installed on url
    if (filters["appInstalled"] || filters["appInstalled"] === 0) {
      url = `${url}&appInstalled=${filters["appInstalled"].id}`;
    }

    // Gender url
    if (filters["gender"]) {
      url = `${url}&gender=${filters["gender"].id}`;
    }

    // PAN Upload url
    if (filters["panUpload"] || filters["panUpload"] === 0) {
      url = `${url}&panCard=${filters["panUpload"].id}`;
    }

    // Preferred Pincodes url
    if (filters["preferredPincodes"]) {
      url = `${url}&preferredPincodes=${filters["preferredPincodes"]}`;
    }

    // Preferred Languages url
    if (filters["preferredLanguages"]) {
      url = `${url}&languages=${filters["preferredLanguages"]}`;
    }

    // Profile Completion url
    if (filters["profileCompletion"]) {
      url = `${url}&profilePercentage=${filters["profileCompletion"].id}`;
    }

    // Assets url
    if (filters["assets"]) {
      url = `${url}&assests=${filters["assets"]}`;
    }

    // Qualifications url
    if (filters["qualifications"]) {
      url = `${url}&qualification=${filters["qualifications"].id}`;
    }

    // Vertical url
    if (filters["vertical"]) {
      url = `${url}&vertical=${filters["vertical"].id}`;
    }

    // Recruiter ID
    if (
      unapplied &&
      filters["recruiterId"] &&
      filters["recruiterId"].id == "-1"
    ) {
      url = `${url}&recruiterAssigned=${filters["recruiterId"].id}`;
    } else if (
      unapplied &&
      filters["recruiterId"] &&
      filters["recruiterId"].id !== "-1"
    ) {
      url = `${url}&recruiterAssigned=${filters["recruiterId"].id}`;
    } else if (!unapplied && filters["recruiterId"]) {
      url = `${url}&recruiterAssigned=${filters["recruiterId"].id}`;
    }

    // Recruiter assigned on url
    if (
      filters["recruiterAssignedBetween"][0] &&
      !filters["recruiterAssignedBetween"][1]
    )
      url = `${url}&recruiterAssignedOnFrom=${moment(
        filters["recruiterAssignedBetween"][0]
      ).format("YYYY-MM-DD")}&recruiterAssignedOnTo=${moment(new Date()).format(
        "YYYY-MM-DD"
      )} 23:59:59`;
    if (
      !filters["recruiterAssignedBetween"][0] &&
      filters["recruiterAssignedBetween"][1]
    )
      url = `${url}&recruiterAssignedOnFrom=2019-01-01&recruiterAssignedOnTo=${moment(
        filters["recruiterAssignedBetween"][1]
      ).format("YYYY-MM-DD")} 23:59:59`;
    if (
      filters["recruiterAssignedBetween"][0] &&
      filters["recruiterAssignedBetween"][1]
    )
      url = `${url}&recruiterAssignedOnFrom=${moment(
        filters["recruiterAssignedBetween"][0]
      ).format("YYYY-MM-DD")}&recruiterAssignedOnTo=${moment(
        filters["recruiterAssignedBetween"][1]
      ).format("YYYY-MM-DD")} 23:59:59`;

    // Recruiter Status url
    if (filters["recruiterStatus"] || filters["recruiterStatus"] === 0) {
      url = `${url}&recruiterMarkedStatus=${filters["recruiterStatus"].id}`;
    }

    // Sub Status url
    if (filters["status"] || filters["status"] === 0) {
      url = `${url}&subStatus=${filters["status"].id}`;
    }

    // Tenth upload url
    if (filters["tenthUpload"] || filters["tenthUpload"] === 0) {
      url = `${url}&tenthCertificate=${filters["tenthUpload"].id}`;
    }

    // Twelfth upload url
    if (filters["twelfthUpload"] || filters["twelfthUpload"] === 0) {
      url = `${url}&twelfthCertificate=${filters["twelfthUpload"].id}`;
    }

    return url;
  };

  const ApplyFilters = () => {
    let url = updateFilterUrl();
    setFilterUrl(url);
    setFilters(filters);
    setCurrentPage(1);
  };

  // Function to open a specific user data
  const handleUserModal = async (event, row, type) => {
    switch (type) {
      case "remark": {
        setRemarkRecord(row);
        setOpenRemarkModal(true);
        return;
      }
      case "applicationHistory": {
        setModalLoader(true);
        setOpenApplicationHistoryModal(true);
        setHistoryColumns([
          {
            key: "jobName",
            label: "Job Name",
          },
          {
            key: "appliedOn",
            label: "Applied on",
          },
          {
            key: "jobStatus",
            label: "Job Status",
          },
          {
            key: "statusUpdateOn",
            label: "Status update on",
          },
          {
            key: "statusUpdateBy",
            label: "Status update by",
          },
        ]);
        await Axios.get(
          `${urlLinks.baseUrl}${urlLinks.leadData.applicationHistory}?assignId=${row.assignId}`
        )
          .then((res) => {
            setHistoryList(res.data.response);
            setModalLoader(false);
          })
          .catch((err) => {
            console.log("err", err);
            showToast(
              "error",
              "Error",
              "Something went wrong, please try after sometime."
            );
          });
        return;
      }
      case "workerHistory": {
        setModalLoader(true);
        setOpenWorkerHistoryModal(true);
        setHistoryColumns([
          {
            key: "clientName",
            label: "Client Name",
          },
          {
            key: "vertical",
            label: "Vertical",
          },
          {
            key: "startDate",
            label: "Start Date",
          },
          {
            key: "status",
            label: "Status",
          },
        ]);
        await Axios.get(
          `${urlLinks.baseUrl}${urlLinks.leadData.getWorkerHistory}?lead_id=${row.leadId}`
        )
          .then((res) => {
            setHistoryList(res.data.response);
            setModalLoader(false);
          })
          .catch((err) => {
            console.log("err", err);
            showToast(
              "error",
              "Error",
              "Something went wrong, please try after sometime."
            );
          });
        return;
      }
      default:
        return;
    }
  };

  // Function to get communication type and message
  const getNotification = async (data) => {
    let userIds = [];
    if (cancelTokens.current.userNotification) {
      cancelTokens.current.userNotification.cancel(
        "Operation canceled due to new request."
      );
    }
    cancelTokens.current.userNotification = cancelTokenSource;

    try {
      data.map((el) => {
        userIds.push(el.userId);
      });
      userIds = userIds.join(",");

      const response = await Axios.get(
        `${UrlLinks.baseUrl}${UrlLinks.leadData.getUserNotification}?userIds=${userIds}`,
        {
          cancelToken: cancelTokenSource.token,
        }
      );
      if (response) {
        return response.data.response;
      }
    } catch (error) {
      if (Axios.isCancel(error)) {
        console.log("Request canceled:", error.message);
      } else {
        console.error("Error:", error);
      }
    }

    return null;
  };

  //Function to create export data as per the main listing order
  const makeLeadExportData = (response) => {
    let extractedData = [];
    response?.map((el) => {
      extractedData.push({
        leadId: el.leadId || "",
        createdAt: el.createdAt
          ? moment(el.createdAt).format("DD/MM/YYYY hh:mm a")
          : "-",
        appInstalled: el.appInstalled ? "Yes" : "No",
        name: el.name || "",
        phoneNumber: el.phoneNumber || "",
        pincode: el.pincode || "",
        address: el.address || "",
        city: el.city || "",
        state: el.state || "",
        aadharNumber: el.aadharNumber || "",
        "PAN Number": el.panNumber || "",
        docAvailable: el.docAvailable ? "Yes" : "No",
        DOB: el.dateOfBirth ? moment(el.dateOfBirth).format("DD/MM/YYYY") : "",
        Gender: el.gender ? el.gender : "",
        "Preferred Pincodes": el.preferredPincodes ? el.preferredPincodes : "",
        "Preferred Languages": el.languages ? el.languages : "",
        Assets: el.assests ? el.assests : "",
        Qualifications: el.qualification ? el.qualification : "",
        jobId: el.jobId || "",
        vertical: el.vertical || "",
        jobRole: el.jobRole || "",
        jobCity: el.jobCity || "",
        source: el.source || "",
        appliedOn: el.appliedOn
          ? moment(el.appliedOn).format("DD/MM/YYYY hh:mm a")
          : "-",
        applicationStatus: el.applicationStatus
          ? applicationStatusList[
              Object.keys(applicationStatusList).find(
                (status) => status == el.applicationStatus
              )
            ]
          : "",
        opsAssigned: el.opsAssignedName || "",
        opsAssignedOn: el.opsAssignedOn
          ? moment(el.opsAssignedOn).format("DD/MM/YYYY hh:mm a")
          : "-",
        recruiterAssigned:
          `${el.recruiterAssigned} ${
            el.recruiterAssignedNumber ? "-" + el.recruiterAssignedNumber : ""
          }` || "",
        recruiterAssignedOn: el.recruiterAssignedOn
          ? moment(el.recruiterAssignedOn).format("DD/MM/YYYY hh:mm a")
          : "-",
        recruiterMarkedStatus: el.recruiterMarkedStatus || "",
        remarks: el.remarks || "",
        "% Profile Complete": el.profilePercentage
          ? el.profilePercentage + "%"
          : "",
        "Follow Up 1": el.follow_up_date_one
          ? moment(el.follow_up_date_one).format("DD/MM/YYYY") +
              "," +
              el.follow_up_time_one || ""
          : "",
        "Follow Up 2": el.follow_up_date_two
          ? moment(el.follow_up_date_two).format("DD/MM/YYYY") +
              "," +
              el.follow_up_time_two || ""
          : "",
        Campaign: el.campaign || "",
      });
      return true;
    });
    return extractedData;
  };

  //Function to call export data
  const createAllRecordExcel = async () => {
    if (!openBulkUpdateModal) {
      setAllRecordLoading(true);
    }

    let excelExportedBy = `excelExportedBy=${localStorage.getItem(
      "userID"
    )}&exportExcel=1`;

    let excelData = await Axios.get(
      `${urlLinks.baseUrl}${
        urlLinks.leadData.mainListing
      }?${excelExportedBy}&applied=${unapplied ? 0 : 1}${filterUrl}`
    )
      .then((response) => {
        if (response) {
          if (openBulkUpdateModal) {
            let asignIdResponse = response.data.response;
            return asignIdResponse;
          } else {
            setAllRecord(makeLeadExportData(response.data.response));
          }
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
    return openBulkUpdateModal ? excelData : "";
  };

  //Function to add communication type and message in the table.
  const statusUpdatedColumn = async (data) => {
    let newData = [];
    let allCommData = await getNotification(data);

    if (allCommData) {
      data?.forEach((res, i) => {
        let comm = allCommData.filter(
          (commData) => commData.userId === res.userId
        );
        newData.push({
          ...res,
          commType: comm[0].message ? "Push notification" : "-",
          lastCommSent: comm[0].message || "-",
        });
      });
      setData(newData);
    }
  };

  //Function to call main listing.
  const getExternalWorkerList = async (rows, pageNo) => {
    // Cancel the previous request if it exists
    if (cancelTokens.current.mainListingData) {
      cancelTokens.current.mainListingData.cancel(
        "Operation canceled due to new request."
      );
    }
    cancelTokens.current.mainListingData = cancelTokenSource;
    setAllRecord([]);
    if (localStorage.getItem("roleType")) {
      setLoader(true);

      let recruiterUrl = "";
      if (unapplied) {
        if (filters["recruiterId"] && filters["recruiterId"].id == "-1") {
          recruiterUrl = `&assigned=0`;
        } else if (
          filters["recruiterId"] &&
          filters["recruiterId"].id !== "-1"
        ) {
          recruiterUrl = `&assigned=1`;
        } else if (!filters["recruiterId"]) {
          recruiterUrl = `&assigned=1`;
        }
      }

      let mainUrl = `${urlLinks.baseUrl}${
        urlLinks.leadData.mainListing
      }?page_no=${pageNo - 1}&rows=${rows}&applied=${
        unapplied ? 0 : 1
      }${recruiterUrl}${filterUrl}`;

      let newUrl = "";
      newUrl = new URL(mainUrl);
      let params = new URLSearchParams(newUrl.search);
      if (unapplied) {
        // List of parameters to delete
        let paramsToDelete = ["applicationStatus", "jobId", "jobStatus"];
        paramsToDelete?.forEach((param) => {
          params.delete(param);
        });

        newUrl.search = params.toString();
        mainUrl = newUrl.toString();
      }

      try {
        const response = await Axios.get(mainUrl, {
          cancelToken: cancelTokenSource.token,
        });
        if (response) {
          setTotalRows(response.data.response?.totalElements);
          setTotalPages(response.data.response?.totalPages);
          if (currentPage > response.data.response?.totalPages) {
            setCurrentPage(1);
          }
          setData(response.data.response ? response.data.response.content : []);
          statusUpdatedColumn(response.data.response?.content);
        }
        setLoader(false);
      } catch (error) {
        if (Axios.isCancel(error)) {
          console.log("Request canceled:", error.message);
        } else {
          console.error("Error:", error);
          setLoader(false);
        }
      }
    }
  };

  const handlePageChange = (page) => {
    getExternalWorkerList(perPage, page);
    setCurrentPage(page);
  };

  const handlePerRowsChange = (newPerPage) => {
    getExternalWorkerList(newPerPage, 1);
    setPerPage(newPerPage);
    setCurrentPage(1);
  };

  const handleActions = (event, type) => {
    // update status validations
    if (event.target.value === "assignRecruiter") {
      setOpenAssignRecruiterModal(true);
    } else if (event.target.value === "assignOps") {
      setOpenAssignOpsModal(true);
    } else if (event.target.value === "sendCommunication") {
      setOpenCommunicationModal(true);
    } else if (event.target.value === "sendJobCampaign") {
      setOpenJobCommunicationModal(true);
    } else if (event.target.value === "bulkUpdate") {
      setOpenBulkUpdateModal(true);
    } else if (event.target.value === "uploadLeads") {
      setOpenUploadLeadModal(true);
    }

    // search bar list validations
    else if (event.target.value === "Lead ID") {
      setSearchKey("leadId");
      setSearchActions("Lead ID");
    } else if (event.target.value === "Aadhar No") {
      setSearchKey("aadhar");
      setSearchActions("Aadhar No");
    } else if (event.target.value === "Pincode") {
      setSearchKey("pincode");
      setSearchActions("Pincode");
    } else if (event.target.value === "Phone No") {
      setSearchKey("phoneNumber");
      setSearchActions("Phone No");
    }
  };

  // Function to handle toggle button
  const handleJobApplication = (event, status, type) => {
    if (type === "applied") {
      setUnapplied(!unapplied);
      setCurrentPage(1);
    }
  };

  // Function to handle tabs
  const handleTabChange = (event, newIndex) => {
    if (!filters.tabs[newIndex]) {
      setTabValue(newIndex);

      // Update filters.tabs based on the newIndex
      const updatedTabs = Object.fromEntries(
        Object.keys(filters.tabs).map((key) => [key, key === newIndex])
      );

      setFilters({
        ...filters,
        tabs: updatedTabs,
      });
    }
  };

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  const makeRecruiterData = (data) => {
    let list = [];
    data.map((el) => {
      if (el.isActive) {
        list.push({
          id: el.recruiterId,
          name: `${el.firstName} ${el.lastName ? el.lastName : ""}`,
          phoneNumber: el.phoneNumber,
        });
      }
      return true;
    });
    return list;
  };

  const makeOpsData = (data) => {
    let list = [];
    data.map((el) => {
      list.push({
        id: el.userId,
        name: `${el.name}`,
      });
      return true;
    });
    return list;
  };

  const makeSearchList = (data) => {
    let list = [];
    data.map((el) => {
      if (el.typeKey != 11 && el.typeKey != 17) {
        list.push({
          typeKey: el.typeKey,
          typeValue: el.typeValue,
        });
      }
      return true;
    });
    list.push({ typeKey: 11, typeValue: "No task available" });
    return list;
  };

  const makeJobData = (data) => {
    let list = [];
    data.map((el) => {
      list.push({
        id: el.jobId,
        name: `${el.jobId} - ${el.client} - ${el.jobTitle}`,
        status: el.jobStatus,
      });
      return true;
    });
    return list;
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    toggleAllRowsSelected,
    state: { selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      updateMyData,
      getDocumentList,
      docListLoader,
      downloadDocError,
      showToast,
      setLoader,
      filters,
      setFilters,
      manualSortBy: true,
    },
    useFilters,
    useSortBy,
    useRowSelect
  );

  const table = (
    <div className={`tableContainer ${styles.tableContainer}`}>
      <table {...getTableProps()}>
        <thead className="thead">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps()}
                  className={
                    column.render("Header") === "Name"
                      ? `${
                          tabValue === "All" && !unapplied
                            ? "stickWorkerName95"
                            : "stickWorkerNameV2"
                        } zIndex1080 header`
                      : column.render("Header") === "Lead ID" &&
                        tabValue === "All" &&
                        !unapplied
                      ? "stickLeadId zIndex1080 header"
                      : column.id === "selection"
                      ? "stickCheckbox zIndex1080 header"
                      : (column.render("Header") === "Pincode" ||
                          column.render("Header") === "State" ||
                          column.render("Header") === "Aadhaar Number" ||
                          column.render("Header") === "PAN Number" ||
                          column.render("Header") === "DOB" ||
                          column.render("Header") === "Job ID" ||
                          column.render("Header") === "Vertical" ||
                          column.render("Header") === "Job Role" ||
                          column.render("Header") === "Job City" ||
                          column.render("Header") === "Applied on" ||
                          column.render("Header") === "Application Status" ||
                          column.render("Header") === "Remarks" ||
                          column.render("Header") === "Application history" ||
                          column.render("Header") === "Worker history" ||
                          column.render("Header") === "Follow Up 1" ||
                          column.render("Header") === "Follow Up 2" ||
                          column.render("Header") === "Campaign") &&
                        unapplied
                      ? "displayNone"
                      : "header"
                  }
                >
                  <span>{column.render("Header")}</span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        className={`${
                          cell.column.Header === "Name"
                            ? `${
                                tabValue === "All" && !unapplied
                                  ? "stickWorkerName95"
                                  : "stickWorkerNameV2"
                              }  zIndex540`
                            : cell.column.Header === "Lead ID" &&
                              tabValue === "All" &&
                              !unapplied
                            ? "stickLeadId zIndex540"
                            : cell.column.id === "selection"
                            ? "stickCheckbox zIndex540"
                            : (cell.column.Header === "Pincode" ||
                                cell.column.Header === "State" ||
                                cell.column.Header === "Aadhaar Number" ||
                                cell.column.Header === "PAN Number" ||
                                cell.column.Header === "DOB" ||
                                cell.column.Header === "Job ID" ||
                                cell.column.Header === "Vertical" ||
                                cell.column.Header === "Job Role" ||
                                cell.column.Header === "Job City" ||
                                cell.column.Header === "Applied on" ||
                                cell.column.Header === "Application Status" ||
                                cell.column.Header === "Remarks" ||
                                cell.column.Header === "Application history" ||
                                cell.column.Header === "Worker history" ||
                                cell.column.Header === "Follow Up 1" ||
                                cell.column.Header === "Follow Up 2" ||
                                cell.column.Header === "Campaign") &&
                              unapplied
                            ? "displayNone"
                            : "cell"
                        }`}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        }
      </table>
    </div>
  );

  /* Useffects */

  useEffect(() => {
    let status = "";
    jobFilterOption === "active"
      ? (status = 1)
      : jobFilterOption === "inactive"
      ? (status = 2)
      : (status = "");

    Axios.get(
      `${UrlLinks.baseUrl}${UrlLinks.jobs.jobsdbListingNew}?paging=0${
        jobFilterOption !== "all" ? `&status=${status}` : ""
      }`
    )
      .then((response) => {
        setJobList(makeJobData(response.data.response));
      })
      .catch((err) => {
        showToast("error", "Could not able to fetch job listing");
      });
  }, [jobFilterOption]);

  useEffect(() => {
    setAllRecordLoading(false);
  }, [allRecord]);

  useEffect(() => {
    if (selectedRowIds) {
      const indexes = Object.values(selectedRowIds);
      let disableButton = true;
      indexes.map((id) => {
        if (id) {
          disableButton = false;
          return true;
        }
        return true;
      });
      setIsRowSelected(disableButton);

      const indexValues = Object.entries(selectedRowIds);
      let assignIdList = [];
      let userIdList = [];
      indexValues.map((id) => {
        if (id[1]) {
          assignIdList.push(data[parseFloat(id[0])].assignId);
          userIdList.push(data[parseFloat(id[0])].userId);
        }
        return true;
      });
      assignIdList = assignIdList.join(",");
      setSelectedAssignIds(assignIdList);
      setSelectedUserIds(userIdList);
    }
  }, [selectedRowIds]);

  useEffect(() => {
    handleSearch();
  }, [searchKey]);

  useEffect(() => {
    let searchByVar = RemoveExtraSpaces(searchBy);
    let count = searchByVar.split(/[ ,]+/).length;
    if (count > 100) {
      setSearchError(true);
    } else {
      setSearchError(false);
    }
  }, [searchBy]);

  useEffect(() => {
    if (
      filters.aadhaarUpload !== "" ||
      filters.appliedBetween[0] !== null ||
      filters.appliedBetween[1] !== null ||
      filters.campaignId !== "" ||
      filters.leadState !== "" ||
      filters.leadCity !== "" ||
      filters.leadSource !== "" ||
      filters.leadAddedOn[0] !== null ||
      filters.leadAddedOn[1] !== null ||
      filters.appInstalled !== "" ||
      filters.gender !== "" ||
      filters.panUpload !== "" ||
      filters.preferredPincodes !== "" ||
      filters.preferredLanguages !== "" ||
      filters.profileCompletion !== "" ||
      filters.assets !== "" ||
      filters.qualifications !== "" ||
      filters.vertical !== "" ||
      filters.recruiterId !== "" ||
      filters.recruiterAssignedBetween[0] !== null ||
      filters.recruiterAssignedBetween[1] !== null ||
      filters.recruiterStatus !== "" ||
      filters.tenthUpload !== "" ||
      filters.twelfthUpload !== ""
    ) {
      setIsFilter(true);
    } else {
      setIsFilter(false);
    }
  }, [filterUrl]);

  useEffect(() => {
    ApplyFilters(filters);
  }, [
    searchByAuto,
    searchKeyByAuto,
    tabValue,
    selectedOption,
    jobFilterOption,
  ]);

  useEffect(() => {
    getExternalWorkerList(perPage, 1);
    setCurrentPage(1);
  }, [
    filterUrl,
    localStorage.getItem("roleType"),
    remarkChanged,
    toggleStatusUpdated,
    unapplied,
  ]);

  useEffect(async () => {
    // Cancel the previous request if it exists
    if (cancelTokens.current.applicationStatus) {
      cancelTokens.current.applicationStatus.cancel(
        "Operation canceled due to new request."
      );
    }

    cancelTokens.current.applicationStatus = cancelTokenSource;

    // API for Lead Application Status
    try {
      const response = await Axios.get(
        `${UrlLinks.baseUrl}${UrlLinks.leadData.getLeadApplicationStatus}`,
        {
          cancelToken: cancelTokenSource.token,
        }
      );
      if (response) {
        setSearchList(makeSearchList(response.data.response));
      }
    } catch (error) {
      if (Axios.isCancel(error)) {
        console.log("Request canceled:", error.message);
      } else {
        console.error("Error:", error);
      }
    }
  }, [tabValue]);

  useEffect(() => {
    // API for Recruiter List
    Axios.get(`${UrlLinks.baseUrl}${UrlLinks.workerUrls.recruiterList}`).then(
      (response) => {
        setRecruiterList(makeRecruiterData(response.data.response.Content));
      }
    );

    // API for Ops List
    Axios.get(`${UrlLinks.baseUrl}${UrlLinks.leadData.getOpsListing}`).then(
      (response) => {
        setOpsList(makeOpsData(response.data.response));
      }
    );
  }, []);

  const label = { inputProps: { "aria-label": "Switch demo" } };
  const BlueSwitch = muiStyled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "#1C75BC",
      "&:hover": {
        backgroundColor: alpha("#1C75BC", theme.palette.action.hoverOpacity),
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#1C75BC",
    },
    "& .MuiSwitch-switchBase": {
      cursor: "pointer",
    },
  }));

  const loaderBar = (
    <div style={{ margin: "130px auto" }}>
      <ScaleLoader height={70} width={10} color={"#1c75bc"} />
    </div>
  );

  return (
    <Styles>
      <Grid container className="padding2percent">
        <Grid item xs={12} className="flexDisplay marginBottom8">
          <Grid item xs={6} className="flexDisplay">
            <JobFilter
              uniqueKey="applicationDB"
              jobFilterOption={jobFilterOption}
              setJobFilterOption={setJobFilterOption}
              jobList={jobList}
              disabled={unapplied}
              filters={filters}
              setFilters={setFilters}
              id="jobId"
              statusId="jobStatus"
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              jobFilterOptionList={jobFilterOptionList}
              placeholder="Type or search the jobs"
            />
          </Grid>
          <Grid item xs={6} className={`${styles.itemRowReverse}`}>
            <div>
              <span>Unapplied</span>
              <BlueSwitch
                {...label}
                checked={unapplied}
                onChange={(e, status) =>
                  handleJobApplication(e, status, "applied")
                }
              />
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12} className="flexDisplay">
          <Grid
            item
            xs={6}
            className="flexDisplay padding0 paddingTop8 justifySpaceBetween"
          >
            <SearchBar
              uniqueId="ApplicationDb"
              searchBy={searchBy}
              setSearchBy={setSearchBy}
              searchError={searchError}
              filters={filters}
              setFilters={setFilters}
              searchActions={searchActions}
              handleActions={handleActions}
              handleSearch={handleSearch}
              searchBarList={searchBarList}
              handleSearchClose={handleSearchClose}
              ApplyFilters={ApplyFilters}
              setFilterUrl={setFilterUrl}
              placeholder='Select "Search by" and search here'
              searchInstructions="Use comma or space as delimiter to search multiple pin., lead id. Max limit 100.
              "
              warningMessage=" Max limit reached. Search 100 pin max."
            />
          </Grid>
          <Grid
            item
            xs={6}
            className={`${styles.itemRowReverse} flexDisplay paddingTop8`}
          >
            {!unapplied && (
              <div className={"marginLeft15"}>
                <ActionsDropdown
                  uniqueKey="uploadData"
                  actions={actions}
                  handleActions={handleActions}
                  actionList={uploadDataList}
                  isRowSelected={false}
                  dropdownName="Upload Data"
                />
              </div>
            )}
            <ExportButton
              uniqueKey="ApplicationDb"
              createAllRecordExcel={createAllRecordExcel}
              allRecordLoading={allRecordLoading}
              filename="Application DB.csv"
              buttonName="Export to Excel"
              loadingMessage="Please wait ... it might take few minutes."
              downloadButton="Download now"
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          className="flexDisplay paddingTop16"
          style={{ borderBottom: "1px solid #CECECE" }}
        >
          <Grid item xs={8}>
            <Tabs
              value={tabValue}
              onChange={(e, newIndex) => handleTabChange(e, newIndex)}
              aria-label="tab filter"
              className={`${classes.tabs}`}
              textColor="primary"
              indicatorColor="primary"
              TabIndicatorProps={{
                style: { background: !unapplied ? "#1C75BC" : "none" },
              }}
            >
              {tabsData.map((tab, index) => (
                <Tab
                  key={index}
                  value={tab.value}
                  label={tab.label}
                  {...a11yProps(index)}
                  disabled={unapplied}
                />
              ))}
            </Tabs>
          </Grid>
          <Grid item xs={4} className={`${styles.itemRowReverse}`}>
            <button
              className={`${styles.filterButton}`}
              onClick={() => setOpenFilterDrawer(true)}
            >
              {!isFilter ? (
                <img src={filterIcon} alt="filterIcon" />
              ) : (
                <img
                  src={filterIconMarked}
                  alt="filterIcon"
                  style={{ height: "16px" }}
                />
              )}
            </button>
            <div className="marginRight15">
              <ActionsDropdown
                uniqueKey="action"
                actions={actions}
                handleActions={handleActions}
                actionList={actionList}
                isRowSelected={isRowSelected}
                dropdownName="Actions"
              />
            </div>
            <div className="marginRight15">
              <CustomStatusDropdown
                disabled={
                  (isRowSelected && !unapplied) ||
                  unapplied ||
                  filters.tabs.All ||
                  filters.tabs.Rejected
                }
                searchList={searchList}
                assignIds={unapplied ? selectedUserIds : selectedAssignIds}
                showToast={showToast}
                dropdownName="Update status"
                buttonName="Update"
                toggleStatusUpdated={toggleStatusUpdated}
                setToggleStatusUpdated={setToggleStatusUpdated}
                uniqueKey="statusUpdate"
                updateMyData={updateMyData}
                fileName="Invalid lead data"
              />
            </div>
          </Grid>
        </Grid>
        {loader ? (
          loaderBar
        ) : data.length === 0 ? (
          <div className={styles.noRecord}>
            <img src={NoRecord} alt="noRecord" />
            <span>No records found</span>
          </div>
        ) : (
          <Grid item xs={12} className="paddingTop16">
            {table}
            <Pagination
              rowsperpage={perPage}
              pageNo={currentPage}
              totalElements={totalRows}
              totalPages={totalPages}
              handlePageChange={handlePageChange}
              handlePerRowsChange={handlePerRowsChange}
            />
          </Grid>
        )}
        {openRemarkModal && (
          <RemarkOkayGo
            open={openRemarkModal}
            setOpen={setOpenRemarkModal}
            setRemarkChanged={setRemarkChanged}
            record={remarkRecord}
            showToast={showToast}
            updateMyData={updateMyData}
            title="Remark"
            placeholder="Enter the Remark here"
            submitButton="Submit"
            cancelButton="Cancel"
          />
        )}
        {openApplicationHistoryModal && (
          <HistoryModal
            uniqueKey="applicationHistory"
            open={openApplicationHistoryModal}
            setOpen={setOpenApplicationHistoryModal}
            title={`Application History`}
            historyList={historyList}
            historyColumns={historyColumns}
            applicationStatusList={applicationStatusList}
          />
        )}
        {openWorkerHistoryModal && (
          <HistoryModal
            uniqueKey="workingHistory"
            open={openWorkerHistoryModal}
            setOpen={setOpenWorkerHistoryModal}
            title={`Working History`}
            historyList={historyList}
            historyColumns={historyColumns}
            applicationStatusList={applicationStatusList}
          />
        )}
        {openAssignRecruiterModal && (
          <AssignToRecruiter
            open={openAssignRecruiterModal}
            setOpen={setOpenAssignRecruiterModal}
            showToast={showToast}
            updateMyData={updateMyData}
            userIds={unapplied ? selectedUserIds : selectedAssignIds}
            title="Assign to Recruiter"
            uniqueKey="recruiterAssign"
            placeholder="Enter the name here"
            buttonName="Assign to recruiter"
            optionList={recruiterList}
            url={`${UrlLinks.baseUrl}${
              unapplied
                ? UrlLinks.leadData.assignToRecruiter
                : UrlLinks.leadData.assignAppliedLeadToRecruiter
            }`}
            unapplied={unapplied}
          />
        )}
        {openAssignOpsModal && (
          <AssignToRecruiter
            open={openAssignOpsModal}
            setOpen={setOpenAssignOpsModal}
            showToast={showToast}
            updateMyData={updateMyData}
            userIds={unapplied ? selectedUserIds : selectedAssignIds}
            title="Assign to operation team"
            uniqueKey="opsAssign"
            placeholder="Enter the name here"
            buttonName="Assign to ops"
            optionList={opsList}
            url={`${UrlLinks.baseUrl}${UrlLinks.leadData.assignLeadToOps}`}
            unapplied={unapplied}
          />
        )}
        {openCommunicationModal && (
          <SendMsg
            open={openCommunicationModal}
            setOpen={setOpenCommunicationModal}
            updateMyData={updateMyData}
            userIds={selectedUserIds}
            title="Communication"
          />
        )}
        {openJobCommunicationModal && (
          <SendJobMsg
            open={openJobCommunicationModal}
            setOpen={setOpenJobCommunicationModal}
            updateMyData={updateMyData}
            userIds={selectedUserIds}
            title="Job Campaign"
            filters={filters}
          />
        )}
        {openBulkUpdateModal && (
          <BulkUpdateModal
            openCasperOnboardingModal={openBulkUpdateModal}
            setOpenCasperOnboardingModal={setOpenBulkUpdateModal}
            statusValues={searchList}
            showToast={showToast}
            updateMyData={updateMyData}
            createAllRecordExcel={createAllRecordExcel}
            title="Bulk Update"
            uniqueKey="applicationDbBulkUpdate"
            unapplied={unapplied}
            url={`${UrlLinks.baseUrl}${
              UrlLinks.leadData.updateLeads
            }?insertedBy=${localStorage.getItem("userID")}`}
            fileName="Invalid lead data"
          />
        )}
        {openUploadLeadModal && (
          <OnboardingModal
            openOnboardingModal={openUploadLeadModal}
            setOpenOnboardingModal={setOpenUploadLeadModal}
            showToast={showToast}
            updateMyData={updateMyData}
            title="Upload leads data"
            uniqueKey="uploadLeads"
            templateButton="Download File Template"
            url={`${UrlLinks.baseUrl}${
              UrlLinks.leadData.uploadLeads
            }?insertedBy=${localStorage.getItem("userID")}`}
            fileName="Invalid lead data"
          />
        )}
        {openFilterDrawer && (
          <Drawer
            anchor="right"
            open={openFilterDrawer}
            onClose={() => {
              setLeadCityId(curLeadId.leadCity);
              setLeadSourceId(curLeadId.leadSource);
              setPreferredPincodes(multiplePreferredPincodes);
              setPreferredLanguages(multiplePreferredLanguages);
              setAssets(multipleAssets);
              setOpenFilterDrawer(false);
            }}
          >
            <FilterDrawer
              handleClose={() => setOpenFilterDrawer(false)}
              filters={filters}
              setFilters={setFilters}
              applyFilters={ApplyFilters}
              resetFilters={resetFiltersWithoutSearch}
              leadCityId={leadCityId}
              setLeadCityId={setLeadCityId}
              leadSourceId={leadSourceId}
              setLeadSourceId={setLeadSourceId}
              preferredPincodes={preferredPincodes}
              setPreferredPincodes={setPreferredPincodes}
              preferredLanguages={preferredLanguages}
              setPreferredLanguages={setPreferredLanguages}
              assets={assets}
              setAssets={setAssets}
              recruiterList={recruiterList}
              unapplied={unapplied}
              searchList={searchList}
            ></FilterDrawer>
          </Drawer>
        )}
      </Grid>
    </Styles>
  );
};

export default ExternalWorker;
