import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { IconButton, TextField } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import Modal from "@mui/material/Modal";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import UrlLinks from "../../UrlLinks";
import axios from "axios";

const style = {
  position: "absolute",
  top: "28%",
  left: "50%",
  padding: "15px 25px",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function RejectModal(props) {
  const { open, setOpen, doc, updateVerificationStatus } = props;

  const [remark, setRemark] = useState(null);
  const [reasons, setReasons] = useState([]);
  const [age, setAge] = useState("");

  const handleClose = () => {
    setOpen(["", false]);
  };

  const handleSubmit = () => {
    updateVerificationStatus(doc, 3, age !== 100 ? age : remark);
  };

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  useEffect(() => {
    let mapped_category = "";
    if (doc === "pan") {
      mapped_category = "pan";
    } else if (doc === "aadhar") {
      mapped_category = "aadhar";
    } else if (doc === "dl") {
      mapped_category = "dl";
    } else if (doc === "profile") {
      mapped_category = "profile";
    } else if (doc === "address") {
      mapped_category = "address";
    }
    axios
      .get(
        `${UrlLinks.baseUrl}configmaster/?category_sub_type=Rejection%20reasons&category_type=document%20verification&rows=100&type_desc=${mapped_category}`
      )
      .then((response) => {
        let a = [];

        response.data.response.content.map((e, i) => {
          a.push(
            <MenuItem key={i} value={e.typeKey}>
              {e.typeValue}
            </MenuItem>
          );
        });
        a.push(
          <MenuItem key={100} value={100}>
            {"Other"}
          </MenuItem>
        );
        setReasons(a);
      });
  }, []);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              style={{
                color: "#1C75BC",
                fontSize: "24px",
                fontWeight: "normal",
                margin: "0",
              }}
            >
              Select the Reason
            </Typography>
            <IconButton
              component="label"
              onClick={handleClose}
              fontSize="large"
              style={{ marginRight: "-15px" }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Select reason</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={age}
              label="Select reason"
              onChange={handleChange}
            >
              {reasons}{" "}
            </Select>
          </FormControl>
          <Box
            sx={{ margin: "25px 0" }}
            component="form"
            noValidate
            autoComplete="off"
          >
            {age === 100 && (
              <TextField
                id="outlined-multiline-flexible"
                fullWidth
                multiline
                onChange={(e) => {
                  setRemark(e.target.value);
                }}
                rows={3}
                placeholder="Enter the reason here"
                variant="outlined"
              />
            )}
          </Box>
          <Stack spacing={2} direction="row">
            <Button
              fullWidth
              variant="contained"
              disabled={
                age === "" ||
                (age === 100 &&
                  (remark !== null && remark.length > 0 ? false : true))
              }
              onClick={() => {
                handleSubmit();
                setOpen(false);
              }}
            >
              Submit
            </Button>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
}
