import * as actionTypes from "../../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  curLeadId: {
    leadCity: [],
    leadSource: [],
  },
  tabValue: "All",
  multiplePreferredPincodes: [],
  multiplePreferredLanguages: [],
  multipleAssets: [],
  multipleQualifications: [],
};

const setCurLeadId = (state, action) => {
  return updateObject(state, {
    curLeadId: {
      leadCity: action.curLeadId.leadCity,
      leadSource: action.curLeadId.leadSource,
    },
    multiplePreferredPincodes: action.multiplePreferredPincodes,
    multiplePreferredLanguages: action.multiplePreferredLanguages,
    multipleAssets: action.multipleAssets,
  });
};

const setTabValue = (state, action) => {
  return updateObject(state, {
    tabValue: action.tabValue,
  });
};

const applicationDbReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CUR_LEAD_ID:
      return setCurLeadId(state, action);
    case actionTypes.TAB_VALUE:
      return setTabValue(state, action);
    default:
      return state;
  }
};
export default applicationDbReducer;
