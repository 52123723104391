import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Grid, Modal , Slide, Backdrop, Button} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
}  from '@material-ui/pickers';
// import './filter.css'


const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: '#ffffff',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: '490px'
    },
    select:{
        minWidth: 140,
        padding: 15,
      }
  }));

const DateFilter = (props) => {
    const {id, filters, setFilters, openDateSelector, setOpenDateSelector, dateVal, label}= props
    const classes = useStyles();
    
    
    const handleDateChange = (date,pos) => {
        let dates=filters[dateVal]
        if(pos===1){
            dates[0]= date
        }
        if(pos===2){
            dates[1]= date
        }
        setFilters({...filters, [dateVal]: dates})
        
    };
    const handleApplyFilters =() =>{
        setFilters({...filters, [id]:"customDates"})
        setOpenDateSelector(false)
    }
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={openDateSelector}
            onClose={() => setOpenDateSelector(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Slide direction="up" in={openDateSelector} mountOnEnter unmountOnExit>
                <Grid container className={classes.paper} alignItems="center">
                    <h2>{label}</h2>
                    <Grid item xs={12}  style={{ minWidth: "100px", padding: "20px"}}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                placeholder="From"
                                format="dd-MM-yyyy"
                                inputVariant="outlined"
                                className="fromDate"
                                value={filters[dateVal][0]}
                                onChange={date => handleDateChange(date,1)}
                            />
                            <br />
                            <KeyboardDatePicker
                                placeholder="To"
                                format="dd-MM-yyyy"
                                inputVariant="outlined"
                                style={{margin:"0 0 10px 0"}}
                                className="toDate"
                                value={filters[dateVal][1]}
                                minDate={filters[dateVal][0]}
                                onChange={date => handleDateChange(date, 2)}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid  xs={12}  style={{ minWidth: "100px", padding: "20px"}}>
                        <Button 
                        className= "dataButton"
                        style={{marginLeft: "30px"}}
                        onClick={handleApplyFilters}
                    >
                        Apply
                    </Button>
                    <Button 
                        className= "dataButton"
                        onClick={() => setOpenDateSelector(false)}
                    >
                        Cancel
                    </Button>
                    </Grid>
                </Grid>
            </Slide>
        </Modal>  );
}

export default DateFilter;