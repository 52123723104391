import * as React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { IconButton, TextField } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  padding: "15px 25px",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function RejectModal(props) {
  const { open, setOpen, updatePaymentStatus, stage, text } = props;

  const handleClose = () => setOpen(false);
  const [remark, setRemark] = React.useState(null);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              style={{
                color: "#1C75BC",
                fontSize: "24px",
                fontWeight: "normal",
                margin: "0",
              }}
            >
              {text}
            </Typography>
            <IconButton
              component="label"
              onClick={handleClose}
              fontSize="large"
              style={{ marginRight: "-15px" }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <Box
            sx={{ margin: "25px 0" }}
            component="form"
            noValidate
            autoComplete="off"
          >
            <TextField
              id="outlined-multiline-flexible"
              fullWidth
              multiline
              onChange={(e) => {
                setRemark(e.target.value);
              }}
              rows={3}
              placeholder="Enter the remark here"
              variant="outlined"
            />
          </Box>
          <Stack spacing={2} direction="row">
            <Button fullWidth variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              fullWidth
              variant="contained"
              disabled={remark !== null && remark.length > 0 ? false : true}
              onClick={() => {
                updatePaymentStatus(stage, remark);
                handleClose();
                setOpen(false);
              }}
            >
              Submit
            </Button>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
}
