import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Axios from "axios";
import document from "./document.svg";
import ResponseModal from "./ResponseModal";
import Typography from "@material-ui/core/Typography";
import { FormControl, Grid, MenuItem, Select } from "@material-ui/core";
import Download from "./Group 3238.svg";
import Styles from "./index.module.scss";
import UrlLinks from "../../../UrlLinks";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    borderBottom: "unset",
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function CustomizedDialogs(props) {
  const {
    openOnboardingModal,
    setOpenOnboardingModal,
    showToast,
    updateMyData,
    data,
  } = props;
  const [excel, setExcel] = useState(null);
  const [openResponseModal, setOpenResponseModal] = useState(false);
  const [name, setName] = useState("");
  const [partnerId, setPartnerId] = useState(null);
  const [excelError, setExcelError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [invalidData, setInvalidData] = useState();
  const [fileLink, setFileLink] = useState(null);
  const [responseData, setResponseData] = useState();
  const [actions, setActions] = useState(0);
  const [closeFile, setCloseFile] = useState(true);

  const handleClose = () => {
    setOpenOnboardingModal(false);
  };

  const handleCloseFile = () => {
    setName("");
    setCloseFile(true);
  };

  const handleUpload = (event) => {
    const fileExtension = event.target.files[0].name.split(".").slice(-1)[0];
    const fileName = event.target.files[0].name;

    if (
      fileExtension.toLowerCase() === "xlsx" ||
      fileExtension.toLowerCase() === "xls"
    ) {
      setName(fileName);
      setExcel(event.target.files[0]);
      setExcelError(false);
      setCloseFile(false);
    } else {
      setExcelError(true);
      setExcel(null);
      setName("");
    }
    setResponseData();
    event.target.value = null;
  };

  let earningZomatoExcel = "/Hub_upload_template_march_2023.xlsx";
  const createInvalidData = (records) => {
    if (records.length > 0) {
      let list = [];
      records.map((record) => {
        if (record.isUploaded === 0) {
          list.push({
            Client: record.client || "",
            "Hub Name": record.hubName || "",
            "Hub City": record.hubCity || "",
            "Hub State": record.hubState || "",
            "Hub Zone": record.hubZone || "",
            "Hub Address": record.hubAddress || "",
            "Hub Latitude": record.hubLat || "",
            "Hub Longitude": record.hubLong || "",
            "Reason for failure": record.reason || "",
          });
        }
        return true;
      });
      return list.length > 0 ? list : null;
    }
  };

  const handleExcel = () => {
    setIsLoading(true);
    let formData = new FormData();
    formData.append("file", excel);
    Axios.post(
      `${UrlLinks.baseUrl}${
        UrlLinks.workerUrls.uploadHubs
      }?insertedBy=${localStorage.getItem("userID")}&partnerId=${partnerId}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
      .then((response) => {
        if (response.data) {
          setInvalidData(
            createInvalidData(
              response.data.response.content.response.invalidExcel
            )
          );
          setResponseData({
            rejected:
              response.data.response.content.response.totalAttempted -
              response.data.response.content.response.totalUploaded,
            uploaded: response.data.response.content.response.totalUploaded,
            total: response.data.response.content.response.totalAttempted,
          });
          updateMyData();
          setIsLoading(false);
        } else {
          setResponseData([]);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        showToast("error", error.message);
      });
  };

  useEffect(() => {
    if (responseData) {
      setOpenResponseModal(true);
    }
  }, [responseData]);
  
  let dialogData = (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={openOnboardingModal}
      className={Styles.onboardingDialog}
      classes={{ paper: Styles.paper }}
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        <span>Upload Hubs</span>
        <a
          href={earningZomatoExcel}
          download
          target="_blank"
          className={Styles.downloadTemplate}
        >
          <span>Download File Template</span>
          <img src={Download} alt="download" />
        </a>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container className="padding0">
          <Grid item xs={4} className="padding0">
            <span style={{ fontWeight: "600", marginLeft: "20px" }}>
              Choose Client
            </span>
          </Grid>
          <Grid item xs={8} className="padding0" alignItems="flex-start">
            <span>
              <FormControl variant="outlined">
                <Select
                  id="actions"
                  value={actions}
                  classes={{ outlined: styles.actionSelect }}
                  onChange={(e) => {
                    setPartnerId(e.target.value);
                    setActions(e.target.value);
                  }}
                >
                  <MenuItem value={0} disabled={true}>
                    {" "}
                    Clients
                  </MenuItem>
                  {data.map((e) => {
                    return (
                      <MenuItem key={e.partnerId} value={e.partnerId}>
                        {e.partnerName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </span>
          </Grid>
        </Grid>
        <Grid item xs={12} className="padding0">
          <input
            style={{ display: "none" }}
            id="contained-button-file"
            type="file"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            onChange={handleUpload}
          />
          <label htmlFor="contained-button-file">
            <Button
              variant="outlined"
              className={Styles.uploadButton}
              component="span"
              startIcon={
                <img src={document} alt={"upload"} style={{ color: "white" }} />
              }
            >
              Browse File
            </Button>
          </label>
        </Grid>
        <Grid item xs={12} className="padding0">
          <span className={`fontSize13 ${excelError ? "redColor" : ""}`}>
            only .xls and .xlsx are allowed
          </span>
        </Grid>
        <Grid item xs={12} className="padding0" style={{ marginTop: "10px" }}>
          {name && (
            <span className={Styles.fileName}>
              {name.length > 70 ? `${name.substring(0, 69)}...` : `${name}`}
              <span>
                <IconButton
                  aria-label="close"
                  style={{ color: "white", paddingRight: "0" }}
                  onClick={handleCloseFile}
                >
                  <CloseIcon />
                </IconButton>
              </span>
            </span>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        {isLoading ? (
          <span>Please wait... it may take few minutes</span>
        ) : (
          <Button
            variant="contained"
            onClick={handleExcel}
            className={`${
              !excel || !partnerId ? "disabledButton" : "blueButtonContained"
            }`}
            disabled={!excel || !partnerId}
          >
            Upload
          </Button>
        )}
      </DialogActions>
      {openResponseModal && (
        <ResponseModal
          openResponseModal={openResponseModal}
          setOpenResponseModal={setOpenResponseModal}
          responseData={responseData}
          setOpenOnboardingModal={setOpenOnboardingModal}
          showToast={showToast}
          invalidData={invalidData}
          updateMyData={updateMyData}
          agreement={true}
          type="Onboarding"
        />
      )}
    </Dialog>
  );
  return dialogData;
}
