import React from 'react'
import {Grid} from '@material-ui/core'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { Button } from '@material-ui/core';
import style from './index.module.scss';
import AutoSelectFilter from './AutoSelectFilter';

const FilterDrawer = (props) => {
    const { handleClose, resetFilters, applyFilters, filters, setFilters} = props;
    const handleCancelFilters =() =>{
        handleClose();
    }
    const bgvStatusList = [
        { id:1, name:"In Process"},
        { id:2, name:"Verified"},
        { id:3, name:"Failed"}
    ]
    const output=<Grid container style={{ width: "520px", marginTop: '20px' }} className={style.FilterContainer}>
        <Grid container className={`${style.FilterHeadingContainer}`}>
            <div className={style.flexBaseLine}>
                <Button onClick={handleClose} className={style.filterCloseArrow}>
                    <ArrowForwardIcon />
                </Button>
                <span className={style.filterHeading}>Filters</span>
            </div>
            <div>
                <Button
                    onClick={resetFilters}
                    className={style.resetFiltersButton}
                >
                    Reset
                </Button>
            </div>
        </Grid>
        <div className={style.filterHeadingDivider}></div>
        <Grid container className={style.filtersContainer}>
            <Grid item xs={12} className={`${style.singleFilterContainer}`}>
                <span className={style.filterTitle}>
                    Aadhaar Status
                </span>
                <AutoSelectFilter 
                    filters={filters}
                    setFilters={setFilters}
                    multipleFlag={false}
                    id="adhaar"
                    options={bgvStatusList}
                />
            </Grid>
            <Grid item xs={12} className={`${style.singleFilterContainer}`}>
                <span className={style.filterTitle}>
                    PAN Status
                </span>
                <AutoSelectFilter 
                    filters={filters}
                    setFilters={setFilters}
                    multipleFlag={false}
                    id="pan"
                    options={bgvStatusList}
                />
            </Grid>
            <Grid item xs={12} className={`${style.singleFilterContainer}`}>
                <span className={style.filterTitle}>
                    DL Status
                </span>
                <AutoSelectFilter 
                    filters={filters}
                    setFilters={setFilters}
                    multipleFlag={false}
                    id="dl"
                    options={bgvStatusList}
                />
            </Grid>
            <Grid item xs={12} className={`${style.singleFilterContainer}`}>
                <span className={style.filterTitle}>
                    Permanent Address
                </span>
                <AutoSelectFilter 
                    filters={filters}
                    setFilters={setFilters}
                    multipleFlag={false}
                    id="permanentAddress"
                    options={bgvStatusList}
                />
            </Grid>
            <Grid item xs={12} className={`${style.singleFilterContainer}`}>
                <span className={style.filterTitle}>
                    Current Address
                </span>
                <AutoSelectFilter 
                    filters={filters}
                    setFilters={setFilters}
                    multipleFlag={false}
                    id="currentAddress"
                    options={bgvStatusList}
                />
            </Grid>
            <Grid item xs={12} className={`${style.singleFilterContainer}`}>
                <span className={style.filterTitle}>
                    Court Permanent Address Verification
                </span>
                <AutoSelectFilter 
                    filters={filters}
                    setFilters={setFilters}
                    multipleFlag={false}
                    id="criminal"
                    options={bgvStatusList}
                />
            </Grid>
            <Grid item xs={12} className={`${style.singleFilterContainer}`}>
                <span className={style.filterTitle}>
                    Court Current Address Verification
                </span>
                <AutoSelectFilter 
                    filters={filters}
                    setFilters={setFilters}
                    multipleFlag={false}
                    id="court"
                    options={bgvStatusList}
                />
            </Grid>
        </Grid>
        <Grid container className={style.filterButtonDividerContainer}>
            <div className={style.filterHeadingDivider}></div>
            <Grid container style={{ marginTop: '30px' }} className='padding0'>
                <div style={{
                    paddingRight: '16px',
                    paddingLeft: '300px'
                }}>  
                    <Button variant="outlined" onClick={handleCancelFilters} size="medium" color="primary" >
                        Cancel
                    </Button>
                </div>
                <Button variant="contained" size="medium" color="primary" className={style.PrimaryColorButton} onClick={()=>{applyFilters(); handleClose()}}>Apply</Button>
                
            </Grid>
        </Grid>
    </Grid >
    return output;
}

export default FilterDrawer;