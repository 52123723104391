import React, { useEffect, useRef, useState } from "react";
import styles from "./Functions.module.scss";
import { Button, makeStyles } from "@material-ui/core";
import { ArrowDropDownOutlined, ArrowDropUpOutlined } from "@material-ui/icons";
import axios from "axios";
import UrlLinks from "../../../UrlLinks";
import ResponseModal from "../../../Utils/dialogs/ResponseModal";
import { useSelector } from "react-redux";
import FollowUp from "../components/FollowUp";

const useStyles = makeStyles((theme) => ({
  statusDropdownDiv: {
    "& .MuiButton-root": {
      border: "1px solid #1c75bc",
      color: "#1c75bc",
      textTransform: "inherit",
      padding: "5px 9px",
      fontSize: "inherit",
      fontWeight: "normal",
      height: "38px",
    },
    "& .MuiButton-root.Mui-disabled": {
      border: "1px solid #cccccc",
      color: "rgba(0, 0, 0, 0.26)",
    },
  },
}));

const CustomStatusDropdown = (props) => {
  const {
    disabled,
    searchList,
    assignIds,
    showToast,
    dropdownName,
    buttonName,
    toggleStatusUpdated,
    setToggleStatusUpdated,
    uniqueKey,
    updateMyData,
    fileName,
  } = props;
  const { applicationDb } = useSelector((state) => state);
  const { tabValue } = applicationDb;

  const classes = useStyles();
  const dropdownRef = useRef(null);
  const [statusDropdownOpen, setStatusDropdownOpen] = useState(false);
  const [searchActions, setSearchActions] = useState("");
  const [invalidData, setInvalidData] = useState();
  const [responseData, setResponseData] = useState();
  const [openResponseModal, setOpenResponseModal] = useState(false);
  const [statusList, setStatusList] = useState(searchList);
  const [openFollowUpModal, setOpenFollowUpModal] = useState(false);

  const handleStatusDropdown = (event) => {
    setStatusDropdownOpen(!statusDropdownOpen);
  };

  const handleSearchAction = (event, itemList) => {
    setSearchActions(itemList.typeKey);
    if (itemList.typeKey === 18 || itemList.typeKey === 19) {
      setOpenFollowUpModal(true);
    }
  };

  //  function to create an invalid excel sheet
  const createInvalidData = (records) => {
    let totalUploaded = 0;
    if (records.length > 0) {
      let list = [];
      records.map((record) => {
        if (
          record.assignStatus === "Application status changed successfully "
        ) {
          totalUploaded++;
        } else {
          list.push({
            assignId: record.assignId || "",
            assignStatus: record.assignStatus || "Worker not assigned",
            responseId: record.responseId || "",
          });
        }
        return true;
      });
      const summary = {
        totalAttempted: records.length,
        totalUploaded: totalUploaded,
      };
      setResponseData(summary);
      return list.length > 0 ? list : null;
    }
  };

  const handleStatusUpdate = () => {
    let data = {
      assignIds: assignIds,
      statusId: searchActions,
      userId: localStorage.getItem("userID"),
    };
    axios
      .post(
        `${UrlLinks.baseUrl}${UrlLinks.leadData.setLeadApplicationStatusInBulk}?assignIds=${data.assignIds}&statusId=${data.statusId}&userId=${data.userId}`
      )
      .then((response) => {
        if (searchActions == 9 || searchActions == 10) {
          if (response.data.response) {
            setInvalidData(createInvalidData(response.data.response));
          } else {
            setResponseData([]);
          }
        } else {
          showToast("success", "Success", "Remark updated successfully.");
        }
      })
      .catch((err) => {
        console.log("err", err);
        showToast("error", "Something went wrong, please try after sometime.");
      });
    setToggleStatusUpdated(!toggleStatusUpdated);
    setStatusDropdownOpen(false);
    updateMyData();
  };

  useEffect(() => {
    if (responseData) {
      setOpenResponseModal(true);
    }
  }, [responseData]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setStatusDropdownOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    let list = [];
    searchList.map((item) => {
      if (
        tabValue === "Applied" &&
        item.typeKey != 22 &&
        item.typeKey != 12 &&
        item.typeKey != 13 &&
        item.typeKey != 11 &&
        item.typeKey != 1
      ) {
        list.push({
          typeKey: item.typeKey,
          typeValue: item.typeValue,
        });
      } else if (
        tabValue === "Screening" &&
        (item.typeKey == 2 ||
          item.typeKey == 3 ||
          item.typeKey == 4 ||
          item.typeKey == 6 ||
          item.typeKey == 7 ||
          item.typeKey == 8 ||
          item.typeKey == 9 ||
          item.typeKey == 10 ||
          item.typeKey == 14 ||
          item.typeKey == 15 ||
          item.typeKey == 16 ||
          item.typeKey == 18 ||
          item.typeKey == 19 ||
          item.typeKey == 20 ||
          item.typeKey == 21 ||
          item.typeKey == 23)
      ) {
        list.push({
          typeKey: item.typeKey,
          typeValue: item.typeValue,
        });
      } else if (
        tabValue === "Document" &&
        (item.typeKey == 2 ||
          item.typeKey == 3 ||
          item.typeKey == 5 ||
          item.typeKey == 6 ||
          item.typeKey == 7 ||
          item.typeKey == 8 ||
          item.typeKey == 9 ||
          item.typeKey == 10 ||
          item.typeKey == 18 ||
          item.typeKey == 19 ||
          item.typeKey == 21 ||
          item.typeKey == 23)
      ) {
        list.push({
          typeKey: item.typeKey,
          typeValue: item.typeValue,
        });
      } else if (
        tabValue === "Training" &&
        (item.typeKey == 3 ||
          item.typeKey == 7 ||
          item.typeKey == 9 ||
          item.typeKey == 10 ||
          item.typeKey == 18 ||
          item.typeKey == 19 ||
          item.typeKey == 23)
      ) {
        list.push({
          typeKey: item.typeKey,
          typeValue: item.typeValue,
        });
      } else if (
        tabValue === "Work" &&
        (item.typeKey == 9 || item.typeKey == 10 || item.typeKey == 11)
      ) {
        list.push({
          typeKey: item.typeKey,
          typeValue: item.typeValue,
        });
      }
      return true;
    });

    let sortedList = list.sort((a, b) =>
      a.typeValue.localeCompare(b.typeValue)
    );
    setStatusList(sortedList);
  }, [searchList]);

  return (
    <div ref={dropdownRef} className={classes.statusDropdownDiv}>
      <Button onClick={(e) => handleStatusDropdown(e)} disabled={disabled}>
        {dropdownName}
        {statusDropdownOpen ? (
          <ArrowDropUpOutlined />
        ) : (
          <ArrowDropDownOutlined />
        )}
      </Button>
      {statusDropdownOpen && (
        <div className={styles.statusDropdownList}>
          {statusList?.map((itemList, index) => (
            <div className={styles.itemList} key={index}>
              <input
                type="radio"
                name="statusOption"
                onChange={(e) => handleSearchAction(e, itemList)}
              />
              {itemList.typeValue}
            </div>
          ))}
          <Button
            onClick={(e) => handleStatusUpdate(e, searchActions)}
            variant="contained"
            className="dataButton"
            disabled={searchActions === "" ? true : false}
          >
            {buttonName}
          </Button>
        </div>
      )}
      {openResponseModal && (
        <ResponseModal
          openResponseModal={openResponseModal}
          setOpenResponseModal={setOpenResponseModal}
          setOpenOnboardingModal={false}
          responseData={responseData}
          invalidData={invalidData}
          uniqueKey={uniqueKey}
          fileName={fileName}
        />
      )}
      {openFollowUpModal && (
        <FollowUp
          openOnboardingModal={openFollowUpModal}
          setOpenOnboardingModal={setOpenFollowUpModal}
          showToast={showToast}
          updateMyData={updateMyData}
          title={searchActions === 18 ? "Follow Up 1" : "Follow Up 2"}
          uniqueKey="followUp"
          assignIds={assignIds}
          searchActions={searchActions}
        />
      )}
    </div>
  );
};

export default CustomStatusDropdown;
