import React, { useState } from "react";
import styles from "../index.module.scss";
import { CircularProgress } from "@material-ui/core";

const DownloadDocuments = (props) => {
  const { row, getDocumentList, docListLoader, downloadDocError } = props;
  const [error, setError] = useState("");

  const handleClick = (row) => {
    setError(getDocumentList(row.original.userId, row.original.name));
  };

  return (
    <div style={{ textAlign: "center" }}>
      {docListLoader === row.original.userId ? (
        <CircularProgress />
      ) : row.original.docAvailable ? (
        <button
          className={styles.workerName}
          type="button"
          style={{ fontSize: "16px !important" }}
          onClick={() => {
            handleClick(row);
          }}
        >
          Downloads
        </button>
      ) : (
        <div>No documents</div>
      )}
      <p>
        {downloadDocError !== null &&
        downloadDocError.userId === row.original.userId
          ? downloadDocError.err
          : null}
      </p>
    </div>
  );
};

export default DownloadDocuments;
