import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import Axios from "axios";
import { TimePicker } from "@material-ui/pickers";
import { Stack } from "@mui/material";
import UrlLinks from "../../../UrlLinks";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import CancelIcon from "@mui/icons-material/Cancel";
import * as moment from "moment";
import AssignToRecruiterRes from "./AssignToRecruiterRes";
import { Autocomplete } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const usestyles = makeStyles({
  inputTime: {
    "& .MuiInput-underline": {
      width: "150px",
      marginLeft: "30px",
    },
    "& .MuiInputLabel-animated": {
      marginLeft: "30px",
    },
  },
});

export default function AssignToRecruiterModal(props) {
  const { open, setOpen, userIds, updateMyData, tabData } = props;
  const handleClose = () => setOpen(false);
  const [partner, setPartner] = useState(100);
  const [resData, setResData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [recruiter, setRecruiter] = useState(null);
  const [openRes, setOpenRes] = useState(false);
  const [menuItems, setmenuItems] = useState([]);
  const [recruiterOptions, setRecruiterOptions] = useState([]);
  const partnerTiming = {
    flipkart: {
      startTime: "2014-08-18T07:00:00",
      endTime: "2014-08-18T16:00:00",
    },
    swiggy: {
      startTime: "2014-08-18T18:00:00",
      endTime: "2014-08-18T00:00:00",
    },
    zomato: {
      startTime: "2014-08-18T18:00:00",
      endTime: "2014-08-18T00:00:00",
    },
  };
  const [startValue, setStartValue] = useState(
    new Date(
      partner === 100
        ? partnerTiming.flipkart.startTime
        : partner === 101
        ? partnerTiming.swiggy.startTime
        : partnerTiming.zomato.startTime
    )
  );
  const [endValue, setEndValue] = useState(
    new Date(
      partner === 100
        ? partnerTiming.flipkart.endTime
        : partner === 101
        ? partnerTiming.swiggy.endTime
        : partnerTiming.zomato.endTime
    )
  );
  const [recruiterAssigned, setRecruiterAssigned] = useState("");
  const classes = usestyles();

  const handleChangeStart = (newValue) => {
    setStartValue(newValue);
  };

  const handleChangeEnd = (newValue) => {
    setEndValue(newValue);
  };

  useEffect(() => {
    Axios.get(`${UrlLinks.baseUrl}${UrlLinks.workerUrls.recruiterList}`).then(
      (response) =>
        setRecruiterOptions(makeRecruiterData(response.data.response))
    );

    Axios.get(`${UrlLinks.baseUrl}${UrlLinks.shifts.workerPartners}`).then(
      (response) => {
        makePartnerData(response.data.response);
      }
    );
  }, []);

  const makePartnerData = (data) => {
    let list = [];
    data.map((el) => {
      if (el.is_active) {
        list.push(<MenuItem value={el.partner_id}>{el.company_name}</MenuItem>);
      }
      return true;
    });
    setmenuItems(list);
    return list;
  };

  useEffect(() => {
    setStartValue(
      new Date(
        partner === 100
          ? partnerTiming.flipkart.startTime
          : partner === 101
          ? partnerTiming.swiggy.startTime
          : partnerTiming.zomato.startTime
      )
    );
    setEndValue(
      new Date(
        partner === 100
          ? partnerTiming.flipkart.endTime
          : partner === 101
          ? partnerTiming.swiggy.endTime
          : partnerTiming.zomato.endTime
      )
    );
  }, [partner]);

  useEffect(() => {
    if (startValue < endValue) {
      setError(false);
    } else {
      if (moment(endValue).format("hh:mm:ss a") === "12:00:00 am") {
        setError(false);
      } else {
        setError(true);
      }
    }
  }, [startValue, endValue]);

  const makeRecruiterData = (data) => {
    let list = [];
    data.Content.map((el) => {
      if (el.isActive == 1) {
        list.push({
          id: el.recruiterId,
          name: ` ${el.firstName} ${el.lastName ? " " + el.lastName : ""}`,
          phoneNumber: el.phoneNumber,
        });
      }
      return true;
    });
    return list;
  };

  const assinAPI = () => {
    let url = "";
    setLoading(true);

    if (tabData) {
      url = `${UrlLinks.baseUrl}${
        UrlLinks.recruiter.assignToRecruiter
      }?insertedBy=${localStorage.getItem(
        "userID"
      )}&partnerId=${partner}&recruiterId=${
        recruiterAssigned.id
      }&userIds=${userIds}&shift_start_time=${moment(
        startValue,
        "h:mm:ss A"
      ).format("HH:mm:ss")}&shift_end_time=${moment(
        endValue,
        "h:mm:ss A"
      ).format("HH:mm:ss")}`;
    } else {
      url = `${UrlLinks.baseUrl}${
        UrlLinks.recruiter.assignToRecruiterLead
      }?insertedBy=${localStorage.getItem("userID")}&recruiterId=${
        recruiterAssigned.id
      }&userIds=${userIds}`;
    }

    Axios.post(url)
      .then((response) => {
        updateMyData();
        setRecruiterAssigned("");
        setResData(response.data.response.content);
        setOpenRes(true);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
      >
        <Box sx={style}>
          <p>
            <b>Assign to Recruiter</b>
            <IconButton
              onClick={handleClose}
              style={{ left: "225px", position: "relative" }}
            >
              <CancelIcon />
            </IconButton>
          </p>
          {error && <div style={{ color: "red" }}>Error in time</div>}
          <Stack spacing={3}>
            {tabData && (
              <FormControl fullWidth>
                <InputLabel
                  id="demo-simple-select-label"
                  style={{ marginLeft: "30px" }}
                >
                  Pitch for
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={partner}
                  label="Picth for"
                  onChange={(e) => {
                    setPartner(e.target.value);
                  }}
                  style={{ width: "100%", marginLeft: "30px" }}
                >
                  {menuItems}
                </Select>
              </FormControl>
            )}
            <Autocomplete
              id="ReAssign"
              value={recruiterAssigned}
              onChange={(e, newValue) => setRecruiterAssigned(newValue)}
              fullWidth
              options={recruiterOptions}
              getOptionLabel={(option) => option.name}
              renderOption={(option) => (
                <React.Fragment>
                  {option.id} - {option.name}{" "}
                  {option.phoneNumber ? " - " + option.phoneNumber : ""}
                </React.Fragment>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Recruiter"
                  size="small"
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
            />
            {tabData && (
              <div>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <TimePicker
                    style={{ width: "150px" }}
                    // fullWidth={false}
                    className={classes.inputTime}
                    label="Start Time"
                    value={startValue}
                    onChange={handleChangeStart}
                    renderInput={(params) => <TextField {...params} />}
                  />
                  <TimePicker
                    style={{ width: "150px", marginLeft: "100px" }}
                    className={classes.inputTime}
                    label="End Time"
                    value={endValue}
                    onChange={handleChangeEnd}
                    minTime={startValue}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </MuiPickersUtilsProvider>
              </div>
            )}
          </Stack>
          <Button
            variant="contained"
            className="dataButton"
            style={{ marginTop: "30px", marginLeft: "155px" }}
            onClick={assinAPI}
            disabled={
              (tabData
                ? !(
                    recruiter !== null &&
                    startValue !== null &&
                    endValue !== null &&
                    !error
                  )
                : !(recruiter !== null && !error)) && loading
            }
          >
            {loading ? "Please wait..." : "Assign"}
          </Button>
        </Box>
      </Modal>
      <AssignToRecruiterRes
        open={openRes}
        resData={resData}
        handleClose={handleClose}
      ></AssignToRecruiterRes>
    </div>
  );
}
