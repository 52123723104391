import React, {
  useState,
  useEffect,
  useMemo,
  useContext,
  forwardRef,
  useRef,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../store/actions/index";
import styled from "styled-components";
import {
  Button,
  Grid,
  Checkbox,
  FormControl,
  Select,
  MenuItem,
  Drawer,
} from "@material-ui/core";
import { useTable, useRowSelect, useSortBy, useFilters } from "react-table";
import urlLinks from "../../UrlLinks";
import swal from "sweetalert2";
import Axios from "axios";
import Modal from "@material-ui/core/Modal";
import OnboardingModalV2 from "./components/onboardingModalV2";
import Pagination from "../../Utils/Pagination";
import { makeStyles } from "@material-ui/core/styles";
import * as moment from "moment";
import { numberFormat } from "../../Utils/CommonFunctions";
import NoRecord from "./Assets/noRecord.svg";
import styles from "./index.module.scss";
import ScaleLoader from "react-spinners/ScaleLoader";
import Calendar from "../../assets/calendar.svg";
import style from "./index.module.scss";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { createVerificationStatus } from "../../services/CommonServices";
import { AuthContext } from "../../services/AuthProvider";
import SendMsg from "./components/SendMsg";
import LastSentMsgs from "../WorkerData/components/LastSentMsgs";
import ConfirmTriggerPaymentModal from "./components/ConfirmTriggerPaymentModal";
import Demo from "./demo.tsx";
import RejectModal from "./components/RejectModal";
import OnHoldRemarkModal from "./components/OnHoldRemarkModal";
import CancelModal from "./components/CancelModal";
import "./indeterminateCheckbox.css";
import EarningModal from "./components/EarningModal";
import SearchBar from "../../Utils/functions/SearchBar";
import filterIcon from "./Assets/filterIcon.svg";
import filterIconMarked from "./Assets/filterIconMarked.svg";
import FilterDrawer from "./components/FilterDrawer";
import RemoveExtraSpaces from "../../Utils/functions/RemoveExtraSpaces";
import ExportButton from "../../Utils/functions/ExportButton";

const Styles = styled.div`
  padding: 1rem;
  .tableContainer {
    overflow-x: scroll;
    border: 1px solid #cfcfcf;
    max-height: 74vh;
  }
  .tableContainer::-webkit-scrollbar {
    width: 0.75em;
  }

  .tableContainer::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  .tableContainer::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    //   border-radius: 8px;
  }
  table {
    border-spacing: 0;
    width: max-content;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    th {
      :last-child {
        border-left: 0;
      }
      background-color: #1c75bc !important;
      color: #ffffff;
    }
    th,
    td {
      padding: 5px 15px;
    }

    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid #cfcfcf;
      border-right: 1px solid #cfcfcf;
      background-color: white;
      nth-last-child(2) {
        border-right: 0;
      }
      :last-child {
        border-right: 0;
      }

      input {
        font-size: 1rem;
        padding: 0;
        margin: 0;
        border: 0;
      }
    }
  }

  .pagination {
    padding: 0.5rem;
  }
`;

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const IndeterminateCheckbox = forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <React.Fragment>
      <Checkbox ref={resolvedRef} {...rest} />
    </React.Fragment>
  );
});

const ExternalWorker = () => {
  /** accessing states from redux store **/

  const { users } = useSelector((state) => state);
  const {
    loader,
    modalLoader,
    currentPage,
    perPage,
    totalRows,
    totalPages,
    isFilter,
    selectedUserIds,
    openFilterDrawer,
    allRecord,
  } = users;

  /** useDispatch is used to update states to redux store **/

  const dispatch = useDispatch();
  const {
    setLoader,
    setModalLoader,
    setCurrentPage,
    setPerPage,
    setTotalRows,
    setTotalPages,
    setIsFilter,
    setSelectedUserIds,
    setOpenFilterDrawer,
    //  setTabValue,
    setAllRecord,
  } = bindActionCreators(actionCreators, dispatch);

  /** other states **/

  const classes = useStyles();
  const [data, setData] = useState([]);
  const [dataBalance, setDataBalance] = useState();
  const { showToast } = useContext(AuthContext);
  const [openSendMsg, setOpenSendMsg] = useState(false);
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [allRecordLoading, setAllRecordLoading] = useState(false);
  const [openOnboardingModalV2, setOpenOnboardingModalV2] = useState(false);
  const [disablePayments, setDisablePayments] = useState(true);
  const [open, setOpen] = useState(false);
  const [searchBy, setSearchBy] = useState("");
  const [searchByAuto, setSearchByAuto] = useState("");
  const [financialAdmin, setFinancialAdmin] = useState(false);
  const [filterUrl, setFilterUrl] = useState("");
  const [selectedOGIds, setSelectedOGIds] = useState("");
  const [totalNetPayableAmount, setTotalNetPayableAmount] = useState("");
  const [openTriggerPaymentPopup, setOpenTriggerPaymentPopup] = useState([
    false,
    false,
  ]);
  const [openRejectInvoicePopup, setOpenRejectInvoicePopup] = useState(false);
  const [openOnHoldPopup, setOpenOnHoldPopup] = useState(false);
  const [disFilterUrl, setDisFilterUrl] = useState("");
  const [clearFilter, setClearFilter] = useState(false);
  const [mainFilterOption, setMainFilterOption] = useState(-1);
  const [actionOption, setActionOption] = useState(0);
  const [searchError, setSearchError] = useState(false);
  const [isRecruiterData, setIsRecruiterData] = useState("");
  const [openViewModal, setOpenViewModal] = useState(false);
  const [financialYearList, setFinancialYearList] = useState([]);
  const [tab, setTab] = useState({
    invoiceTab: false,
    transactionTab: true,
    workerDataTab: true,
    recruiterDataTab: false,
  });
  const [selectedOption, setSelectedOption] = useState({
    id: "",
    name: "",
    val: "",
  });
  const [filters, setFilters] = useState({
    searchBy: "",
    advanceTaken: "",
    billDate: [null, null],
    clientId: "",
    facility_name: "",
    facility_state: "",
    invoiceDate: [null, null],
    modalFlag: "",
    paymentDate: [null, null],
    payment_stage: null,
    payment_status: "",
    payment_type: "",
    verification_type: "",
    worker_status: "",
    worker_type: "",
    zone: "",
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const resetFiltersWithoutSearch = () => {
    setIsFilter(false);
    const filterList = {
      searchBy: filters.searchBy,
      advanceTaken: "",
      billDate: filters.billDate,
      clientId: "",
      facility_name: "",
      facility_state: "",
      invoiceDate: [null, null],
      modalFlag: "",
      paymentDate: [null, null],
      payment_stage: null,
      payment_status: "",
      payment_type: "",
      verification_type: "",
      worker_status: "",
      worker_type: "",
      zone: "",
    };

    if (openFilterDrawer) {
      let resetFilterUrl = "";

      if (filters["searchBy"])
        resetFilterUrl = `${resetFilterUrl}&searchBy=${filters["searchBy"]}`;
      if (filters["billDate"][0] && !filters["billDate"][1])
        resetFilterUrl = `${resetFilterUrl}&billFrom=${moment(
          filters["billDate"][0]
        ).format("YYYY-MM-DD")}&billTo=${moment(new Date()).format(
          "YYYY-MM-DD"
        )} 23:59:59`;
      if (!filters["billDate"][0] && filters["billDate"][1])
        resetFilterUrl = `${resetFilterUrl}&billFrom=2019-01-01&billTo=${moment(
          filters["billDate"][1]
        ).format("YYYY-MM-DD")} 23:59:59`;
      if (filters["billDate"][0] && filters["billDate"][1])
        resetFilterUrl = `${resetFilterUrl}&billFrom=${moment(
          filters["billDate"][0]
        ).format("YYYY-MM-DD")}&billTo=${moment(filters["billDate"][1]).format(
          "YYYY-MM-DD"
        )} 23:59:59`;

      setFilterUrl(resetFilterUrl);
    }

    setFilters(filterList);
    setCurrentPage(1);
    return filterList;
  };

  const handleSearch = () => {
    setFilters({ ...filters, searchBy: searchBy });
    setSearchByAuto(searchBy);
  };

  const handleSearchClose = () => {
    setFilters({ ...filters, searchBy: "" });
    setSearchBy("");
    setSearchByAuto("");
  };

  const updateFilterUrl = () => {
    let url = "";

    // Search By
    if (filters["searchBy"]) url = `${url}&searchBy=${filters["searchBy"]}`;

    // Advance Taken
    if (filters["advanceTaken"] || filters["advanceTaken"].id === 0) {
      url = `${url}&advanceTaken=${filters["advanceTaken"].id}`;
    }

    // Bill Date
    if (filters["billDate"][0] && !filters["billDate"][1])
      url = `${url}&billFrom=${moment(filters["billDate"][0]).format(
        "YYYY-MM-DD"
      )}&billTo=${moment(new Date()).format("YYYY-MM-DD")} 23:59:59`;
    if (!filters["billDate"][0] && filters["billDate"][1])
      url = `${url}&billFrom=2019-01-01&billTo=${moment(
        filters["billDate"][1]
      ).format("YYYY-MM-DD")} 23:59:59`;
    if (filters["billDate"][0] && filters["billDate"][1])
      url = `${url}&billFrom=${moment(filters["billDate"][0]).format(
        "YYYY-MM-DD"
      )}&billTo=${moment(filters["billDate"][1]).format(
        "YYYY-MM-DD"
      )} 23:59:59`;

    // Client Id
    if (filters["clientId"]) {
      url = `${url}&partnerId=${filters["clientId"].id}`;
    }

    // Facility Name
    if (filters["facility_name"]) {
      url = `${url}&facility_name=${filters["facility_name"].name}`;
    }

    // Facility State
    if (filters["facility_state"]) {
      url = `${url}&facility_state=${filters["facility_state"].name}`;
    }

    // Invoice Date
    if (filters["invoiceDate"][0] && !filters["invoiceDate"][1])
      url = `${url}&invoiceFrom=${moment(filters["invoiceDate"][0]).format(
        "YYYY-MM-DD"
      )}&invoiceTo=${moment(new Date()).format("YYYY-MM-DD")} 23:59:59`;
    if (!filters["invoiceDate"][0] && filters["invoiceDate"][1])
      url = `${url}&invoiceFrom=2019-01-01&invoiceTo=${moment(
        filters["invoiceDate"][1]
      ).format("YYYY-MM-DD")} 23:59:59`;
    if (filters["invoiceDate"][0] && filters["invoiceDate"][1])
      url = `${url}&invoiceFrom=${moment(filters["invoiceDate"][0]).format(
        "YYYY-MM-DD"
      )}&invoiceTo=${moment(filters["invoiceDate"][1]).format(
        "YYYY-MM-DD"
      )} 23:59:59`;

    // Modal Flag
    if (filters["modalFlag"] || filters["modalFlag"].id === 0) {
      url = `${url}&is_lma_worker=${filters["modalFlag"].id}`;
    }

    // Payment Date
    if (filters["paymentDate"][0] && !filters["paymentDate"][1])
      url = `${url}&dateFrom=${moment(filters["paymentDate"][0]).format(
        "YYYY-MM-DD"
      )}&dateTo=${moment(new Date()).format("YYYY-MM-DD")} 23:59:59`;
    if (!filters["paymentDate"][0] && filters["paymentDate"][1])
      url = `${url}&dateFrom=2019-01-01&dateTo=${moment(
        filters["paymentDate"][1]
      ).format("YYYY-MM-DD")} 23:59:59`;
    if (filters["paymentDate"][0] && filters["paymentDate"][1])
      url = `${url}&dateFrom=${moment(filters["paymentDate"][0]).format(
        "YYYY-MM-DD"
      )}&dateTo=${moment(filters["paymentDate"][1]).format(
        "YYYY-MM-DD"
      )} 23:59:59`;

    // Payment Stage
    if (
      filters["payment_stage"] !== undefined &&
      filters["payment_stage"] !== null
    ) {
      url = `${url}&payment_stage=${filters["payment_stage"]}`;
    }

    // Payment Status
    if (filters["payment_status"]) {
      url = `${url}&payment_status=${filters["payment_status"].id}`;
    }

    // Payment Type
    if (filters["payment_type"]) {
      url = `${url}&paymentType=${filters["payment_type"].id}`;
    }

    // Verification Type
    if (filters["verification_type"]) {
      if (filters["verification_type"].id == 0) {
        url = `${url}&verificationType=${filters["verification_type"].id}&verificationSubType=0`;
      } else if (filters["verification_type"].id == 6) {
        url = `${url}&verificationType=0&verificationSubType=1`;
      } else {
        url = `${url}&verificationType=${filters["verification_type"].id}`;
      }
    }

    // Worker Status
    if (filters["worker_status"]) {
      url = `${url}&worker_status=${filters["worker_status"].id}`;
    }

    // Worker Type
    if (filters["worker_type"] || filters["worker_type"].id === 0) {
      url = `${url}&isGold=${filters["worker_type"].id}`;
    }

    // Zone
    if (filters["zone"]) {
      url = `${url}&zone=${filters["zone"].id}`;
    }

    return url;
  };

  const ApplyFilters = () => {
    let url = updateFilterUrl();
    setFilterUrl(url);
    setFilters(filters);
    setCurrentPage(1);
  };

  const handleActions = () => {
    setOpenOnboardingModalV2(true);
  };

  const handleOnHold = (onHold, remark) => {
    let InvoiceId = makeInvoiceIdsList();
    if (onHold) {
      Axios.post(
        `${urlLinks.baseUrl}${
          urlLinks.paymentUrls.paymentunHold
        }?requested_by=${localStorage.getItem(
          "userID"
        )}&invoice_ids=${InvoiceId}${remark ? "&remark=" + remark : ""}`
      )
        .then((response) => {
          if (response) {
            swal.fire({
              title: `Successfully ${makePaymentStage(101)}`,
              icon: "success",
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Okay",
            });
            updateMyData();
          }
        })
        .catch((error) => {
          showToast("error", error.message);
        });
    } else {
      Axios.post(
        `${urlLinks.baseUrl}${
          urlLinks.paymentUrls.paymentOnHold
        }?requested_by=${localStorage.getItem(
          "userID"
        )}&invoice_ids=${InvoiceId}${remark ? "&remark=" + remark : ""}`
      )
        .then((response) => {
          if (response) {
            swal.fire({
              title: `Successfully ${makePaymentStage(10)}`,
              icon: "success",
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Okay",
            });
            updateMyData();
          }
        })
        .catch((error) => {
          showToast("error", error.message);
        });
    }
  };

  const columnsOkayGo = useMemo(
    () => [
      {
        id: "selection",
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <div>
            <IndeterminateCheckbox
              {...getToggleAllRowsSelectedProps()}
              className="overrideCheckbox"
              color="white"
            />
          </div>
        ),
        Cell: ({ row }) => {
          return (
            <>
              {(mainFilterOption === 1 || mainFilterOption === 8) &&
              localStorage.getItem("roleType") === "6" ? (
                <div>
                  {row.original.invoiceFilePath !== "" && (
                    <div>
                      <IndeterminateCheckbox
                        {...row.getToggleRowSelectedProps()}
                        style={
                          row.original.invoiceFilePath === ""
                            ? {}
                            : { display: "flex", justifyItems: "center" }
                        }
                        disabled={row.original.invoiceFilePath === ""}
                      />
                    </div>
                  )}
                </div>
              ) : (
                <div>
                  <IndeterminateCheckbox
                    {...row.getToggleRowSelectedProps()}
                    style={{ display: "flex", justifyItems: "center" }}
                  />
                </div>
              )}
            </>
          );
        },
      },
      {
        Header: "Project Name",
        accessor: "projectName",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Client id(s)",
        Cell: (row) => {
          return (
            <div>
              {row.row.original.clientId && (
                <div>
                  <b>{row.row.original.source} </b>
                  {row.row.original.clientId}
                </div>
              )}
            </div>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "OkayGo id",
        accessor: "okaygoId",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Client",
        accessor: "source",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Invoice Status",
        accessor: "payment_stage",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Remark",
        accessor: "remark",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Modal",
        accessor: "lmaEflexGroupFlex",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Name",
        accessor: "name",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Contact No",
        accessor: "contactNo",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Last SMS sent",
        Cell: LastSentMsgs,
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "PAN No",
        accessor: "panNumber",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Bank Details filled",
        accessor: "bankDetailsFilled",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Bank details verification",
        accessor: "pennyStatus",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Invoice From",
        accessor: "billFrom",
        defaultCanFilter: false,
        defaultCanSort: false,
        isVisible: true,
      },
      {
        Header: "Invoice To",
        accessor: "billTo",
        defaultCanFilter: false,
        defaultCanSort: false,
        isVisible: false,
      },
      {
        Header: "Invoice Date",
        accessor: "billDate",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Invoice No",
        accessor: "billNo",
        Cell: (row) => {
          return row.row.original.invoiceFilePath ? (
            <a
              href={row.row.original.invoiceFilePath}
              download
              className={style.downloadTemplate}
            >
              <span style={{ color: "#1c75bc" }}>
                {row.row.original.billNo}
              </span>
            </a>
          ) : (
            <span></span>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Shipment Qty (from daily data)",
        accessor: "calc_shipment_qty",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Shipment Qty (Uploaded)",
        accessor: "shipmentQuantity",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Rate Card",
        accessor: "rateCard",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Unit rate applicable (calc)",
        accessor: "unitPrice",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Shipment Amount (Calc)",
        accessor: "calc_shipment_amount",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Shipment Amount (Upload)",
        accessor: "shipmentAmount",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "MG Contribution",
        accessor: "mg_amount",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Surge Charges",
        accessor: "surgeCharges",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Accidental Insurance",
        accessor: "accidentalInsurance",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Medical Insurance",
        accessor: "medicalInsurance",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Recovery",
        accessor: "adjustments",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "EMI Deduction",
        accessor: "emiDeduction",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "TDS",
        accessor: "tdsDeduction",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Total Worker Payable Amount (calc)",
        accessor: "calc_net_payable_amount",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Total Worker Payable Amount (Upload)",
        accessor: "netPayableAmount",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Net Payable Amount",
        accessor: "totalAmount",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Advance Taken",
        accessor: "advancePayment",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "OkayGo Payout (uploaded)",
        accessor: "og_payout",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Margin Value",
        accessor: "og_margin",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Margin %",
        accessor: "marginPercent",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Margin Type (as per database)",
        accessor: "margin_type",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Expected Margin (as per database)",
        accessor: "expected_margin",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Payment Status",
        accessor: "paymentStatus",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Failed/Reversed Reason",
        accessor: "razorpayReason",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Payment Date",
        accessor: "paymentDate",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Financial Earning",
        Cell: (row) => {
          return (
            <div>
              <Button
                className={`blueColorOutlined`}
                style={{ textTransform: "capitalize", padding: "4px 8px" }}
                onClick={(e) => handleUserModal(e, row.row.original, "earning")}
              >
                View
              </Button>
            </div>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
    ],
    [filters, filterUrl, perPage, currentPage, mainFilterOption]
  );

  const recruiterInvoiceCols = useMemo(
    () => [
      {
        id: "selection",
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <div>
            <IndeterminateCheckbox
              {...getToggleAllRowsSelectedProps()}
              className="overrideCheckbox"
              color="white"
            />
          </div>
        ),
        Cell: ({ row }) => {
          return (
            <>
              {(mainFilterOption === 1 || mainFilterOption === 8) &&
              localStorage.getItem("roleType") === "6" ? (
                <div>
                  {row.original.invoiceFilePath !== "" && (
                    <div>
                      <IndeterminateCheckbox
                        {...row.getToggleRowSelectedProps()}
                        style={
                          row.original.invoiceFilePath === ""
                            ? {}
                            : { display: "flex", justifyItems: "center" }
                        }
                        disabled={row.original.invoiceFilePath === ""}
                      />
                    </div>
                  )}
                </div>
              ) : (
                <div>
                  <IndeterminateCheckbox
                    {...row.getToggleRowSelectedProps()}
                    style={{ display: "flex", justifyItems: "center" }}
                  />
                </div>
              )}
            </>
          );
        },
      },
      {
        Header: "Recruiter ID",
        accessor: "okaygoId",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Client",
        accessor: "source",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Invoice Status",
        accessor: "payment_stage",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Remark",
        accessor: "remark",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Name",
        accessor: "name",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Contact No",
        accessor: "contactNo",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "PAN No",
        accessor: "panNumber",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "PAN Verification Status",
        accessor: "panVerification",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Bank Details filled",
        accessor: "bankDetailsFilled",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Bank Details Verification",
        accessor: "pennyStatus",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Invoice Cycle (from)",
        accessor: "billFrom",
        defaultCanFilter: false,
        defaultCanSort: false,
        isVisible: true,
      },
      {
        Header: "Invoice Cycle (to)",
        accessor: "billTo",
        defaultCanFilter: false,
        defaultCanSort: false,
        isVisible: false,
      },
      {
        Header: "Invoice No",
        accessor: "billNo",
        Cell: (row) => {
          return row.row.original.invoiceFilePath ? (
            <a
              href={row.row.original.invoiceFilePath}
              download
              className={style.downloadTemplate}
            >
              <span style={{ color: "#1c75bc" }}>
                {row.row.original.billNo}
              </span>
            </a>
          ) : (
            <span></span>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Service Charges",
        accessor: "shipmentAmount",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Surge Charges (incentives etc.)",
        accessor: "surgeCharges",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Deductions (TDS etc.)",
        accessor: "tdsAdjustments",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Net Payable Amount",
        accessor: "totalAmount",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Payment Date",
        accessor: "paymentDate",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Payment Status",
        accessor: "paymentStatus",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Failed/Reversed Reason",
        accessor: "razorpayReason",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
    ],
    [filters, filterUrl, perPage, currentPage, mainFilterOption]
  );

  const recruiterTransCols = useMemo(
    () => [
      {
        Header: "Recruiter ID",
        accessor: "okaygoId",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Name",
        accessor: "name",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Contact No",
        accessor: "contactNo",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Invoice No",
        accessor: "billNo",
        Cell: (row) => {
          return row.row.original.invoiceFilePath ? (
            <a
              href={row.row.original.invoiceFilePath}
              download
              className={style.downloadTemplate}
            >
              <span style={{ color: "#1c75bc" }}>
                {row.row.original.billNo}
              </span>
            </a>
          ) : (
            <span></span>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Invoice Cycle (from)",
        accessor: "billFrom",
        defaultCanFilter: false,
        defaultCanSort: false,
        isVisible: true,
      },
      {
        Header: "Invoice Cycle (to)",
        accessor: "billTo",
        defaultCanFilter: false,
        defaultCanSort: false,
        isVisible: false,
      },
      {
        Header: "Transaction Date",
        accessor: "paymentDate",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Total Amount",
        accessor: "totalAmount",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Transaction Status",
        accessor: "paymentStatus",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Transaction Mode",
        accessor: "paymentMode",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Transaction Remarks",
        accessor: "paymentRemarks",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Transaction ID",
        accessor: "transactionNo",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
    ],
    [filters, filterUrl, perPage, currentPage, mainFilterOption]
  );

  //columns list
  const transactionCols = useMemo(
    () => [
      {
        Header: "Client id",
        accessor: "casperId",
        defaultCanFilter: false,
        defaultCanSort: false,
        Cell: (row) => {
          return row.row.original.casperId ? (
            <div>
              <b>
                {`${
                  row.row.original.source ? row.row.original.source + " : " : ""
                }`}{" "}
              </b>{" "}
              &nbsp;{row.row.original.casperId}{" "}
            </div>
          ) : (
            <span></span>
          );
        },
      },
      {
        Header: "OkayGo id",
        accessor: "okaygoId",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Name",
        accessor: "name",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Contact No",
        accessor: "contactNo",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Invoice No",
        accessor: "billNo",
        Cell: (row) => {
          return row.row.original.invoiceFilePath ? (
            <a
              href={row.row.original.invoiceFilePath}
              download
              className={style.downloadTemplate}
            >
              <span style={{ color: "#1c75bc" }}>
                {row.row.original.billNo}
              </span>
            </a>
          ) : (
            <span></span>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Bill From",
        accessor: "billFrom",
        defaultCanFilter: false,
        defaultCanSort: false,
        isVisible: true,
      },
      {
        Header: "Bill To",
        accessor: "billTo",
        defaultCanFilter: false,
        defaultCanSort: false,
        isVisible: false,
      },
      {
        Header: "Okaygo Charges",
        accessor: "okaygoCharges",
        defaultCanFilter: false,
        defaultCanSort: false,
        isVisible: false,
      },
      {
        Header: "Paid Amount",
        accessor: "totalAmount",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Payment Type",
        accessor: "paymentType",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Transaction Date",
        accessor: "paymentDate",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Transaction Status",
        accessor: "paymentStatus",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Transaction Mode",
        accessor: "paymentMode",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Transaction Remarks",
        accessor: "paymentRemarks",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Transaction ID",
        accessor: "transactionNo",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
    ],
    []
  );

  const handleFilterDates = (date, index, type) => {
    let dates = filters.billDate;
    dates[index] = date;
    setFilters({ ...filters, [type]: dates });
    setCurrentPage(1);
  };

  //Function to create table data
  const makeData = (response) => {
    let extractedData = [];
    let lastupdatedon = [];

    response.content.map((el) => {
      const totalAmount = el.totalAmount;
      const recovery = el.recovery ? parseFloat(el.recovery) : 0;
      const emiDeduction = el.emiDeduction ? parseFloat(el.emiDeduction) : 0;
      const arrears = el.arrears ? parseFloat(el.arrears) : 0;
      const netPayable = totalAmount - recovery + arrears - emiDeduction;

      extractedData.push({
        lastSmsTemplate: el.lastSmsTemplate || "",
        lastSmsTime: el.lastSmsTime
          ? moment(el.lastSmsTime).format("hh:mm a, DD/MM/YYYY")
          : "",
        lastSmsMessage: el.lastSmsMessage || "",
        casperId: el.casperId || "",
        swiggyId: el.swiggyId || "",
        okaygoId: el.okaygoId || "",
        source: el.invoiceFor || "",
        payment_stage: el.payment_stage,
        remark: el.remarks || "",
        has_desc: el.has_desc ? (el.has_desc !== null ? el.has_desc : 0) : 0,
        employeeId: el.clientId || "",
        client: el.invoiceFor || "",
        clientEmpId: el.clientEmpId || "",
        projectName: el.projectName || "",
        clientId: el.invoiceFor === "Flipkart" ? el.casperId : el.clientId,
        delhiveryId: el.delhiveryId || null,
        jiomartId: el.jiomartId || null,
        panNumber: el.panNumber || "",
        panVerification:
          el.panVerificationStatus === 1
            ? "Yes"
            : el.panVerificationStatus === 0
            ? "No"
            : "",
        name: `${el.firstName ? el.firstName : ""} ${
          el.lastName ? el.lastName : ""
        }`,
        contactNo: el.phoneNumber || "",
        bankDetailsFilled: !el.accountNumber ? "No" : "Yes",
        billFrom: el.billFrom ? moment(el.billFrom).format("DD/MM/YYYY") : "",
        billTo: el.billTo ? moment(el.billTo).format("DD/MM/YYYY") : "",
        billDate: el.billDate ? moment(el.billDate).format("DD/MM/YYYY") : "",
        billNo: el.billNumber || "",
        netPayableAmount: el.netPayableAmount
          ? numberFormat(el.netPayableAmount)
          : "",
        netPayableAmountRaw: el.netPayableAmount ? el.netPayableAmount : 0,
        totalAmountRaw: el.totalAmount ? el.totalAmount : "",
        totalAmount: el.totalAmount ? numberFormat(el.totalAmount) : "",
        shipmentAmount: el.shipmentAmount
          ? numberFormat(parseFloat(el.shipmentAmount))
          : "",
        surgeCharges: el.surgeCharges
          ? numberFormat(parseFloat(el.surgeCharges))
          : "",
        accidentalInsurance: el.accidentalInsurance
          ? numberFormat(parseFloat(el.accidentalInsurance))
          : "",
        medicalInsurance: el.medicalInsurance
          ? numberFormat(parseFloat(el.medicalInsurance))
          : "",
        leaseCharges: el.leaseCharges
          ? numberFormat(parseFloat(el.leaseCharges))
          : "",
        advancePayment: el.advancePayment
          ? "- " + numberFormat(parseFloat(el.advancePayment))
          : "",
        advancePaymentRaw: el.advancePayment ? el.advancePayment : null,
        netPayable: numberFormat(netPayable),
        paymentDate: el.paymentDate
          ? moment(el.paymentDate).format("DD/MM/YYYY")
          : "",
        paymentStatus: el.paymentStatus || "",
        paymentRemarks: el.paymentRemarks || "",
        paymentMode: el.okaygoCharges ? "IMPS" : el.paymentStatus ? "NEFT" : "",
        transactionNo: el.transactionId || "",
        invoiceFilePath: el.invoiceFilePath || "",
        verificationStatus: createVerificationStatus(el.verificationStatus),
        status: el.verificationStatus,
        pennyStatus:
          el.verificationStatus === 1
            ? "Verified"
            : el.penny_testing_status !== null
            ? el.penny_testing_status === "Blank" ||
              el.penny_testing_status === "not verified"
              ? "-"
              : el.penny_testing_status
            : "-",
        adjustments: el.recovery
          ? `- ${numberFormat(parseFloat(el.recovery))}`
          : "",
        tdsAdjustments: el.tdsDeduction
          ? `- ${numberFormat(parseFloat(el.tdsDeduction))}`
          : "",
        emiDeduction: el.emiDeduction
          ? `- ${numberFormat(parseFloat(el.emiDeduction))}`
          : "- ₹0.00",
        longDistance: el.longDistance ? `${el.longDistance} km` : "",
        longDistanceAmount: el.longDistanceAmount
          ? numberFormat(parseFloat(el.longDistanceAmount))
          : "",
        penalty: el.penalty ? `- ${numberFormat(parseFloat(el.penalty))}` : "",
        accountNumber: el.accountNumber,
        lmaEflexGroupFlex:
          el.invoiceFor === "Flipkart"
            ? el.is_lma_worker
              ? "LMA"
              : "Eflex"
            : "",
        rateCard:
          el.is_lma_worker && el.invoiceFor === "Flipkart"
            ? el.lmaRateCard
            : !el.is_lma_worker && el.invoiceFor === "Flipkart"
            ? el.rateCard
            : "",
        payoutToOkayGo: el.okaygoPayout
          ? `${numberFormat(parseFloat(el.okaygoPayout))}`
          : "",
        marginPercent: el.margin ? `${el.margin.toFixed(2)} %` : "",
        queued: el.queued,
        userId: el.userId,
        workerId: el.workerId,
        invoiceId: el.invoiceId || "",
        isInvoiceCreated: el.isInvoiceCreated,
        transactionId: el.transactionId || "",
        paymentType:
          el.okaygoCharges !== null
            ? "Advance Payout"
            : el.weeklyPayment !== null
            ? "Weekly Payout"
            : "15 Day Payout",
        okaygoCharges: el.okaygoCharges ? numberFormat(el.okaygoCharges) : "",
        calc_shipment_qty: el.calc_shipment_qty || "",
        unitPrice: el.unitPrice ? numberFormat(el.unitPrice) : "",
        calc_shipment_amount: el.calc_shipment_amount
          ? numberFormat(el.calc_shipment_amount)
          : "",
        mg_amount: el.mg_amount ? numberFormat(el.mg_amount) : "",
        calc_net_payable_amount: el.calc_net_payable_amount
          ? numberFormat(el.calc_net_payable_amount)
          : "",
        og_margin: el.og_margin ? numberFormat(el.og_margin) : "",
        margin_type:
          el.margin_type !== null
            ? el.margin_type === 0
              ? "Fixed Amount"
              : el.margin_type === 1
              ? "Fixed %"
              : "Variable"
            : "",
        og_payout: el.og_payout ? numberFormat(el.og_payout) : "",
        expected_margin:
          el.expected_margin !== null
            ? el.margin_type !== null
              ? el.margin_type === 1
                ? parseFloat(el.expected_margin).toFixed(2) + " %"
                : numberFormat(el.expected_margin)
              : el.expected_margin
            : "",
        shipmentQuantity: el.shipmentQuantity ? el.shipmentQuantity : "",
        razorpayReason: el.razorpayReason ? el.razorpayReason : "",
        tdsDeduction: el.tdsDeduction ? `Rs. ${el.tdsDeduction}` : "",
      });
      lastupdatedon.push(el.lastUpdatedOn);
      return true;
    });

    return extractedData;
  };

  //Function to create All record export data
  const columns =
    !tab.invoiceTab && tab.workerDataTab
      ? columnsOkayGo
      : !tab.invoiceTab && tab.recruiterDataTab
      ? recruiterInvoiceCols
      : tab.recruiterDataTab && !tab.transactionTab
      ? recruiterTransCols
      : transactionCols;

  const updateMyData = () => {
    getPaymentListing(perPage, currentPage);
  };

  const getPaymentListing = (rows, pageNo) => {
    setAllRecord([]);
    setLoader(true);

    if (!tab.invoiceTab) {
      Axios.get(
        `${urlLinks.baseUrl}${
          urlLinks.paymentUrls.paymentListingMultiClientV2
        }?page_no=${pageNo - 1}&rows=${rows}${filterUrl}${disFilterUrl}${
          mainFilterOption !== -1 ? "&payment_stage=" + mainFilterOption : ""
        }&roleType=${localStorage.getItem("roleType")}${isRecruiterData}`
      )
        .then((response) => {
          if (response) {
            setTotalRows(response.data.response.totalElements);
            setTotalPages(response.data.response.totalPages);
            if (currentPage > response.data.response.totalPages)
              setCurrentPage(1);
            setData(makeData(response.data.response));
            setLoader(false);
          }
        })
        .catch((error) => {
          setLoader(false);
        });
    } else if (tab.invoiceTab) {
      Axios.post(
        `${urlLinks.baseUrl}/payments/listing/transactions?page_no=${
          pageNo - 1
        }&rows=${rows}${filterUrl}${isRecruiterData}`
      )
        .then((response) => {
          if (response) {
            setTotalRows(response.data.response.totalElements);
            setTotalPages(response.data.response.totalPages);
            if (currentPage > response.data.response.totalPages)
              setCurrentPage(1);
            setData(makeData(response.data.response));
            setLoader(false);
          }
        })
        .catch((error) => {
          showToast("error", error.message);
        });
    }
  };

  const handlePageChange = (page) => {
    getPaymentListing(perPage, page);
    setCurrentPage(page);
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    isAllRowsSelected,
    state: { selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        hiddenColumns: [""],
      },
      updateMyData,
      showToast,
      setLoader,
      filters,
      setFilters,
      manualSortBy: true,
    },
    useFilters,
    useSortBy,
    useRowSelect
  );

  const handlePerRowsChange = (newPerPage) => {
    getPaymentListing(newPerPage, 1);
    setPerPage(newPerPage);
    setCurrentPage(1);
  };

  // for invoice data export
  const makeAllRecordData = (response) => {
    let extractedData = [];
    response.map((el) => {
      let temp;
      if (!tab.invoiceTab && tab.recruiterDataTab) {
        temp = {
          recruiterId: el.okaygoId || "",
          client: el.invoiceFor || "",
          Invoice_status: el.payment_stage || "",
          remark: el.remarks || "",
          name: `${el.firstName} ${el.lastName ? el.lastName : ""}`,
          contactNo: el.phoneNumber || "",
          "Pan Number": el.panNumber || "",
          "Pan Verification Status":
            el.panVerificationStatus === 1 ? "Yes" : "No",
          bankDetailsFilled: !el.accountNumber ? "No" : "Yes",
          bankDetailsVerification:
            el.penny_testing_status !== null
              ? el.penny_testing_status === "Blank"
                ? "-"
                : el.penny_testing_status
              : "-",
          InvoiceFrom: el.billFrom
            ? moment(el.billFrom).format("DD/MM/YYYY")
            : "",
          InvoiceTo: el.billTo ? moment(el.billTo).format("DD/MM/YYYY") : "",
          InvoiceNo: el.billNumber || "",
          "Service Charges": el.shipmentAmount
            ? numberFormat(parseFloat(el.shipmentAmount))
            : "",
          surgeCharges: el.surgeCharges
            ? parseFloat(el.surgeCharges).toFixed(2)
            : "",
          Deductions: el.tdsDeduction
            ? "- " + numberFormat(parseFloat(el.tdsDeduction))
            : "",
          "Net Payable Amount": el.totalAmount
            ? numberFormat(el.totalAmount)
            : "",
          paymentDate: el.paymentDate
            ? moment(el.paymentDate).format("DD/MM/YYYY")
            : "",
          paymentStatus: el.paymentStatus || "",
          "Failed/Reversed Reason": el.razorpayReason ? el.razorpayReason : "",
        };
      } else {
        temp = {
          projectName: el.projectName ? `${el.projectName}` : "",
          clientId: el.casperId ? `${el.casperId}` : "",
          okaygoId: el.okaygoId || "",
          client: el.invoiceFor || "",
          Invoice_status: el.payment_stage || "",
          remark: el.remarks || "",
          Model:
            el.invoiceFor === "Flipkart"
              ? el.is_lma_worker
                ? "LMA"
                : "Eflex"
              : "",
          name: `${el.firstName} ${el.lastName ? el.lastName : ""}`,
          contactNo: el.phoneNumber || "",
          bankDetailsFilled: !el.accountNumber ? "No" : "Yes",
          bankDetailsVerification:
            el.penny_testing_status !== null
              ? el.penny_testing_status === "Blank"
                ? "-"
                : el.penny_testing_status
              : "-",
          InvoiceFrom: el.billFrom
            ? moment(el.billFrom).format("DD/MM/YYYY")
            : "",
          InvoiceTo: el.billTo ? moment(el.billTo).format("DD/MM/YYYY") : "",
          InvoiceDate: el.billDate
            ? moment(el.billDate).format("DD/MM/YYYY")
            : "",
          InvoiceNo: el.billNumber || "",
          "Shipment Qty (from daily data)": el.calc_shipment_qty || "",
          "Shipment Qty (Uploaded)": el.shipmentQuantity
            ? el.shipmentQuantity
            : "",
          rateCard:
            el.is_lma_worker && el.invoiceFor === "Flipkart"
              ? el.lmaRateCard
              : !el.is_lma_worker && el.invoiceFor === "Flipkart"
              ? el.rateCard
              : "",
          "Unit rate applicable (calc)": el.unitPrice
            ? numberFormat(el.unitPrice)
            : "",
          "Shipment Amount (Calc)": el.calc_shipment_amount
            ? numberFormat(el.calc_shipment_amount)
            : "",
          "Shipment Amount (Upload)": el.shipmentAmount
            ? parseFloat(el.shipmentAmount).toFixed(2)
            : "",
          "MG Contribution": el.mg_amount ? numberFormat(el.mg_amount) : "",
          surgeCharges: el.surgeCharges
            ? parseFloat(el.surgeCharges).toFixed(2)
            : "",
          "Accidental Insurance": el.accidentalInsurance
            ? parseFloat(el.accidentalInsurance).toFixed(2)
            : "",
          "Medical Insurance": el.medicalInsurance
            ? parseFloat(el.medicalInsurance).toFixed(2)
            : "",
          "Order Incentive": el.orderIncentive
            ? parseFloat(el.orderIncentive).toFixed(2)
            : "",
          "Attendance Incentive": el.attendanceIncentive
            ? parseFloat(el.attendanceIncentive).toFixed(2)
            : "",
          Bonus: el.bonus ? parseFloat(el.bonus).toFixed(2) : "",
          "Long Distance Amount": el.longDistanceAmount
            ? parseFloat(el.longDistanceAmount).toFixed(2)
            : "",
          "Petrol Incentive": el.petrolIncentive
            ? parseFloat(el.petrolIncentive).toFixed(2)
            : "",
          "Security Deposit Deduction": el.securityDepositDeduction
            ? `-${parseFloat(el.securityDepositDeduction).toFixed(2)}`
            : "",
          "Reusable Bag/Raincoat/Winter Jacket Deduction":
            el.bagRaincoatJacketDeduction
              ? `-${parseFloat(el.bagRaincoatJacketDeduction).toFixed(2)}`
              : "",
          "COD Deduction": el.codDeduction
            ? `-${parseFloat(el.codDeduction).toFixed(2)}`
            : "",
          "TDS Deduction": el.tdsDeduction
            ? `-${parseFloat(el.tdsDeduction).toFixed(2)}`
            : "",
          recovery: el.recovery ? -parseFloat(el.recovery).toFixed(2) : "",
          emiDeduction: el.emiDeduction
            ? -parseFloat(el.emiDeduction).toFixed(2)
            : "",
          "Total Worker Payable Amount (calc)": el.calc_net_payable_amount
            ? numberFormat(el.calc_net_payable_amount)
            : "",
          "Total Worker Payable Amount (Upload)": el.netPayableAmount
            ? numberFormat(el.netPayableAmount)
            : "",
          "Net Payable Amount": el.totalAmount
            ? numberFormat(el.totalAmount)
            : "",
          "Advance Taken": el.advancePayment
            ? "- " + numberFormat(parseFloat(el.advancePayment))
            : "",
          "OkayGo Payout (uploaded)": el.og_payout
            ? numberFormat(el.og_payout)
            : "",
          "Margin Value": el.og_margin ? numberFormat(el.og_margin) : "",
          "Margin %": el.margin ? `${el.margin.toFixed(2)} %` : "",
          "Margin Type (as per database)":
            el.margin_type !== null
              ? el.margin_type === 0
                ? "Fixed Amount"
                : el.margin_type === 1
                ? "Fixed %"
                : "Variable"
              : "",
          "Expected Margin (as per database)":
            el.expected_margin !== null
              ? el.margin_type !== null
                ? el.margin_type === 1
                  ? parseFloat(el.expected_margin).toFixed(2) + " %"
                  : numberFormat(el.expected_margin)
                : el.expected_margin
              : "",
          paymentStatus: el.paymentStatus || "",
          "Faiiled/Reversed Reason": el.razorpayReason ? el.razorpayReason : "",
          paymentDate: el.paymentDate
            ? moment(el.paymentDate).format("DD/MM/YYYY")
            : "",
          panNumber: el.panNumber || "",
        };
      }
      extractedData.push(temp);
      return true;
    });

    return extractedData;
  };

  //for transaction export
  const makeAllTransactionRecordData = (response) => {
    let extractedData = [];
    response.map((el) => {
      let temp;
      if (!tab.transactionTab && tab.recruiterDataTab) {
        temp = {
          recruiterId: el.okaygoId || "",
          name: `${el.firstName} ${el.lastName ? el.lastName : ""}`,
          contactNo: el.phoneNumber || "",
          InvoiceNo: el.billNumber || "",
          InvoiceFrom: el.billFrom
            ? moment(el.billFrom).format("DD/MM/YYYY")
            : "",
          InvoiceTo: el.billTo ? moment(el.billTo).format("DD/MM/YYYY") : "",
          TransactionDate: el.paymentDate
            ? moment(el.paymentDate).format("DD/MM/YYYY")
            : "",
          totalAmount: el.totalAmount ? numberFormat(el.totalAmount) : "",
          TransactionStatus: el.paymentStatus || "",
          TransactionMode: el.okaygoCharges
            ? "IMPS"
            : el.paymentStatus
            ? "NEFT"
            : "",
          TransactionRemarks: el.paymentRemarks || "",
          "Transaction Id": el.transactionId || "",
        };
      } else {
        temp = {
          clientId: el.casperId
            ? el.invoiceFor
              ? `${el.invoiceFor}: ${el.casperId}`
              : el.casperId
            : "",
          okaygoId: el.okaygoId || "",
          name: `${el.firstName} ${el.lastName ? el.lastName : ""}`,
          contactNo: el.phoneNumber || "",
          InvoiceNo: el.billNumber || "",
          billFrom: el.billFrom ? moment(el.billFrom).format("DD/MM/YYYY") : "",
          billTo: el.billTo ? moment(el.billTo).format("DD/MM/YYYY") : "",
          okaygoCharges: el.okaygoCharges ? numberFormat(el.okaygoCharges) : "",
          paymentType:
            el.okaygoCharges !== null ? "Advance Payout" : "15 Day payout",
          TransactionDate: el.paymentDate
            ? moment(el.paymentDate).format("DD/MM/YYYY")
            : "",
          totalAmount: el.totalAmount ? numberFormat(el.totalAmount) : "",
          TransactionStatus: el.paymentStatus || "",
          TransactionMode: el.okaygoCharges
            ? "IMPS"
            : el.paymentStatus
            ? "NEFT"
            : "",
          TransactionRemarks: el.paymentRemarks || "",
          transactionId: el.transactionId || "",
        };
      }

      extractedData.push(temp);
      return true;
    });

    return extractedData;
  };

  const createAllRecordExcel = () => {
    setAllRecordLoading(true);
    if (!tab.invoiceTab) {
      Axios.get(
        `${urlLinks.baseUrl}${
          urlLinks.paymentUrls.paymentListingMultiClientV2
        }?exportExcel=1${filterUrl}${isRecruiterData}${disFilterUrl}${
          mainFilterOption !== -1 ? "&payment_stage=" + mainFilterOption : ""
        }&roleType=${localStorage.getItem("roleType")}` +
          (searchBy ? `&searchBy=${searchBy}` : "")
      ).then((response) => {
        if (response) {
          if (!tab.invoiceTab) {
            setAllRecord(makeAllRecordData(response.data.response));
          } else if (!tab.transactionTab) {
            setAllRecord(makeAllTransactionRecordData(response.data.response));
          }
        }
      });
    } else if (tab.invoiceTab) {
      Axios.post(
        `${urlLinks.baseUrl}${urlLinks.paymentUrls.paymentListing}/transactions?pageRequest=0${filterUrl}${isRecruiterData}`
      ).then((response) => {
        if (response) {
          if (!tab.invoiceTab) {
            setAllRecord(makeAllRecordData(response.data.response));
          } else {
            setAllRecord(makeAllTransactionRecordData(response.data.response));
          }
        }
      });
    }
  };

  const loaderBar = (
    <div style={{ margin: "130px auto" }}>
      <ScaleLoader height={70} width={10} color={"#1c75bc"} />
    </div>
  );

  const table = (
    <div className={`tableContainer ${style.tableContainer}`}>
      <table {...getTableProps()} className="table">
        <thead className="thead">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps()}
                  className={
                    column.render("Header") === "Worker Id"
                      ? "stickWorkerIdnewMatch zIndex1060 header"
                      : column.render("Header") === "Name" &&
                        tab.transactionTab === true
                      ? "stickPaymentNameV2 zIndex1060 header"
                      : (column.render("Header") === "OkayGo id" &&
                          tab.transactionTab === false) ||
                        (column.render("Header") === "Recruiter ID" &&
                          tab.transactionTab === false)
                      ? "stickOkayGoId zIndex1060 header"
                      : column.render("Header") === "Name" &&
                        tab.transactionTab === false
                      ? "stickPaymentNameV2Trans zIndex1060 header"
                      : column.id === "selection"
                      ? "stickCheckbox zIndex1060 header width25"
                      : column.render("Header") === "Last SMS sent"
                      ? "width200 header"
                      : column.id === "Mark onHold"
                      ? financialAdmin
                        ? ""
                        : "displayNone"
                      : "header"
                  }
                >
                  <span {...column.getSortByToggleProps()}>
                    {column.render("Header")}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  className={`row ${
                    row.original.has_desc === 1 ? "criminalRecord" : ""
                  }`}
                >
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        className={
                          cell.column.Header === "Worker Id"
                            ? `stickWorkerIdnewMatch  zIndex540`
                            : (cell.column.Header === "OkayGo id" &&
                                tab.transactionTab === false) ||
                              (cell.column.Header === "Recruiter ID" &&
                                tab.transactionTab === false)
                            ? "stickOkayGoId zIndex540"
                            : cell.column.Header === "Name" &&
                              tab.transactionTab === false
                            ? "stickPaymentNameV2Trans zIndex540"
                            : cell.column.Header === "Name" &&
                              tab.transactionTab === true
                            ? `stickPaymentNameV2 zIndex540 ${
                                row.original.has_desc === 1
                                  ? "criminalRecord"
                                  : ""
                              }`
                            : cell.column.id === "selection"
                            ? `stickCheckbox zIndex540 ${
                                row.original.has_desc === 1
                                  ? "criminalRecord"
                                  : ""
                              }`
                            : cell.column.Header === "Mark onHold"
                            ? financialAdmin
                              ? ""
                              : "displayNone"
                            : `cell ${
                                row.original.has_desc === 1
                                  ? "criminalRecord"
                                  : ""
                              }`
                        }
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        }
      </table>
    </div>
  );

  const handleTab = (event, name) => {
    if (name === "2") {
      setTab({ ...tab, invoiceTab: true, transactionTab: false });
      setDisFilterUrl("");
      setCurrentPage(1);
    }
    if (name === "1") {
      setTab({ ...tab, invoiceTab: false, transactionTab: true });
      setCurrentPage(1);
    }
    if (name === "3") {
      setTab({ ...tab, workerDataTab: true, recruiterDataTab: false });
      setIsRecruiterData("");
      setCurrentPage(1);
    } else if (name === "4") {
      setTab({ ...tab, workerDataTab: false, recruiterDataTab: true });
      setIsRecruiterData("&isRecruiter=1");
      setCurrentPage(1);
    }
  };

  // Function to open a specific user data
  const handleUserModal = async (event, row, type) => {
    switch (type) {
      case "earning": {
        setModalLoader(true);
        setOpenViewModal(true);
        await Axios.get(
          `${urlLinks.baseUrl}${urlLinks.payments.getFinancialYearListingAmount}${row.workerId}`
        )
          .then((res) => {
            if (res.data.response.length > 0) {
              setFinancialYearList(res.data.response);
              setSelectedOption({
                id: res.data.response[0].financialYear,
                name: res.data.response[0].financialYear,
                val: res.data.response[0].totalEarning,
              });
            }
            setModalLoader(false);
          })
          .catch((err) => {
            console.log("err", err);
            showToast(
              "error",
              "Error",
              "Something went wrong, please try after sometime."
            );
          });
        return;
      }
      default:
        return;
    }
  };

  const handleSendMsg = () => {
    const indexes = Object.entries(selectedRowIds);
    let idList = "";
    indexes.map((id, index) => {
      if (id[1]) {
        idList += data[parseFloat(id[0])].userId + ",";
      }
      return true;
    });
    idList = idList.substr(0, idList.length - 1);
    setSelectedUserIds(idList);
    setOpenSendMsg(!openSendMsg);
    setActionOption(0);
  };

  //handling Cancel Modal
  const handleCancelModal = () => {
    const indexes = Object.entries(selectedRowIds);
    let idList = [];
    let okayGoIdList = [];
    indexes.map((id) => {
      if (id[1] && data[parseFloat(id[0])].advancePaymentRaw === null) {
        idList.push(data[parseFloat(id[0])].invoiceId);
      } else {
        okayGoIdList.push(data[parseFloat(id[0])].okaygoId);
      }
      return true;
    });
    setSelectedUserIds(idList);
    setSelectedOGIds(okayGoIdList);
    setOpenCancelModal(!openCancelModal);
    setActionOption(0);
  };

  const listNetPaybleAmt = () => {
    const indexes = Object.entries(selectedRowIds);
    let idList = 0;
    indexes.map((id, index) => {
      if (id[1]) {
        idList += parseInt(data[id[0]].totalAmountRaw);
      }
      return true;
    });
    setTotalNetPayableAmount(idList);
    const indexes2 = Object.entries(selectedRowIds);
    let idList2 = "";
    indexes2.map((id, index) => {
      if (id[1]) {
        idList2 += data[parseFloat(id[0])].invoiceId + ",";
      }
      return true;
    });
    idList2 = idList2.substr(0, idList2.length - 1);
    setSelectedUserIds(idList2);
  };

  const menuOptionList = [
    [-1, "ALL INVOICES"],
    [0, "RAISED"],
    [1, "VALIDATED"],
    [2, "REQUESTED FOR APPROVAL"],
    [3, "APPROVED / QUEUED"],
    [4, "PROCESSING"],
    [5, "PROCESSED"],
    [6, "FAILED / REVERSED"],
    [7, "ACCOUNT DETAILS NOT VALID"],
    [8, "REJECTED"],
    [9, "CANCELLED"],
    [10, "ON-HOLD"],
  ];

  const makePaymentStage = (stage) => {
    let stageName = "";
    switch (stage) {
      case 0:
        stageName = "Raised";
        break;
      case 1:
        stageName = "Validated";
        break;
      case 2:
        stageName = "Requested For Approval";
        break;
      case 3:
        stageName = "Approved/queued";
        break;
      case 4:
        stageName = "Processing";
        break;
      case 5:
        stageName = "Processed";
        break;
      case 6:
        stageName = "Failed/Reversed";
        break;
      case 8:
        stageName = "Rejected";
        break;
      case 9:
        stageName = "Cancelled";
        break;
      case 10:
        stageName = "On hold";
        break;
      case 101:
        stageName = "Un hold";
        break;
      default:
        break;
    }
    return stageName;
  };

  const makeInvoiceIdsList = () => {
    // making invoiceIds comma sep
    const indexes = Object.entries(selectedRowIds);
    let idList = "";
    indexes.map((id, index) => {
      if (id[1]) {
        idList += data[parseFloat(id[0])].invoiceId + ",";
      }
      return true;
    });
    idList = idList.substr(0, idList.length - 1);
    return idList;
  };

  const makeAdvanceNotTakenInvoiceIdsList = () => {
    // making invoiceIds comma sep
    const indexes = Object.entries(selectedRowIds);
    let idList = "";
    indexes.map((id, index) => {
      if (id[1] && data[parseFloat(id[0])].advancePaymentRaw === null) {
        idList += data[parseFloat(id[0])].invoiceId + ",";
      }
      return true;
    });
    idList = idList.substr(0, idList.length - 1);
    return idList;
  };

  const updatePaymentStatus = (stage, remark) => {
    let idList;
    if (stage === 9) {
      idList = makeAdvanceNotTakenInvoiceIdsList();
    } else {
      idList = makeInvoiceIdsList();
    }
    let queryParams =
      remark === null
        ? `?Stage=${stage}&invoice_ids=${idList}&requestedBy=${localStorage.getItem(
            "userID"
          )}`
        : `?Stage=${stage}&invoice_ids=${idList}&remarks=${remark}&requestedBy=${localStorage.getItem(
            "userID"
          )}`;
    Axios.post(
      `${urlLinks.baseUrl}${urlLinks.paymentUrls.updatePaymentStage}${queryParams}`
    )
      .then((response) => {
        swal.fire({
          title: `Successfully ${makePaymentStage(stage)}`,
          icon: "success",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Okay",
        });
        updateMyData();
      })
      .catch((error) => {
        swal.fire("Error Occured", "error");
      });
  };

  const getActionMenuItems = () => {
    switch (mainFilterOption) {
      case -1:
        return [[1, "Send Message"]];
      case 0:
        return [
          [1, "Send Message"],
          [2, "Cancel Invoice"],
        ];
      case 1:
        return [
          [1, "Send Message"],
          [2, "Cancel Invoice"],
          [8, "On-Hold"],
        ];
      case 2:
        return [[1, "Send Message"]];
      case 3:
        return [[1, "Send Message"]];
      case 4:
        return [[1, "Send Message"]];
      case 5:
        return [[1, "Send Message"]];
      case 6:
        return [[1, "Send Message"]];
      case 7:
        return [[1, "Send Message"]];
      case 8:
        return [
          [1, "Send Message"],
          [2, "Cancel Invoice"],
          [8, "On-Hold"],
        ];
      case 10:
        return [
          [1, "Send Message"],
          [2, "Cancel Invoice"],
          [9, "Un-Hold"],
        ];
      case 9:
        return [[1, "Send Message"]];
      default:
        return [1, "Send Message"];
    }
  };

  const handleMainMenuChange = (e) => {
    setMainFilterOption(e.target.value);
    setActionOption(0);
    setCurrentPage(1);
  };

  const notApplicablePopup = (txt) => {
    swal.fire({
      title: txt,
      icon: "error",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Okay",
    });
    setActionOption(0);
  };

  const handleActionChange = (e) => {
    let action = e.target.value;
    setActionOption(e.target.value);
    switch (action) {
      case 1: {
        handleSendMsg();
        break;
      }
      case 8: {
        setOpenOnHoldPopup(true);
        setActionOption(0);
        break;
      }
      case 9: {
        handleOnHold(true, null);
        setActionOption(0);
        break;
      }
      case 2: {
        if (
          localStorage.getItem("roleType") === "2" &&
          mainFilterOption === 1
        ) {
          notApplicablePopup("Selected Invoices are already Validated");
        } else {
          handleCancelModal();
        }
        break;
      }
      default: {
      }
    }
  };

  useEffect(() => {
    setAllRecordLoading(false);
  }, [allRecord]);

  useEffect(() => {
    if (
      filters.advanceTaken !== "" ||
      filters.clientId !== "" ||
      filters.facility_name !== "" ||
      filters.facility_state !== "" ||
      filters.invoiceDate[0] !== null ||
      filters.invoiceDate[1] !== null ||
      filters.modalFlag !== "" ||
      filters.paymentDate[0] !== null ||
      filters.paymentDate[1] !== null ||
      filters.payment_status !== "" ||
      filters.payment_type !== "" ||
      filters.verification_type !== "" ||
      filters.worker_status !== "" ||
      filters.worker_type !== "" ||
      filters.zone !== ""
    ) {
      setIsFilter(true);
    } else {
      setIsFilter(false);
    }
  }, [filterUrl]);

  useEffect(() => {
    let searchByVar = RemoveExtraSpaces(searchBy);
    let count = searchByVar.split(/[ ,]+/).length;
    if (count > 100) {
      setSearchError(true);
    } else {
      setSearchError(false);
    }
  }, [searchBy]);

  useEffect(() => {
    ApplyFilters(filters);
  }, [searchByAuto, filters.billDate[0], filters.billDate[1]]);

  useEffect(() => {
    getPaymentListing(perPage, 1);
    setCurrentPage(1);
  }, [
    filterUrl,
    localStorage.getItem("roleType"),
    tab,
    clearFilter,
    mainFilterOption,
  ]);

  useEffect(() => {
    rows.map((record, index) => {
      if (
        (mainFilterOption === 1 || mainFilterOption === 8) &&
        localStorage.getItem("roleType") === "6"
      ) {
        if (record.original.invoiceFilePath === "") {
          selectedRowIds[index] = false;
        }
      }
    });
  }, [isAllRowsSelected]);

  useEffect(() => {
    if (selectedRowIds) {
      const indexes = Object.values(selectedRowIds);
      let disableButton = true;
      indexes.map((id) => {
        if (id) {
          disableButton = false;
          return true;
        }
        return true;
      });
      setDisablePayments(disableButton);
    }
  }, [selectedRowIds]);

  useEffect(() => {
    Axios.get(
      `${urlLinks.baseUrl}${
        urlLinks.paymentUrls.financeAdmin
      }${localStorage.getItem("userID")}`
    )
      .then((response) => {
        if (response.data.financeAdmin === 1) {
          setFinancialAdmin(true);
        } else {
          setFinancialAdmin(false);
        }
      })
      .catch((error) => {
        showToast("error", error.message);
      });

    Axios.get(`${urlLinks.baseUrl}${"advance/getAccountBalance"}`)
      .then((response) => {
        setDataBalance(response.data.response);
      })
      .catch((error) => {
        showToast("error", error.message);
      });
  }, []);

  return (
    <Styles>
      <Grid container className="padding2percent">
        <Grid
          item
          xs={12}
          className="flexDisplay justifyFlexStart"
          style={{ justifyItems: "space-between" }}
        >
          <Grid item xs={6}>
            <span className="fontSize24 fontWeightBold marginRight20">
              Payment List
            </span>
            <Button
              onClick={(event) => {
                if (tab.recruiterDataTab === true) {
                  handleTab(event, "3");
                }
              }}
              className={`statusButtons marginRight20 ${
                tab.workerDataTab ? "blueColorBorder" : "greyColorBorder"
              }`}
            >
              Worker
            </Button>
            <Button
              onClick={(event) => {
                if (tab.workerDataTab === true) {
                  handleTab(event, "4");
                }
              }}
              className={`statusButtons ${
                tab.recruiterDataTab ? "blueColorBorder" : "greyColorBorder"
              }`}
            >
              Recruiter
            </Button>
          </Grid>
          <Grid
            item
            sm={6}
            className="flexDisplay"
            style={{ justifyContent: "flex-end" }}
          >
            <>
              {" "}
              <Button
                style={{
                  marginLeft: "15px",
                  marginBottom: "8px",
                }}
                onClick={(event) => {
                  if (tab.invoiceTab === true) {
                    handleTab(event, "1");
                  }
                }}
                className={`statusButtons marginRight20 ${
                  tab.transactionTab ? "blueColorBorder" : "greyColorBorder"
                }`}
              >
                Invoices
              </Button>
              <Button
                style={{
                  marginBottom: "8px",
                }}
                onClick={(event) => {
                  if (tab.transactionTab === true) {
                    handleTab(event, "2");
                  }
                }}
                className={`statusButtons ${
                  tab.invoiceTab ? "blueColorBorder" : "greyColorBorder"
                }`}
              >
                Transactions
              </Button>
            </>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          className="flexDisplay"
          style={{ justifyContent: "space-between" }}
        >
          <Grid item xs={5} className="flexDisplay padding0 paddingTop8">
            <SearchBar
              uniqueId="PaymentListing"
              searchBy={searchBy}
              setSearchBy={setSearchBy}
              searchError={searchError}
              filters={filters}
              setFilters={setFilters}
              handleSearch={handleSearch}
              handleSearchClose={handleSearchClose}
              ApplyFilters={ApplyFilters}
              setFilterUrl={setFilterUrl}
              placeholder={
                tab.recruiterDataTab
                  ? "Recruiter id/Contact No"
                  : "Client id/Contact No/OkayGo id"
              }
              buttonName="Search"
              searchInstructions={
                tab.workerDataTab
                  ? "Use comma or space as delimiter to search multiple IDs. Max limit 100."
                  : "Use comma or space as delimiter to search multiple Rec. IDs. Max limit 100."
              }
              warningMessage=" Max limit reached. Search 100 IDs max."
            />
          </Grid>
          <Grid
            item
            xs={4}
            className="flexDisplay"
            style={{ justifyContent: "space-evenly" }}
          >
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                id="billDateFrom"
                placeholder="Bill date from"
                format="dd/MM/yyyy"
                inputVariant="outlined"
                className={style.datePicker}
                clearable
                value={filters.billDate[0]}
                onChange={(date) => handleFilterDates(date, 0, "billDate")}
                keyboardIcon={
                  <img
                    src={Calendar}
                    alt="calendar"
                    width="18px"
                    height="18px"
                  />
                }
              />
            </MuiPickersUtilsProvider>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                id="billDateTo"
                placeholder="Bill date to"
                format="dd/MM/yyyy"
                inputVariant="outlined"
                className={style.datePicker}
                clearable
                value={filters.billDate[1]}
                onChange={(date) => handleFilterDates(date, 1, "billDate")}
                keyboardIcon={
                  <img
                    src={Calendar}
                    alt="calendar"
                    width="18px"
                    height="18px"
                  />
                }
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid
            item
            xs={3}
            className="flexDisplay"
            style={{ justifyContent: "flex-end" }}
          >
            {!tab.invoiceTab && tab.workerDataTab && (
              <Demo
                updateMyData={updateMyData}
                setDisFilterUrl={setDisFilterUrl}
                clearFilter={clearFilter}
                setClearFilter={setClearFilter}
              />
            )}
            {tab.workerDataTab && (
              <button
                className={`${styles.filterButton}`}
                style={{ margin: "7px 0px 7px 20px", height: "36px" }}
                onClick={() => setOpenFilterDrawer(true)}
              >
                {!isFilter ? (
                  <img src={filterIcon} alt="filterIcon" />
                ) : (
                  <img
                    src={filterIconMarked}
                    alt="filterIcon"
                    style={{ height: "16px" }}
                  />
                )}
              </button>
            )}
          </Grid>
        </Grid>
        {!tab.invoiceTab ? (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "2000px",
              marginTop: "10px",
            }}
          >
            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "25px", marginTop: "6px" }}>
                <FormControl>
                  <Select
                    style={{ width: "250px", height: "40px" }}
                    variant="outlined"
                    value={mainFilterOption}
                    onChange={handleMainMenuChange}
                  >
                    {menuOptionList.map((option) => {
                      return (
                        <MenuItem key={option[0]} value={option[0]}>
                          {option[1]}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
              {localStorage.getItem("roleType") !== "31" && (
                <div style={{ marginRight: "25px", marginTop: "6px" }}>
                  <FormControl>
                    <Select
                      style={{
                        width: "200px",
                        height: "40px",
                        color: actionOption === 0 ? "grey" : "",
                      }}
                      variant="outlined"
                      value={actionOption}
                      disabled={disablePayments}
                      onChange={handleActionChange}
                    >
                      <MenuItem key={0} disabled value={0}>
                        {"Action"}
                      </MenuItem>
                      {getActionMenuItems().map((option) => {
                        return (
                          <MenuItem key={option[0]} value={option[0]}>
                            {option[1]}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
              )}
              <div>
                <ExportButton
                  uniqueKey="paymentListingInvoice"
                  createAllRecordExcel={createAllRecordExcel}
                  allRecordLoading={allRecordLoading}
                  filename={
                    !tab.invoiceTab
                      ? `InvoiceList_${moment().format("DD/MM/YYYY HH:mm")}.csv`
                      : `TransactionList_${moment().format(
                          "DD/MM/YYYY HH:mm"
                        )}.csv`
                  }
                  buttonName="Export to Excel"
                  loadingMessage="Please wait ... it might take few minutes."
                  downloadButton="Download now"
                />
              </div>
              <div>
                <Button
                  variant="contained"
                  className="dataButton2"
                  onClick={handleActions}
                  style={{ marginRight: "20px" }}
                >
                  Upload Invoice
                </Button>
              </div>
              {localStorage.getItem("roleType") === "31" && (
                <div>
                  <Button
                    variant="contained"
                    className="dataButton2"
                    onClick={handleOpen}
                    style={{ marginRight: "20px" }}
                  >
                    Check Balance
                  </Button>
                </div>
              )}
            </div>
            <div style={{ display: "flex" }}>
              {mainFilterOption === 0 &&
                localStorage.getItem("roleType") === "6" && (
                  <div style={{ marginLeft: "25px" }}>
                    <Button
                      variant="contained"
                      className="dataButton2"
                      disabled={disablePayments}
                      onClick={() => {
                        updatePaymentStatus(1, null);
                      }}
                    >
                      VALIDATE
                    </Button>
                  </div>
                )}
              {(mainFilterOption === 1 || mainFilterOption === 8) &&
                localStorage.getItem("roleType") === "6" && (
                  <div style={{ marginLeft: "25px" }}>
                    <Button
                      variant="contained"
                      className="dataButton2"
                      disabled={disablePayments}
                      onClick={() => {
                        updatePaymentStatus(2, null);
                      }}
                    >
                      SEND FOR APPROVAL
                    </Button>
                  </div>
                )}
              {mainFilterOption === 2 &&
                localStorage.getItem("roleType") === "31" && (
                  <div style={{ marginLeft: "25px" }}>
                    <Button
                      variant="contained"
                      className="dataButton2"
                      onClick={() => {
                        setOpenRejectInvoicePopup(true);
                      }}
                      disabled={disablePayments}
                      style={{ marginRight: "20px" }}
                    >
                      REJECT
                    </Button>
                    <Button
                      variant="contained"
                      className="dataButton2"
                      onClick={() => {
                        listNetPaybleAmt();
                        setOpenTriggerPaymentPopup([true, false]);
                      }}
                      disabled={disablePayments}
                    >
                      APPROVE
                    </Button>
                  </div>
                )}
              {mainFilterOption === 6 &&
                (localStorage.getItem("roleType") === "31" ||
                  localStorage.getItem("roleType") === "6") && (
                  <Button
                    variant="contained"
                    className="dataButton2"
                    onClick={() => {
                      listNetPaybleAmt();
                      setOpenTriggerPaymentPopup([true, true]);
                      setActionOption(0);
                    }}
                    disabled={disablePayments}
                  >
                    RI-TRIGGER PAYMENT
                  </Button>
                )}
            </div>
          </div>
        ) : (
          <div>
            <ExportButton
              uniqueKey="paymentListingTransaction"
              createAllRecordExcel={createAllRecordExcel}
              allRecordLoading={allRecordLoading}
              filename={
                !tab.invoiceTab
                  ? `InvoiceList_${moment().format("DD/MM/YYYY HH:mm")}.csv`
                  : `TransactionList_${moment().format("DD/MM/YYYY HH:mm")}.csv`
              }
              buttonName="Export to Excel"
              loadingMessage="Please wait ... it might take few minutes."
              downloadButton="Download now"
            />
          </div>
        )}
        <RejectModal
          open={openRejectInvoicePopup}
          setOpen={setOpenRejectInvoicePopup}
          updatePaymentStatus={updatePaymentStatus}
          stage={8}
          text={"Reject Invoice Remark"}
        />
        <OnHoldRemarkModal
          open={openOnHoldPopup}
          setOpen={setOpenOnHoldPopup}
          handleOnHold={handleOnHold}
          text={"On Hold Remark"}
        />
        {loader ? (
          loaderBar
        ) : data.length === 0 ? (
          <div className={styles.noRecord}>
            <img src={NoRecord} alt="noRecord" />
            <span>No records found</span>
          </div>
        ) : (
          <Grid item xs={12} className="paddingTop16">
            {table}
            <Pagination
              rowsperpage={perPage}
              pageNo={currentPage}
              totalElements={totalRows}
              totalPages={totalPages}
              handlePageChange={handlePageChange}
              handlePerRowsChange={handlePerRowsChange}
            />
          </Grid>
        )}
        {openOnboardingModalV2 && (
          <OnboardingModalV2
            openOnboardingModal={openOnboardingModalV2}
            setOpenOnboardingModal={setOpenOnboardingModalV2}
            showToast={showToast}
            updateMyData={updateMyData}
          />
        )}
        <Modal
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div className={classes.paper}>
            <h2 id="simple-modal-title">Balance</h2>
            <table>
              <tr>
                <td>Total Balance:</td>
                <td> {dataBalance}</td>
              </tr>
            </table>
          </div>
        </Modal>
        {openSendMsg && (
          <SendMsg
            filterUrl={filterUrl}
            open={openSendMsg}
            setOpen={setOpenSendMsg}
          />
        )}
        {openCancelModal && (
          <CancelModal
            open={openCancelModal}
            setOpen={setOpenCancelModal}
            selectedOGIds={selectedOGIds}
            updatePaymentStatus={updatePaymentStatus}
          />
        )}
        <ConfirmTriggerPaymentModal
          open={openTriggerPaymentPopup[0]}
          retrigger={openTriggerPaymentPopup[1]}
          setOpen={setOpenTriggerPaymentPopup}
          updateMyData={updateMyData}
          totalAmountToBePaid={totalNetPayableAmount}
          showToast={showToast}
        />
        {openFilterDrawer && (
          <Drawer
            anchor="right"
            open={openFilterDrawer}
            onClose={() => {
              setOpenFilterDrawer(false);
            }}
          >
            <FilterDrawer
              handleClose={() => setOpenFilterDrawer(false)}
              filters={filters}
              setFilters={setFilters}
              applyFilters={ApplyFilters}
              resetFilters={resetFiltersWithoutSearch}
            ></FilterDrawer>
          </Drawer>
        )}
        {openViewModal && (
          <EarningModal
            open={openViewModal}
            setOpen={setOpenViewModal}
            title="Cumulative Earning"
            uniqueKey="earningModal"
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            financialYearList={financialYearList}
          />
        )}
      </Grid>
    </Styles>
  );
};

export default ExternalWorker;
