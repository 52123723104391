import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as Axios from "axios";
import { useDebounce } from "use-debounce";
import { InputAdornment } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import "./SearchSelectFilter.css";

export default function SearchSelectFilter(props) {
  const {
    filters,
    setFilters,
    id,
    sourceId,
    setSourceId,
    module,
    multiple,
    placeholder,
    url,
  } = props;
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [searchBy, setSearchBy] = useState("");

  const [debouncedText] = useDebounce(searchBy, 350);
  let loading = false;

  const onChangeHandle = (value) => {
    setSearchBy(value);
  };

  const makeData = (data) => {
    let list = [];
    if (module === "jobListing") {
      data.map((el) => {
        if (el.city_id !== null && el.city_id !== "") {
          list.push({
            id: el.city_id,
            name: el.cityName,
          });
        }
        return true;
      });
    } else if (module === "preferredPincodesListing") {
      data.map((el) => {
        if (el.distinct_city_id !== null && el.pin !== "") {
          list.push({
            id: el.pin,
            name: el.pin.toString(),
          });
        }
        return true;
      });
    } else {
      data.content.map((el) => {
        if (el !== null && el !== "") {
          if (
            (module === "leadCityListing" && !filters.leadCity.includes(el)) ||
            (module === "driveExamCityListing" &&
              !filters.examCity.includes(el)) ||
            (module === "invigilatorCityNameListing" &&
              !filters.city.includes(el)) ||
            (module === "attendanceCityListing" && !filters.city.includes(el))
          ) {
            list.push({
              id: el,
              name: el,
            });
          }
        }
        return true;
      });
    }
    return list;
  };

  const getOptionsData = () => {
    if (debouncedText) {
      loading = true;
      Axios.get(`${url}${debouncedText}`)
        .then((response) => {
          setOptions(makeData(response.data.response));
          loading = false;
        })
        .catch((err) => console.log(err));
    }
  };

  const handleValue = (val) => {
    let list = [];
    if (module === "jobListing") {
      list.push(val?.id);
    } else {
      val.map((e) => {
        list.push(e.id);
      });
    }
    return list;
  };

  useEffect(() => {
    getOptionsData();
  }, [debouncedText]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      id="multiple-limit-tags"
      multiple={multiple}
      limitTags={1}
      freeSolo
      value={sourceId}
      onChange={(event, value) => {
        let a = handleValue(value);
        setFilters({
          ...filters,
          [id]: module === "jobListing" ? a : a.toString(),
        });
        setSourceId(typeof value === "string" ? value.split(",") : value);
      }}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      getOptionLabel={(option) => option.name}
      options={options}
      loading={loading}
      style={{ width: "250px" }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          placeholder={
            sourceId == "" || sourceId == null || sourceId?.length === 0
              ? placeholder
              : ""
          }
          size="small"
          onChange={(event) => {
            if (event.target.value !== "" || event.target.value !== null) {
              onChangeHandle(event.target.value);
            }
          }}
          className={
            // (sourceId != "" || sourceId != null || sourceId?.length > 0) && module !== "jobListing"
            //   ? "ZeroPadding"
            //   : "DefaultPadding"
            "DefaultPadding"
          }
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <div>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {sourceId == "" || sourceId == null ? (
                  <InputAdornment positionEnd>
                    <SearchIcon
                      edge="end"
                      style={{ color: "#A7A7A7", marginLeft: "20px" }}
                    />
                  </InputAdornment>
                ) : (
                  <InputAdornment position="end" edge="end">
                    {params.InputProps.endAdornment}
                  </InputAdornment>
                )}
              </div>
            ),
          }}
        />
      )}
    />
  );
}
