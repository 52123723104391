import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../../store/actions/index";
import { withStyles } from "@material-ui/core/styles";
import Styles from "./Components.module.scss";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { ScaleLoader } from "react-spinners";
import * as XLSX from "xlsx";
import { Checkbox, FormControlLabel, Grid } from "@material-ui/core";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    borderBottom: "unset",
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function CustomizedDialogs(props) {
  const {
    openCasperOnboardingModal,
    setOpenCasperOnboardingModal,
    statusValues,
    createAllRecordExcel,
    title,
    uniqueKey,
    buttonName,
  } = props;

  /** accessing states from redux store **/

  const { users, driveDb } = useSelector((state) => state);
  const { modalLoader } = users;
  const { driveTab } = driveDb;

  /** useDispatch is used to update states to redux store **/

  const dispatch = useDispatch();
  const { setModalLoader } = bindActionCreators(actionCreators, dispatch);

  /** other states **/

  const [isColumnSelected, setIsColumnSelected] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState({
    /* The column names here should be same as the 'columnsList' variable in index.js */
    driveDashboard: {
      all: true,
      Month: { id: "month", val: true },
      Zone: {
        id: "zone",
        val: true,
      },
      State: {
        id: "state",
        val: true,
      },
      "Exam City": {
        id: "examCity",
        val: true,
      },
      "TC code": {
        id: "tcCode",
        val: true,
      },
      "Venue Name": {
        id: "venueName",
        val: true,
      },
      "Exam Name": {
        id: "examName",
        val: true,
      },
      "Exam Date": {
        id: "examDate",
        val: true,
      },
      "Exam Timing": {
        id: "examTiming",
        val: true,
      },
      "Role Type": {
        id: "roleType",
        val: true,
      },
      "Shift 1": {
        id: ["fulfilledNumberShift1", "requirementNumberShift1"],
        val: true,
      },
      "Shift 2": {
        id: ["fulfilledNumberShift2", "requirementNumberShift2"],
        val: true,
      },
      "Shift 3": {
        id: ["fulfilledNumberShift3", "requirementNumberShift3"],
        val: true,
      },
      "Shift 4": {
        id: ["fulfilledNumberShift3", "requirementNumberShift3"],
        val: true,
      },
      "Total no. shifts Utilised": {
        id: "totalNoOfShiftsUtilized",
        val: true,
      },
    },
    workerDashboard: {
      all: true,
      Zone: {
        id: "zone",
        val: true,
      },
      State: {
        id: "state",
        val: true,
      },
      "City Name": {
        id: "cityName",
        val: true,
      },
      "OkayGo ID": {
        id: "ogId",
        val: true,
      },
      "Client Resource ID": {
        id: "clientResourceId",
        val: true,
      },
      Name: {
        id: "name",
        val: true,
      },
      Role: {
        id: "role",
        val: true,
      },
      "Contact Number": {
        id: "contactNumber",
        val: true,
      },
      "Email ID": {
        id: "emailId",
        val: true,
      },
      "Name of the Agency/Vendor": {
        id: "agencyVendorName",
        val: true,
      },
      "Workforce Father’s Name": {
        id: "workforceFathersName",
        val: true,
      },
      "Workforce Mother’s Name": {
        id: "workforceMothersName",
        val: true,
      },
      DOB: {
        id: "dob",
        val: true,
      },
      "Complete permanent address": {
        id: "completePermAddress",
        val: true,
      },
      "Aadhaar Card": {
        id: "aadhaarCard",
        val: true,
      },
      "Aadhaar Number": {
        id: "aadhaarNo",
        val: true,
      },
      "Aadhaar Validation": {
        id: "aadhaarValidation",
        val: true,
      },
      "PAN Card": {
        id: "panCard",
        val: true,
      },
      "PAN Number": {
        id: "panNo",
        val: true,
      },
      "PAN Validation": {
        id: "panValidation",
        val: true,
      },
      "12th Pass Certificate": {
        id: "twelfthPassCertificate",
        val: true,
      },
      "12th Validation": {
        id: "twelfthPassCertificateValidation",
        val: true,
      },
      "10th Pass Certificate": {
        id: "tenthPassCertificate",
        val: true,
      },
      "10th Validation": {
        id: "tenthPassCertificateValidation",
        val: true,
      },
      "BGV Status": {
        id: "bgvStatus",
        val: true,
      },
      "If Yes, BGV Date": {
        id: "bgvDate",
        val: true,
      },
      "BGV Validation": {
        id: "bgvValidation",
        val: true,
      },
    },
  });

  //  function to close modal
  const handleClose = () => {
    setOpenCasperOnboardingModal(false);
  };

  //  function to create an excel sheet
  const handleExcelExport = async (data) => {
    // creating a workbook called wb
    const workbook = XLSX.utils.book_new();

    // Convert your data to a worksheet
    const worksheet = XLSX.utils.json_to_sheet(data);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    XLSX.writeFile(
      workbook,
      driveTab.workerDashboard ? "INVIGILATOR DB.xlsx" : "DRIVE DB.xlsx"
    );
  };

  //  function to handle excel export
  const handleOnExport = async () => {
    setModalLoader(true);
    let recordData = await createAllRecordExcel(selectedColumns);
    await handleExcelExport(recordData);
    setModalLoader(false);
    handleClose();
  };

  const handleCheckBox = (e, type, dashboard) => {
    if (type === "all") {
      let newSelectedColumns = selectedColumns[dashboard];
      Object.keys(newSelectedColumns).forEach((el) => {
        if (el === type) newSelectedColumns[el] = e.target.checked;
        else {
          newSelectedColumns[el].val = e.target.checked;
        }
      });

      setSelectedColumns({
        ...selectedColumns,
        [dashboard]: newSelectedColumns,
      });
    } else {
      const newSelectedColumns = {
        ...selectedColumns[dashboard],
        [type]: {
          ...selectedColumns[dashboard][type],
          val: e.target.checked,
        },
      };

      const allChecked = Object.keys(newSelectedColumns).every(
        (key) => key === "all" || newSelectedColumns[key].val
      );
      newSelectedColumns.all = allChecked;

      setSelectedColumns({
        ...selectedColumns,
        [dashboard]: newSelectedColumns,
      });
    }
  };

  useEffect(() => {
    let flag = false;
    flag = Object.values(
      driveTab.workerDashboard
        ? selectedColumns.workerDashboard
        : selectedColumns.driveDashboard
    ).some((val) => val.val);

    setIsColumnSelected(flag);
  }, [selectedColumns]);

  let dialogData = (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={openCasperOnboardingModal}
      className={Styles.onboardingDialog}
      classes={{
        paper: driveTab.workerDashboard
          ? Styles.workerPaper
          : Styles.drivePaper,
      }}
    >
      <DialogTitle
        id="customized-dialog-title"
        className={Styles.onboardingDialogTitle}
        onClose={handleClose}
      >
        <div>{title}</div>
        <FormControlLabel
          control={
            <Checkbox
              checked={
                driveTab.workerDashboard
                  ? selectedColumns.workerDashboard.all
                  : selectedColumns.driveDashboard.all
              }
              onChange={(e) =>
                handleCheckBox(
                  e,
                  "all",
                  driveTab.workerDashboard
                    ? "workerDashboard"
                    : "driveDashboard"
                )
              }
            />
          }
          label="Select all"
        />
      </DialogTitle>
      <DialogContent className={Styles.onboardingDialogContent} dividers>
        <Grid container>
          {statusValues.map((columnName, id) => {
            return (
              columnName && (
                <Grid item xs={driveTab.workerDashboard ? 4 : 6} key={id}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          driveTab.workerDashboard
                            ? selectedColumns.workerDashboard[columnName].val
                            : selectedColumns.driveDashboard[columnName].val
                        }
                        onChange={(e) =>
                          handleCheckBox(
                            e,
                            columnName,
                            driveTab.workerDashboard
                              ? "workerDashboard"
                              : "driveDashboard"
                          )
                        }
                      />
                    }
                    label={columnName}
                  />
                </Grid>
              )
            );
          })}
        </Grid>
      </DialogContent>
      <DialogActions className={`${Styles.onboardingButton}`}>
        {modalLoader ? (
          <span>Please wait... it may take few minutes</span>
        ) : (
          <Button
            variant="contained"
            onClick={handleOnExport}
            className={`${
              !isColumnSelected ? "disabledButton" : "blueButtonContained"
            }`}
            disabled={!isColumnSelected}
            style={{ width: "100%" }}
          >
            {buttonName}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );

  return dialogData;
}
