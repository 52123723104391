import React from "react";
import styles from '../index.module.scss'
import verifiedIcon from '../Verified_icon.png'
import notVerifiedIcon from '../Not-Verified_icon.png'
import inProcessIcon from '../In_process_icon.png' 
const LastSentMsgs = (props) => {
  const { status, isListing } = props;
  const createBgvStatus =() =>{
    switch (status) {
      case 0:  //Not initiated
        return "Not initiated"
      case 1:  //in prgress
        return isListing?<div className={styles.bgvStatus}><img src={inProcessIcon} alt="bgvstatus" /><span>In Process</span></div>:"In Process"
      case 2: //verified
        return isListing?<div className={styles.bgvStatus}><img src={verifiedIcon} alt="bgvstatus"  /><span>Verified</span></div>:"Verified"
      case 3:  //failed
        return isListing?<div className={styles.bgvStatus}><img src={notVerifiedIcon} alt="bgvstatus"  /><span>Failed</span></div>:"Failed"
      case 4:  //incomplete details
        return "Incomplete Details"
      case 5:  //No status
        return ""
                
      default:
        return <div></div>
    }
  }
  return (
    createBgvStatus()
  );
};

export default LastSentMsgs;
