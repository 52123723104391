import React, { useState } from "react";
import HRBPview from "./HRBPview";
import HRBPedit from "./HRBPedit";
import { Formik } from "formik";
import styles from "../index.module.scss";

function HRBPuse(props) {
  const { data, showToast, getWorkerData } = props;
  const [editButton, setEditButton] = useState(false);
  const [initialValues, setInitialValues] = useState({
    casperId: data.casperId,
    sourceBy: data.sourceBy,
    sourceByName: data.sourceByName,
    zone: data.zone,
    facilityName: data.facilityName,
    facilityCity: data.facilityCity,
    facilityState: data.facilityState,
    rateCard: data.rateCard,
    facilityType: data.facilityType ? data.facilityType : "DELIVERY_HUB",
    designation: data.designation,
    edab: data.edab,
    criminalRecord: data.criminalRecord,
    date_of_joining: data.date_of_joining,
  });

  const handleEdit = () => {
    setEditButton(true);
  };

  const hrbp = (
    <div>
      {(localStorage.getItem("roleType") === "2" ||
        localStorage.getItem("roleType") === "6") &&
        !editButton &&
        data.workerStatus !== 3 && (
          <button className={styles.editButton} onClick={handleEdit}>
            Edit
          </button>
        )}
      {editButton ? (
        <Formik initialValues={initialValues} enableReinitialize>
          {(formikProps) => {
            return (
              <form>
                {
                  <HRBPedit
                    data={data}
                    showToast={showToast}
                    getWorkerData={getWorkerData}
                    formikProps={formikProps}
                    setEditButton={setEditButton}
                  />
                }
              </form>
            );
          }}
        </Formik>
      ) : (
        <HRBPview data={data} />
      )}
    </div>
  );

  return hrbp;
}

export default HRBPuse;
