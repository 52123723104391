import React, { useState, useContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import { AuthContext } from "../../services/AuthProvider";
import styles from "./index.module.scss";
import artImage from "./art.svg";
import logo from "./logo.svg";
import ForgotPasswordModal from "./Components/ForgotPassword";
import { toast, ToastContainer } from "react-toastify";

const Login = () => {
  const history = useHistory();
  const location = useLocation();
  const { session, login } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [openForgotPasswordModal, setOpenForgotPasswordModal] = useState(false);
  const [errors, setErrors] = useState({
    email: false,
    password: false,
  });

  const { from } = location.state || { from: { pathname: "/" } };

  useEffect(() => {
    if (session) {
      history.replace(from);
    }
  }, [history, from, session]);

  const showToast = (type, message) => {
    if (type === "success") toast.success(message);
    else if (type === "error") toast.error(message);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    login(email, password)
      .then(() => {
        history.replace(from);
      })
      .catch((error) => {
        if (error.message === "User is not confirmed.") {
          showToast(
            "error",
            "Your account is not activated yet. Please activate your account using the link sent to your email."
          );
        } else {
          showToast("error", error.message);
        }
      });
  };

  const handleEmail = (event) => {
    setEmail(event.target.value);
  };

  const handlePassword = (event) => {
    if (event.target.value) {
      if (event.target.value.length < 8) {
        setErrors({ ...errors, password: true });
      } else {
        setErrors({ ...errors, password: false });
      }
    }
    setPassword(event.target.value);
  };

  return (
    <div className={styles.page}>
      <ToastContainer
        position="top-right"
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        autoClose={3000}
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      ></ToastContainer>
      <img src={artImage} alt="loginSide" className={styles.artImage} />
      <div className={styles.loginSection}>
        <div className={styles.logoHolder}>
          <img src={logo} alt="logo" className={styles.logo} />
        </div>
        <div className={styles.loginForm}>
          <div className={styles.heading}>Login</div>
          <form className={styles.form} onSubmit={onSubmit}>
            <div className={styles.formElement}>
              <label htmlFor="emailId">Username</label>
              <input
                id="emailId"
                type="text"
                placeholder="Username"
                value={email}
                onChange={handleEmail}
              />
            </div>
            <div className={styles.formElement}>
              <label htmlFor="password">Password</label>
              <input
                id="password"
                type="password"
                placeholder="Enter your password"
                value={password}
                onChange={handlePassword}
              />
            </div>
            {errors.password && (
              <div>
                <span style={{ color: "red" }}>Enter a valid password</span>
              </div>
            )}
            <div className={styles.forgotPassword}>
              <button
                type="button"
                onClick={() => setOpenForgotPasswordModal(true)}
              >
                Forgot password?
              </button>
            </div>
            <div className={styles.loginButtonHolder}>
              <button
                disabled={
                  errors.email || errors.password || !email || !password
                }
                type="submit"
                className={styles.loginButton}
              >
                Login
              </button>
            </div>
          </form>
          {openForgotPasswordModal && (
            <ForgotPasswordModal
              openForgotPasswordModal={openForgotPasswordModal}
              setOpenForgotPasswordModal={setOpenForgotPasswordModal}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Login;
