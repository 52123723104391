import React, { useState, useEffect } from "react";
import Axios from "axios";
import Styles from "./index.module.scss";
import {
  Popover,
  Button,
  Divider,
  Grid,
  IconButton,
  Badge,
} from "@material-ui/core";
import SelectFilter from "./SelectFilter";
import FilterIcon from "../../assets/filter.svg";
import urlLinks from "../../UrlLinks";
import AutoSelectFilter from "./components/AutoSelectFilter";

export default function SimplePopover(props) {
  const { isFilterApplied, setIsFilterApplied } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [filters, setFilters] = useState();
  const [hubsList, setHubsList] = useState([]);
  const [facilityStateList, setFacilityStateList] = useState([]);
  const [verificationType, setVerificationType] = useState([]);
  const [partnerList, setPartnerList] = useState([]);

  const makeHubList = (data) => {
    let list = [];
    let count = 0;
    if (data?.length > 0) {
      data.map((skill) => {
        count++;
        if (skill !== null) {
          let list2 = {
            name: skill.facilityName,
            id: count,
          };
          list.push(list2);
        }
      });
    }
    return list;
  };

  const makeFacilityStateList = (data) => {
    let list = [];
    let count = 0;
    if (data?.length > 0) {
      data.map((skill) => {
        count++;
        if (skill !== null) {
          let list2 = {
            name: skill,
            id: count,
          };
          list.push(list2);
        }
      });
    }
    return list;
  };

  const makeVerificationType = (data) => {
    let list = [];
    if (data?.length > 0) {
      data.map((skill) => {
        if (skill !== null) {
          let list2 = {
            id: skill.typeKey,
            typeValue: skill.typeValue,
          };
          list.push(list2);
        }
      });
    }
    return list;
  };

  useEffect(() => {
    setFilters(props.filters);

    Axios.get(`${urlLinks.baseUrl}configmaster/getActiveHubs`).then(
      (response) => {
        setHubsList(makeHubList(response.data.response));
      }
    );

    Axios.get(`${urlLinks.baseUrl}/configmaster/getFacilityStates`).then(
      (response) => {
        setFacilityStateList(makeFacilityStateList(response.data.response));
      }
    );

    Axios.get(`${urlLinks.baseUrl}/configmaster/getPartners`).then(
      (response) => {
        setPartnerList(makeNonAJPartnerList(response.data.response));
      }
    );

    Axios.get(
      `${urlLinks.baseUrl}/configmaster/?category_sub_type=penny_testing_stage&category_type=penny_testing`
    ).then((response) => {
      setVerificationType(makeVerificationType(response.data.response.content));
    });
  }, []);

  const makeNonAJPartnerList = (data) => {
    let list = [];
    if (data?.length > 0) {
      data.map((skill) => {
        if (
          skill !== null &&
          (skill.is_aj_client === 0 || skill.is_aj_client === null)
        ) {
          list.push(skill);
        }
      });
    }
    return list;
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleResetFilters = () => {
    const newFilters = props.resetFilters();
    setFilters(newFilters);
    setIsFilterApplied(false);
  };

  const handleApplyFilters = () => {
    props.setFilters(filters);
    handleClose();
  };

  const handleCancelFilters = () => {
    setFilters(props.filters);
    handleClose();
  };

  let popoverData = <Grid></Grid>;
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  popoverData = (
    <Grid container>
      <Grid item sm className="padding20 margin20" style={{ width: "30px" }}>
        <div style={{ width: "250px" }}>
          <SelectFilter
            filters={filters}
            setFilters={setFilters}
            label="Zone"
            id="zone"
          />
        </div>
      </Grid>
      <Grid item sm className="padding20 margin20" style={{ width: "30px" }}>
        <AutoSelectFilter
          filters={filters}
          setFilters={setFilters}
          multipleFlag={false}
          placeholder="Facility State"
          id="facility_state"
          options={facilityStateList}
        />
      </Grid>
      <Grid container>
        <Grid item sm className="padding20 margin20" style={{ width: "30px" }}>
          <div style={{ width: "250px" }}>
            <AutoSelectFilter
              filters={filters}
              setFilters={setFilters}
              multipleFlag={false}
              placeholder="Facility Hubs"
              id="facility_name"
              options={hubsList}
            />
          </div>
        </Grid>
        <Grid item sm className="padding20 margin20" style={{ width: "10px" }}>
          <div style={{ width: "250px" }}>
            <SelectFilter
              filters={filters}
              setFilters={setFilters}
              label="Payment Status"
              id="payment_status"
              style={{ width: "30px" }}
            />
          </div>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item sm className="padding20 margin20" style={{ width: "150px" }}>
          <div style={{ width: "250px" }}>
            <SelectFilter
              filters={filters}
              setFilters={setFilters}
              label="Worker Status"
              id="worker_status"
              style={{ width: "30px" }}
            />
          </div>
        </Grid>
        <Grid item sm className="padding20 margin20" style={{ width: "50px" }}>
          <div style={{ width: "250px" }}>
            <SelectFilter
              filters={filters}
              setFilters={setFilters}
              label="Worker type"
              id="worker_type"
              style={{ width: "30px" }}
            />
          </div>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item sm className="padding20 margin20" style={{ width: "50px" }}>
          <div style={{ width: "250px" }}>
            <SelectFilter
              filters={filters}
              setFilters={setFilters}
              label="Payment type"
              id="payment_type"
              style={{ width: "30px" }}
            />
          </div>
        </Grid>
        <Grid item sm className="padding20 margin20" style={{ width: "50px" }}>
          <div style={{ width: "250px" }}>
            <SelectFilter
              filters={filters}
              setFilters={setFilters}
              label="Advance Taken"
              id="advanceTaken"
              style={{ width: "30px" }}
            />
          </div>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item sm className="padding20 margin20" style={{ width: "50px" }}>
          <div style={{ width: "250px" }}>
            <SelectFilter
              filters={filters}
              setFilters={setFilters}
              label="Modal Flag"
              id="modalFlag"
              style={{ width: "30px" }}
            />
          </div>
        </Grid>
        <Grid item sm className="padding20 margin20" style={{ width: "10px" }}>
          <div style={{ width: "250px" }}>
            <SelectFilter
              filters={filters}
              setFilters={setFilters}
              label="Client"
              id="clientId"
              data={partnerList ? partnerList : ""}
              style={{ width: "30px" }}
            />
          </div>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item sm className="padding20 margin20" style={{ width: "50px" }}>
          <div style={{ width: "250px" }}>
            <SelectFilter
              filters={filters}
              setFilters={setFilters}
              label="Verification Type"
              id="verification_type"
              data={verificationType}
              multipleFlag={false}
              style={{ width: "30px" }}
            />
          </div>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <div>
      <Badge color="secondary" variant="dot" invisible={!isFilterApplied}>
        <IconButton
          className="filterButton marginLight20"
          onClick={handleClick}
        >
          <img src={FilterIcon} alt="img" />
        </IconButton>
      </Badge>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Grid container className="buttonList">
          <Grid item xs={4} style={{ padding: "10px" }}>
            <span className="FilterHeading">All Payment filters</span>
          </Grid>
          <Grid
            item
            xs={8}
            style={{ padding: "20px" }}
            className="flexRowReverse"
          >
            <Button
              variant="contained"
              color="primary"
              style={{ marginLeft: "30px", backgroundColor: "#1c75bc" }}
              onClick={handleResetFilters}
            >
              Reset
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{ marginLeft: "30px", backgroundColor: "#1c75bc" }}
              onClick={handleApplyFilters}
            >
              Apply
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{ marginLeft: "30px", backgroundColor: "#1c75bc" }}
              onClick={handleCancelFilters}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
        <Divider />
        {popoverData}
      </Popover>
    </div>
  );
}
