import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

export default function ResponseTable(props) {
  const { resData } = props;

  return (
    <TableContainer component={Paper} style={{ maxHeight: "350px" }}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>UserId</TableCell>
            <TableCell align="left">Assigned</TableCell>
            <TableCell align="left">Reason</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {typeof resData !== "string"
            ? resData?.map((row) => (
                <>
                  {!row[0].isAssigned && (
                    <TableRow
                      key={row[0].userId}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row[0].userId}
                      </TableCell>
                      <TableCell align="left">No</TableCell>
                      <TableCell align="left">
                        {row[0].assignedStatus}
                      </TableCell>
                    </TableRow>
                  )}
                </>
              ))
            : null}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
