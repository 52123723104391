import React, { useState, useEffect } from "react";
import Axios from "axios";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { FormHelperText } from "@material-ui/core";
import {
  Backdrop,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import UrlLinks from "../../../UrlLinks";
import { Link, useHistory, useParams } from "react-router-dom";
import styles from "./index.module.scss";
import EditIcon from "@mui/icons-material/Edit";
import { AppData } from "../../../context/context";
import { useContext } from "react";
import { AuthContext } from "../../../services/AuthProvider";
import MultipleSelectFilter from "./InputComponents/MultipleSelectFilter";

const useStyles = makeStyles({
  clientName: {
    "& .MuiFormControl-root": {
      "& .MuiFormLabel-root.Mui-focused": {
        color: "#1C75BC",
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "2px solid #1C75BC",
      },
    },
  },
  disabled: {
    background: "#F5F5F5",
    color: "#959595 !important",
  },
});

const AddMpa = (data) => {
  const classes = useStyles();

  const { showToast } = useContext(AuthContext);
  const { mpaId } = useParams();
  const history = useHistory();
  const [laoding, setLoading] = useState(false);
  const [alreadySelectedValue, setAlreadySelectedValue] = useState([]);
  const { mpaFormMode, setMpaFormMode } = useContext(AppData);
  const [fieldEmptyCheck, setFieldEmptyCheck] = useState(false);
  const [optionList, setOptionList] = useState({
    verticalList: [],
  });
  const [validationErr, setValidationErr] = useState({
    spocEmail: false,
    phoneNo: false,
    panNo: false,
    gstNo: false,
  });
  const [validationMessage, setValidationMessage] = useState({
    spocEmailError: "",
    phoneNoError: "",
    panNoError: "",
    gstNoError: "",
  });
  const [formData, setFormData] = useState({
    mpaType: "",
    mpaName: "",
    spocName: "",
    spocEmail: "",
    phoneNo: "",
    verticals: "",
    pan: "",
    gst: "",
  });

  useEffect(() => {
    let currentFormMode = mpaFormMode;

    if (mpaId !== "add") {
      currentFormMode = "view";
    }

    Axios.get(`${UrlLinks.baseUrl}${UrlLinks.mpaListing.getAllVerticals}`).then(
      (response) => {
        setOptionList((prev) => ({
          ...prev,
          verticalList: makeVerticalData(response.data.response),
        }));
      }
    );

    if (currentFormMode === "view") {
      getProjectsById(mpaId);
    }

    setMpaFormMode(currentFormMode);
  }, []);

  useEffect(() => {
    setFieldEmptyCheck(!validateFormData(formData));
  }, [formData]);

  useEffect(() => {
    if (mpaFormMode !== "view") {
      return;
    }
    getProjectsById(mpaId);
  }, [optionList]);

  useEffect(() => {
    setFormData({
      ...formData,
      verticals: alreadySelectedValue.map((e) => e.name).join(","),
    });
  }, [alreadySelectedValue]);

  const makeVerticalData = (data) => {
    let list = [];
    data.map((el) => {
      list.push({
        id: el.typeKey,
        name: el.verticalName,
      });
      return true;
    });
    return list.sort((a, b) => a.id - b.id);
  };

  const getProjectsById = (id) => {
    setMpaFormMode("view");

    Axios.get(
      `${UrlLinks.baseUrl}${UrlLinks.mpaListing.mainListing}?mpa_id=${id}`
    ).then((response) => {
      let data = response.data.response[0];
      let verticalIdArray = data.vertical.split(",").map(Number);
      let verticalNames = optionList.verticalList
        .filter((item) => verticalIdArray.includes(item.id))
        .map((item) => item.name);

      let newFormData = {
        mpaType: data.is_company === 1 ? "company" : "individual",
        mpaName: data.mpa_name ? data.mpa_name : "",
        spocName: data.spoc_name ? data.spoc_name : "",
        spocEmail: data.spoc_email_id ? data.spoc_email_id : "",
        phoneNo: data.phone_number ? data.phone_number : "",
        verticals: verticalNames ? verticalNames.join(",") : "",
        pan: data.pan ? data.pan : "",
        gst: data.gst ? data.gst : "",
      };
      setFormData(newFormData);
      setAlreadySelectedValue(
        optionList.verticalList.filter((item) =>
          verticalIdArray.includes(item.id)
        )
      );
    });
  };

  const validateFormData = (formData) => {
    // Loop through the keys in the formData object
    if (formData.mpaType === "individual") {
      for (const key in formData) {
        if (key === "gst") continue;
        if (key === "mpaName") continue;
        if (!formData[key]) {
          return false;
        }
      }
      return true;
    } else {
      for (const key in formData) {
        if (key === "pan") continue;
        if (!formData[key]) {
          return false;
        }
      }
      return true;
    }
  };

  const handleInputChange = (e, type) => {
    e.preventDefault();
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    const phonePattern = /^\d{10}$/;
    const panPattern = /^[A-Z]{5}[0-9]{4}[A-Z]$/;
    const gstPattern =
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;

    if (type === 1) {
      setFormData((prev) => ({ ...prev, mpaType: e.target.value }));
    } else if (type === 2) {
      setFormData((prev) => ({
        ...prev,
        mpaName: e.target.value.replace(/[^a-zA-Z ]/gi, ""),
      }));
    } else if (type === 3) {
      setFormData((prev) => ({
        ...prev,
        spocName: e.target.value.replace(/[^a-zA-Z ]/gi, ""),
      }));
    } else if (type === 4) {
      if (!emailPattern.test(e.target.value) && e.target.value !== "") {
        setValidationErr({ ...validationErr, spocEmail: true });
        setValidationMessage((prev) => ({
          ...prev,
          spocEmailError: "Invalid Email ID.",
        }));
      } else {
        setValidationErr({ ...validationErr, spocEmail: false });
        setValidationMessage((prev) => ({ ...prev, spocEmailError: "" }));
      }
      setFormData((prev) => ({ ...prev, spocEmail: e.target.value }));
    } else if (type === 5) {
      if (!phonePattern.test(e.target.value) && e.target.value !== "") {
        setValidationErr({ ...validationErr, phoneNo: true });
        setValidationMessage((prev) => ({
          ...prev,
          phoneNoError: "Invalid Phone No.",
        }));
      } else {
        setValidationErr({ ...validationErr, phoneNo: false });
        setValidationMessage((prev) => ({ ...prev, phoneNoError: "" }));
      }
      setFormData((prev) => ({ ...prev, phoneNo: e.target.value }));
    } else if (type === 6) {
      if (!panPattern.test(e.target.value) && e.target.value !== "") {
        setValidationErr({ ...validationErr, panNo: true });
        setValidationMessage((prev) => ({
          ...prev,
          panNoError: "Invalid Pan No.",
        }));
      } else {
        setValidationErr({ ...validationErr, panNo: false });
        setValidationMessage((prev) => ({ ...prev, panNoError: "" }));
      }
      setFormData((prev) => ({ ...prev, pan: e.target.value }));
    } else if (type === 7) {
      if (!gstPattern.test(e.target.value) && e.target.value !== "") {
        setValidationErr({ ...validationErr, gstNo: true });
        setValidationMessage((prev) => ({
          ...prev,
          gstNoError: "Invalid GST No.",
        }));
      } else {
        setValidationErr({ ...validationErr, gstNo: false });
        setValidationMessage((prev) => ({ ...prev, gstNoError: "" }));
      }
      setFormData((prev) => ({ ...prev, gst: e.target.value }));
    }
  };

  const handleSaveBtn = () => {
    if (mpaFormMode === "create") {
      Axios.post(
        `${UrlLinks.baseUrl}${UrlLinks.mpaListing.AddMpaUser}?isCompany=${
          formData.mpaType === "individual" ? 0 : 1
        }${
          formData.mpaType === "individual"
            ? `&mpaName=${formData.spocName}`
            : `&mpaName=${formData.mpaName}`
        }${formData.spocName !== "" ? `&spocName=${formData.spocName}` : ""}${
          formData.spocEmail !== "" ? `&spocEmailId=${formData.spocEmail}` : ""
        }${formData.phoneNo !== "" ? `&phoneNumber=${formData.phoneNo}` : ""}${
          alreadySelectedValue.length !== 0
            ? `&vertical=${alreadySelectedValue.map((e) => e.id).join(",")}`
            : ""
        }${formData.pan !== "" ? `&pan=${formData.pan}` : ""}${
          formData.gst !== "" ? `&gst=${formData.gst}` : ""
        }`,
        {}
      ).then((response) => {
        if (response.data.response.mpa_id) {
          showToast("success", "New MPA added successfully");
          history.push(`/mpaConfig/mpa/${response.data.response.mpa_id}`);
          getProjectsById(response.data.response.mpa_id);
        } else {
          showToast("error", response.data.response);
        }
      });
    }

    if (mpaFormMode === "edit") {
      Axios.post(
        `${UrlLinks.baseUrl}${UrlLinks.mpaListing.AddMpaUser}?mpaName=${
          formData.mpaType === "individual"
            ? formData.spocName
            : formData.mpaName
        }${formData.spocName !== "" ? `&spocName=${formData.spocName}` : ""}${
          formData.spocEmail !== "" ? `&spocEmailId=${formData.spocEmail}` : ""
        }${formData.phoneNo !== "" ? `&phoneNumber=${formData.phoneNo}` : ""}${
          alreadySelectedValue.length !== 0
            ? `&vertical=${alreadySelectedValue.map((e) => e.id).join(",")}`
            : ""
        }${formData.pan !== "" ? `&pan=${formData.pan}` : ""}${
          formData.gst !== "" ? `&gst=${formData.gst}` : ""
        }`,
        {}
      ).then((response) => {
        if (response.data.response.mpa_id) {
          showToast("success", "MPA is successfully updated");
          history.push(`/mpaConfig/mpa/${mpaId}`);
          getProjectsById(mpaId);
        } else {
          showToast("error", response.data.response);
        }
      });
    }
  };

  return (
    <>
      <div>
        <div>
          <div className={styles.mainBox}>
            <div className={styles.InputContainer}>
              <div className={styles.BackNavigator}>
                <div>
                  <Link to="/mpaConfig" style={{ textDecoration: "none" }}>
                    <ArrowBackIcon
                      sx={{
                        color: "black",
                        fontSize: "40",
                        marginBottom: "-5px",
                      }}
                      onClick={() => {
                        setMpaFormMode("create");
                      }}
                    ></ArrowBackIcon>
                  </Link>
                  <span className={styles.formHeading}>
                    {" "}
                    {data.match.params.mpaId === "add"
                      ? "Add MPA"
                      : formData.mpaName}
                  </span>
                </div>
                {mpaFormMode === "view" && (
                  <div>
                    <button
                      className={styles.editBtn}
                      onClick={() => {
                        setMpaFormMode("edit");
                      }}
                    >
                      {" "}
                      <EditIcon /> <span>Edit</span>{" "}
                    </button>
                  </div>
                )}
              </div>
              <br></br>
              <div className={styles.InputBox}>
                <span
                  className={`${styles.formFont} ${
                    mpaFormMode === "view"
                      ? styles.lightText
                      : styles.requiredField
                  }`}
                >
                  MPA Type
                </span>
                {mpaFormMode === "view" ? (
                  <p>{formData.mpaType}</p>
                ) : (
                  <FormControl disabled={mpaFormMode === "edit"}>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "50%",
                      }}
                    >
                      <FormControlLabel
                        value="individual"
                        checked={formData.mpaType === "individual"}
                        control={<Radio />}
                        label="Individual"
                        onChange={(e) => handleInputChange(e, 1)}
                      />
                      <FormControlLabel
                        value="company"
                        checked={formData.mpaType === "company"}
                        control={<Radio />}
                        label="Company"
                        onChange={(e) => handleInputChange(e, 1)}
                      />
                    </RadioGroup>
                  </FormControl>
                )}
              </div>
              {mpaFormMode === "create" ||
              ((mpaFormMode === "view" || mpaFormMode === "edit") &&
                formData.mpaType === "company") ? (
                <div className={`${styles.InputBox} ${classes.clientName}`}>
                  <span
                    className={`${styles.formFont} ${
                      mpaFormMode === "view" ||
                      formData.mpaType === "" ||
                      formData.mpaType === "individual"
                        ? styles.lightText
                        : styles.requiredField
                    }`}
                  >
                    MPA name
                  </span>
                  {mpaFormMode === "view" ? (
                    <p>{formData.mpaName}</p>
                  ) : (
                    <FormControl variant="outlined">
                      <TextField
                        id="outlined-adornment-weight"
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                        }}
                        className={styles.textComponent}
                        InputProps={{
                          classes: {
                            disabled: classes.disabled,
                          },
                        }}
                        label="Type MPA name"
                        value={formData.mpaName}
                        onChange={(e) => {
                          handleInputChange(e, 2);
                        }}
                        disabled={
                          formData.mpaType === "" ||
                          mpaFormMode === "edit" ||
                          formData.mpaType === "individual"
                        }
                      />
                    </FormControl>
                  )}
                </div>
              ) : (
                <FormControl variant="outlined"></FormControl>
              )}
              <div className={`${styles.InputBox} ${classes.clientName}`}>
                <span
                  className={`${styles.formFont} ${
                    mpaFormMode === "view" || formData.mpaType === ""
                      ? styles.lightText
                      : styles.requiredField
                  }`}
                >
                  SPOC Name
                </span>
                {mpaFormMode === "view" ? (
                  <p>{formData.spocName}</p>
                ) : (
                  <FormControl variant="outlined">
                    <TextField
                      id="outlined-adornment-weight"
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        "aria-label": "weight",
                      }}
                      className={styles.textComponent}
                      InputProps={{
                        classes: {
                          disabled: classes.disabled,
                        },
                      }}
                      label="Type SPOC name"
                      value={formData.spocName}
                      onChange={(e) => {
                        handleInputChange(e, 3);
                      }}
                      disabled={formData.mpaType === ""}
                    />
                  </FormControl>
                )}
              </div>
              <div className={`${styles.InputBox} ${classes.clientName}`}>
                <span
                  className={`${styles.formFont} ${
                    mpaFormMode === "view" || formData.mpaType === ""
                      ? styles.lightText
                      : styles.requiredField
                  }`}
                >
                  SPOC Email ID
                </span>
                {mpaFormMode === "view" ? (
                  <p>{formData.spocEmail}</p>
                ) : (
                  <FormControl variant="outlined">
                    <TextField
                      id="outlined-adornment-weight"
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        "aria-label": "weight",
                      }}
                      className={styles.textComponent}
                      InputProps={{
                        classes: {
                          disabled: classes.disabled,
                        },
                      }}
                      label="Type SPOC email ID"
                      value={formData.spocEmail}
                      onChange={(e) => {
                        handleInputChange(e, 4);
                      }}
                      disabled={formData.mpaType === ""}
                    />
                  </FormControl>
                )}
                {validationErr.spocEmail && (
                  <FormHelperText
                    style={{ color: "red", fontSize: "12px" }}
                    id="component-error-text"
                  >
                    {validationMessage.spocEmailError}
                  </FormHelperText>
                )}
              </div>
              <div className={`${styles.InputBox} ${classes.clientName}`}>
                <span
                  className={`${styles.formFont} ${
                    mpaFormMode === "view" || formData.mpaType === ""
                      ? styles.lightText
                      : styles.requiredField
                  }`}
                >
                  Phone No
                </span>
                {mpaFormMode === "view" ? (
                  <p>{formData.phoneNo}</p>
                ) : (
                  <FormControl variant="outlined">
                    <TextField
                      id="outlined-adornment-weight"
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        "aria-label": "weight",
                      }}
                      className={styles.textComponent}
                      InputProps={{
                        classes: {
                          disabled: classes.disabled,
                        },
                      }}
                      label="Type phone no"
                      value={formData.phoneNo}
                      onChange={(e) => {
                        handleInputChange(e, 5);
                      }}
                      disabled={formData.mpaType === ""}
                    />
                  </FormControl>
                )}
                {validationErr.phoneNo && (
                  <FormHelperText
                    style={{ color: "red", fontSize: "12px" }}
                    id="component-error-text"
                  >
                    {validationMessage.phoneNoError}
                  </FormHelperText>
                )}
              </div>
              <div className={`${styles.InputBox} ${classes.clientName}`}>
                <span
                  className={`${styles.formFont} ${
                    mpaFormMode === "view" || formData.mpaType === ""
                      ? styles.lightText
                      : styles.requiredField
                  }`}
                >
                  Verticals
                </span>
                {mpaFormMode === "view" ? (
                  <p>{formData.verticals}</p>
                ) : (
                  <MultipleSelectFilter
                    id="verticals"
                    options={optionList.verticalList}
                    multipleFlag={true}
                    disabled={formData.mpaType === ""}
                    alreadySelectedValue={alreadySelectedValue}
                    setAlreadySelectedValue={setAlreadySelectedValue}
                  />
                )}
              </div>
              {mpaFormMode === "create" ||
              ((mpaFormMode === "view" || mpaFormMode === "edit") &&
                formData.mpaType === "individual") ? (
                <div className={`${styles.InputBox} ${classes.clientName}`}>
                  <span
                    className={`${styles.formFont} ${
                      mpaFormMode === "view" ||
                      formData.mpaType === "" ||
                      formData.mpaType === "company"
                        ? styles.lightText
                        : styles.requiredField
                    }`}
                  >
                    PAN No
                  </span>
                  {mpaFormMode === "view" ? (
                    <p>{formData.pan}</p>
                  ) : (
                    <FormControl variant="outlined">
                      <TextField
                        id="outlined-adornment-weight"
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                        }}
                        className={styles.textComponent}
                        InputProps={{
                          classes: {
                            disabled: classes.disabled,
                          },
                        }}
                        label="Type PAN no"
                        value={formData.pan}
                        onChange={(e) => {
                          handleInputChange(e, 6);
                        }}
                        disabled={
                          formData.mpaType === "" ||
                          formData.mpaType === "company"
                        }
                      />
                    </FormControl>
                  )}
                  {validationErr.panNo && (
                    <FormHelperText
                      style={{ color: "red", fontSize: "12px" }}
                      id="component-error-text"
                    >
                      {validationMessage.panNoError}
                    </FormHelperText>
                  )}
                </div>
              ) : (
                ""
              )}
              {mpaFormMode === "create" ||
              ((mpaFormMode === "view" || mpaFormMode === "edit") &&
                formData.mpaType === "company") ? (
                <div className={`${styles.InputBox} ${classes.clientName}`}>
                  <span
                    className={`${styles.formFont} ${
                      mpaFormMode === "view" ||
                      formData.mpaType === "" ||
                      formData.mpaType === "individual"
                        ? styles.lightText
                        : styles.requiredField
                    }`}
                  >
                    GST No
                  </span>
                  {mpaFormMode === "view" ? (
                    <p>{formData.gst}</p>
                  ) : (
                    <FormControl variant="outlined">
                      <TextField
                        id="outlined-adornment-weight"
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                        }}
                        className={styles.textComponent}
                        InputProps={{
                          classes: {
                            disabled: classes.disabled,
                          },
                        }}
                        label="Type GST no"
                        value={formData.gst}
                        onChange={(e) => {
                          handleInputChange(e, 7);
                        }}
                        disabled={
                          formData.mpaType === "" ||
                          formData.mpaType === "individual"
                        }
                      />
                    </FormControl>
                  )}
                  {validationErr.gstNo && (
                    <FormHelperText
                      style={{ color: "red", fontSize: "12px" }}
                      id="component-error-text"
                    >
                      {validationMessage.gstNoError}
                    </FormHelperText>
                  )}
                </div>
              ) : (
                ""
              )}
            </div>

            <div className={styles.saveCancel}>
              <div className={styles.BtnBox}>
                <Link to="/mpaConfig" style={{ textDecoration: "none" }}>
                  {" "}
                  <Button
                    onClick={() => {
                      setMpaFormMode("create");
                    }}
                    variant="outlined"
                  >
                    Cancel
                  </Button>{" "}
                </Link>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Button
                  disabled={
                    mpaFormMode === "view" ||
                    validationErr.spocEmail ||
                    validationErr.phoneNo ||
                    validationErr.panNo ||
                    validationErr.gstNo ||
                    fieldEmptyCheck
                  }
                  onClick={handleSaveBtn}
                  variant="contained"
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </div>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={laoding}
          //   onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    </>
  );
};
export default AddMpa;
