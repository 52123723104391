import React from "react";
import styles from "./Functions.module.scss";
import SearchIcon from "@material-ui/icons/Search";
import CloseSharpIcon from "@material-ui/icons/CloseSharp";
import WarningIcon from "@mui/icons-material/Warning";
import {
  IconButton,
  InputAdornment,
  makeStyles,
  TextField,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-root": {
      marginLeft: "0",
      "&.Mui-focused fieldset": {
        border: "1px solid grey",
      },
    },
    "& .MuiOutlinedInput-adornedStart": {
      padding: "0",
    },
  },
  input: {
    '& [class*="MuiOutlinedInput-input"]': {
      padding: "8px 135px 8px 8px",
      borderLeft: "1px solid grey",
      borderRadius: "0px",
    },
    "& .MuiInputAdornment-positionStart": {
      margin: "0 8px",
    },
  },
}));

const SearchBar = (props) => {
  const {
    uniqueId,
    searchBy,
    setSearchBy,
    searchError,
    handleSearch,
    handleSearchClose,
    placeholder,
    buttonName,
    searchInstructions,
    warningMessage,
  } = props;
  const classes = useStyles();

  return (
    <div className={`${styles.searchBarMain}`}>
      <div style={{ display: "flex" }}>
        <TextField
          className={`${classes.root} marginLeft0`}
          placeholder={placeholder}
          fullWidth
          value={searchBy}
          onChange={(event) => setSearchBy(event.target.value)}
          variant="outlined"
          onKeyPress={(e) => {
            if (e.key === "Enter" && !searchError) {
              handleSearch();
            }
          }}
          InputProps={{
            className: classes.input,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <div className={styles.searchButtonDiv}>
          <IconButton
            className={`${styles.closeButton} ${
              searchBy ? "" : styles.noVisibility
            }`}
            onClick={handleSearchClose}
          >
            <CloseSharpIcon />
          </IconButton>
          <button
            onClick={!searchError ? handleSearch : undefined}
            className={styles.searchButton}
            disabled={searchError}
          >
            {buttonName}
          </button>
        </div>
      </div>
      {!searchError ? (
        <div className={`searchInstruction ${styles.searchInstruction}`}>
          {searchInstructions}
        </div>
      ) : (
        <div
          className={`searchInstructionError ${styles.searchInstructionError}`}
        >
          <WarningIcon />
          &nbsp;{warningMessage}
        </div>
      )}
    </div>
  );
};

export default SearchBar;
