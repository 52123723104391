import React, { useEffect, useState } from "react";
import styles from "../index.module.scss";
import { CircularProgress } from "@material-ui/core";

const DocumentDownload = (props) => {
  const { row, columnName } = props;

  return row[columnName] ? (
    columnName === "bgvStatus" || columnName === "bgvValidation" ? (
      <div style={{ textAlign: "center", width: "87px" }}>
        <a href={row[columnName]} target="_blank">
          Link
        </a>
      </div>
    ) : (
      <div style={{ textAlign: "center", width: "87px" }}>
        <a href={row[columnName]} target="_blank">
          View
        </a>
      </div>
    )
  ) : (
    <div style={{ width: "87px" }}>-</div>
  );
};

export default DocumentDownload;
