import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as Axios from "axios";
import { useDebounce } from "use-debounce";
import UrlLinks from "../../../UrlLinks";

export default function Asynchronous(props) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const { facilityData, setFacilityData, filters, setFilters, id } = props;
  const [searchBy, setSearchBy] = useState("");
  const [debouncedText] = useDebounce(searchBy, 350);
  let loading = false;

  const onChangeHandle = (value) => {
    setSearchBy(value);
  };

  const getOptionsData = () => {
    if (debouncedText) {
      loading = true;
      Axios.get(
        `${UrlLinks.baseUrl}${UrlLinks.workerUrls.leadCities}${debouncedText}`
      ).then((response) => {
        setOptions(makeLeadCityData(response.data.response));
        loading = false;
      });
    }
  };
  useEffect(() => {
    getOptionsData();
  }, [debouncedText]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const makeLeadCityData = (data) => {
    let list = [];
    data.content.map((el) => {
      if (el !== null && el !== "") {
        list.push({
          id: el,
          name: el,
        });
      }
      return true;
    });
    return list;
  };

  return (
    <Autocomplete
      id="FacilitySearch"
      freeSolo
      value={filters[id]}
      onChange={(event, value) => setFilters({ ...filters, [id]: value })}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionLabel={(option) => option.name}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          placeholder="Search and select"
          onChange={(ev) => {
            if (ev.target.value !== "" || ev.target.value !== null) {
              onChangeHandle(ev.target.value);
            }
          }}
          InputProps={{
            ...params.InputProps,
            style: {
              width: 240,
              borderRadius: 0,
              padding: 0,
              marginTop: "-6px",
            },
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
