import React, {useState, } from 'react';
import {Button ,Divider , Grid } from '@material-ui/core';

export default function SimplePopover(props) {
    const [filters, setFilters] = useState(props.filters)
    const handleResetFilters =() =>{
        const newFilters=props.resetFilters()
        setFilters(newFilters);        
    }
    const handleApplyFilters =() =>{
        props.updateFilters(filters);
        props.setFilterDrawer(false)
    }
    const handleCancelFilters =() =>{
        setFilters(props.filters);
        props.setFilterDrawer(false)
    }
    let popoverData= <Grid></Grid>
    
    popoverData= 
        <Grid container className="padding10" style={{width:"350px", display:"flex", flexDirection:"row"}}>
        </Grid>
    return (
        <Grid container className="flexDisplay " direction= "column" style={{ marginTop:"40px"}}>
            <Grid item xs={12} className="flexDisplay padding10" direction ="row">
                <Grid item xs={5} className="padding0">
                    <span className= "FilterHeading">Other Filters</span>
                </Grid>
                <Grid item xs={7}  className="flexRowReverse padding0">      
                    <Button 
                        className= "dataButton"
                        style={{marginLeft: "30px"}}
                        onClick={handleResetFilters}
                    >
                        Reset
                    </Button>
                </Grid>
            </Grid>
            <Divider />
                {popoverData}
            <Divider />
            <Grid container style={{padding: "20px"}}>      
                <Button 
                    className= "dataButton"
                    style={{margin: "0 30px"}}
                    onClick={handleApplyFilters}
                >
                    Apply
                </Button>
                <Button 
                    className= "dataButton"
                    onClick={handleCancelFilters}
                >
                    Cancel
                </Button>
            </Grid>
        </Grid>
    );
}
