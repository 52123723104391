import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Axios from "axios";
import document from "../document.svg";
import ResponseModal from "./response";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import Styles from "../index.module.scss";
import UrlLinks from "../../../UrlLinks";
import Download from "../Group 3238.svg";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    borderBottom: "unset",
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  loader: {
    marginTop: "8rem",
  },
  paper: {
    marginTop: 15,
    height: 270,
    width: 330,
    borderRadius: 12,
    backgroundColor: "rgba(255, 255, 255, 0.78)",
  },
  inputRoot: {
    // This matches the specificity of the default styles at https://github.com/mui-org/material-ui/blob/v4.11.3/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L90
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      // Default left padding is 6px
      color: "black",
      fontSize: "0.8rem",
    },
    "& .MuiOutlinedInput-notchedOutline": {},
    "& .MuiSvgIcon-root": {
      fontSize: "2rem",
    },
  },
  popupIndicator: {
    fill: "black",
    color: "black",
  },
}));
export default function CustomizedDialogs(props) {
  const {
    openOnboardingModal,
    setOpenOnboardingModal,
    showToast,
    updateMyData,
  } = props;
  const [excel, setExcel] = useState(null);
  const classes = useStyles();
  const FirstUpperCase = (str) => {
    let s = "";
    s = str;

    if (s.length > 1) {
      let sub = s.substring(0, 1).toUpperCase();
      let remaining = s.substring(1, s.length);
      return sub + remaining;
    }
  };
  let city = "Select City";
  const upperCaseCity = FirstUpperCase(city);
  const [openResponseModal, setOpenResponseModal] = useState(false);
  const [name, setName] = useState("");
  const [options, setOptions] = useState([]);
  const [cityChange, setCityChange] = useState(false);
  const [currentCity, steCurrentCity] = useState(upperCaseCity);
  const [val, setVal] = useState({ title: currentCity });
  const [excelError, setExcelError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [invalidData, setInvalidData] = useState();
  const [responseData, setResponseData] = useState();
  const [inputValue, setInputValue] = useState("");
  const [closeFile, setCloseFile] = useState(true);

  const handleClose = () => {
    setOpenOnboardingModal(false);
  };
  const handleCloseFile = () => {
    setName("");
    setCloseFile(true);
  };
  const handleUpload = (event) => {
    const fileExtension = event.target.files[0].name.split(".").slice(-1)[0];
    const fileName = event.target.files[0].name;
    if (
      fileExtension.toLowerCase() === "xlsx" ||
      fileExtension.toLowerCase() === "xls"
    ) {
      setExcel(event.target.files[0]);
      setName(fileName);
      setExcelError(false);
      setCloseFile(false);
    } else {
      setExcelError(true);
      setExcel(null);
      setName("");
    }
    setResponseData();
    event.target.value = null;
  };

  const createInvalidData = (records) => {
    if (records.response.invalidExcel.length > 0) {
      let list = [];
      records.response.invalidExcel.map(
        (record) => {
          list.push({
            Name: record.name ? record.name : "",
            "Phone Number": record.phoneNumber ? record.phoneNumber : "",
            Area: record.area ? record.area : "",
            Company: record.company ? record.company : "",
            "Current Job": record.currentJob ? record.currentJob : "",
            Education: record.education ? record.education : "",
            Experience: record.experience ? record.experience : "",
            status: record.status ? record.status : "",
            feedback: record.feedback ? record.feedback : "",
            "Applied On": record.appliedOn ? record.appliedOn : "",
            "Reason of failure": record.reason ? record.reason : "",
          });

          return true;
        }
        // }
      );
      return list.length > 0 ? list : null;
    }
  };
  const handleExcel = () => {
    setIsLoading(true);
    let formData = new FormData();
    formData.append("file", excel);

    Axios.post(`${UrlLinks.baseUrl}upload/apnaLeads?insertedBy=1`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        if (response.data) {
          setInvalidData(createInvalidData(response.data));
          setResponseData(response.data.response);
          updateMyData();
          setIsLoading(false);
        } else {
          setResponseData([]);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        showToast("error", error.message);
      });
  };
  let ApnaOnboardingExcel = "/Upload_Leads_Template.xlsx";
  useEffect(() => {
    if (responseData) {
      setOpenResponseModal(true);
    }
  }, [responseData]);

  let dialogData = (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={openOnboardingModal}
      classes={{ paper: Styles.paper }}
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        <span>Upload leads data</span>
        <a
          style={{ paddingRight: "2.5rem" }}
          href={ApnaOnboardingExcel}
          download
          target="_blank"
          className={Styles.downloadTemplate}
        >
          <span>Download File Template</span>
          <img
            style={{ paddingLeft: "0.5rem" }}
            src={Download}
            alt="download"
          />
        </a>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container item xs={12} className="padding0">
          <input
            style={{ display: "none" }}
            id="contained-button-file"
            type="file"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            onChange={handleUpload}
          />
          <label htmlFor="contained-button-file">
            <Button
              variant="outlined"
              className={Styles.uploadButton}
              component="span"
              startIcon={
                <img src={document} alt={"upload"} style={{ color: "white" }} />
              }
            >
              Browse File
            </Button>
          </label>
        </Grid>
        <Grid item xs={12} className="padding0">
          <span className={`fontSize13 ${excelError ? "redColor" : ""}`}>
            only .xls and .xlsx are allowed
          </span>
        </Grid>
        <Grid item xs={12} className="padding0" style={{ marginTop: "10px" }}>
          {name && (
            <span className={Styles.fileName}>
              {name.length > 70 ? `${name.substring(0, 69)}...` : `${name}`}
              <span>
                <IconButton
                  aria-label="close"
                  style={{ color: "white", paddingRight: "0" }}
                  onClick={handleCloseFile}
                >
                  <CloseIcon />
                </IconButton>
              </span>
            </span>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        {isLoading ? (
          <span>Please wait... it may take few minutes</span>
        ) : (
          <Button
            variant="contained"
            onClick={handleExcel}
            className={`${!excel ? "disabledButton" : "blueButtonContained"}`}
            disabled={!excel}
          >
            Upload
          </Button>
        )}
      </DialogActions>
      {openResponseModal && (
        <ResponseModal
          openResponseModal={openResponseModal}
          setOpenResponseModal={setOpenResponseModal}
          responseData={responseData}
          setOpenOnboardingModal={setOpenOnboardingModal}
          showToast={showToast}
          invalidData={invalidData}
          updateMyData={updateMyData}
          agreement={true}
          type="Onboarding"
        />
      )}
    </Dialog>
  );

  return dialogData;
}
