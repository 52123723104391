import React, { useState, useEffect } from "react";
import Axios from "axios";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Divider,
  FormHelperText,
  MenuItem,
  Select,
  TextareaAutosize,
  Tooltip,
} from "@material-ui/core";
import {
  Backdrop,
  Button,
  CircularProgress,
  FormControl,
  TextField,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert";
import AutoSelect from "./InputComponents/AutoSelect";
import "./index.css";
import axios from "axios";
import UrlLinks from "../../../UrlLinks";
import { Link, useHistory, useParams } from "react-router-dom";
import styles from "./index.module.scss";
import InfoIcon from "@mui/icons-material/Info";
import EditIcon from "@mui/icons-material/Edit";
import { AppData } from "../../../context/context";
import { useContext } from "react";
import { AuthContext } from "../../../services/AuthProvider";
import exclamation_mark from "../exclamation-mark.svg";

const useStyles = makeStyles({
  clientName: {
    "& .MuiFormControl-root": {
      "& .MuiFormLabel-root.Mui-focused": {
        color: "#1C75BC",
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "2px solid #1C75BC",
      },
    },
  },
});

const AddProject = (data) => {
  const classes = useStyles();
  const { projectId } = useParams();
  const { showToast } = useContext(AuthContext);
  const [tatFormat, setTatFormat] = useState({
    overall_tat: "1",
    open_to_assign: "1",
    assign_to_submission: "1",
    submission_to_qc: "1",
    rework_to_submission: "1",
  });
  const [prevTatFormat, setPrevTatFormat] = useState(tatFormat);
  const history = useHistory();
  const [companyName, setCompanyName] = useState("");
  const [paymentConfiguration, setPaymentConfiguration] = useState(null);
  const [laoding, setLoading] = useState(false);
  const [document, setDocument] = useState({
    adhr: false,
    rc: false,
    pan: false,
    dl: false,
    address: false,
  });

  const [formData, setFormData] = useState({
    overall_tat_days: "",
    overall_tat_hours: "",
    open_to_assign_days: "",
    open_to_assign_hours: "",
    assign_to_submission_days: "",
    assign_to_submission_hours: "",
    submission_to_qc_days: "",
    submission_to_qc_hours: "",
    rework_to_submission_days: "",
    rework_to_submission_hours: "",
    workflow_id: projectId,
  });
  const { formMode, setFormMode } = useContext(AppData);
  const [validationErr, setValidationErr] = useState({
    sumTotal: false,
  });

  const handleInputChange = (e, type) => {
    e.preventDefault();

    let finalSum =
      formData["overall_tat_days"] !== ""
        ? formData["overall_tat_days"]
        : formData["overall_tat_hours"];
    let overallSum = 0;
    const regex = /^[1-9]\d*$/;

    if (type === 1 && (e.target.value === "" || regex.test(e.target.value))) {
      tatFormat.overall_tat == 1
        ? setFormData((prev) => ({
            ...prev,
            overall_tat_days: e.target.value,
            overall_tat_hours: "",
          }))
        : setFormData((prev) => ({
            ...prev,
            overall_tat_days: "",
            overall_tat_hours: e.target.value,
          }));

      Object.keys(formData).map((i) => {
        if (
          i !== "overall_tat_days" &&
          i !== "overall_tat_hours" &&
          i !== "workflow_id"
        ) {
          overallSum += +formData[i];
        }
      });
      overallSum > e.target.value
        ? setValidationErr((prev) => ({ ...prev, sumTotal: true }))
        : setValidationErr((prev) => ({ ...prev, sumTotal: false }));
    } else if (
      type === 2 &&
      (e.target.value === "" || regex.test(e.target.value))
    ) {
      tatFormat.open_to_assign == 1
        ? setFormData((prev) => ({
            ...prev,
            open_to_assign_days: e.target.value,
            open_to_assign_hours: "",
          }))
        : setFormData((prev) => ({
            ...prev,
            open_to_assign_days: "",
            open_to_assign_hours: e.target.value,
          }));

      Object.keys(formData).map((i) => {
        if (
          i !== "overall_tat_days" &&
          i !== "overall_tat_hours" &&
          i !== "open_to_assign_days" &&
          i !== "open_to_assign_hours" &&
          i !== "workflow_id"
        ) {
          overallSum += +formData[i];
        }
      });
      overallSum += +e.target.value;
      overallSum > finalSum
        ? setValidationErr((prev) => ({ ...prev, sumTotal: true }))
        : setValidationErr((prev) => ({ ...prev, sumTotal: false }));
    } else if (
      type === 3 &&
      (e.target.value === "" || regex.test(e.target.value))
    ) {
      tatFormat.assign_to_submission == 1
        ? setFormData((prev) => ({
            ...prev,
            assign_to_submission_days: e.target.value,
            assign_to_submission_hours: "",
          }))
        : setFormData((prev) => ({
            ...prev,
            assign_to_submission_days: "",
            assign_to_submission_hours: e.target.value,
          }));

      Object.keys(formData).map((i) => {
        if (
          i !== "overall_tat_days" &&
          i !== "overall_tat_hours" &&
          i !== "assign_to_submission_days" &&
          i !== "assign_to_submission_hours" &&
          i !== "workflow_id"
        ) {
          overallSum += +formData[i];
        }
      });
      overallSum += +e.target.value;
      overallSum > finalSum
        ? setValidationErr((prev) => ({ ...prev, sumTotal: true }))
        : setValidationErr((prev) => ({ ...prev, sumTotal: false }));
    } else if (
      type === 4 &&
      (e.target.value === "" || regex.test(e.target.value))
    ) {
      tatFormat.submission_to_qc == 1
        ? setFormData((prev) => ({
            ...prev,
            submission_to_qc_days: e.target.value,
            submission_to_qc_hours: "",
          }))
        : setFormData((prev) => ({
            ...prev,
            submission_to_qc_days: "",
            submission_to_qc_hours: e.target.value,
          }));

      Object.keys(formData).map((i) => {
        if (
          i !== "overall_tat_days" &&
          i !== "overall_tat_hours" &&
          i !== "submission_to_qc_days" &&
          i !== "submission_to_qc_hours" &&
          i !== "workflow_id"
        ) {
          overallSum += +formData[i];
        }
      });
      overallSum += +e.target.value;
      overallSum > finalSum
        ? setValidationErr((prev) => ({ ...prev, sumTotal: true }))
        : setValidationErr((prev) => ({ ...prev, sumTotal: false }));
    } else if (
      type === 5 &&
      (e.target.value === "" || regex.test(e.target.value))
    ) {
      tatFormat.rework_to_submission == 1
        ? setFormData((prev) => ({
            ...prev,
            rework_to_submission_days: e.target.value,
            rework_to_submission_hours: "",
          }))
        : setFormData((prev) => ({
            ...prev,
            rework_to_submission_days: "",
            rework_to_submission_hours: e.target.value,
          }));

      Object.keys(formData).map((i) => {
        if (
          i !== "overall_tat_days" &&
          i !== "overall_tat_hours" &&
          i !== "rework_to_submission_days" &&
          i !== "rework_to_submission_hours" &&
          i !== "workflow_id"
        ) {
          overallSum += +formData[i];
        }
      });
      overallSum += +e.target.value;
      overallSum > finalSum
        ? setValidationErr((prev) => ({ ...prev, sumTotal: true }))
        : setValidationErr((prev) => ({ ...prev, sumTotal: false }));
    }
  };

  const handleSaveBtn = () => {
    if (formMode === "create") {
      Axios.post(
        `${UrlLinks.baseUrl}${UrlLinks.projectListing.editTat}?${
          formData.overall_tat_days
            ? `overall_tat_days=${formData.overall_tat_days}`
            : `overall_tat_hours=${formData.overall_tat_hours}`
        }${
          formData.open_to_assign_days
            ? `&open_to_assign_days=${formData.open_to_assign_days}`
            : ""
        }${
          formData.open_to_assign_hours
            ? `&open_to_assign_hours=${formData.open_to_assign_hours}`
            : ""
        }${
          formData.assign_to_submission_days
            ? `&assign_to_submission_days=${formData.assign_to_submission_days}`
            : ""
        }${
          formData.assign_to_submission_hours
            ? `&assign_to_submission_hours=${formData.assign_to_submission_hours}`
            : ""
        }${
          formData.submission_to_qc_days
            ? `&submission_to_qc_days=${formData.submission_to_qc_days}`
            : ""
        }${
          formData.submission_to_qc_hours
            ? `&submission_to_qc_hours=${formData.submission_to_qc_hours}`
            : ""
        }${
          formData.rework_to_submission_days
            ? `&rework_to_submission_days=${formData.rework_to_submission_days}`
            : ""
        }${
          formData.rework_to_submission_hours
            ? `&rework_to_submission_hours=${formData.rework_to_submission_hours}`
            : ""
        }&workflow_id=${formData.workflow_id}&userId=${localStorage.getItem(
          "userID"
        )}`,
        {}
      ).then((response) => {
        showToast("success", "TAT configured successfully", "success1");
        history.push(`/tatConfig/project/${response.data.response.workflowId}`);
        getProjectsById(response.data.response.workflowId);
      });
    }

    if (formMode === "edit") {
      Axios.post(
        `${UrlLinks.baseUrl}${UrlLinks.projectListing.editTat}?${
          formData.overall_tat_days
            ? `overall_tat_days=${formData.overall_tat_days}`
            : ""
        }${
          formData.overall_tat_hours
            ? `overall_tat_hours=${formData.overall_tat_hours}`
            : ""
        }${
          formData.open_to_assign_days
            ? `&open_to_assign_days=${formData.open_to_assign_days}`
            : ""
        }${
          formData.open_to_assign_hours
            ? `&open_to_assign_hours=${formData.open_to_assign_hours}`
            : ""
        }${
          formData.assign_to_submission_days
            ? `&assign_to_submission_days=${formData.assign_to_submission_days}`
            : ""
        }${
          formData.assign_to_submission_hours
            ? `&assign_to_submission_hours=${formData.assign_to_submission_hours}`
            : ""
        }${
          formData.submission_to_qc_days
            ? `&submission_to_qc_days=${formData.submission_to_qc_days}`
            : ""
        }${
          formData.submission_to_qc_hours
            ? `&submission_to_qc_hours=${formData.submission_to_qc_hours}`
            : ""
        }${
          formData.rework_to_submission_days
            ? `&rework_to_submission_days=${formData.rework_to_submission_days}`
            : ""
        }${
          formData.rework_to_submission_hours
            ? `&rework_to_submission_hours=${formData.rework_to_submission_hours}`
            : ""
        }${
          formData.workflow_id ? `&workflow_id=${formData.workflow_id}` : ""
        }&userId=${localStorage.getItem("userID")}`,
        {}
      ).then((response) => {
        showToast("success", "The project is successfully updated", "success1");
        history.push(`/tatConfig/project/${response.data.response.workflowId}`);
        getProjectsById(response.data.response.workflowId);
      });
    }
  };

  const handleChange = (event, type) => {
    if (type === 1) {
      setTatFormat({
        overall_tat: event.target.value.toString(),
        open_to_assign: event.target.value.toString(),
        assign_to_submission: event.target.value.toString(),
        submission_to_qc: event.target.value.toString(),
        rework_to_submission: event.target.value.toString(),
      });
    }
  };

  useEffect(() => {
    if (prevTatFormat.overall_tat != tatFormat.overall_tat) {
      if (tatFormat.overall_tat == 1) {
        setFormData((prev) => ({
          ...prev,
          overall_tat_days: formData.overall_tat_hours,
          overall_tat_hours: "",
        }));
      } else {
        setFormData((prev) => ({
          ...prev,
          overall_tat_days: "",
          overall_tat_hours: formData.overall_tat_days,
        }));
      }
    }

    if (prevTatFormat.open_to_assign != tatFormat.open_to_assign) {
      if (tatFormat.open_to_assign == 1) {
        setFormData((prev) => ({
          ...prev,
          open_to_assign_days: formData.open_to_assign_hours,
          open_to_assign_hours: "",
        }));
      } else {
        setFormData((prev) => ({
          ...prev,
          open_to_assign_days: "",
          open_to_assign_hours: formData.open_to_assign_days,
        }));
      }
    }

    if (prevTatFormat.assign_to_submission != tatFormat.assign_to_submission) {
      if (tatFormat.assign_to_submission == 1) {
        setFormData((prev) => ({
          ...prev,
          assign_to_submission_days: formData.assign_to_submission_hours,
          assign_to_submission_hours: "",
        }));
      } else {
        setFormData((prev) => ({
          ...prev,
          assign_to_submission_days: "",
          assign_to_submission_hours: formData.assign_to_submission_days,
        }));
      }
    }

    if (prevTatFormat.submission_to_qc != tatFormat.submission_to_qc) {
      if (tatFormat.submission_to_qc == 1) {
        setFormData((prev) => ({
          ...prev,
          submission_to_qc_days: formData.submission_to_qc_hours,
          submission_to_qc_hours: "",
        }));
      } else {
        setFormData((prev) => ({
          ...prev,
          submission_to_qc_days: "",
          submission_to_qc_hours: formData.submission_to_qc_days,
        }));
      }
    }

    if (prevTatFormat.rework_to_submission != tatFormat.rework_to_submission) {
      if (tatFormat.rework_to_submission == 1) {
        setFormData((prev) => ({
          ...prev,
          rework_to_submission_days: formData.rework_to_submission_hours,
          rework_to_submission_hours: "",
        }));
      } else {
        setFormData((prev) => ({
          ...prev,
          rework_to_submission_days: "",
          rework_to_submission_hours: formData.rework_to_submission_days,
        }));
      }
    }

    setPrevTatFormat(tatFormat);
  }, [tatFormat]);

  const getProjectsById = (id) => {
    Axios.get(
      `${UrlLinks.baseUrl}${UrlLinks.projectListing.getTatConfig}?workflowId=${id}`
    )
      .then((response) => {
        let data = response.data.response[0];
        if (response.data.response !== "Invalid WorkFlowID") {
          let newFormData = {
            overall_tat_days: data.overallTatDays ? data.overallTatDays : "",
            overall_tat_hours: data.overallTatHours ? data.overallTatHours : "",
            open_to_assign_days: data.openToAssignDays
              ? data.openToAssignDays
              : "",
            open_to_assign_hours: data.openToAssignHours
              ? data.openToAssignHours
              : "",
            assign_to_submission_days: data.assignToSubmissionDays
              ? data.assignToSubmissionDays
              : "",
            assign_to_submission_hours: data.assignToSubmissionHours
              ? data.assignToSubmissionHours
              : "",
            submission_to_qc_days: data.submissionToQcDays
              ? data.submissionToQcDays
              : "",
            submission_to_qc_hours: data.submissionToQcHours
              ? data.submissionToQcHours
              : "",
            rework_to_submission_days: data.reworkToSubmissionDays
              ? data.reworkToSubmissionDays
              : "",
            rework_to_submission_hours: data.reworkToSubmissionHours
              ? data.reworkToSubmissionHours
              : "",
            workflow_id: data.workflowId ? data.workflowId : "",
          };

          setFormData(newFormData);
          setFormMode("view");
        }
      })
      .catch((err) => {
        console.log("errr", err);
      });
  };

  useEffect(() => {
    let currentFormMode = formMode;

    Axios.get(
      `${UrlLinks.baseUrl}${UrlLinks.projectListing.getTatConfig}?workflowId=${projectId}`
    ).then((response) => {
      let data = response.data.response[0];

      if (data.overallTatDays === null && data.overallTatHours === null) {
        currentFormMode = "create";
      } else {
        currentFormMode = "view";
        getProjectsById(projectId);
      }
    });
  }, []);

  return (
    <>
      <div>
        <div>
          <div className={styles.mainBox}>
            <div className={styles.InputContainer}>
              <div className={styles.BackNavigator}>
                <div>
                  <Link to="/projectConfig" style={{ textDecoration: "none" }}>
                    <ArrowBackIcon
                      sx={{
                        color: "black",
                        fontSize: "40",
                        marginBottom: "-5px",
                      }}
                      onClick={() => {
                        setFormMode("create");
                      }}
                    ></ArrowBackIcon>
                  </Link>
                  <span className={styles.formHeading}> Project TAT</span>
                </div>
                {formMode === "view" && (
                  <div>
                    <button
                      className={styles.editBtn}
                      onClick={() => {
                        setFormMode("edit");
                      }}
                    >
                      {" "}
                      <EditIcon /> <span>Edit</span>{" "}
                    </button>
                  </div>
                )}
              </div>
              <br></br>
              <div className={styles.InputBox}>
                <span
                  className={`${styles.formFont} ${
                    formMode === "view"
                      ? styles.lightText
                      : styles.requiredField
                  }`}
                >
                  Overall Process TAT
                </span>
                {formMode === "view" ? (
                  <p>
                    {formData.overall_tat_days !== ""
                      ? `${formData.overall_tat_days} days`
                      : formData.overall_tat_hours !== ""
                      ? `${formData.overall_tat_hours} hours`
                      : ""}
                  </p>
                ) : (
                  <FormControl
                    variant="outlined"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <TextField
                      id="outlined-adornment-weight"
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        "aria-label": "weight",
                        min: 1,
                      }}
                      className={styles.textComponent}
                      value={
                        formData.overall_tat_days
                          ? formData.overall_tat_days
                          : formData.overall_tat_hours
                      }
                      onChange={(e) => {
                        handleInputChange(e, 1);
                      }}
                      disabled={
                        formData.is_has_job === 1 ? formMode === "edit" : false
                      }
                    />
                    <div
                      style={{
                        maxWidth: "120px",
                        marginLeft: "15px",
                      }}
                    >
                      <Select
                        value={tatFormat.overall_tat}
                        variant="outlined"
                        style={{
                          maxHeight: "40px",
                        }}
                        onChange={(e) => {
                          handleChange(e, 1);
                        }}
                      >
                        <MenuItem value={1}>Days</MenuItem>
                        <MenuItem value={2}>Hours</MenuItem>
                      </Select>
                    </div>
                  </FormControl>
                )}
              </div>
            </div>
            <div style={{ margin: "4%" }}>
              <Divider />
            </div>
            <div
              className={`${styles.formHeading}`}
              style={{ margin: "0 4% 4% 4%" }}
            >
              Stage level TAT{" "}
              <span style={{ color: "#757575", fontSize: "18px" }}>
                (optional)
              </span>
              {validationErr.sumTotal && (
                <FormHelperText
                  style={{
                    color: "red",
                    fontSize: "18px",
                    display: "flex",
                    marginTop: "2%",
                  }}
                  id="component-error-text"
                >
                  <img
                    src={exclamation_mark}
                    style={{
                      width: "20px",
                      height: "30px",
                      marginRight: "5px",
                    }}
                  />
                  Sum of stage level TATs is more than overall TAT value.
                </FormHelperText>
              )}
            </div>
            <div className={styles.InputContainer}>
              <div className={styles.InputBox}>
                <span className={`${styles.formFont}`}>
                  Open to Assign
                  <Tooltip
                    title={
                      <div
                        style={{
                          maxWidth: "192px",
                          fontSize: "14px",
                          padding: "3px 0",
                        }}
                      >
                        TAT from task upload to task assignment
                      </div>
                    }
                  >
                    <InfoIcon
                      style={{ height: "20px", marginBottom: "-3px" }}
                    />
                  </Tooltip>
                </span>
                {formMode === "view" ? (
                  <p>
                    {formData.open_to_assign_days !== ""
                      ? `${formData.open_to_assign_days} days`
                      : formData.open_to_assign_hours !== ""
                      ? `${formData.open_to_assign_hours} hours`
                      : ""}
                  </p>
                ) : (
                  <FormControl
                    variant="outlined"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <TextField
                      id="outlined-adornment-weight"
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        "aria-label": "weight",
                      }}
                      className={styles.textComponent}
                      value={
                        formData.open_to_assign_days
                          ? formData.open_to_assign_days
                          : formData.open_to_assign_hours
                      }
                      onChange={(e) => {
                        handleInputChange(e, 2);
                      }}
                      disabled={
                        formData.is_has_job === 1 ? formMode === "edit" : false
                      }
                    />
                    <div
                      style={{
                        maxWidth: "120px",
                        marginLeft: "15px",
                      }}
                    >
                      <Button
                        variant="outlined"
                        style={{
                          border: "1px solid #D1D1D1",
                          color: "black",
                          minHeight: "40px",
                          textTransform: "none",
                          fontSize: "16px",
                          fontWeight: "400",
                        }}
                      >
                        {tatFormat.overall_tat == 1 ? "Days" : "Hours"}
                      </Button>
                    </div>
                  </FormControl>
                )}
              </div>
              <div className={styles.InputBox}>
                <span className={`${styles.formFont}`}>
                  Assign to Submission
                  <Tooltip
                    title={
                      <div
                        style={{
                          maxWidth: "192px",
                          fontSize: "14px",
                          padding: "3px 0",
                        }}
                      >
                        TAT from task assignment to task submission
                      </div>
                    }
                  >
                    <InfoIcon
                      style={{ height: "20px", marginBottom: "-3px" }}
                    />
                  </Tooltip>
                </span>
                {formMode === "view" ? (
                  <p>
                    {formData.assign_to_submission_days !== ""
                      ? `${formData.assign_to_submission_days} days`
                      : formData.assign_to_submission_hours !== ""
                      ? `${formData.assign_to_submission_hours} hours`
                      : ""}
                  </p>
                ) : (
                  <FormControl
                    variant="outlined"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <TextField
                      id="outlined-adornment-weight"
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        "aria-label": "weight",
                      }}
                      className={styles.textComponent}
                      value={
                        formData.assign_to_submission_days
                          ? formData.assign_to_submission_days
                          : formData.assign_to_submission_hours
                      }
                      onChange={(e) => {
                        handleInputChange(e, 3);
                      }}
                      disabled={
                        formData.is_has_job === 1 ? formMode === "edit" : false
                      }
                    />
                    <div
                      style={{
                        maxWidth: "120px",
                        marginLeft: "15px",
                      }}
                    >
                      <Button
                        variant="outlined"
                        style={{
                          border: "1px solid #D1D1D1",
                          color: "black",
                          minHeight: "40px",
                          textTransform: "none",
                          fontSize: "16px",
                          fontWeight: "400",
                        }}
                      >
                        {tatFormat.overall_tat == 1 ? "Days" : "Hours"}
                      </Button>
                    </div>
                  </FormControl>
                )}
              </div>
              <div className={styles.InputBox}>
                <span className={`${styles.formFont}`}>
                  Submission to QC
                  <Tooltip
                    title={
                      <div
                        style={{
                          maxWidth: "192px",
                          fontSize: "14px",
                          padding: "3px 0",
                        }}
                      >
                        TAT from task submission to task QC completed
                      </div>
                    }
                  >
                    <InfoIcon
                      style={{ height: "20px", marginBottom: "-3px" }}
                    />
                  </Tooltip>
                </span>
                {formMode === "view" ? (
                  <p>
                    {formData.submission_to_qc_days !== ""
                      ? `${formData.submission_to_qc_days} days`
                      : formData.submission_to_qc_hours !== ""
                      ? `${formData.submission_to_qc_hours} hours`
                      : ""}
                  </p>
                ) : (
                  <FormControl
                    variant="outlined"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <TextField
                      id="outlined-adornment-weight"
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        "aria-label": "weight",
                      }}
                      className={styles.textComponent}
                      value={
                        formData.submission_to_qc_days
                          ? formData.submission_to_qc_days
                          : formData.submission_to_qc_hours
                      }
                      onChange={(e) => {
                        handleInputChange(e, 4);
                      }}
                      disabled={
                        formData.is_has_job === 1 ? formMode === "edit" : false
                      }
                    />
                    <div
                      style={{
                        maxWidth: "120px",
                        marginLeft: "15px",
                      }}
                    >
                      <Button
                        variant="outlined"
                        style={{
                          border: "1px solid #D1D1D1",
                          color: "black",
                          minHeight: "40px",
                          textTransform: "none",
                          fontSize: "16px",
                          fontWeight: "400",
                        }}
                      >
                        {tatFormat.overall_tat == 1 ? "Days" : "Hours"}
                      </Button>
                    </div>
                  </FormControl>
                )}
              </div>
              <div className={styles.InputBox}>
                <span className={`${styles.formFont}`}>
                  Rework to Submission
                  <Tooltip
                    title={
                      <div
                        style={{
                          maxWidth: "192px",
                          fontSize: "14px",
                          padding: "3px 0",
                        }}
                      >
                        TAT from assignment to submission when a rework task is
                        assigned to a new worker
                      </div>
                    }
                  >
                    <InfoIcon
                      style={{ height: "20px", marginBottom: "-3px" }}
                    />
                  </Tooltip>
                </span>
                {formMode === "view" ? (
                  <p>
                    {formData.rework_to_submission_days !== ""
                      ? `${formData.rework_to_submission_days} days`
                      : formData.rework_to_submission_hours !== ""
                      ? `${formData.rework_to_submission_hours} hours`
                      : ""}
                  </p>
                ) : (
                  <FormControl
                    variant="outlined"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <TextField
                      id="outlined-adornment-weight"
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        "aria-label": "weight",
                      }}
                      className={styles.textComponent}
                      value={
                        formData.rework_to_submission_days
                          ? formData.rework_to_submission_days
                          : formData.rework_to_submission_hours
                      }
                      onChange={(e) => {
                        handleInputChange(e, 5);
                      }}
                      disabled={
                        formData.is_has_job === 1 ? formMode === "edit" : false
                      }
                    />
                    <div
                      style={{
                        maxWidth: "120px",
                        marginLeft: "15px",
                      }}
                    >
                      <Button
                        variant="outlined"
                        style={{
                          border: "1px solid #D1D1D1",
                          color: "black",
                          minHeight: "40px",
                          textTransform: "none",
                          fontSize: "16px",
                          fontWeight: "400",
                        }}
                      >
                        {tatFormat.overall_tat == 1 ? "Days" : "Hours"}
                      </Button>
                    </div>
                  </FormControl>
                )}
              </div>
            </div>
            <div className={styles.saveCancel}>
              <div className={styles.BtnBox}>
                <Link to="/projectConfig" style={{ textDecoration: "none" }}>
                  {" "}
                  <Button
                    onClick={() => {
                      setFormMode("create");
                    }}
                    variant="outlined"
                  >
                    Cancel
                  </Button>{" "}
                </Link>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Button
                  disabled={
                    formMode === "view" ||
                    (formData.overall_tat_days === "" &&
                      formData.overall_tat_hours === "") ||
                    formData.overall_tat_days === "0" ||
                    formData.overall_tat_hours === "0" ||
                    formData.overall_tat_days === "0." ||
                    formData.overall_tat_hours === "0." ||
                    validationErr.sumTotal
                  }
                  onClick={handleSaveBtn}
                  variant="contained"
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </div>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={laoding}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    </>
  );
};
export default AddProject;
