import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Axios from "axios";
import document from "./document.svg";
import ResponseModal from "./ResponseModal";
import Typography from "@material-ui/core/Typography";
import * as moment from "moment";
import { Grid } from "@material-ui/core";
import Calendar from "../../assets/calendar.svg";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Styles from "./index.module.scss";
import UrlLinks from "../../UrlLinks";
import Download from "./Group 3238.svg";
import { CSVLink } from "react-csv";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: "30px 40px 10px 40px",
    borderBottom: "unset",
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: "0 25px 25px 25px",
  },
}))(MuiDialogActions);

export default function CustomizedDialogs(props) {
  const {
    openOnboardingModal,
    setOpenOnboardingModal,
    showToast,
    updateMyData,
    data,
  } = props;
  const [excel, setExcel] = useState(null);
  const [openResponseModal, setOpenResponseModal] = useState(false);
  const [planRecord, setPlanRecord] = useState([]);
  const [effectiveDate, setEffectiveDate] = useState(moment().add(2, "days"));
  const [name, setName] = useState("");
  const [excelError, setExcelError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [invalidData, setInvalidData] = useState();
  const [fileLink, setFileLink] = useState(null);
  const [responseData, setResponseData] = useState();
  const [closeFile, setCloseFile] = useState(true);

  const clearData = () => {
    setExcel(null);
    setFileLink(null);
    setName("");
    setPlanRecord([]);
  };
  const handleClose = () => {
    clearData();
    setOpenOnboardingModal(false);
  };

  const handleCloseFile = () => {
    setName("");
    setCloseFile(true);
  };

  const handleUpload = (event) => {
    const fileExtension = event.target.files[0].name.split(".").slice(-1)[0];
    const fileName = event.target.files[0].name;

    if (
      fileExtension.toLowerCase() === "xlsx" ||
      fileExtension.toLowerCase() === "xls"
    ) {
      setName(fileName);
      setExcel(event.target.files[0]);
      setExcelError(false);
      setCloseFile(false);
    } else {
      setExcelError(true);
      setExcel(null);
      setName("");
    }
    setResponseData();
    event.target.value = null;
  };

  const createInvalidData = (records) => {
    if (records.length > 1) {
      let list = [];
      records.map((record) => {
        if (record.hasOwnProperty("ShiftDetails")) {
          if (record.ShiftDetails.upload_status === "not_uploaded") {
            list.push({
              "OkayGo Id": record.ShiftDetails.okaygo_id,
              Name: record.ShiftDetails.worker_name,
              "07:00AM-08:00AM": record.ShiftDetails.shift_7am_8am,
              "08:00AM-09:00AM": record.ShiftDetails.shift_8am_9am,
              "09:00AM-10:00AM": record.ShiftDetails.shift_9am_10am,
              "10:00AM-11:00AM": record.ShiftDetails.shift_10am_11am,
              "11:00AM-12:00PM": record.ShiftDetails.shift_11am_12pm,
              "12:00PM-01:00PM": record.ShiftDetails.shift_12pm_13pm,
              "01:00PM-02:00PM": record.ShiftDetails.shift_13pm_14pm,
              "02:00PM-03:00PM": record.ShiftDetails.shift_14pm_15pm,
              "03:00PM-04:00PM": record.ShiftDetails.shift_15pm_16pm,
              "04:00PM-05:00PM": record.ShiftDetails.shift_16pm_17pm,
              "05:00PM-06:00PM": record.ShiftDetails.shift_17pm_18pm,
              "06:00PM-07:00PM": record.ShiftDetails.shift_18pm_19pm,
              "07:00PM -08:00PM": record.ShiftDetails.shift_19pm_20pm,
              "08:00PM-09:00PM": record.ShiftDetails.shift_20pm_21pm,
              "09:00PM- 10:00PM": record.ShiftDetails.shift_21pm_22pm,
              "10:00PM-11:00PM": record.ShiftDetails.shift_22pm_23pm,
              "11:00PM-12:00AM": record.ShiftDetails.shift_23pm_24am,
              Reason: record.ShiftDetails.reason,
            });
          }
        }
        return true;
      });
      return list.length > 0 ? list : null;
    }
  };

  const handleExcel = () => {
    setIsLoading(true);
    let formData = new FormData();
    formData.append("file", excel);

    Axios.post(
      `${UrlLinks.baseUrl}${
        UrlLinks.shifts.uploadingShiftingPlan
      }${localStorage.getItem("userID")}&shiftEffectiveFromDate=${moment(
        effectiveDate
      ).format("YYYY-MM-DD 00:00:00")}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
      .then((response) => {
        if (response.data.response) {
          setInvalidData(createInvalidData(response.data.response));
          const summary = response.data.response.slice(-1)[0];
          setResponseData(summary);
          updateMyData();
          setIsLoading(false);
        } else {
          showToast("error", "Failed to update Shift plan");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        showToast("error", error.message);
      });
  };

  useEffect(() => {
    Axios.post(`${UrlLinks.baseUrl}${UrlLinks.shifts.shiftDetailExcel}`, null, {
      timeout: 60 * 5 * 1000,
    }).then((response) => {
      setFileLink(response.data);
    });
  }, []);

  const createExcelRecords = () => {
    const list = [];
    data.map((record) => {
      list.push({
        "OkayGo Id": record.okaygoId,
        Name: record.workerName,
        "07:00AM-08:00AM": record.hourlyShifts["07:00:00"],
        "08:00AM-09:00AM": record.hourlyShifts["08:00:00"],
        "09:00AM-10:00AM": record.hourlyShifts["09:00:00"],
        "10:00AM-11:00AM": record.hourlyShifts["10:00:00"],
        "11:00AM-12:00PM": record.hourlyShifts["11:00:00"],
        "12:00PM-01:00PM": record.hourlyShifts["12:00:00"],
        "01:00PM-02:00PM": record.hourlyShifts["13:00:00"],
        "02:00PM-03:00PM": record.hourlyShifts["14:00:00"],
        "03:00PM-04:00PM": record.hourlyShifts["15:00:00"],
        "04:00PM-05:00PM": record.hourlyShifts["16:00:00"],
        "05:00PM-06:00PM": record.hourlyShifts["17:00:00"],
        "06:00PM-07:00PM": record.hourlyShifts["18:00:00"],
        "07:00PM -08:00PM": record.hourlyShifts["19:00:00"],
        "08:00PM-09:00PM": record.hourlyShifts["20:00:00"],
        "09:00PM- 10:00PM": record.hourlyShifts["21:00:00"],
        "10:00PM-11:00PM": record.hourlyShifts["22:00:00"],
        "11:00PM-12:00AM": record.hourlyShifts["23:00:00"],
      });
      return true;
    });
    setPlanRecord(list);
  };

  useEffect(() => {
    createExcelRecords();
  }, []);

  useEffect(() => {
    if (responseData) {
      setOpenResponseModal(true);
    }
  }, [responseData]);

  let dialogData = (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={openOnboardingModal}
      className={Styles.onboardingDialog}
      classes={{ paper: Styles.paper }}
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        <div className={Styles.ShiftPlanHeadingContainer}>
          <span>Upload Shift details</span>
          {planRecord?.length > 0 ? (
            <CSVLink
              data={planRecord}
              filename={`Shift planning.csv`}
              target="_blank"
              style={{
                color: "#1c75bc",
                marginRight: "50px",
                fontSize: "17px",
              }}
            >
              <span>Download Current shift plan</span>
              <img
                src={Download}
                alt="download"
                style={{ marginLeft: "5px" }}
              />
            </CSVLink>
          ) : null}
        </div>
      </DialogTitle>
      <DialogContent dividers style={{ padding: "20px" }}>
        <Grid item xs={12} className="padding0">
          <input
            style={{ display: "none" }}
            id="contained-button-file"
            type="file"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            onChange={handleUpload}
          />
          <label htmlFor="contained-button-file">
            <Button
              variant="outlined"
              className={Styles.uploadButton}
              component="span"
              startIcon={
                <img src={document} alt={"upload"} style={{ color: "white" }} />
              }
            >
              Browse File
            </Button>
          </label>
        </Grid>
        <Grid item xs={12} className="padding0">
          <span className={`fontSize13 ${excelError ? "redColor" : ""}`}>
            only .xls and .xlsx are allowed
          </span>
        </Grid>
        <Grid item xs={12} className="padding0" style={{ marginTop: "10px" }}>
          {name && (
            <span className={Styles.fileName}>
              {name.length > 70 ? `${name.substring(0, 69)}...` : `${name}`}
              <span>
                <IconButton
                  aria-label="close"
                  style={{ color: "white", paddingRight: "0" }}
                  onClick={handleCloseFile}
                >
                  <CloseIcon />
                </IconButton>
              </span>
            </span>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          className="padding0"
          style={{ padding: "20px 0 10px 0" }}
        >
          <span className={Styles.effectiveDateHeading}>
            Shift plan effective date
          </span>
        </Grid>
        <Grid item xs={12} className="padding0">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              id="effectiveDate"
              placeholder="Effective date"
              format="dd/MM/yyyy"
              inputVariant="outlined"
              className={Styles.datePicker}
              clearable
              value={effectiveDate}
              minDate={moment()}
              onChange={(date) => setEffectiveDate(date)}
              keyboardIcon={
                <img src={Calendar} alt="calendar" width="18px" height="18px" />
              }
            />
          </MuiPickersUtilsProvider>
        </Grid>
      </DialogContent>
      <DialogActions>
        {isLoading ? (
          <span>Please wait... it may take few minutes</span>
        ) : (
          <Button
            variant="contained"
            onClick={handleExcel}
            className={`${
              !excel || !effectiveDate
                ? "disabledButton"
                : "blueButtonContained"
            }`}
            disabled={!excel || !effectiveDate}
          >
            Upload
          </Button>
        )}
      </DialogActions>
      {openResponseModal && (
        <ResponseModal
          openResponseModal={openResponseModal}
          setOpenResponseModal={setOpenResponseModal}
          responseData={responseData}
          setOpenOnboardingModal={setOpenOnboardingModal}
          invalidData={invalidData}
        />
      )}
    </Dialog>
  );

  return dialogData;
}
