import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {IconButton} from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal';
import RejectModal from './RejectModal';
import { useSelector } from 'react-redux';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  padding: "15px 25px",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function CancelModal(props) {
  const { open, setOpen, selectedOGIds, updatePaymentStatus } = props;

  /** accessing states from redux store **/

  const { users } = useSelector((state) => state);
  const { selectedUserIds } = users;

  /** other states **/

  const [openRejectModal, setOpenRejectModal] = useState(false);

  const handleClose = () => setOpen(false);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              style={{
                color: "#1C75BC",
                fontSize: "24px",
                fontWeight: "normal",
                margin: "0",
              }}
            >
              Cancel Invoices
            </Typography>
            <IconButton
              component="label"
              onClick={handleClose}
              fontSize="large"
              style={{ marginRight: "-15px" }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          {selectedOGIds.length > 0 ? (
            <div style={{ fontSize: "14px", marginTop: "10px" }}>
              <p style={{ color: "#FF0000", marginTop: "5px" }}>
                {" "}
                {selectedOGIds.length} Worker has already taken advance income.
                You can't cancel this invoice.
              </p>
              <div style={{ fontSize: "18px", margin: "2% 0 2% 0" }}>
                Do you want to proceed with other{" "}
                <b>{selectedUserIds.length} invoices</b> for cancel?
              </div>
            </div>
          ) : (
            <div>
              <div style={{ fontSize: "18px", margin: "5% 0 5% 0" }}>
                Do you want to cancel the{" "}
                <b>{selectedUserIds.length} invoices</b>?
              </div>
            </div>
          )}
          <Stack spacing={2} direction="row">
            <Button fullWidth variant="outlined" onClick={handleClose}>
              Discard
            </Button>
            <Button
              fullWidth
              variant="contained"
              onClick={() => {
                setOpenRejectModal(true);
              }}
              disabled={selectedUserIds.length !== 0 ? false : true}
            >
              Okay
            </Button>
          </Stack>
        </Box>
      </Modal>

      <RejectModal
        open={openRejectModal}
        setOpen={handleClose}
        updatePaymentStatus={updatePaymentStatus}
        stage={9}
        text={"Cancel Invoice Remark"}
      />
    </div>
  );
}
