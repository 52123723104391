import { Grid } from "@material-ui/core";
import React from "react";
import style from "./index.module.scss";
import FlipkartIcon from "./Flipart-Logo-Icon-PNG-Image.png";
import SwiggyIcon from "./swiggy-logo.png";
import ZomotoIcon from "./zomato-logo.svg";
import DelhiveryIcon from "./Delhi.png";
import JioIcon from "./jio.png";

const TimeLineColumn = (props) => {
  const { row } = props;
  const workerHourlyShift = Object.entries(row.original.hourlyShifts);
  const list = [];
  let partner = null;
  let beginingIndex = 7;
  let lastIndex = 0;
  workerHourlyShift.map((shift, index) => {
    if (index > 6) {
      if (index > 0 && shift[1] !== workerHourlyShift[index - 1][1]) {
        beginingIndex = index;
      }
      if (index < 23 && shift[1] !== workerHourlyShift[index + 1][1]) {
        lastIndex = index;
        partner = shift[1];
        list.push({ beginingIndex, lastIndex, partner });
      }
      if (index === 23 && shift[1]) {
        lastIndex = index;
        partner = shift[1];
        list.push({ beginingIndex, lastIndex, partner });
      }
    }
    return true;
  });

  let shiftDivs = [];
  list.map((shift) => {
    let classValues = null;
    let divWidth = `${(shift.lastIndex - shift.beginingIndex + 1) * 60}px`;
    let divValues = null;
    const timing = `${
      shift.beginingIndex === 0
        ? "12"
        : shift.beginingIndex > 12
        ? shift.beginingIndex % 12
        : shift.beginingIndex
    }:00 ${
      shift.beginingIndex < 11 || shift.beginingIndex > 23
        ? "AM"
        : shift.beginingIndex > 10
        ? "PM"
        : ""
    } - ${
      shift.lastIndex === 23
        ? "12"
        : shift.lastIndex > 11
        ? (shift.lastIndex % 12) + 1
        : shift.lastIndex + 1
    }:00 ${
      shift.lastIndex < 11 || shift.lastIndex === 23
        ? "AM"
        : shift.lastIndex > 10
        ? "PM"
        : ""
    } `;
    if (shift.partner === null) {
      classValues = `${style.shiftTimeContainerBlank}`;
    } else if (shift.partner.toLowerCase().trim() === "flipkart") {
      divValues = (
        <div className={style.divValuesContainer}>
          <img
            src={FlipkartIcon}
            alt="flipkart-icon"
            className={style.partnerIcon}
          />
          <span>{timing} </span>
        </div>
      );
      classValues = `${style.shiftTimeContainer}`;
    } else if (shift.partner.toLowerCase().trim() === "swiggy") {
      divValues = (
        <div className={style.divValuesContainer}>
          <img
            src={SwiggyIcon}
            alt="swiggy-icon"
            className={style.partnerIcon}
          />{" "}
          <span> {timing} </span>
        </div>
      );
      classValues = `${style.shiftTimeContainer}`;
    } else if (shift.partner.toLowerCase().trim() === "zomato") {
      divValues = (
        <div className={style.divValuesContainer}>
          <img
            src={ZomotoIcon}
            alt="zomato-icon"
            className={style.partnerIcon}
          />{" "}
          <span> {timing} </span>
        </div>
      );
      classValues = `${style.shiftTimeContainer}`;
    } else if (shift.partner.toLowerCase().trim() === "jiomart") {
      divValues = (
        <div className={style.divValuesContainer}>
          <img src={JioIcon} alt="swiggy-icon" className={style.partnerIcon} />{" "}
          <span> {timing} </span>
        </div>
      );
      classValues = `${style.shiftTimeContainer}`;
    } else if (shift.partner.toLowerCase().trim() === "delhivery") {
      divValues = (
        <div className={style.divValuesContainer}>
          <img
            src={DelhiveryIcon}
            alt="zomato-icon"
            className={style.partnerIcon}
          />{" "}
          <span> {timing} </span>
        </div>
      );
      classValues = `${style.shiftTimeContainer}`;
    }

    shiftDivs.push(
      <div className={classValues} style={{ width: divWidth }}>
        {divValues}
      </div>
    );
  });

  return (
    <Grid container>
      <div className={style.timeLineContainer}>{shiftDivs}</div>
    </Grid>
  );
};

export default TimeLineColumn;
