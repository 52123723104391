import * as actionTypes from "../actionTypes";
import { setLoader } from "../Config/Users/users";

export const setRowData = (data) => {
  return (dispatch) => {
    setLoader(true);
    dispatch({
      type: actionTypes.ROW_DATA,
      rowData: data,
    });
    setLoader(false);
  };
};
