import * as actionTypes from "../actionTypes";
import { setLoader } from "../Config/Users/users";

export const setCurLeadId = (
  leadCity,
  leadSource,
  multiplePreferredPincodes,
  multiplePreferredLanguages,
  multipleAssets
) => {
  return (dispatch) => {
    setLoader(true);
    dispatch({
      type: actionTypes.CUR_LEAD_ID,
      curLeadId: {
        leadCity: leadCity,
        leadSource: leadSource,
      },
      multiplePreferredPincodes: multiplePreferredPincodes,
      multiplePreferredLanguages: multiplePreferredLanguages,
      multipleAssets: multipleAssets,
    });
    setLoader(false);
  };
};

export const setTabValue = (tabValue) => {
  return (dispatch) => {
    setLoader(true);
    dispatch({
      type: actionTypes.TAB_VALUE,
      tabValue: tabValue,
    });
    setLoader(false);
  };
};
