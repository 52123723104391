import * as actionTypes from "../../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  rowData: null,
};

const setRowData = (state, action) => {
  return updateObject(state, {
    rowData: action.rowData,
  });
};

const workerDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ROW_DATA:
      return setRowData(state, action);
    default:
      return state;
  }
};
export default workerDataReducer;
