import axios from "axios";
import * as actionTypes from "../../actionTypes";

export const setLoader = (loadingState) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.LOADER,
      loader: loadingState,
    });
  };
};

export const setModalLoader = (loadingState) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.MODAL_LOADER,
      loader: loadingState,
    });
  };
};

export const setCurrentPage = (page) => {
  return (dispatch) => {
    setLoader(true);
    dispatch({
      type: actionTypes.CURRENT_PAGE,
      currentPage: page,
    });
    setLoader(false);
  };
};

export const setPerPage = (rowsPerPage) => {
  return (dispatch) => {
    setLoader(true);
    dispatch({
      type: actionTypes.PER_PAGE,
      perPage: rowsPerPage,
    });
    setLoader(false);
  };
};

export const setTotalRows = (totalRows) => {
  return (dispatch) => {
    setLoader(true);
    dispatch({
      type: actionTypes.TOTAL_ROWS,
      totalRows: totalRows,
    });
    setLoader(false);
  };
};

export const setTotalPages = (totalPages) => {
  return (dispatch) => {
    setLoader(true);
    dispatch({
      type: actionTypes.TOTAL_PAGES,
      totalPages: totalPages,
    });
    setLoader(false);
  };
};

export const setIsFilter = (isFilter) => {
  return (dispatch) => {
    setLoader(true);
    dispatch({
      type: actionTypes.IS_FILTER,
      isFilter: isFilter,
    });
    setLoader(false);
  };
};

export const setSelectedAssignIds = (ids) => {
  return (dispatch) => {
    setLoader(true);
    dispatch({
      type: actionTypes.SELECTED_ASSIGN_IDS,
      selectedAssignIds: ids,
    });
    setLoader(false);
  };
};

export const setSelectedUserIds = (ids) => {
  return (dispatch) => {
    setLoader(true);
    dispatch({
      type: actionTypes.SELECTED_USER_IDS,
      selectedUserIds: ids,
    });
    setLoader(false);
  };
};

export const setOpenFilterDrawer = (flag) => {
  return (dispatch) => {
    setLoader(true);
    dispatch({
      type: actionTypes.OPEN_FILTER_DRAWER,
      openFilterDrawer: flag,
    });
    setLoader(false);
  };
};

export const setAllRecord = (data) => {
  return (dispatch) => {
    setLoader(true);
    dispatch({
      type: actionTypes.ALL_RECORD,
      allRecord: data,
    });
    setLoader(false);
  };
};

// export const userData = (res) => {
//   return {
//     type: actionTypes.USER_DATA,
//     userData: res.content,
//     totalPages: res.totalPages,
//     totalRows: res.totalElements,
//   };
// };

// export const getUserData = (url, rows, pageNo) => {
//   if (localStorage.getItem("roleType")) {
//     return (dispatch) => {
//       setLoader(true);
//       axios
//         .get(url)
//         .then((response) => {

//           if (response.data.response) {
//             let res = response.data.response;
//             dispatch(userData(res));
//           }
//         })
//         .catch((error) => {
//           console.error("Error getting user data:", error);
//           throw error;
//         })
//         .finally(() => {});
//       setLoader(false);
//     };
//   }
// };
