import React, { useState, useEffect, useMemo, useContext } from "react";
import styled from "styled-components";
import {
  Button,
  Grid,
  IconButton,
  Checkbox,
  TextField,
  InputAdornment,
  FormControl,
  MenuItem,
  Drawer,
  Select,
} from "@material-ui/core";
import { useTable, useRowSelect, useSortBy, useFilters } from "react-table";
import urlLinks from "../../UrlLinks";
import Axios from "axios";
import styles from "./index.module.scss";
import { CSVLink } from "react-csv";
import SearchIcon from "@material-ui/icons/Search";
import OnboardingModal from "./components/onboardingModal";
import OnboardingModalV2 from "./components/onboardingModalV2";
import OnBoardingModalV3 from "./components/onBoardingModalV3";
import AssignToRecruiterModal from "./components/AssignToRecruiter";
import CasperOnboardingModal from "./components/CasperOnboardingModal";
import OffboardingModal from "./components/offboardingModal";
import CloseSharpIcon from "@material-ui/icons/CloseSharp";
import Pagination from "../../Utils/Pagination";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import FilterDrawer from "./filters";
import * as moment from "moment";
import NoRecord from "./noRecord.svg";
import EditContactModal from "./components/EditPhoneNumber";
import ReactivationModal from "./components/ReactivtionDialog";
import CriminalRecord from "./components/CriminalRecord";
import SendMsg from "./components/SendMsg";
import ScaleLoader from "react-spinners/ScaleLoader";
import JSZip from "jszip";
import JSZipUtils from "jszip-utils";
import { AuthContext } from "../../services/AuthProvider";
import InfoIcon from "@material-ui/icons/Info";
import { saveAs } from "file-saver";
import { Link } from "react-router-dom";
import UrlLinks from "../../UrlLinks";
import Tooltip from "@material-ui/core/Tooltip";
import LastSentMsgs from "./components/LastSentMsgs";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const Styles = styled.div`
  padding: 1rem;
  .tableContainer {
    overflow-x: scroll;
    border: 1px solid #cfcfcf;
    max-height: 74vh;
  }
  .tableContainer::-webkit-scrollbar {
    width: 0.75em;
  }

  .tableContainer::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  .tableContainer::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    //   border-radius: 8px;
  }
  table {
    border-spacing: 0;
    width: max-content;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    th {
      :last-child {
        border-left: 0;
      }
      background-color: #1c75bc !important;
      color: #ffffff;
    }
    th,
    td {
      padding: 5px 15px;
    }

    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid #cfcfcf;
      border-right: 1px solid #cfcfcf;
      background-color: white;
      nth-last-child(2) {
        border-right: 0;
      }
      :last-child {
        border-right: 0;
      }

      input {
        font-size: 1rem;
        padding: 0;
        margin: 0;
        border: 0;
      }
    }
  }

  .pagination {
    padding: 0.5rem;
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-root": {
      marginLeft: "0",
      "&.Mui-focused fieldset": {
        border: "1px solid grey",
      },
    },
  },
  input: {
    '& [class*="MuiOutlinedInput-input"]': {
      padding: "8px",
      borderLeft: "1px solid grey",
      borderRadius: "0px",
    },
  },
  select: {
    "& .MuiOutlinedInput-inputMarginDense": {
      padding: "8px 10px",
    },
  },
}));

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <React.Fragment>
        <Checkbox ref={resolvedRef} {...rest} color="primary" />
      </React.Fragment>
    );
  }
);

const WorkerData = (props) => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [selectedUserIds, setSelectedUserIds] = useState("");
  const [allRecordLoading, setAllRecordLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openSendMsg, setOpenSendMsg] = useState(false);
  const [allRecord, setAllRecord] = useState([]);
  const [agreementDownloading, setAgreementDownloading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [allDocumentDownload, setAllDocumentDownload] = useState(null);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [openOffboardingModal, setOpenOffboardingModal] = useState(false);
  const [openOnboardingModal, setOpenOnboardingModal] = useState(false);
  const [openAssignModal, setOpenAssignModal] = useState(false);
  const [openOnboardingModalV2, setOpenOnboardingModalV2] = useState(false);
  const [openOnboardingApnaModalV3, setOpenOnboardingApnaModalV3] =
    useState(false);
  const [openCasperOnboardingModal, setOpenCasperOnboardingModal] =
    useState(false);
  const [disable, setDisable] = useState(true);
  const [searchByAuto, setsearchByAuto] = useState("");
  const [editContactData, setEditContactData] = useState({
    userId: null,
    contact: null,
    open: false,
    error: "",
  });
  const [reactivationData, setReactivationData] = useState({
    open: false,
    userId: null,
    date: null,
  });
  const [actions, setActions] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [options, setOptions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [sortBy, setSortBy] = useState("");
  const [sortDirection, setSortDirection] = useState(false);
  const [sortDirectionUrl, setSortDirectionUrl] = useState("");
  const [searchBy, setSearchBy] = useState("");
  const [sortUrl, setSortUrl] = useState("");
  const [filterUrl, setFilterUrl] = useState("");
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);
  const [assignFilterString, setassignFilterString] =
    useState("&notAssigned=0");
  const [criminalRecordData, setCriminalRecordData] = useState({
    open: false,
    userId: null,
  });
  const [tab, setTab] = useState({
    workerDataTab: true,
    leadDataTab: false,
  });
  const [AssignFilter, setAssignFilter] = useState({
    all: false,
    assignTab: false,
    notAssignTab: true,
  });
  const [isLeadData, setisLeadData] = useState("");
  const [filters, setFilters] = useState({
    searchBy: "",
    source: "",
    all: true,
    onboardingInitiated: false,
    active: false,
    inactive: false,
    criminalRecord: false,
    temporarilyInactive: false,
    offboardingInitiated: false,
    zone: "",
    facilityState: "",
    facilityName: "",
    facilityCity: "",
    partnerId: "",
    workFor: "",
    workerStatus: "",
    psf: "",
    callStatus: "",
    interviewStatus: "",
    recruiterId: "",
    trainingUpdate: "",
    preferedHub: "",
    appLead: "",
    interviewDate: [null, null],
    firstEarningDate: [null, null],
    leadAddedDate: [null, null],
    bankDetails: "",
    leadCity: "",
    leadSource: "",
    lastEarning: "",
  });

  const [partners, setPartners] = useState([{}]);

  const FirstUpperCase = (str) => {
    let s = "";
    s = str;

    if (s.length > 1) {
      let sub = s.substring(0, 1).toUpperCase();
      let remaining = s.substring(1, s.length);
      return sub + remaining;
    }
  };

  let c = null;
  let partnersArray = [];

  useEffect(() => {
    Axios.get(`${UrlLinks.baseUrl}/configmaster/getCities`).then((response) => {
      let v = [];
      response.data.response.map((e) => v.push(FirstUpperCase(e.trim())));
      c = v.sort();
      c.unshift("All Cities");
      c = v;
      handleOptions();
    });

    Axios.get(`${UrlLinks.baseUrl}${UrlLinks.getPartner}`).then((response) => {
      setPartners(response.data.response);
      partnersArray = response.data.response;
    });
  }, []);

  const handleOptions = () => {
    setOptions(
      c.map((option) => {
        return { title: option };
      })
    );
  };

  useEffect(() => {
    ApplyFilters(filters);
  }, [searchByAuto]);

  const { showToast } = useContext(AuthContext);

  const createDocumentList = (documents, profilePhoto) => {
    let list = [];
    let aadharBackError = "Aadhar Back,";
    let aadharFrontError = " Aadhar Front,";
    let profilePicError = " Profile pic";
    let errorText = " not Found!";
    documents.map((doc) => {
      if (doc.document_type_id === 1929 || doc.document_type_id === 1867) {
        if (doc.document_type_id === 1929) aadharBackError = "";
        if (doc.document_type_id === 1867) aadharFrontError = "";
        if (doc.file_path.substring(0, 5) === "http:") {
          // in case if it come https, link wont become httpss

          list.push("https" + doc.file_path.substring(4, doc.file_path.length));
        } else list.push(doc.file_path);
      }
    });
    if (profilePhoto !== null && profilePhoto !== "") {
      profilePicError = "";
      if (profilePhoto.substring(0, 5) === "http:") {
        // in case if it come https, link wont become httpss
        list.push("https" + profilePhoto.substring(4, profilePhoto.length));
      } else list.push(profilePhoto);
    }
    if (
      aadharBackError.length !== 0 ||
      aadharFrontError.length !== 0 ||
      profilePicError.length !== 0
    ) {
      errorText =
        aadharBackError + aadharFrontError + profilePicError + errorText;
      showToast("error", errorText);
    }

    return list.forEach((path) => {
      window.open(path, "_blank");
    });
  };

  const getDocumentList = (userId, profilePhoto) => {
    Axios.get(
      `${urlLinks.baseUrl}${urlLinks.workerUrls.docList}${parseInt(userId)}`
    ).then((response) => {
      setAllDocumentDownload(
        createDocumentList(response.data.response.content, profilePhoto)
      );
    });
  };

  const handleSearch = () => {
    setFilters({ ...filters, searchBy: searchBy });
    setsearchByAuto(searchBy);
  };

  const handleSearchClose = () => {
    setFilters({ ...filters, searchBy: "" });
    setSearchBy("");
    setFilterUrl("");
  };

  const handleCriminalRecord = (event) => {
    setCriminalRecordData({ userId: event.target.id, open: true });
  };

  const ApplySort = () => {
    let url = "";
    switch (sortBy) {
      case "casper_id":
        url = "&orderBy=wm.casper_id";
        break;
      case "profile_id":
        url = "&orderBy=wm.worker_id";
        break;
      case "okaygo_id":
        url = "&orderBy=wm.okaygo_id";
        break;
      case "onboarding_date":
        url = "&orderBy=wm.onboarding_initiation_date";
        break;
      case "active_date":
        url = "&orderBy=wm.active_date";
        break;
      case "offboarding_date":
        url = "&orderBy=wm.offboarding_initiation_date";
        break;
      case "inactive_date":
        url = "&orderBy=wm.inactive_date";
        break;
      default:
        url = "";
        break;
    }
    setSortUrl(url);
  };

  const handleEditContactClose = () => {
    setEditContactData({
      userId: null,
      contact: null,
      open: false,
      error: "",
    });
  };

  const handleReactivationDateClose = () => {
    setReactivationData({
      open: false,
      userId: null,
      date: null,
    });
  };

  useEffect(() => {
    ApplySort();
    setSortDirection(false);
    setSortDirectionUrl("");
  }, [sortBy]);

  const handleTab = (event, name) => {
    if (name === "2") {
      setTab({ ...tab, leadDataTab: true, workerDataTab: false });
      setisLeadData("&leadRecords=1");
      setCurrentPage(1);
    } else if (name === "1") {
      setTab({ ...tab, leadDataTab: false, workerDataTab: true });
      setisLeadData("");
      setCurrentPage(1);
    }
  };

  const ApplyFilters = () => {
    // debugger
    let url = "";
    if (filters["searchBy"]) url = `${url}&searchBy=${filters["searchBy"]}`;
    if (filters["onboardingInitiated"]) {
      url = `${url}&workerStatus=2,5`;
    }
    if (filters["status"]) {
      url = `${url}&status=${filters["status"]}`;
      setIsFilterApplied(true);
    }
    if (filters["offboardingInitiated"]) {
      url = `${url}&workerStatus=4`;
    }
    if (filters["temporarilyInactive"]) {
      url = `${url}&workerStatus=6,8`;
    }
    if (filters["criminalRecord"]) {
      url = `${url}&criminal_record=1`;
    }
    if (filters["partnerId"]) {
      url = `${url}&partnerId=${filters["partnerId"].id}`;
      setIsFilterApplied(true);
    }
    if (filters["facilityName"]) {
      url = `${url}&facilityName=${filters["facilityName"].name}`;
      setIsFilterApplied(true);
    }
    if (filters["facilityCity"]) {
      url = `${url}&facilityCity=${filters["facilityCity"].name}`;
      setIsFilterApplied(true);
    }
    if (filters["facilityState"]) {
      url = `${url}&facilityState=${filters["facilityState"].name}`;
      setIsFilterApplied(true);
    }
    if (filters["source"]) {
      url = `${url}&source=${filters["source"].id}`;
      setIsFilterApplied(true);
    }
    if (filters["workerStatus"]) {
      url = `${url}&workerStatus=${filters["workerStatus"].id}`;
      setIsFilterApplied(true);
    }
    if (filters["workFor"]) {
      url = `${url}&workFor=${filters["workFor"]}`;
      setIsFilterApplied(true);
    }
    if (filters["psf"]) {
      url = `${url}&psf=${filters["psf"].id}`;
      setIsFilterApplied(true);
    }
    if (filters["callStatus"]) {
      url = `${url}&callStatus=${filters["callStatus"].id}`;
      setIsFilterApplied(true);
    }
    if (filters["interviewStatus"]) {
      url = `${url}&interviewStatus=${filters["interviewStatus"].id}`;
      setIsFilterApplied(true);
    }
    if (filters["recruiterId"]) {
      url = `${url}&recruiterId=${filters["recruiterId"].id}`;
      setIsFilterApplied(true);
    }
    if (filters["trainingUpdate"]) {
      url = `${url}&trainingUpdate=${filters["trainingUpdate"].id}`;
      setIsFilterApplied(true);
    }
    if (filters["preferedHub"]) {
      url = `${url}&preferedHub=${filters["preferedHub"].id}`;
      setIsFilterApplied(true);
    }
    if (filters["appLead"]) {
      url = `${url}&appLead=${filters["appLead"].id}`;
      setIsFilterApplied(true);
    }
    if (filters["bankDetails"] || filters["bankDetails"] === 0) {
      url = `${url}&bankdetail=${filters["bankDetails"].id}`;
    }
    if (filters["leadCity"] || filters["leadCity"] === 0) {
      url = `${url}&leadCity=${filters["leadCity"].id}`;
    }
    if (filters["leadSource"] || filters["leadSource"] === 0) {
      url = `${url}&leadSource=${filters["leadSource"].id}`;
    }

    if (filters["interviewDate"][0] && !filters["interviewDate"][1])
      url = `${url}&interviewDateFrom=${moment(
        filters["interviewDate"][0]
      ).format("YYYY-MM-DD")}&interviewDateTO=${moment(new Date()).format(
        "YYYY-MM-DD"
      )} 23:59:59`;
    if (!filters["interviewDate"][0] && filters["interviewDate"][1])
      url = `${url}&interviewDateFrom=2019-01-01&interviewDateTO=${moment(
        filters["interviewDate"][1]
      ).format("YYYY-MM-DD")} 23:59:59`;
    if (filters["interviewDate"][0] && filters["interviewDate"][1])
      url = `${url}&interviewDateFrom=${moment(
        filters["interviewDate"][0]
      ).format("YYYY-MM-DD")}&interviewDateTO=${moment(
        filters["interviewDate"][1]
      ).format("YYYY-MM-DD")} 23:59:59`;

    if (filters["firstEarningDate"][0] && !filters["firstEarningDate"][1])
      url = `${url}&firstEarningFrom=${moment(
        filters["firstEarningDate"][0]
      ).format("YYYY-MM-DD")}&firstEarningTo=${moment(new Date()).format(
        "YYYY-MM-DD"
      )} 23:59:59`;
    if (!filters["firstEarningDate"][0] && filters["firstEarningDate"][1])
      url = `${url}&firstEarningFrom=2019-01-01&firstEarningTo=${moment(
        filters["firstEarningDate"][1]
      ).format("YYYY-MM-DD")} 23:59:59`;
    if (filters["firstEarningDate"][0] && filters["firstEarningDate"][1])
      url = `${url}&firstEarningFrom=${moment(
        filters["firstEarningDate"][0]
      ).format("YYYY-MM-DD")}&firstEarningTo=${moment(
        filters["firstEarningDate"][1]
      ).format("YYYY-MM-DD")} 23:59:59`;

    if (filters["leadAddedDate"][0] && !filters["leadAddedDate"][1])
      url = `${url}&leadAddedFrom=${moment(filters["leadAddedDate"][0]).format(
        "YYYY-MM-DD"
      )}&leadAddedTo=${moment(new Date()).format("YYYY-MM-DD")} 23:59:59`;
    if (!filters["leadAddedDate"][0] && filters["leadAddedDate"][1])
      url = `${url}&leadAddedFrom=2019-01-01&leadAddedTo=${moment(
        filters["leadAddedDate"][1]
      ).format("YYYY-MM-DD")} 23:59:59`;
    if (filters["leadAddedDate"][0] && filters["leadAddedDate"][1])
      url = `${url}&leadAddedFrom=${moment(filters["leadAddedDate"][0]).format(
        "YYYY-MM-DD"
      )}&leadAddedTo=${moment(filters["leadAddedDate"][1]).format(
        "YYYY-MM-DD"
      )} 23:59:59`;

    if (filters["lastEarning"].id === "last3days")
      url = `${url}&lastEarningFrom=${moment()
        .subtract(2, "days")
        .format("YYYY-MM-DD")}&lastEarningTo=${moment().format(
        "YYYY-MM-DD"
      )} 23:59:59`;
    if (filters["lastEarning"].id === "last5days")
      url = `${url}&lastEarningFrom=${moment()
        .subtract(4, "days")
        .format("YYYY-MM-DD")}&lastEarningTo=${moment().format(
        "YYYY-MM-DD"
      )} 23:59:59`;
    if (filters["lastEarning"].id === "last7days")
      url = `${url}&lastEarningFrom=${moment()
        .subtract(6, "days")
        .format("YYYY-MM-DD")}&lastEarningTo=${moment().format(
        "YYYY-MM-DD"
      )} 23:59:59`;

    setFilterUrl(url);
    setFilters(filters);
    setCurrentPage(1);
  };

  const resetFilters = () => {
    const filterList = {
      source: "",
      searchBy: "",
      zone: "",
      facilityState: "",
      facilityName: "",
      partnerId: "",
      facilityCity: "",
      workFor: "",
      workerStatus: "",
      psf: "",
      callStatus: "",
      interviewStatus: "",
      recruiterId: "",
      trainingUpdate: "",
      preferedHub: "",
      appLead: "",
      interviewDate: [null, null],
      firstEarningDate: [null, null],
      leadAddedDate: [null, null],
      bankDetails: "",
      leadCity: "",
      leadSource: "",
      lastEarning: "",
    };
    setFilters(filterList);
    setFilterUrl("");
    setIsFilterApplied(true);
    setCurrentPage(1);
    return filterList;
  };

  //columns list

  const workerDataColumn = useMemo(
    () => [
      {
        id: "selection",
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <div>
            <IndeterminateCheckbox
              {...getToggleAllRowsSelectedProps()}
              style={{ color: "white" }}
            />
          </div>
        ),
        Cell: ({ row }) => (
          <div>
            <IndeterminateCheckbox
              {...row.getToggleRowSelectedProps()}
              userId={row}
            />
          </div>
        ),
      },
      {
        Header: "Client id(s) raqw",
        accessor: "casperId",
        Cell: (row) => {
          return (
            <div>
              {row.row.original.casperId && (
                <div>
                  <b>Flipkart: </b>
                  {row.row.original.casperId}
                </div>
              )}
              {row.row.original.swiggyId && (
                <div>
                  <b>Swiggy: </b>
                  {row.row.original.swiggyId}
                </div>
              )}
              {row.row.original.zomatoId && (
                <div>
                  <b>Zomato: </b>
                  {row.row.original.zomatoId}
                </div>
              )}
              {row.row.original.delhiveryId && (
                <div>
                  <b>Delhivery: </b>
                  {row.row.original.delhiveryId}
                </div>
              )}
              {row.row.original.jiomartId && (
                <div>
                  <b>Jiomart: </b>
                  {row.row.original.jiomartId}
                </div>
              )}
            </div>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },

      {
        Header: "OkayGo id",
        accessor: "okaygoId",
        defaultCanFilter: false,
        defaultCanSort: false,
      },

      {
        Header: "Name",
        accessor: "name",
        Cell: (row) => {
          return row.row.original.criminalRecord === "Yes" ? (
            <button
              className={styles.workerName}
              type="button"
              id={row.row.original.userId}
              onClick={handleCriminalRecord}
            >
              {row.row.original.name}
            </button>
          ) : (
            <Link
              to={{ pathname: `/workerview/v2/${row.row.original.userId}` }}
              className="nameLink"
            >
              {row.row.original.name}
            </Link>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Contact No",
        accessor: "contactNo",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Source",
        accessor: "partnerName",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Client(s)",
        accessor: "clients",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Okaygo App Status",
        accessor: "status",
        Cell: (row) => {
          return (
            <div>
              {row.row.original.status && (
                <div>
                  <b>Flipkart: </b>
                  {row.row.original.status}
                </div>
              )}
              {row.row.original.swiggyWorkerStatus && (
                <div>
                  <b>Swiggy: </b>
                  {row.row.original.swiggyWorkerStatus}
                </div>
              )}
              {row.row.original.zomatoWorkerStatus && (
                <div>
                  <b>Zomato: </b>
                  {row.row.original.zomatoWorkerStatus}
                </div>
              )}
              {row.row.original.delhiveryWorkerStatus && (
                <div>
                  <b>Delhivery: </b>
                  {row.row.original.delhiveryWorkerStatus}
                </div>
              )}
              {row.row.original.jiomartWorkerStatus && (
                <div>
                  <b>Jiomart: </b>
                  {row.row.original.jiomartWorkerStatus}
                </div>
              )}
            </div>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Client Earning Status",
        Cell: (row) => {
          return (
            <div>
              {row.row.original.flipkartEarningStatus && (
                <div>
                  <b>Flipkart: </b>
                  {row.row.original.flipkartEarningStatus}
                </div>
              )}
              {row.row.original.swiggyEarningStatus && (
                <div>
                  <b>Swiggy: </b>
                  {row.row.original.swiggyEarningStatus}
                </div>
              )}
              {row.row.original.zomatoEarningStatus && (
                <div>
                  <b>Zomato: </b>
                  {row.row.original.zomatoEarningStatus}
                </div>
              )}
              {row.row.original.delhiveryEarningStatus && (
                <div>
                  <b>Delhivery: </b>
                  {row.row.original.delhiveryEarningStatus}
                </div>
              )}
              {row.row.original.jioEarningStatus && (
                <div>
                  <b>Jiomart: </b>
                  {row.row.original.jioEarningStatus}
                </div>
              )}
            </div>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Documents",
        accessor: "documents",
        Cell: (row) => {
          return (
            <button
              className={styles.workerName}
              type="button"
              style={{ fontSize: "16px !important" }}
              onClick={() =>
                getDocumentList(
                  row.row.original.userId,
                  row.row.original.profilePhoto
                )
              }
            >
              Download
            </button>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },

      {
        Header: "Last SMS sent",
        // accessor: "zone",
        Cell: LastSentMsgs,
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Father's Name",
        accessor: "fathersName",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Aadhar Number",
        accessor: "aadharNumber",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Gender",
        accessor: "gender",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Date of birth",
        accessor: "dob",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Present Address",
        accessor: "present_address",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Present City",
        accessor: "present_city",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Present State",
        accessor: "present_state",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Present Pincode",
        accessor: "present_pincode",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Permanent address",
        accessor: "permanent_address",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Permanent city",
        accessor: "permanent_city",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Permanent state",
        accessor: "permanent_state",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Permanent Pincode",
        accessor: "permanent_pincode",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Bank details",
        accessor: "bankDetails",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Groupflex/ Eflex",
        accessor: "groupflex",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Leader",
        accessor: "leader",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Sourced by",
        accessor: "sourcedBy",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Facility name",
        accessor: "facility_name",
        Cell: (row) => {
          return (
            <div>
              {row.row.original.facility_name && (
                <div>
                  <b>Flipkart: </b>
                  {row.row.original.facility_name}
                </div>
              )}
              {row.row.original.swiggyZone && (
                <div>
                  <b>Swiggy: </b>
                  {row.row.original.swiggyZone}
                </div>
              )}
              {row.row.original.zomatoZone && (
                <div>
                  <b>Zomato: </b>
                  {row.row.original.zomatoZone}
                </div>
              )}
              {row.row.original.delhiveryZone && (
                <div>
                  <b>Delhivery: </b>
                  {row.row.original.delhiveryZone}
                </div>
              )}
              {row.row.original.jiomartZone && (
                <div>
                  <b>Jiomart: </b>
                  {row.row.original.jiomartZone}
                </div>
              )}
            </div>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Facility city",
        accessor: "facility_city",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Facility state",
        accessor: "facility_state",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Zone",
        accessor: "zone",
        defaultCanFilter: false,
        defaultCanSort: false,
      },

      {
        Header: "Rate Card",
        accessor: "rateCard",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Activation Date",
        accessor: "activationDate",
        Cell: (row) => {
          return (
            <div>
              {row.row.original.activationDate && (
                <div>
                  <b>Flipkart: </b>
                  {row.row.original.activationDate}
                </div>
              )}
              {row.row.original.swiggyActivationDate && (
                <div>
                  <b>Swiggy: </b>
                  {row.row.original.swiggyActivationDate}
                </div>
              )}
              {row.row.original.zomatoActivationDate && (
                <div>
                  <b>Zomato: </b>
                  {row.row.original.zomatoActivationDate}
                </div>
              )}
              {row.row.original.delhiveryactiveDate && (
                <div>
                  <b>Delhivery: </b>
                  {row.row.original.delhiveryactiveDate}
                </div>
              )}
              {row.row.original.jiomartactiveDate && (
                <div>
                  <b>Jiomart: </b>
                  {row.row.original.jiomartactiveDate}
                </div>
              )}
            </div>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Date of joining",
        accessor: "date_of_joining",
        Cell: (row) => {
          return (
            <div>
              {row.row.original.date_of_joining && (
                <div>
                  <b>Flipkart: </b>
                  {row.row.original.date_of_joining}
                </div>
              )}
              {row.row.original.swiggyJoiningDate && (
                <div>
                  <b>Swiggy: </b>
                  {row.row.original.swiggyJoiningDate}
                </div>
              )}
              {row.row.original.zomatoJoiningDate && (
                <div>
                  <b>Zomato: </b>
                  {row.row.original.zomatoJoiningDate}
                </div>
              )}
              {row.row.original.delhiveryDateOfJoining && (
                <div>
                  <b>Delhivery: </b>
                  {row.row.original.delhiveryDateOfJoining}
                </div>
              )}
              {row.row.original.jiomartDateOfJoining && (
                <div>
                  <b>Jiomart: </b>
                  {row.row.original.jiomartDateOfJoining}
                </div>
              )}
            </div>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "First Earning Date",
        accessor: "firstEarningDate",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Exit Date",
        accessor: "exitDate",
        Cell: (row) => {
          return (
            <div>
              {row.row.original.exitDate && (
                <div>
                  <b>Flipkart: </b>
                  {row.row.original.exitDate}
                </div>
              )}
              {row.row.original.swiggyExitDate && (
                <div>
                  <b>Swiggy: </b>
                  {row.row.original.swiggyExitDate}
                </div>
              )}
              {row.row.original.zomatoExitDate && (
                <div>
                  <b>Zomato: </b>
                  {row.row.original.zomatoExitDate}
                </div>
              )}
              {row.row.original.delhiveryInactiveDate && (
                <div>
                  <b>Delhivery: </b>
                  {row.row.original.delhiveryInactiveDate}
                </div>
              )}
              {row.row.original.jiomartInactiveDate && (
                <div>
                  <b>Jiomart: </b>
                  {row.row.original.jiomartInactiveDate}
                </div>
              )}
            </div>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Blacklisted",
        accessor: "blacklisted",
        defaultCanFilter: false,
        defaultCanSort: false,
      },

      {
        Header: "Recruiter Assigned",
        accessor: "recruiterAssignedName",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Training Start Date",
        accessor: "trainingStartDate",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Training Update",
        accessor: "trainingUpdate",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Last Earning Date",
        accessor: "lastEarningDate",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Any Concerns",
        accessor: "anyConcerns",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Added by",
        accessor: "addedBy",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Added on",
        accessor: "addedOn",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
    ],
    []
  );

  const handleSendMsg = () => {
    const indexes = Object.entries(selectedRowIds);
    let idList = "";
    indexes.map((id, index) => {
      if (id[1]) {
        idList += data[parseFloat(id[0])].userId + ",";
      }

      return true;
    });
    idList = idList.substr(0, idList.length - 1);
    setSelectedUserIds(idList);
    setOpenSendMsg(!openSendMsg);
  };

  const leadsColumn = useMemo(
    () => [
      {
        id: "selection",
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <div>
            <IndeterminateCheckbox
              {...getToggleAllRowsSelectedProps()}
              style={{ color: "white" }}
            />
          </div>
        ),
        Cell: ({ row }) => (
          <>
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>{" "}
          </>
        ),
      },
      {
        Header: "Lead id",
        accessor: "leadId",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Name",
        accessor: "name",
        Cell: (row) => {
          // till the worker id not assigned to lead -> showing lead view
          if (
            row.row.original.workerId === null ||
            row.row.original.workerId === undefined
          ) {
            return row.row.original.criminalRecord === "Yes" ? (
              <button
                className={styles.workerName}
                type="button"
                id={row.row.original.userId}
                onClick={handleCriminalRecord}
              >
                {row.row.original.name}
              </button>
            ) : (
              <Link
                to={{
                  pathname: `/LeadWorkerview/v2/${row.row.original.userId}`,
                }}
                className="nameLink"
              >
                {row.row.original.name}
              </Link>
            );
          } else {
            return row.row.original.criminalRecord === "Yes" ? (
              <button
                className={styles.workerName}
                type="button"
                id={row.row.original.userId}
                onClick={handleCriminalRecord}
              >
                {row.row.original.name}
              </button>
            ) : (
              <Link
                to={{ pathname: `/workerview/v2/${row.row.original.userId}` }}
                className="nameLink"
              >
                {row.row.original.name}
              </Link>
            );
          }
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      // not working clientName
      {
        Header: "Contact No",
        accessor: "contactNo",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Client Name",
        accessor: "clientName",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "registeredOnApp",
        accessor: "registeredOnApp",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: (row) => {
          return (
            <div>
              Profile Completion{" "}
              <HtmlTooltip
                title={
                  <div>
                    <li>Basic Details</li>
                    <li>Address Info</li>
                    <li>Aadhar, DL & Address Proof</li>
                    <li>RC & PAN</li>
                  </div>
                }
              >
                <InfoIcon
                  style={{ verticalAlign: "bottom" }}
                  fontSize="small"
                />
              </HtmlTooltip>
            </div>
          );
        },
        accessor: "profileStatus",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Documents",
        accessor: "documents",
        Cell: (row) => {
          return (
            <button
              className={styles.workerName}
              type="button"
              style={{ fontSize: "16px !important" }}
              onClick={() =>
                getDocumentList(
                  row.row.original.userId,
                  row.row.original.profilePhoto
                )
              }
            >
              Download
            </button>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Last SMS sent",
        // accessor: "zone",
        Cell: LastSentMsgs,
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "City",
        accessor: "leadCity",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Pin code",
        accessor: "leadPincode",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Location",
        accessor: "userGoogleLocation",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Aadhar Card",
        // leadIsAdhaar
        accessor: "leadIsAdhaar",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Bike",
        accessor: "leadIsBike",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Driving License",
        accessor: "leadIsDrivingLicense",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Documents Verified",
        accessor: "documentsVerified",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Father's Name",
        accessor: "fathersName",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Date of birth",
        accessor: "dob",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Gender",
        accessor: "gender",
        defaultCanFilter: false,
        defaultCanSort: false,
      },

      {
        Header: "Aadhar Number",
        accessor: "aadharNumberLead",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Present Address",
        accessor: "present_address",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Present City",
        accessor: "present_city",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Present State",
        accessor: "present_state",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Present Pincode",
        accessor: "present_pincode",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Permanent address",
        accessor: "permanent_address",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Permanent city",
        accessor: "permanent_city",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Permanent state",
        accessor: "permanent_state",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Permanent Pincode",
        accessor: "permanent_pincode",
        defaultCanFilter: false,
        defaultCanSort: false,
      },

      {
        Header: "Recruiter Assigned",
        accessor: "recruiterAssignedName",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Interview Date",
        accessor: "interviewDate",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Interview Status",
        accessor: "interviewStatus",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Call Status",
        accessor: "callSatuts",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Preferred Hub",
        accessor: "preferredHub",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Training Start Date",
        accessor: "trainingStartDate",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Training Update",
        accessor: "trainingUpdate",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Added on",
        accessor: "leadInsertedOn",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Lead Source",
        accessor: "leadSource",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
    ],
    []
  );

  const createWorkerStatus = (status) => {
    let text = "";
    switch (status) {
      case 0:
        text = "Not Onboarded";
        break;
      case 1:
        text = "Active";
        break;
      case 2:
        text = "Partially Onboarded";
        break;
      case 3:
        text = "Inactive";
        break;
      case 4:
        text = "Offboarding Initiated";
        break;
      case 5:
        text = "Onboarding Completed";
        break;
      case 6:
        text = "Temporarily Inactive";
        break;
      case 7:
        text = "Temporary Inactivation Initiated";
        break;
      case 8:
        text = "Reactivation Initiated";
        break;
      default:
        break;
    }
    return text;
  };

  //Function to create table data
  const makeData = (response) => {
    let extractedData = [];
    let lastupdatedon = [];
    response.content.map((el) => {
      const sourceByType =
        el.sourceBy === "VENDOR"
          ? "Vendor"
          : el.sourceBy === "TA"
          ? "HRBP/TA"
          : el.sourceBy === "EREF"
          ? "Employee reference"
          : "";
      const sourceByName = el.sourceByName ? ` - ${el.sourceByName}` : "";
      const addedEmail = el.workerInsertedByEmail
        ? `(${el.workerInsertedByEmail})`
        : "";
      extractedData.push({
        lastSmsTemplate: el.lastSmsTemplate || "",
        lastSmsTime: el.lastSmsTime
          ? moment(el.lastSmsTime).format("hh:mm a, DD/MM/YYYY")
          : "",
        lastSmsMessage: el.lastSmsMessage || "",
        leadId: el.leadId || "",
        registeredOnApp:
          el.appLead !== null && el.appLead !== 0
            ? moment(el.leadInsertedOn).format("DD/MM/YYYY hh:mm a")
            : "--",
        leadCity: el.leadCity || "",
        userGoogleLocation: el.userGoogleLocation || "",
        leadIsAdhaar: el.leadIsAdhaar
          ? el.leadIsAdhaar === 1
            ? "Yes"
            : "No"
          : "No",
        leadIsDrivingLicense: el.leadIsDrivingLicense
          ? el.leadIsDrivingLicense === 1
            ? "Yes"
            : "No"
          : "No",
        leadIsBike: el.leadIsBike ? (el.leadIsBike === 1 ? "Yes" : "No") : "No",
        leadPincode: el.leadPincode || "",
        aadharNumberLead: el.aadharNumberLead || "",
        profileStatus:
          el.profileStatus === "4" ? "Completed" : el.profileStatus + "/4",
        clientName: el.clientName || "",
        // profile completed
        // document verified
        // lead inserted on

        //client ids
        //Client(s)
        casperId: el.casperId || null,
        okaygoId: el.okaygoId || null,
        zomatoId: el.zomatoId || null,
        swiggyId: el.swiggyId || null,
        delhiveryId: el.delhiveryId || null,
        jiomartId: el.jiomartId || null,

        //swiggy
        swiggyZone: el.swiggyZone || "",
        swiggyJoiningDate: el.swiggyJoiningDate
          ? moment(el.swiggyJoiningDate).format("DD/MM/YYYY")
          : "",
        swiggyExitDate: el.swiggyExitDate
          ? moment(el.swiggyExitDate).format("DD/MM/YYYY")
          : "",
        swiggyWorkerStatus:
          el.swiggyWorkerStatus !== null
            ? createWorkerStatus(el.swiggyWorkerStatus)
            : null,
        swiggyActivationDate: el.swiggyActiveDate
          ? moment(el.swiggyActiveDate).format("DD/MM/YYYY")
          : "",
        swiggyEarningStatus:
          el.swiggyEarningStatus !== null
            ? el.swiggyEarningStatus
              ? "Active"
              : "Inactive"
            : null,
        zomatoEarningStatus:
          el.zomatoEarningStatus !== null
            ? el.zomatoEarningStatus
              ? "Active"
              : "Inactive"
            : null,
        delhiveryEarningStatus:
          el.delhiveryEarningStatus !== null
            ? el.delhiveryEarningStatus
              ? "Active"
              : "Inactive"
            : null,
        jioEarningStatus:
          el.jioEarningStatus !== null
            ? el.jioEarningStatus
              ? "Active"
              : "Inactive"
            : null,
        flipkartEarningStatus:
          el.flipkartEarningStatus !== null
            ? el.flipkartEarningStatus
              ? "Active"
              : "Inactive"
            : null,
        //zomato
        zomatoZone: el.zomatoZone || "",
        zomatoJoiningDate: el.zomatoJoiningDate
          ? moment(el.zomatoJoiningDate).format("DD/MM/YYYY")
          : "",
        zomatoExitDate: el.zomatoExitDate
          ? moment(el.zomatoExitDate).format("DD/MM/YYYY")
          : "",
        zomatoWorkerStatus:
          el.zomatoWorkerStatus !== null
            ? createWorkerStatus(el.zomatoWorkerStatus)
            : null,
        zomatoActivationDate: el.zomatoActiveDate
          ? moment(el.zomatoActiveDate).format("DD/MM/YYYY")
          : "",
        //delhivery
        delhiveryZone: el.delhiveryZone || "",
        delhiveryDateOfJoining: el.delhiveryDateOfJoining
          ? moment(el.delhiveryDateOfJoining).format("DD/MM/YYYY")
          : "",
        delhiveryInactiveDate: el.delhiveryInactiveDate
          ? moment(el.delhiveryInactiveDate).format("DD/MM/YYYY")
          : "",
        delhiveryWorkerStatus:
          el.delhiveryWorkerStatus !== null
            ? createWorkerStatus(el.delhiveryWorkerStatus)
            : null,
        delhiveryactiveDate: el.delhiveryactiveDate
          ? moment(el.delhiveryactiveDate).format("DD/MM/YYYY")
          : "",
        //jiomart
        jiomartZone: el.jiomartZone || "",
        jiomartDateOfJoining: el.jiomartDateOfJoining
          ? moment(el.jiomartDateOfJoining).format("DD/MM/YYYY")
          : "",
        jiomartInactiveDate: el.jiomartInactiveDate
          ? moment(el.jiomartInactiveDate).format("DD/MM/YYYY")
          : "",
        jiomartWorkerStatus:
          el.jiomartWorkerStatus !== null
            ? createWorkerStatus(el.jiomartWorkerStatus)
            : null,
        jiomartactiveDate: el.jiomartactiveDate
          ? moment(el.jiomartactiveDate).format("DD/MM/YYYY")
          : "",

        smsCount: el.smsSentCount || "",
        smsDeliveryStatus: el.smsDeliveryStatus || "",
        LastSmsDeliveredOn: el.smsDeliveredOn
          ? moment(el.smsDeliveredOn).format("DD/MM/YYYY hh:mm a")
          : "",
        status: createWorkerStatus(el.workerStatus),
        name: `${el.firstName ? el.firstName : ""} ${
          el.lastName ? el.lastName : ""
        }`,
        fathersName: el.fatherName || "",
        contactNo: el.phoneNumber || "",
        aadharNumber: el.aadhaarNumber || "",
        gender:
          el.gender === "26"
            ? "Male"
            : el.gender === "27"
            ? "Female"
            : el.gender === "30"
            ? "Other"
            : "",
        dob: el.dateOfBirth ? moment(el.dateOfBirth).format("DD/MM/YYYY") : "",
        maritalStatus:
          el.maritalStatus === 1871
            ? "Single"
            : el.maritalStatus === 1872
            ? "Married"
            : el.maritalStatus === 1873
            ? "Other"
            : "",
        edab:
          el.physicallyDisabled === 1
            ? "Yes"
            : el.physicallyDisabled === 0
            ? "No"
            : "",
        criminalRecord:
          el.criminalRecord === 0 ? "No" : el.criminalRecord === 1 ? "Yes" : "",
        present_address: el.curAddressLine1 || "",
        workerStatus: el.workerStatus,
        present_city: el.curCity || "",
        present_state: el.curStateName || "",
        permanent_pincode: el.perPincode || "",
        present_pincode: el.curPincode || "",
        leadSource: el.leadSource || "",
        permanent_address: el.perAddressLine1 || "",
        permanent_city: el.perCity || "",
        permanent_state: el.perStateName || "",
        emergencyContactNo: el.emergencyContact || "",
        qualification: el.qualification || "",
        bankDetails: el.accountNumber ? "Yes" : "No",
        workExperience:
          el.totalExpYear || el.totalExpMonth
            ? `${el.totalExpYear ? el.totalExpYear : 0} yrs ${
                el.totalExpMonth ? el.totalExpMonth : 0
              } months`
            : "",
        rolePreference: el.jobRolePreferenceName || "",
        sourcedBy: `${sourceByType}${sourceByName}`,
        zone: el.zone || "",
        facility_name: el.facilityName || "",
        facility_city: el.facilityCity || "",
        facility_state: el.facilityState || "",
        rateCard: el.rateCardType || "",
        facility_type: el.facilityType ? el.facilityType : "",
        designation: el.designation || "",
        date_of_joining: el.dateOfJoining
          ? moment(el.dateOfJoining).format("DD/MM/YYYY")
          : "",
        activationDate: el.activeDate
          ? moment(el.activeDate).format("DD/MM/YYYY")
          : "",
        reactivationDate: el.reactivationDate
          ? moment(el.reactivationDate).format("DD/MM/YYYY")
          : null,
        reactivationDateTimestamp: el.reactivationDate
          ? el.reactivationDate
          : null,
        exitType: el.exitType || "",
        exitDate: el.exitDate ? moment(el.exitDate).format("DD/MM/YYYY") : "",
        deactivationDate: el.inactiveDate
          ? moment(el.inactiveDate).format("DD/MM/YYYY")
          : "",
        reasonOfLeaving: el.reasonOfLeaving || "",
        offboardingInitiateDate: el.offboardingInitiationDate
          ? moment(el.offboardingInitiationDate).format("DD/MM/YYYY")
          : "",
        offboardingInitiateBy: el.offboardingInitiateBy || "",
        blacklisted:
          el.blacklisted === 1 ? "Yes" : el.blacklisted === 0 ? "No " : "",
        userId: el.userId,
        agreementPath: el.agreementFilePath,
        agreementName: el.agreementFileName,
        profilePhoto: el.profilePhoto,
        addedBy: `${
          el.workerInsertedByName ? el.workerInsertedByName : ""
        } ${addedEmail}`,
        addedOn: el.workerInsertedOn
          ? moment(el.workerInsertedOn).format("DD/MM/YYYY hh:mm a")
          : "",
        groupflex: el.groupflexId ? el.groupflexId : "Eflex",
        partnerName: el.partnerName ? el.partnerName : "",
        workerId: el.workerId ? el.workerId : null,
        clients: makeClients(
          el.facilityName,
          el.zomatoWorkerStatus,
          el.swiggyWorkerStatus,
          el.delhiveryWorkerStatus,
          el.jiomartWorkerStatus
        ),
        documentsVerified: el.documentsVerified,
        leadInsertedOn: el.leadInsertedOn
          ? moment(el.leadInsertedOn).format("DD/MM/YYYY")
          : "",
        profileCompleted:
          el.docsUploadedUser !== null
            ? el.docsUploadedUser === 1
              ? "YES"
              : "NO"
            : "-",
        leader: el.isLeader !== null ? (el.isLeader === 1 ? "YES" : "NO") : "-",
        firstEarningDate: el.firstEarningDate
          ? moment(el.firstEarningDate).format("DD/MM/YYYY")
          : "",

        interviewStatus: el.interviewStatus !== null ? el.interviewStatus : "",
        interviewDate: el.interviewDate
          ? moment(el.interviewDate).format("DD/MM/YYYY")
          : "", // himnashu asked for this
        callSatuts: el.callSatuts !== null ? el.callSatuts : "",
        preferredHub: el.preferredHub !== null ? el.preferredHub : "",

        recruiterAssignedName:
          el.recruiterAssignedName !== null
            ? el.recruiterAssignedName +
              (el.recruiterAssignedNumber !== null
                ? " - " + el.recruiterAssignedNumber
                : "")
            : "",
        trainingUpdate: el.trainingUpdate !== null ? el.trainingUpdate : "",
        trainingStartDate:
          el.trainingUpdate !== null
            ? el.trainingStartDate
              ? moment(el.trainingStartDate).format("DD/MM/YYYY")
              : ""
            : "",
        lastEarningDate: el.lastEarningDate
          ? moment(el.lastEarningDate).format("DD/MM/YYYY")
          : "",
        anyConcerns: el.anyConcerns
          ? el.anyConcerns === 1
            ? "YES"
            : "NO"
          : "",
      });
      lastupdatedon.push(el.lastUpdatedOn);
      return true;
    });
    return extractedData;
  };

  const makeClients = (
    casperId,
    zomatoId,
    swiggyId,
    delhiveryId,
    jiomartId
  ) => {
    let str = " ";
    if (casperId !== null && casperId !== undefined) {
      str = str + "Flipkart,";
    }
    if (zomatoId !== null && zomatoId !== undefined) {
      str = str + "Zomato,";
    }
    if (swiggyId !== null && swiggyId !== undefined) {
      str = str + "Swiggy,";
    }
    if (delhiveryId !== null && delhiveryId !== undefined) {
      str = str + "Delhivery,";
    }
    if (jiomartId !== null && jiomartId !== undefined) {
      str = str + "Jiomart,";
    }
    return str.substring(0, str.length - 1);
  };

  const makeWorkerData = (response) => {
    let extractedData = [];

    response.map((el) => {
      const addedEmail = el.workerInsertedByEmail
        ? `(${el.workerInsertedByEmail})`
        : "";
      const sourceByType =
        el.sourceBy === "VENDOR"
          ? "Vendor"
          : el.sourceBy === "TA"
          ? "HRBP/TA"
          : el.sourceBy === "EREF"
          ? "Employee reference"
          : "";
      const sourceByName = el.sourceByName ? ` - ${el.sourceByName}` : "";
      const temp = {
        casperId: el.casperId || "",
        zomatoId: el.zomatoId || "",
        swiggyId: el.swiggyId || "",
        delhiveryId: el.delhiveryId || null,
        jiomartId: el.jiomartId || null,
        okaygoId: el.okaygoId || "",
        name: `${el.firstName ? el.firstName : ""} ${
          el.lastName ? el.lastName : ""
        }`,
        contactNo: el.phoneNumber || "",
        smsDelivered: el.smsSentCount || "",
        Source: el.partnerName ? el.partnerName : "",
        clients: makeClients(
          el.facilityName,
          el.zomatoWorkerStatus,
          el.swiggyWorkerStatus,
          el.delhiveryWorkerStatus,
          el.jiomartWorkerStatus
        ),
        fathersName: el.fatherName || "",
        aadharNumber: el.aadhaarNumber || "",
        gender:
          el.gender === "26"
            ? "Male"
            : el.gender === "27"
            ? "Female"
            : el.gender === "30"
            ? "Other"
            : "",
        dob: el.dateOfBirth ? moment(el.dateOfBirth).format("DD/MM/YYYY") : "",
        present_address: el.curAddressLine1 || "",
        present_city: el.curCity || "",
        present_state: el.curStateName || "",
        present_pincode: el.curPincode || "",
        permanent_address: el.perAddressLine1 || "",
        permanent_city: el.perCity || "",
        permanent_state: el.perStateName || "",
        permanent_pincode: el.perPincode || "",
        bankDetails: el.accountNumber ? "Yes" : "No",
        groupflex: el.groupflexId ? el.groupflexId : "Eflex",
        leader: el.isLeader !== null ? (el.isLeader === 1 ? "YES" : "NO") : "-",
        sourcedBy: `${sourceByType}${sourceByName}`,
        flipkartStatus: createWorkerStatus(el.workerStatus),
        flipkartEarningStatus:
          el.flipkartEarningStatus !== null
            ? el.flipkartEarningStatus
              ? "Active"
              : "Inactive"
            : null,
        facility_name: el.facilityName || "",
        facility_city: el.facilityCity || "",
        facility_state: el.facilityState || "",
        zone: el.zone || "",
        rateCard: el.rateCardType || "",
        activationDate: el.activeDate
          ? moment(el.activeDate).format("DD/MM/YYYY")
          : "",
        date_of_joining: el.dateOfJoining
          ? moment(el.dateOfJoining).format("DD/MM/YYYY")
          : "",
        firstEarningDate: el.firstEarningDate
          ? moment(el.firstEarningDate).format("DD/MM/YYYY")
          : "",
        exitDate: el.exitDate ? moment(el.exitDate).format("DD/MM/YYYY") : "",
        blacklisted:
          el.blacklisted === 1 ? "Yes" : el.blacklisted === 0 ? "No " : "",
        swiggyZone: el.swiggyZone || "",
        swiggyJoiningDate: el.swiggyJoiningDate
          ? moment(el.swiggyJoiningDate).format("DD/MM/YYYY")
          : "",
        swiggyExitDate: el.swiggyExitDate
          ? moment(el.swiggyExitDate).format("DD/MM/YYYY")
          : "",
        swiggyActivationDate: el.swiggyActiveDate
          ? moment(el.swiggyActiveDate).format("DD/MM/YYYY")
          : "",
        swiggyWorkerStatus: el.swiggyWorkerStatus
          ? createWorkerStatus(el.swiggyWorkerStatus)
          : null,
        swiggyEarningStatus:
          el.swiggyEarningStatus !== null
            ? el.swiggyEarningStatus
              ? "Active"
              : "Inactive"
            : null,

        zomatoZone: el.zomatoZone || "",
        zomatoJoiningDate: el.zomatoJoiningDate
          ? moment(el.zomatoJoiningDate).format("DD/MM/YYYY")
          : "",
        zomatoExitDate: el.zomatoExitDate
          ? moment(el.zomatoExitDate).format("DD/MM/YYYY")
          : "",
        zomatoActivationDate: el.zomatoActiveDate
          ? moment(el.zomatoActiveDate).format("DD/MM/YYYY")
          : "",
        zomatoWorkerStatus: el.zomatoWorkerStatus
          ? createWorkerStatus(el.zomatoWorkerStatus)
          : null,
        zomatoEarningStatus:
          el.zomatoEarningStatus !== null
            ? el.zomatoEarningStatus
              ? "Active"
              : "Inactive"
            : null,
        //delhivery
        delhiveryZone: el.delhiveryZone || "",
        delhiveryDateOfJoining: el.delhiveryDateOfJoining
          ? moment(el.delhiveryDateOfJoining).format("DD/MM/YYYY")
          : "",
        delhiveryInactiveDate: el.delhiveryInactiveDate
          ? moment(el.delhiveryInactiveDate).format("DD/MM/YYYY")
          : "",
        delhiveryWorkerStatus:
          el.delhiveryWorkerStatus !== null
            ? createWorkerStatus(el.delhiveryWorkerStatus)
            : null,
        delhiveryactiveDate: el.delhiveryactiveDate
          ? moment(el.delhiveryactiveDate).format("DD/MM/YYYY")
          : "",
        delhiveryEarningStatus:
          el.delhiveryEarningStatus !== null
            ? el.delhiveryEarningStatus
              ? "Active"
              : "Inactive"
            : null,

        //jiomart
        jiomartZone: el.jiomartZone || "",
        jiomartDateOfJoining: el.jiomartDateOfJoining
          ? moment(el.jiomartDateOfJoining).format("DD/MM/YYYY")
          : "",
        jiomartInactiveDate: el.jiomartInactiveDate
          ? moment(el.jiomartInactiveDate).format("DD/MM/YYYY")
          : "",
        jiomartWorkerStatus:
          el.jiomartWorkerStatus !== null
            ? createWorkerStatus(el.jiomartWorkerStatus)
            : null,
        jiomartactiveDate: el.jiomartactiveDate
          ? moment(el.jiomartactiveDate).format("DD/MM/YYYY")
          : "",
        jioEarningStatus:
          el.jioEarningStatus !== null
            ? el.jioEarningStatus
              ? "Active"
              : "Inactive"
            : null,

        recruiterAssignedName:
          el.recruiterAssignedName !== null
            ? el.recruiterAssignedName +
              (el.recruiterAssignedNumber !== null
                ? " - " + el.recruiterAssignedNumber
                : "")
            : "",
        trainingUpdate: el.trainingUpdate !== null ? el.trainingUpdate : "",
        trainingStartDate:
          el.trainingUpdate !== null
            ? el.trainingStartDate
              ? moment(el.trainingStartDate).format("DD/MM/YYYY")
              : ""
            : "",
        lastEarningDate: el.lastEarningDate
          ? moment(el.lastEarningDate).format("DD/MM/YYYY")
          : "",
        anyConcerns: el.anyConcerns
          ? el.anyConcerns === 1
            ? "YES"
            : "NO"
          : "",
        addedOn: el.workerInsertedOn
          ? moment(el.workerInsertedOn).format("DD/MM/YYYY hh:mm a")
          : "",
        addedBy: `${
          el.workerInsertedByName ? el.workerInsertedByName : ""
        } ${addedEmail}`,
      };
      extractedData.push(temp);

      return true;
    });

    return extractedData;
  };

  const makeLeadData = (response) => {
    let extractedData = [];
    response.map((el) => {
      const temp = {
        leadId: el.leadId || "",
        name: `${el.firstName ? el.firstName : ""} ${
          el.lastName ? el.lastName : ""
        }`,
        contactNo: el.phoneNumber || "",
        clientName: el.clientName || "",
        registeredOnApp:
          el.appLead !== null && el.appLead !== 0
            ? moment(el.leadInsertedOn).format("DD/MM/YYYY hh:mm a")
            : "--",
        City: el.leadCity || "",
        Pincode: el.leadPincode || "",
        Location: el.userGoogleLocation || "",
        AdhaarCard: el.leadIsAdhaar
          ? el.leadIsAdhaar === 1
            ? "Yes"
            : "No"
          : "No",
        Bike: el.leadIsBike ? (el.leadIsBike === 1 ? "Yes" : "No") : "No",
        DrivingLicense: el.leadIsDrivingLicense
          ? el.leadIsDrivingLicense === 1
            ? "Yes"
            : "No"
          : "No",
        profileCompletion:
          el.profileStatus === "4"
            ? "Completed"
            : el.profileStatus
            ? "(" + el.profileStatus + "/4)"
            : "",
        documentsVerified: el.documentsVerified,
        fathersName: el.fatherName || "",
        dob: el.dateOfBirth ? moment(el.dateOfBirth).format("DD/MM/YYYY") : "",
        gender:
          el.gender === "26"
            ? "Male"
            : el.gender === "27"
            ? "Female"
            : el.gender === "30"
            ? "Other"
            : "",
        AadharNumber: el.aadharNumberLead || "",
        present_address: el.curAddressLine1 || "",
        present_city: el.curCity || "",
        present_state: el.curStateName || "",
        present_pincode: el.curPincode || "",
        permanent_address: el.perAddressLine1 || "",
        permanent_city: el.perCity || "",
        permanent_state: el.perStateName || "",
        permanent_pincode: el.perPincode || "",
        recruiterAssignedName:
          el.recruiterAssignedName !== null
            ? el.recruiterAssignedName +
              (el.recruiterAssignedNumber !== null
                ? " - " + el.recruiterAssignedNumber
                : "")
            : "",
        interviewStatus: el.interviewStatus !== null ? el.interviewStatus : "",
        interviewDate: el.interviewDate
          ? moment(el.interviewDate).format("DD/MM/YYYY")
          : "", // himnashu asked for this
        callStatus: el.callSatuts !== null ? el.callSatuts : "",
        preferredHub: el.preferredHub !== null ? el.preferredHub : "",
        trainingUpdate: el.trainingUpdate !== null ? el.trainingUpdate : "",
        trainingStartDate:
          el.trainingUpdate !== null
            ? el.trainingStartDate
              ? moment(el.trainingStartDate).format("DD/MM/YYYY")
              : ""
            : "",
        AddedOn: el.leadInsertedOn
          ? moment(el.leadInsertedOn).format("DD/MM/YYYY")
          : "",
        Lead_Source: el.leadSource || "",
      };
      extractedData.push(temp);

      return true;
    });

    return extractedData;
  };

  const updateMyData = () => {
    getExternalWorkerList(perPage, currentPage);
  };

  const handleEditPhoneNumberService = (contact, userId) => {
    Axios.post(
      `${urlLinks.baseUrl}${urlLinks.workerUrls.editContact}?userId=${userId}&value=${contact}`
    )
      .then((response) => {
        if (response.data.code === 1000) {
          showToast("success", "Contact number updated successfully");
          handleEditContactClose();
          updateMyData();
        } else {
          setEditContactData({
            ...editContactData,
            error: response.data.response.error,
          });
        }
      })
      .catch((error) => {
        setEditContactData({ ...editContactData, error: error.message });
      });
  };

  const createAllRecordExcel = () => {
    setAllRecordLoading(true);

    if (isLeadData === "") {
      Axios.get(
        `${urlLinks.baseUrl}${urlLinks.workerUrls.workerListingV3}?${filterUrl}${sortUrl}${sortDirectionUrl}` +
          "&exportExcel=1"
      ).then((response) => {
        if (response) {
          setAllRecord(makeWorkerData(response.data.response));
        }
      });
    } else {
      Axios.get(
        `${urlLinks.baseUrl}${urlLinks.workerUrls.workerListingV3}?${filterUrl}${sortUrl}${sortDirectionUrl}` +
          "&exportExcel=1&leadRecords=1"
      ).then((response) => {
        if (response) {
          setAllRecord(makeLeadData(response.data.response));
        }
      });
    }
  };

  useEffect(() => {
    setAllRecordLoading(false);
  }, [allRecord]);

  const getExternalWorkerList = (rows, pageNo) => {
    setLoading(true);

    if (localStorage.getItem("roleType")) {
      Axios.get(
        `${urlLinks.baseUrl}${urlLinks.workerUrls.workerListingV3}?page_no=${
          pageNo - 1
        }&rows=${rows}${filterUrl}${sortUrl}${sortDirectionUrl}${isLeadData}${
          tab.leadDataTab ? assignFilterString : ""
        }`
      )
        .then((response) => {
          if (response) {
            setTotalRows(response.data.response.totalElements);
            setTotalPages(response.data.response.totalPages);
            if (currentPage > response.data.response.totalPages)
              setCurrentPage(1);
            setData(makeData(response.data.response));
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  const handleReactivationDate = () => {
    Axios.post(
      `${UrlLinks.baseUrl}${
        UrlLinks.workerUrls.workerReactivation
      }?reActivationDate=${moment(reactivationData.date).format(
        "YYYY-MM-DD 00:00:00"
      )}&userId=${reactivationData.userId}&updatedBy=${parseInt(
        localStorage.getItem("userID")
      )}`
    )
      .then((response) => {
        showToast("success", "Activation date set successfully");
        getExternalWorkerList(perPage, currentPage);
        setReactivationData({
          open: false,
          userId: null,
          date: null,
        });
      })
      .catch((error) => {
        showToast("error", error.message);
      });
  };

  useEffect(() => {
    getExternalWorkerList(perPage, 1);
  }, [
    filterUrl,
    sortUrl,
    sortDirectionUrl,
    localStorage.getItem("roleType"),
    tab,
    assignFilterString,
  ]);

  const columns = tab.workerDataTab === true ? workerDataColumn : leadsColumn;

  const handlePageChange = (page) => {
    getExternalWorkerList(perPage, page);
    setCurrentPage(page);
  };

  const handleActions = (event) => {
    if (event.target.value === "uploadWorkerData") {
      // new onboarding Model v2
      setOpenOnboardingModalV2(true);
    } else if (event.target.value === "uploadWorkerData") {
      setOpenOnboardingModal(true);
    } else if (event.target.value === "uploadApnaWorkerData") {
      setOpenOnboardingApnaModalV3(true);
    } else if (event.target.value === "uploadOffboardingData") {
      setOpenOffboardingModal(true);
    } else if (event.target.value === "UploadCasperID") {
      setOpenCasperOnboardingModal(true);
    } else if (event.target.value === "agreement") {
      handleDownloadAgreements();
    }
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    toggleAllRowsSelected,
    state: { selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      updateMyData,
      showToast,
      setLoading,
      filters,
      setFilters,
      manualSortBy: true,
    },
    useFilters,
    useSortBy,
    useRowSelect
  );

  const handlePerRowsChange = (newPerPage) => {
    getExternalWorkerList(newPerPage, 1);
    setPerPage(newPerPage);
    setCurrentPage(1);
  };

  const createZipForDocuments = (urls) => {
    var zip = new JSZip();
    var count = 0;
    var zipFilename = "Agreements.zip";
    urls.forEach(async function (url, index) {
      const record = Object.entries(url);
      const filename = `${record[0][0]}`;
      const path = `${record[0][1]}`;
      try {
        await JSZipUtils.getBinaryContent(path, function (err, data) {
          if (err) {
            throw err; // or handle the error
          }
          zip.file(filename, data, { binary: true });
          count++;
          if (count === urls.length) {
            zip.generateAsync({ type: "blob" }).then(function (content) {
              saveAs(content, zipFilename);
              setAgreementDownloading(false);
            });
          }
        });
      } catch (err) {}
    });
  };

  useEffect(() => {
    if (selectedRowIds) {
      const indexes = Object.values(selectedRowIds);
      let disableButton = true;
      indexes.map((id) => {
        if (id) {
          disableButton = false;
          return true;
        }
        return true;
      });
      setDisable(disableButton);
    }
  }, [selectedRowIds]);

  const handleDownloadAgreements = () => {
    setAgreementDownloading(true);
    const rowIds = Object.keys(selectedRowIds);
    let agreementUrls = [];
    rowIds.map((row) => {
      if (data[row].agreementPath) {
        const fileName = `${data[row].contactNo}_${data[row].name}.pdf`;
        agreementUrls.push({
          [fileName]: data[row].agreementPath,
        });
      }
    });
    if (agreementUrls.length > 0) {
      createZipForDocuments(agreementUrls);
    } else {
      setAgreementDownloading(false);
      showToast("error", "It will take some time to generate agreement.");
    }
  };

  const loader = (
    <div style={{ margin: "130px auto" }}>
      <ScaleLoader height={70} width={10} color={"#1c75bc"} />
    </div>
  );

  const table = (
    <div className={`tableContainer ${styles.tableContainer}`}>
      <table {...getTableProps()} className="table">
        <thead className="thead">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps()}
                  className={
                    column.render("Header") === "Worker Id"
                      ? "stickWorkerIdnewMatch zIndex1080 header"
                      : column.render("Header") === "Name"
                      ? "stickWorkerNameMatchNew zIndex1080 header"
                      : column.render("Header") === "Profile Completion"
                      ? ""
                      : column.id === "selection"
                      ? "stickCheckbox zIndex1080 header"
                      : column.render("Header") === "Last SMS sent"
                      ? "width200"
                      : "header"
                  }
                >
                  <span>{column.render("Header")}</span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  className={`row ${
                    row.original.criminalRecord ? "criminalRecord" : ""
                  }`}
                >
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        className={`${
                          cell.column.Header === "Worker Id"
                            ? "stickWorkerIdnewMatch  zIndex540"
                            : cell.column.Header === "Name"
                            ? "stickWorkerNameMatchNew zIndex540"
                            : cell.column.id === "selection"
                            ? "stickCheckbox zIndex540"
                            : "cell"
                        } ${
                          row.original.criminalRecord === "Yes"
                            ? "criminalRecord"
                            : ""
                        }`}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        }
      </table>
    </div>
  );

  const handleAssignFilter = (ev) => {
    if (ev === "assigned") {
      setAssignFilter({
        ...AssignFilter,
        notAssignTab: false,
        assignTab: true,
        all: false,
      });
      setassignFilterString("&notAssigned=1");
    } else if (ev === "notAssigned") {
      setAssignFilter({
        ...AssignFilter,
        notAssignTab: true,
        assignTab: false,
        all: false,
      });
      setassignFilterString("&notAssigned=0");
    } else if (ev === "all") {
      setAssignFilter({
        ...AssignFilter,
        notAssignTab: false,
        assignTab: false,
        all: true,
      });
      setassignFilterString("");
    }
  };
  return (
    <Styles>
      <Grid container className="padding2percent">
        <Grid item xs={12}>
          <span className="fontSize24 fontWeightBold marginRight20">
            Worker Data{" "}
          </span>
          <Button
            onClick={(event) => {
              if (tab.leadDataTab === true) {
                handleTab(event, "1");
              }
            }}
            className={`statusButtons marginRight20 ${
              tab.workerDataTab ? "blueColorBorder" : "greyColorBorder"
            }`}
          >
            Worker Data
          </Button>
          <Button
            onClick={(event) => {
              if (tab.workerDataTab === true) {
                handleTab(event, "2");
              }
            }}
            className={`statusButtons marginRight20 ${
              tab.leadDataTab ? "blueColorBorder" : "greyColorBorder"
            }`}
          >
            Leads data
          </Button>
        </Grid>
        <Grid item xs={12} className="flexDisplay alignItemsFlexStart">
          <Grid
            item
            xs={5}
            className="flexDisplay padding0 paddingTop8 justifySpaceBetween"
          >
            <TextField
              className={`${classes.root} marginLeft0`}
              placeholder="Search by Aadhar No./ Contact number / OkayGo ID / Client ID"
              fullWidth
              value={searchBy}
              onChange={(event) => setSearchBy(event.target.value)}
              variant="outlined"
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleSearch();
                }
              }}
              InputProps={{
                className: classes.input,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <div className={styles.searchButtonDiv}>
              <IconButton
                className={`${styles.closeButton} ${
                  searchBy ? "" : styles.noVisibility
                }`}
                onClick={handleSearchClose}
              >
                <CloseSharpIcon />
              </IconButton>
              <button onClick={handleSearch} className={styles.searchButton}>
                Search
              </button>
            </div>
          </Grid>
          <div style={{ position: "relative", left: "234px" }}>
            <FormControl
              variant="outlined"
              className="margin0"
              style={{ paddingRight: "18px" }}
            >
              <Select
                id="actions"
                value={actions}
                style={{ marginLeft: "", marginTop: "7px" }}
                classes={{ outlined: styles.actionSelect }}
                onChange={handleActions}
              >
                <MenuItem value={0} disabled={true}>
                  {" "}
                  Actions
                </MenuItem>
                <MenuItem value="uploadWorkerData">
                  Upload Onboarding data
                </MenuItem>
                <MenuItem value="uploadApnaWorkerData">
                  Upload Apna leads data
                </MenuItem>
                <MenuItem value="UploadCasperID">Upload Client IDs</MenuItem>
                <MenuItem value="uploadOffboardingData">
                  Upload Offboarding data
                </MenuItem>
                <MenuItem
                  disabled={Object.keys(selectedRowIds).length === 0}
                  value="agreement"
                >
                  Download Agreements
                </MenuItem>
              </Select>
            </FormControl>
            <Button
              variant="contained"
              className="dataButton"
              style={{ marginTop: "7px" }}
              onClick={createAllRecordExcel}
              disabled={allRecordLoading}
            >
              Export to Excel
            </Button>
            {allRecordLoading ? (
              <span style={{ marginRight: "5px" }}>
                Please wait...it might take few minutes
              </span>
            ) : allRecord.length > 0 ? (
              <CSVLink
                data={allRecord}
                filename={
                  isLeadData === "" ? `Worker Data.csv` : `Lead Data.csv`
                }
                className="btn btn-primary blueColor downloadExcelButton"
                target="_blank"
                style={{
                  color: "inherit",
                  textDecoration: "none",
                  marginRight: "20px",
                  fontWeight: "500",
                }}
              >
                Download now
              </CSVLink>
            ) : null}
            <button
              className={styles.blueOutlinedButton}
              style={{ marginLeft: "16px" }}
              onClick={() => setOpenFilterDrawer(true)}
            >
              filters
            </button>
            {(localStorage.getItem("roleType") === "2" ||
              localStorage.getItem("roleType") === "6") && (
              <button
                className={
                  disable
                    ? styles.greyOutlinedButton
                    : styles.blueOutlinedButton
                }
                style={{ marginLeft: "16px" }}
                onClick={() => handleSendMsg()}
                disabled={disable}
              >
                Send Message
              </button>
            )}
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          className="flexDisplay paddingTop16 justifyFlexStart"
        >
          {tab.workerDataTab && (
            <Button
              variant="contained"
              className="dataButton"
              style={{ marginTop: "7px" }}
              onClick={() => {
                const indexes = Object.entries(selectedRowIds);
                let idList = "";
                indexes.map((id, index) => {
                  if (id[1]) {
                    idList += data[parseFloat(id[0])].userId + ",";
                  }
                  return true;
                });
                idList = idList.substr(0, idList.length - 1);

                setSelectedUserIds(idList);
                setOpenAssignModal(true);
              }}
              disabled={disable}
            >
              Assign To Recruiter
            </Button>
          )}
          {tab.workerDataTab === false && (
            <>
              <Button
                onClick={(event) => {
                  handleAssignFilter("all");
                }}
                className={`statusButtons marginRight20 ${
                  AssignFilter.all ? "blueColorBorder" : "greyColorBorder"
                }`}
              >
                All
              </Button>
              <Button
                onClick={(event) => {
                  {
                    handleAssignFilter("notAssigned");
                  }
                }}
                className={`statusButtons marginRight20 ${
                  AssignFilter.notAssignTab
                    ? "blueColorBorder"
                    : "greyColorBorder"
                }`}
              >
                Not Assigned
              </Button>
              <Button
                onClick={(event) => {
                  {
                    handleAssignFilter("assigned");
                  }
                }}
                className={`statusButtons marginRight20 ${
                  AssignFilter.assignTab ? "blueColorBorder" : "greyColorBorder"
                }`}
              >
                Assigned
              </Button>{" "}
            </>
          )}

          <div className={styles.criminalCheckBox}>
            <div></div>
            <span>Candidate with Criminal record</span>
          </div>
        </Grid>
        {loading ? (
          loader
        ) : data.length === 0 ? (
          <div className={styles.noRecord}>
            <img src={NoRecord} alt="noRecord" />
            <span>No records found</span>
          </div>
        ) : (
          <Grid item xs={12} className="paddingTop16">
            {table}
            <Pagination
              rowsperpage={perPage}
              pageNo={currentPage}
              totalElements={totalRows}
              totalPages={totalPages}
              handlePageChange={handlePageChange}
              handlePerRowsChange={handlePerRowsChange}
            />
          </Grid>
        )}
        <Drawer
          anchor="right"
          open={openFilterDrawer}
          onClose={() => setOpenFilterDrawer(false)}
        >
          <FilterDrawer
            handleClose={() => setOpenFilterDrawer(false)}
            filters={filters}
            setFilters={setFilters}
            applyFilters={ApplyFilters}
            resetFilters={resetFilters}
          ></FilterDrawer>
        </Drawer>
        {openOnboardingModalV2 && (
          <OnboardingModalV2
            openOnboardingModal={openOnboardingModalV2}
            setOpenOnboardingModal={setOpenOnboardingModalV2}
            showToast={showToast}
            updateMyData={updateMyData}
          />
        )}
        {openOnboardingApnaModalV3 && (
          <OnBoardingModalV3
            openOnboardingModal={openOnboardingApnaModalV3}
            setOpenOnboardingModal={setOpenOnboardingApnaModalV3}
            showToast={showToast}
            updateMyData={updateMyData}
          />
        )}
        {openOnboardingModal && (
          <OnboardingModal
            openOnboardingModal={openOnboardingModal}
            setOpenOnboardingModal={setOpenOnboardingModal}
            showToast={showToast}
            updateMyData={updateMyData}
          />
        )}
        {openOffboardingModal && (
          <OffboardingModal
            openOffboardingModal={openOffboardingModal}
            setOpenOffboardingModal={setOpenOffboardingModal}
            showToast={showToast}
            updateMyData={updateMyData}
          />
        )}
        {openCasperOnboardingModal && (
          <CasperOnboardingModal
            openCasperOnboardingModal={openCasperOnboardingModal}
            setOpenCasperOnboardingModal={setOpenCasperOnboardingModal}
            showToast={showToast}
            partners={partners}
            updateMyData={updateMyData}
          />
        )}
        {openAssignModal && (
          <AssignToRecruiterModal
            open={openAssignModal}
            setOpen={setOpenAssignModal}
            showToast={showToast}
            userIds={selectedUserIds}
            updateMyData={updateMyData}
          />
        )}
        {criminalRecordData.open && (
          <CriminalRecord
            criminalRecordData={criminalRecordData}
            setCriminalRecordData={setCriminalRecordData}
          />
        )}
        {editContactData.open && (
          <EditContactModal
            open={editContactData}
            handleClose={handleEditContactClose}
            handleEditPhoneNumberService={handleEditPhoneNumberService}
          />
        )}
        {reactivationData.open && (
          <ReactivationModal
            open={reactivationData}
            handleClose={handleReactivationDateClose}
            handleReactivationDate={handleReactivationDate}
            setReactivationData={setReactivationData}
          />
        )}
        {openSendMsg && (
          <SendMsg
            isLeadData={isLeadData}
            filterUrl={filterUrl}
            open={openSendMsg}
            setOpen={setOpenSendMsg}
            userIds={selectedUserIds}
          />
        )}
      </Grid>
    </Styles>
  );
};

export default WorkerData;
