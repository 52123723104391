import React, { useState, useEffect, useContext } from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { useParams } from "react-router-dom";
import { Grid, Checkbox, FormControlLabel, Tooltip } from "@material-ui/core";
import Axios from "axios";
import styles from "./index.module.scss";
import { dateFormat } from "../../services/CommonServices";
import ReactPanZoom from "react-image-pan-zoom-rotate";
import { AuthContext } from "../../services/AuthProvider";
import qs from "querystring";
import UrlLinks from "../../UrlLinks";

const BankDetailsList = (props) => {
  const { showToast } = useContext(AuthContext);
  const params = useParams();
  const { userId, status } = params;
  const [currentUserId, setCurrentUserId] = useState(userId);
  const [data, setData] = useState({});
  const [showNextButton, setShowNextButton] = useState(true);
  const [submissionError, setSubmissionError] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [editChecks, setEditChecks] = useState({
    nameDisable: true,
    ifscDisable: true,
    imageDisable: true,
    numberDisable: true,
    submitDisable: true,
    editDisable: true,
    updateDisable: true,
    submitHide: true,
    editHide: true,
    mismatchHide: true,
    updateHide: true,
  });
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);

  const makeData = (response) => {
    setData({
      name: `${response.firstName ? response.firstName : ""} ${
        response.lastName ? response.lastName : ""
      }`,
      phoneNumber: response.phoneNumber || "",
      okaygoId: response.okaygoId || "",
      casperId: response.casperId || "",
      verifiedBy: response.verifiedBy || "",
      verifiedOn: dateFormat(response.verifiedOn),
      confirmedBy: response.confirmedBy || "",
      confirmedOn: dateFormat(response.confirmedOn),
      distortImage: response.distortImage === 1 ? true : false,
      accountHolderName: response.account_holder || "",
      ifscCode: response.ifsc_code || "",
      accountNumber: response.account_number || "",
      workerInput: response.account_number || "",
      adminInput: response.accountNumberByAdmin || "",
      accountProof: response.file_path
        ? `${response.file_path.replace("https://", "http://")}`
        : "",
      accountHolderNameMismatch: response.isAccHolderMatch === 1 ? false : true,
      ifscCodeMismatch: response.isIfscMatch === 1 ? false : true,
      accountNumberMismatch: response.isAccNumberMatch === 1 ? false : true,
      verification: response.verification,
      userId: response.user_id,
      tag: response.tag,
    });
  };

  const handleAdminAccountInput = (event) => {
    if (submissionError) {
      setSubmissionError(false);
    }
    setData({
      ...data,
      adminInput: event.target.value,
      accountNumberMismatch: false,
    });
  };

  const handleCheckBox = (event) => {
    setData({ ...data, [event.target.name]: event.target.checked });
  };

  useEffect(() => {
    if (data.verification >= 0) {
      const temp = {
        nameDisable: false,
        ifscDisable: false,
        numberDisable: false,
        imageDisable: false,
        submitDisable: false,
        editDisable: false,
        updateDisable: false,
        submitHide: false,
        editHide: true,
      };

      switch (data.verification) {
        case 0:
          if (data.distortImage) {
            temp.nameDisable = true;
            temp.ifscDisable = true;
            temp.numberDisable = true;
            temp.submitDisable = false;
            temp.updateDisable = false;
          } else if (!data.distortImage) {
            temp.nameDisable = false;
            temp.ifscDisable = false;
            temp.numberDisable = false;
            if (data.adminInput) {
              temp.submitDisable = false;
              temp.updateDisable = false;
            } else {
              temp.submitDisable = true;
              temp.updateDisable = true;
            }
          }
          setEditChecks({
            ...editChecks,
            nameDisable: temp.nameDisable,
            ifscDisable: temp.ifscDisable,
            numberDisable: temp.numberDisable,
            imageDisable: false,
            submitDisable: temp.submitDisable,
            editDisable: true,
            updateDisable: temp.updateDisable,
            submitHide: false,
            editHide: true,
            mismatchHide: true,
            updateHide: true,
          });
          break;
        case 1:
          if (edit) {
            if (data.distortImage) {
              temp.nameDisable = true;
              temp.ifscDisable = true;
              temp.numberDisable = true;
              temp.submitDisable = false;
              temp.updateDisable = false;
            } else if (!data.distortImage) {
              temp.nameDisable = false;
              temp.ifscDisable = false;
              temp.numberDisable = false;
              if (data.adminInput) {
                temp.submitDisable = false;
                temp.updateDisable = false;
              } else {
                temp.submitDisable = true;
                temp.updateDisable = true;
              }
            }
          } else {
            temp.nameDisable = true;
            temp.ifscDisable = true;
            temp.numberDisable = true;
            temp.imageDisable = true;
            temp.submitDisable = false;
            temp.updateDisable = false;
          }
          setEditChecks({
            ...editChecks,
            nameDisable: temp.nameDisable,
            ifscDisable: temp.ifscDisable,
            numberDisable: temp.numberDisable,
            imageDisable: temp.imageDisable,
            submitDisable: temp.submitDisable,
            editDisable: false,
            updateDisable: temp.updateDisable,
            submitHide: true,
            editHide: edit ? true : false,
            mismatchHide: edit ? true : false,
            updateHide: edit ? false : true,
          });
          break;
        case 2:
          if (edit) {
            if (data.distortImage) {
              temp.nameDisable = true;
              temp.ifscDisable = true;
              temp.numberDisable = true;
              temp.submitDisable = false;
              temp.updateDisable = false;
            } else if (!data.distortImage) {
              temp.nameDisable = false;
              temp.ifscDisable = false;
              temp.numberDisable = false;
              if (data.adminInput) {
                temp.submitDisable = false;
                temp.updateDisable = false;
              } else {
                temp.submitDisable = true;
                temp.updateDisable = true;
              }
            }
          } else {
            temp.nameDisable = true;
            temp.ifscDisable = true;
            temp.numberDisable = true;
            temp.imageDisable = true;
            temp.submitDisable = false;
            temp.updateDisable = false;
          }
          if (localStorage.getItem("roleType") === 6) {
            setEditChecks({
              ...editChecks,
              nameDisable: temp.nameDisable,
              ifscDisable: temp.ifscDisable,
              numberDisable: temp.numberDisable,
              imageDisable: temp.imageDisable,
              submitDisable: temp.submitDisable,
              editDisable: false,
              updateDisable: temp.updateDisable,
              submitHide: false,
              editHide: edit ? true : false,
              mismatchHide: edit ? true : false,
              updateHide: edit ? false : true,
            });
          } else {
            setEditChecks({
              ...editChecks,
              nameDisable: temp.nameDisable,
              ifscDisable: temp.ifscDisable,
              numberDisable: temp.numberDisable,
              imageDisable: temp.imageDisable,
              submitDisable: temp.submitDisable,
              editDisable: false,
              updateDisable: temp.updateDisable,
              submitHide: true,
              editHide: edit ? true : false,
              mismatchHide: false,
              updateHide: edit ? false : true,
            });
          }
          break;
        case 3:
          setEditChecks({
            ...editChecks,
            nameDisable: true,
            ifscDisable: true,
            numberDisable: true,
            imageDisable: true,
            submitDisable: true,
            editDisable: false,
            updateDisable: !data.distortImage || !data.adminInput,
            submitHide: true,
            editHide: true,
            mismatchHide: true,
            updateHide: true,
          });
          break;

        default:
          break;
      }
    }
  }, [data, edit]);

  const handleNextRecord = () => {
    setLoading(true);
    Axios.get(
      `${UrlLinks.baseUrl}${UrlLinks.bankVerification.DetailsVerificationNext}${currentUserId}&verificationStatus=${status}`
    )
      .then((response) => {
        if (response) {
          if (response.data.response.user_id !== userId) {
            makeData(response.data.response);
            setCurrentUserId(response.data.response.user_id);
            setEdit(false);
            setLoading(false);
          } else {
            showToast("error", "No more records to be verified");
            setShowNextButton(false);
            setLoading(false);
          }
        }
      })
      .catch((error) => {
        showToast("error", "Error loading next record");
        setLoading(false);
      });
  };

  const getRecord = () => {
    setLoading(true);
    if (currentUserId) {
      Axios.get(
        `${UrlLinks.baseUrl}${UrlLinks.bankVerification.DetailsVerificationInitial}${currentUserId}`
      ).then((response) => {
        if (response) {
          makeData(response.data.response);
          setLoading(false);
        }
      });
    }
  };

  const handleDetailsSubmit = () => {
    setDisableSubmit(true);
    const postData = {
      accountNumberByAdmin: data.adminInput,
      isAccountHolderMatched: data.accountHolderNameMismatch ? 0 : 1,
      isIFSCMatched: data.ifscCodeMismatch ? 0 : 1,
      distortImage: 0,
      requestedBy: localStorage.getItem("userID"),
      workerUserId: data.userId,
    };
    if (data.distortImage) {
      delete postData.accountNumberByAdmin;
      delete postData.isAccountHolderMatched;
      delete postData.isIFSCMatched;
      postData["distortImage"] = 1;
    }

    Axios.post(
      `${UrlLinks.baseUrl}${UrlLinks.bankVerification.submitDetails}`,
      qs.stringify(postData)
    )
      .then((response) => {
        if (response.data.code === 1000) {
          getRecord();
          setEdit(false);
          setDisableSubmit(false);
          if (
            response.data.response.tag === "mismatch" &&
            response.data.response.isAccNumberMatch === 0
          ) {
            setSubmissionError(true);
          }
        } else if (response.data.code === 1002) {
          showToast("error", "Worker already reviewed by other admin");
          setDisableSubmit(false);
          if (
            response.data.response.tag === "mismatch" &&
            response.data.response.isAccNumberMatch === 0
          ) {
            setSubmissionError(true);
          }
        }
      })
      .catch((error) => {
        showToast("error", "Api failed");
        setDisableSubmit(false);
      });
  };

  useEffect(() => {
    if (data.distortImage) {
      setData({
        ...data,
        accountHolderNameMismatch: false,
        ifscCodeMismatch: false,
        adminInput: "",
      });
    }
  }, [data.distortImage]);

  const handeDetailsMismatch = () => {
    setDisableSubmit(true);
    const postData = {
      isMissMatchConfirmed: 1,
      requestedBy: localStorage.getItem("userID"),
      workerUserId: data.userId,
    };

    Axios.post(
      `${UrlLinks.baseUrl}${UrlLinks.bankVerification.submitDetails}`,
      qs.stringify(postData)
    )
      .then((response) => {
        if (response.data.code === 1000) {
          getRecord();
          setDisableSubmit(false);
          if (response.data.response.tag === "mismatch") {
            setSubmissionError(true);
          }
        } else if (response.data.code === 1002) {
          showToast("error", "Worker already reviewed by other Super admin");
          setDisableSubmit(false);
        }
      })
      .catch((error) => {
        showToast("error", "Api failed");
        setDisableSubmit(false);
      });
  };

  useEffect(() => {
    if (userId) {
      getRecord();
    } else {
      props.history.push("/bankverificationlisting");
    }
  }, []);

  return loading ? (
    <Grid container style={{ margin: "130px 0 0 40px" }}>
      <Grid item xs={5}>
        <Grid item xs={12}>
          <Skeleton variant="rect" width="90%" height={200} />
        </Grid>
        <Grid item xs={12} style={{ marginTop: "30px" }}>
          <Skeleton variant="rect" width="90%" height={400} />
        </Grid>
      </Grid>
      <Grid item xs={7}>
        <Skeleton variant="rect" width="90%" height="100%" />
      </Grid>
    </Grid>
  ) : (
    <Grid container className={styles.container}>
      <Grid item xs={5}>
        <Grid
          container
          className={`${styles.sectionContainer} ${styles.displayRow}`}
        >
          <Grid
            item
            xs={6}
            className={`${styles.displayColumn} ${styles.rightBorder}`}
          >
            <span className={styles.headingText}>{data.name}</span>
            <span
              className={styles.infoValueText}
            >{`+91 ${data.phoneNumber}`}</span>
            <Grid item xs={11} className={styles.displayRow}>
              <span className={styles.infoTitleText}>OkayGo ID</span>
              <span className={styles.infoValueText}>{data.okaygoId}</span>
            </Grid>
            <Grid item xs={11} className={styles.displayRow}>
              <span className={styles.infoTitleText}>Casper ID</span>
              <span className={styles.infoValueText}>{data.casperId}</span>
            </Grid>
          </Grid>
          <Grid
            item
            xs={6}
            className={`${styles.displayColumn} ${styles.paddingleft16}`}
          >
            {data.verifiedBy && (
              <Grid item xs={12} className={styles.displayRow}>
                <span className={styles.infoTitleText}>Verified by</span>
                <span className={styles.infoValueText}>{data.verifiedBy}</span>
              </Grid>
            )}
            {data.verifiedOn && (
              <Grid item xs={12} className={styles.displayRow}>
                <span className={styles.infoTitleText}>Verified On</span>
                <span className={styles.infoValueText}>{data.verifiedOn}</span>
              </Grid>
            )}
            {data.confirmedBy && (
              <Grid item xs={12} className={styles.displayRow}>
                <span className={styles.infoTitleText}>Confirmed by</span>
                <span className={styles.infoValueText}>{data.confirmedBy}</span>
              </Grid>
            )}
            {data.confirmedOn && (
              <Grid item xs={12} className={styles.displayRow}>
                <span className={styles.infoTitleText}>Confirmed On</span>
                <span className={styles.infoValueText}>{data.confirmedOn}</span>
              </Grid>
            )}
          </Grid>
        </Grid>
        {localStorage.getItem("roleType") === "6" &&
        (data.verification === 3 || data.verification === 2) ? (
          <Grid container className={styles.sectionContainer}>
            <Grid
              item
              xs={12}
              className={styles.displayRow}
              style={{ marginBottom: "36px" }}
            >
              <span className={styles.titleText}>Bank details</span>
              <div className={styles.displayRow}>
                {data.tag === "mismatch_confirmed" && (
                  <div className={styles.mismatchConfirmed}>
                    <span>Mismatch confirmed</span>
                  </div>
                )}
                {!editChecks.editHide && (
                  <button
                    className={styles.EditButton}
                    onClick={() => setEdit(true)}
                  >
                    EDIT
                  </button>
                )}
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              className={`${styles.displayColumn} ${styles.detailsContainer}`}
            >
              <Grid
                item
                xs={12}
                className={styles.displayRow}
                style={{ marginBottom: "16px" }}
              >
                <FormControlLabel
                  className={styles.detailsMismatch}
                  value="end"
                  control={
                    <Checkbox
                      color="primary"
                      checked={data.distortImage}
                      onChange={handleCheckBox}
                      name="distortImage"
                      disabled={editChecks.imageDisable}
                    />
                  }
                  label="Image not clear / details missing"
                  labelPlacement="end"
                />
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              className={`${styles.displayColumn} ${styles.detailsContainer}`}
            >
              <Grid
                item
                xs={12}
                className={styles.displayRow}
                style={{ marginBottom: "16px" }}
              >
                <span className={styles.subHeadingText}>
                  Account holder name
                </span>
                <FormControlLabel
                  className={styles.detailsMismatch}
                  value="end"
                  control={
                    <Checkbox
                      color="primary"
                      checked={data.accountHolderNameMismatch}
                      onChange={handleCheckBox}
                      name="accountHolderNameMismatch"
                      disabled={editChecks.nameDisable}
                    />
                  }
                  label="Details mismatch"
                  labelPlacement="end"
                />
              </Grid>
              <Grid item xs={12}>
                <span>{data.accountHolderName}</span>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              className={`${styles.displayColumn} ${styles.detailsContainer}`}
            >
              <Grid
                item
                xs={12}
                className={styles.displayRow}
                style={{ marginBottom: "16px" }}
              >
                <span className={styles.subHeadingText}>IFSC code</span>
                <FormControlLabel
                  className={styles.detailsMismatch}
                  value="end"
                  control={
                    <Checkbox
                      color="primary"
                      checked={data.ifscCodeMismatch}
                      className={styles.detailsMismatch}
                      onChange={handleCheckBox}
                      name="ifscCodeMismatch"
                      disabled={editChecks.ifscDisable}
                    />
                  }
                  label="Details mismatch"
                  labelPlacement="end"
                />
              </Grid>
              <Grid item xs={5}>
                <span>{data.ifscCode}</span>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              className={`${styles.displayColumn} ${styles.detailsContainer}`}
            >
              <Grid
                item
                xs={12}
                className={styles.displayRow}
                style={{ marginBottom: "30px" }}
              >
                <span className={styles.subHeadingText}>Account number</span>
                {data.tag === "mismatch" && data.accountNumberMismatch && (
                  <div className={styles.accountMismatch}>
                    <span>Account number mismatch</span>
                  </div>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                className={styles.displayRow}
                style={{ marginBottom: "30px" }}
              >
                <span className={styles.subHeadingTextSemiBold}>
                  Worker input
                </span>
                <span>{data.workerInput}</span>
              </Grid>
              <Grid
                item
                xs={12}
                className={styles.displayRow}
                style={{ marginBottom: "30px" }}
              >
                <span className={styles.subHeadingTextSemiBold}>
                  Admin input
                </span>
                <div>
                  <input
                    id="adminInput"
                    type="text"
                    className={`hrbpEditInput ${styles.inputNoError}`}
                    value={data.adminInput}
                    onChange={handleAdminAccountInput}
                    disabled={editChecks.numberDisable}
                  />
                </div>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              className={`${styles.displayRow} ${styles.buttonContainer}`}
            >
              {edit ? (
                <button
                  className={styles.mismatchButton}
                  onClick={handleDetailsSubmit}
                  disabled={editChecks.updateDisable || disableSubmit}
                >
                  UPDATE
                </button>
              ) : data.tag !== "mismatch_confirmed" ? (
                <button
                  className={styles.mismatchButton}
                  onClick={handeDetailsMismatch}
                  disabled={disableSubmit}
                >
                  CONFIRM MISMATCH
                </button>
              ) : (
                <div></div>
              )}
            </Grid>
          </Grid>
        ) : (
          //okaygo admin and not mismatched section
          <Grid container className={styles.sectionContainer}>
            <Grid
              item
              xs={12}
              className={styles.displayRow}
              style={{ marginBottom: "36px" }}
            >
              <span className={styles.titleText}>Bank details</span>
              {!editChecks.editHide &&
                localStorage.getItem("roleType") === "6" && (
                  <Tooltip
                    disableHoverListener={
                      data.tag === "verified"
                        ? localStorage.getItem("roleType") !== "6"
                          ? false
                          : true
                        : true
                    }
                    title="Please contact your admin to edit"
                  >
                    <button
                      disabled={
                        data.tag === "verified"
                          ? localStorage.getItem("roleType") !== "6"
                            ? true
                            : false
                          : false
                      }
                      className={
                        data.tag === "verified"
                          ? localStorage.getItem("roleType") !== "6"
                            ? styles.GreyButton
                            : styles.EditButton
                          : styles.EditButton
                      }
                      onClick={() => setEdit(true)}
                    >
                      EDIT
                    </button>
                  </Tooltip>
                )}
            </Grid>
            <Grid
              item
              xs={12}
              className={`${styles.displayColumn} ${styles.detailsContainer}`}
            >
              <Grid
                item
                xs={12}
                className={styles.displayRow}
                style={{ marginBottom: "16px" }}
              >
                <FormControlLabel
                  className={styles.detailsMismatch}
                  value="end"
                  control={
                    <Checkbox
                      color="primary"
                      checked={data.distortImage}
                      onChange={handleCheckBox}
                      name="distortImage"
                      disabled={editChecks.imageDisable}
                    />
                  }
                  label="Image not clear / details missing"
                  labelPlacement="end"
                />
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              className={`${styles.displayColumn} ${styles.detailsContainer}`}
            >
              <Grid
                item
                xs={12}
                className={styles.displayRow}
                style={{ marginBottom: "16px" }}
              >
                <span className={styles.subHeadingText}>
                  Account holder name
                </span>
                <FormControlLabel
                  className={styles.detailsMismatch}
                  value="end"
                  control={
                    <Checkbox
                      color="primary"
                      checked={data.accountHolderNameMismatch}
                      onChange={handleCheckBox}
                      name="accountHolderNameMismatch"
                      disabled={editChecks.nameDisable}
                    />
                  }
                  label="Details mismatch"
                  labelPlacement="end"
                />
              </Grid>
              <Grid item xs={12}>
                <span>{data.accountHolderName}</span>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              className={`${styles.displayColumn} ${styles.detailsContainer}`}
            >
              <Grid
                item
                xs={12}
                className={styles.displayRow}
                style={{ marginBottom: "16px" }}
              >
                <span className={styles.subHeadingText}>IFSC code</span>
                <FormControlLabel
                  className={styles.detailsMismatch}
                  value="end"
                  control={
                    <Checkbox
                      color="primary"
                      checked={data.ifscCodeMismatch}
                      className={styles.detailsMismatch}
                      onChange={handleCheckBox}
                      name="ifscCodeMismatch"
                      disabled={editChecks.ifscDisable}
                    />
                  }
                  label="Details mismatch"
                  labelPlacement="end"
                />
              </Grid>
              <Grid item xs={5}>
                <span>{data.ifscCode}</span>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              className={`${styles.displayColumn} ${styles.detailsContainer}`}
            >
              <Grid
                item
                xs={12}
                className={styles.displayRow}
                style={{ marginBottom: "30px" }}
              >
                <span className={styles.subHeadingText}>Account number</span>
                {data.tag === "mismatch" &&
                  data.accountNumberMismatch &&
                  !submissionError && (
                    <div className={styles.accountMismatch}>
                      <span>Account number mismatch</span>
                    </div>
                  )}
              </Grid>
              <Grid item xs={12} className={styles.displayRow}>
                <Grid item xs>
                  <span>Type the account number</span>
                </Grid>
                <Grid item xs className={styles.displayColumn}>
                  <input
                    id="adminInput"
                    type="text"
                    className={`hrbpEditInput ${
                      data.accountNumberMismatch
                        ? styles.inputError
                        : styles.inputNoError
                    }`}
                    value={data.adminInput}
                    onChange={handleAdminAccountInput}
                    disabled={editChecks.numberDisable}
                  />
                  {submissionError && (
                    <span
                      className={styles.accountMismatchText}
                      style={{ color: "#d9534f" }}
                    >
                      This entry does not match the worker input. Please recheck
                      it once again.
                    </span>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              className={`${styles.displayRow} ${styles.buttonContainer}`}
            >
              {!editChecks.updateHide && (
                <button
                  className={styles.mismatchButton}
                  onClick={handleDetailsSubmit}
                  disabled={editChecks.updateDisable || disableSubmit}
                >
                  UPDATE
                </button>
              )}
              {!editChecks.submitHide && (
                <button
                  className={styles.mismatchButton}
                  onClick={handleDetailsSubmit}
                  disabled={editChecks.submitDisable || disableSubmit}
                >
                  SUBMIT
                </button>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid item xs={7} className={styles.imageContainer}>
        <Grid container className={styles.imageInnerContainer}>
          <Grid item xs={12} className={styles.displayRow}>
            <span className={styles.titleText}>Account Proof</span>
            {showNextButton && (
              <button
                className={styles.nextRecordButton}
                onClick={handleNextRecord}
                disabled={data.verification === 0}
              >
                {`Next Record >`}
              </button>
            )}
          </Grid>
          <Grid item xs={12} className={styles.imageMagnifier}>
            <ReactPanZoom
              image={data.accountProof}
              alt="account_proof"
              crossOrigin="anonymous"
              className={styles.imagePan}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BankDetailsList;
