import React, { useState } from "react";
import { Grid, Button, Modal, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import urlLinks from "../../UrlLinks";
import Axios from "axios";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const flexDisplay = {
    margin: "auto",
  },
  confirm = {
    width: "116px",
    height: "40px",
    borderRadius: "4px",
    backgroundColor: "#1c75bc",
    color: "#f4f4f4",
    fontSize: "14px",
    float: "right",
    marginRight: "25px",
  },
  cancel = {
    width: "111px",
    height: "40px",
    borderRadius: "4px",
    border: "solid 1px #1c75bc",
    backgroundColor: "#ffffff",
    color: "#1c75bc",
    float: "right",
    marginRight: "24px",
  };

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: "681px",
    height: "168px",
  },
}));

const AdminNotesModel = (props) => {
  const classes = useStyles();
  const {
    openAdminNotes,
    setOpenAdminNotes,
    row,
    showToast,
    noteData,
    updateMyData,
    recruiterMasterId,
  } = props;
  const [reminderData, setReminderData] = useState({
    data: noteData ? noteData : "",
  });

  let caption = "Remarks";
  const handleSaveReminder = () => {
    if (reminderData.data) {
      const postData = {
        field: "remarks",
        value: reminderData.data,
        referralId: row.referralId,
      };
      Axios.post(
        `${urlLinks.baseUrl}${urlLinks.referral.updateReferralFields}?field=${
          postData.field
        }&value=${postData.value}&updatedBy=${localStorage.getItem(
          "userID"
        )}&referralId=${postData.referralId}`
      ).then((response) => {
        if (response.data.code === 1000) {
          updateMyData();
          setOpenAdminNotes(false);
        } else {
          showToast("error", "some err occured");
        }
      });
    }
  };

  return (
    <Modal
      className={classes.modal}
      open={openAdminNotes}
      onClose={() => setOpenAdminNotes(false)}
      closeAfterTransition
    >
      <div className={classes.paper} style={{ width: "33%", height: "50%" }}>
        <Grid container>
          <Grid
            className="padding0"
            item
            xs={12}
            style={flexDisplay}
            direction="row"
          >
            <h1>
              {caption}
              <IconButton
                style={{ marginLeft: "230px" }}
                aria-label="close"
                onClick={() => setOpenAdminNotes(false)}
              >
                <CloseIcon />
              </IconButton>
            </h1>
          </Grid>
          <Grid
            item
            xs={12}
            className="padding0"
            style={{ marginBottom: "15px" }}
          >
            <TextField
              rows={6}
              multiline
              fullWidth
              variant="outlined"
              style={{
                width: "94%",
                height: "149px",
              }}
              value={reminderData.data}
              onChange={(event) =>
                setReminderData({ ...reminderData, data: event.target.value })
              }
              placeholder=""
              required
            />
          </Grid>
          <Grid item xs={12} className="padding0" direction="row">
            <Button style={confirm} onClick={handleSaveReminder}>
              SAVE
            </Button>
            <Button style={cancel} onClick={() => setOpenAdminNotes(false)}>
              CANCEL
            </Button>
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
};

export default AdminNotesModel;
