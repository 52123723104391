import React, { useState, useEffect } from "react";
import { Select, MenuItem, IconButton } from "@material-ui/core";
import DoneSharpIcon from "@material-ui/icons/DoneSharp";
import CloseSharpIcon from "@material-ui/icons/CloseSharp";
import urlLinks from "../../UrlLinks";
import Axios from "axios";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  select: {
    right: "0",
  },
}));

const EditableDropdownSubComponent = (props) => {
  const {
    row,
    columnId,
    initialValue,
    updateMyData,
    showToast,
    setLoading,
  } = props;
  const classes = useStyles();
  const [value, setValue] = useState(initialValue);
  const [showButtons, setShowButtons] = useState(false);

  const onChange = (e) => {
    setShowButtons(true);
    setValue(e.target.value);
  };

  const updateStatus = () => {
    setLoading(true);
    if (columnId === "Selection Status") {
      const postData = {
        status: value,
        recruiterMasterId: row.recruiterMasterId,
        userId: row.userId,
        jobId: row.jobId,
      };

      Axios.post(
        `${urlLinks.baseUrl}${
          urlLinks.jobs.updateJobSelection
        }?updatedBy=${localStorage.getItem("userId")}&user_id=${
          postData.userId
        }&status=${postData.status}&job_id=${postData.jobId}`
      ).then((response) => {
        if (response.data.code === 1000) {
          updateMyData();
          setLoading(false);
        } else {
          showToast(response.data.message);
          setLoading(false);
        }
      });
    }
  };

  const handleConfirm = () => {
    setLoading(true);
    updateStatus();
  };

  const handleCancel = () => {
    setValue(initialValue);
    setShowButtons(false);
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  let dropdown = <div></div>;
  dropdown = (
    <div className="index">
      {columnId === "Selection Status" && (
        <Select
          id="Selection Status"
          value={value}
          disableUnderline
          onChange={onChange}
          inputProps={{
            classes: {
              icon: "selectIcon",
            },
          }}
          className={classes.select}
        >
          <MenuItem value="-" disabled>
            <em>Selection status</em>
          </MenuItem>
          <MenuItem value={1}>Selected</MenuItem>
          <MenuItem value={2}>Rejected</MenuItem>
        </Select>
      )}
      {showButtons && (
        <div>
          <IconButton color="primary" onClick={handleConfirm}>
            <DoneSharpIcon />
          </IconButton>
          <IconButton color="secondary" onClick={handleCancel}>
            <CloseSharpIcon />
          </IconButton>
        </div>
      )}
    </div>
  );

  return <React.Fragment>{dropdown}</React.Fragment>;
};

export default EditableDropdownSubComponent;
