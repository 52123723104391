/* eslint-disable no-use-before-define */
import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
});

export default function AutoSelect(props) {
  const {
    list,
    data,
    setData,
    id,
    multipleFlag,
    placeHolder,
    label,
    disabled,
    width,
    searchText,
  } = props;
  const classes = useStyles();

  return (
    <Autocomplete
      multiple={multipleFlag === false ? false : true}
      id={id}
      value={data[id]}
      onChange={(event, value) => setData({ ...data, [id]: value })}
      options={list}
      style={{ width }}
      classes={{
        option: classes.option,
      }}
      disabled={disabled}
      getOptionLabel={(option) => option.name}
      renderOption={(option) => <React.Fragment>{option.name}</React.Fragment>}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          size="small"
          inputProps={{
            ...params.inputProps,
          }}
          placeHolder={placeHolder}
          label={label}
          disabled={disabled}
          onChange={
            (event) => {
              const inputValue = event.target.value;
              if (searchText) {
                setData({ ...data, [searchText]: inputValue });
              }
            } // Update the data state when input changes
          }
        />
      )}
    />
  );
}
