import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Axios from "axios";
import document from "../document.svg";
import ResponseModal from "./ResponseModal";
import Typography from "@material-ui/core/Typography";
import {
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from "@material-ui/core";
import Styles from "../index.module.scss";
import UrlLinks from "../../../UrlLinks";
import Download from "../Group 3238.svg";
import { ScaleLoader } from "react-spinners";
import { CSVLink } from "react-csv";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    borderBottom: "unset",
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function CustomizedDialogs(props) {
  const {
    openCasperOnboardingModal,
    setOpenCasperOnboardingModal,
    showToast,
    updateMyData,
    partners,
  } = props;
  const [excel, setExcel] = useState(null);
  const [openResponseModal, setOpenResponseModal] = useState(false);
  const [name, setName] = useState("");
  const [casperLoading, setCasperLoading] = useState(false);
  const [excelError, setExcelError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [invalidData, setInvalidData] = useState();
  const [fileLink, setFileLink] = useState(null);
  const [responseData, setResponseData] = useState();
  const [actions, setActions] = useState(0);
  const [partnerId, setPartnerId] = useState("");
  const [diableBrowse, setDiableBrowse] = useState(true);
  const handleClose = () => {
    setOpenCasperOnboardingModal(false);
  };

  const handleUpload = (event) => {
    const fileName = event.target.files[0].name.split(".").slice(-1)[0];
    if (fileName.toLowerCase() === "xlsx" || fileName.toLowerCase() === "xls") {
      setExcel(event.target.files[0]);
      setName(event.target.files[0].name);
      setExcelError(false);
    } else {
      setExcelError(true);
      setExcel(null);
      setName("");
    }
    setResponseData();
  };
  const createInvalidData = (records) => {
    if (records.length > 0) {
      let list = [];
      records.map((record) => {
        list.push({
          "Casper Id": record.swiggyId || "",
          "OkayGo id": record.okaygoId || "",
          "First Name": record.firstName || "",
          "Last Name": record.lastName || "",
          "Phone No": record.phoneNumber || "",
          "Reason for failure": record.reasonOfFailure || "",
        });
        return true;
      });
      return list.length > 0 ? list : null;
    }
  };

  const createInvalidDataClients = (records) => {
    if (records.length > 0) {
      let list = [];
      records.map((record) => {
        list.push({
          [`${actions} Id`]: record.jiomartId || "",
          OkayGoId: record.okaygoId || "",
          "First Name": record.firstName || "",
          "Last Name": record.lastName || "",
          "Phone No": record.phoneNumber || "",
          "Reason for failure": record.reasonOfFailure || "",
        });
        return true;
      });
      return list.length > 0 ? list : null;
    }
  };

  const handleExcel = () => {
    setIsLoading(true);
    let formData = new FormData();
    formData.append("file", excel);
    Axios.post(
      `${UrlLinks.baseUrl}${
        UrlLinks.workerUrls.uploadCasperOnboardingData2
      }${actions}&insertedBy=${localStorage.getItem(
        "userID"
      )}&partnerId=${partnerId}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
      .then((response) => {
        if (actions === "Flipkart") {
          if (response.data.response) {
            if (response.data.response.invalidExcel) {
              setInvalidData(
                createInvalidData(response.data.response.invalidExcel)
              );
            }
            let invalid = response.data.response.rejected;
            let uploaded = response.data.response.uploaded;
            let total = invalid + uploaded;
            const summary = {
              "Total records attempted": total,
              "Invalid data records": invalid,
              "Workers onboarded": uploaded,
            };
            setResponseData(summary);
            updateMyData();
            setIsLoading(false);
          } else {
            setResponseData([]);
          }
        } else {
          if (response.data.response.invalidExcel) {
            setInvalidData(
              createInvalidDataClients(response.data.response.invalidExcel)
            );
            let invalid = response.data.response.rejected;
            let uploaded = response.data.response.uploaded;
            let total = invalid + uploaded;
            const summary = {
              "Total records attempted": total,
              "Invalid data records": invalid,
              "Workers onboarded": uploaded,
            };
            setResponseData(summary);
            updateMyData();
            setIsLoading(false);
          } else {
            setResponseData([]);
          }
        }
      })
      .catch((error) => {
        setIsLoading(false);
        showToast("error", error.message);
      });
  };

  const createWorkerWithoutData = (response) => {
    const list = [];
    response.map((record) => {
      list.push({
        [`${actions} Id`]: record.clientId,
        "OkayGo Id": record.okaygoId,
        "First Name": record.firstName,
        "Last Name": record.lastName,
        "Phone No": record.phoneNumber,
      });
    });
    return list;
  };

  const getWorkerWithoutCasperID = () => {
    setCasperLoading(true);
    let requestedBy = `&requestedById=${localStorage.getItem("userID")}`;

    Axios.get(
      `${UrlLinks.baseUrl}${UrlLinks.workerUrls.workerWithoutClientCreatedIds}${actions}${requestedBy}&partnerId=${partnerId}`,
      null,
      { timeout: 60 * 5 * 1000 }
    )
      .then((response) => {
        setFileLink(createWorkerWithoutData(response.data.response));
      })
      .catch((error) => {
        setFileLink("");
        showToast("error", error.message);
      });
  };

  useEffect(() => {
    if (fileLink === "") {
      setCasperLoading(false);
    } else if (fileLink !== null) {
      setCasperLoading(false);
    }
  }, [fileLink]);

  const loader = (
    <div style={{ margin: "130px auto", width: "fit-content" }}>
      <ScaleLoader height={70} width={10} color={"#1c75bc"} />
    </div>
  );

  useEffect(() => {
    if (responseData) {
      setOpenResponseModal(true);
    }
  }, [responseData]);

  const handleActions = (event) => {
    setActions(event.target.value);
    let arrayObj = partners.filter((p) => {
      if (p.company_name === event.target.value) {
        return p.partner_id;
      }
    });
    setPartnerId(arrayObj[0].partner_id);
    setDiableBrowse(false);
    setExcel(null);
  };

  useEffect(() => {
    if (actions) getWorkerWithoutCasperID();
  }, [actions]);

  let dialogData = (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={openCasperOnboardingModal}
      classes={{ paper: Styles.paper }}
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        <span>Upload Client IDs</span>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container item xs={12} className="padding0">
          <Grid item xs={4} className="padding0">
            <span style={{ fontWeight: "600", marginLeft: "20px" }}>
              Choose Client
            </span>
          </Grid>
          <Grid item xs={8} className="padding0" alignItems="flex-start">
            <span>
              <FormControl variant="outlined">
                <Select
                  id="actions"
                  value={actions}
                  classes={{ outlined: styles.actionSelect }}
                  onChange={handleActions}
                >
                  <MenuItem value={0} disabled={true}>
                    {" "}
                    Clients
                  </MenuItem>
                  {partners.map((e) => {
                    return (
                      <MenuItem key={e.company_name} value={e.company_name}>
                        {e.company_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </span>
          </Grid>
        </Grid>
        {casperLoading ? (
          loader
        ) : (
          <Grid container>
            <Grid item xs={12} className="padding0">
              {/* {fileLink?.length>0 && <p>Download worker list (.csv) here, to fill and upload Client IDs</p>} */}
              {fileLink?.length > 0 ? (
                <CSVLink
                  data={fileLink}
                  filename={`Worker List without Id.csv`}
                  className={Styles.casperDownloadTemplate}
                  target="_blank"
                  style={{
                    color: "inherit",
                    textDecoration: "none",
                    fontWeight: "500",
                    marginLeft: "190px",
                  }}
                >
                  <span>Download worker list</span>
                  <img src={Download} alt="download" />
                </CSVLink>
              ) : (
                <span></span>
              )}
              <Grid
                item
                xs={12}
                className="padding10"
                style={{ marginTop: "10px" }}
              >
                <span
                  style={{
                    marginTop: "30px",
                    fontWeight: "600",
                    marginRight: "48px",
                  }}
                >
                  Upload Client IDs
                </span>
                <input
                  style={{ display: "none", marginLeft: "20px" }}
                  id="contained-button-file"
                  type="file"
                  disabled={diableBrowse}
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  onChange={handleUpload}
                />
                <label htmlFor="contained-button-file">
                  <Button
                    variant="outlined"
                    className={Styles.uploadButton}
                    component="span"
                    disabled={diableBrowse}
                    startIcon={<img src={document} alt={"upload"} />}
                  >
                    Browse File
                  </Button>
                </label>
              </Grid>
              {name && (
                <span
                  style={{ marginLeft: "180px", marginBottom: "5px" }}
                  className={Styles.fileName}
                >
                  <span>File name: </span>
                  {`${name}`}
                </span>
              )}
            </Grid>
            <Grid item xs={12} className="padding0">
              <span
                style={{ marginLeft: "180px" }}
                className={`fontSize13 ${excelError ? "redColor" : ""}`}
              >
                only .xls and .xlsx are allowed
              </span>
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        {isLoading ? (
          <span>Please wait... it may take few minutes</span>
        ) : (
          <Button
            variant="contained"
            onClick={handleExcel}
            className={`${!excel ? "disabledButton" : "blueButtonContained"}`}
            disabled={!excel}
          >
            Upload
          </Button>
        )}
      </DialogActions>
      {openResponseModal && (
        <ResponseModal
          openResponseModal={openResponseModal}
          setOpenResponseModal={setOpenResponseModal}
          responseData={responseData}
          updateMyData={updateMyData}
          setOpenOnboardingModal={setOpenCasperOnboardingModal}
          showToast={showToast}
          invalidData={invalidData}
          type="OnboardingCasper"
        />
      )}
    </Dialog>
  );

  return dialogData;
}
