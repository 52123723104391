import React, { useState } from "react";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { Grid } from "@material-ui/core";

export default function DateFilter(props) {
  const { id, filters, setFilters, labelTo, labelFrom } = props;

  const [value, setValue] = useState(filters[id]);

  const handleDateChange = (date, pos) => {
    let dateData = value;
    if (pos === 1) dateData[0] = date;
    if (pos === 2) dateData[1] = date;

    setValue([...dateData]);
    setFilters({ ...filters, [id]: dateData });
  };

  return (
    <Grid container alignItems="center">
      <Grid item xs={12}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            id={`{id}To`}
            placeholder="From"
            label={labelFrom}
            format="dd-MM-yyyy"
            clearable
            className="fromDate"
            value={filters[id] ? filters[id][0] : ""}
            onChange={(date) => handleDateChange(date, 1)}
          />
          <br />
          <KeyboardDatePicker
            id={id}
            placeholder="To"
            format="dd-MM-yyyy"
            style={{ margin: "0 0 10px 0" }}
            clearable
            label={labelTo}
            className="toDate"
            value={filters[id] ? filters[id][1] : ""}
            minDate={filters[id] ? filters[id][0] : ""}
            onChange={(date) => handleDateChange(date, 2)}
          />
        </MuiPickersUtilsProvider>
      </Grid>
    </Grid>
  );
}
