import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import Axios from "axios";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { Button } from "@material-ui/core";
import style from "./index.module.scss";
import AutoSelectFilter from "./FilterComponents/AutoSelectFilter";
import ScaleLoader from "react-spinners/ScaleLoader";
import urlLinks from "../../UrlLinks";
import "./indeterminateCheckbox.css";
import SearchSelectFilter from "../../Utils/FilterComponents/SearchSelectFilter";

const timingList = () => {
  let list = [];
  for (var i = 0; i < 24; i++) {
    if (i <= 9) {
      list.push({ id: `0${i}:00:00`, name: `0${i}:00:00` });
    } else {
      list.push({ id: `${i}:00:00`, name: `${i}:00:00` });
    }
  }
  return list;
};

const FilterDrawer = (props) => {
  const {
    handleClose,
    resetFilters,
    applyFilters,
    filters,
    setFilters,
    leadCityId,
    setLeadCityId,
    curLeadId,
    setCurLeadId,
  } = props;
  const [verticalList, setVerticalList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [timeList, setTimeList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const handleCancelFilters = () => {
    setLeadCityId(curLeadId.leadCity);
    handleClose();
  };

  const handleApplyFilters = () => {
    setCurLeadId({
      leadCity: leadCityId,
    });
    applyFilters();
    handleClose();
  };

  const jobStatus = [
    { id: 1, name: "Active" },
    { id: 2, name: "Inactive" },
  ];

  const loader = (
    <div style={{ margin: "130px auto" }}>
      <ScaleLoader height={70} width={10} color={"#1c75bc"} />
    </div>
  );

  const makeVerticalData = (data) => {
    let list = [];
    data.map((el) => {
      list.push({
        id: el.typeKey,
        name: el.verticalName,
      });
      return true;
    });
    return list;
  };

  const makeProjectData = (data) => {
    let list = [];
    data.map((el) => {
      list.push({
        id: el.projects_id,
        name: el.project_name,
      });
      return true;
    });
    return list;
  };

  useEffect(() => {
    if (projectList.length > 0) {
      setIsLoading(false);
    }
  }, [projectList]);

  useEffect(() => {
    Axios.get(`${urlLinks.baseUrl}${urlLinks.jobs.jobProject}`).then(
      (response) => {
        setProjectList(makeProjectData(response.data.response));
      }
    );

    Axios.get(`${urlLinks.baseUrl}${urlLinks.jobs.jobsVerticals}`).then(
      (response) => {
        setVerticalList(makeVerticalData(response.data.response));
      }
    );

    let tList = timingList();
    setTimeList(tList);
  }, []);

  const output = isLoading ? (
    <Grid container style={{ width: "520px", marginTop: "20px" }}>
      {loader}
    </Grid>
  ) : (
    <Grid
      container
      style={{ width: "520px", marginTop: "20px" }}
      className={style.FilterContainer}
    >
      <Grid container className={`${style.FilterHeadingContainer}`}>
        <div className={style.flexBaseLine}>
          <Button onClick={handleClose} className={style.filterCloseArrow}>
            <ArrowForwardIcon />
          </Button>
          <span className={style.filterHeading}>Filters</span>
        </div>
        <div>
          <Button onClick={resetFilters} className={style.resetFiltersButton}>
            Reset
          </Button>
        </div>
      </Grid>
      <div className={style.filterHeadingDivider}></div>
      <Grid container className={style.filtersContainer}>
        <Grid item xs={12} className={`${style.singleFilterContainer}`}>
          <span className={style.filterTitle}>Project</span>
          <AutoSelectFilter
            filters={filters}
            setFilters={setFilters}
            multipleFlag={false}
            id="projectId"
            options={projectList}
          />
        </Grid>
        <Grid item xs={12} className={`${style.singleFilterContainer}`}>
          <span className={style.filterTitle}>City</span>
          <SearchSelectFilter
            filters={filters}
            setFilters={setFilters}
            id="facilityCity"
            sourceId={leadCityId}
            setSourceId={setLeadCityId}
            module="jobListing"
            multiple={false}
            placeholder="Search and select"
            url={`${urlLinks.baseUrl}${urlLinks.jobs.jobCities}`}
          />
        </Grid>
        <Grid item xs={12} className={`${style.singleFilterContainer}`}>
          <span className={style.filterTitle}>Vertical</span>
          <AutoSelectFilter
            filters={filters}
            setFilters={setFilters}
            multipleFlag={false}
            id="verticalId"
            options={verticalList}
          />
        </Grid>
        <Grid item xs={12} className={`${style.singleFilterContainer}`}>
          <span className={style.filterTitle}>Status</span>
          <AutoSelectFilter
            filters={filters}
            setFilters={setFilters}
            multipleFlag={false}
            id="jobStatus"
            options={jobStatus}
          />
        </Grid>
      </Grid>
      <Grid container className={style.filterButtonDividerContainer}>
        <div className={style.filterHeadingDivider}></div>
        <Grid container style={{ marginTop: "30px" }} className="padding0">
          <div
            style={{
              paddingRight: "16px",
              paddingLeft: "300px",
            }}
          >
            <Button
              variant="outlined"
              onClick={handleCancelFilters}
              size="medium"
              color="primary"
              style={{ color: "#2475bc" }}
            >
              Cancel
            </Button>
          </div>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            onClick={handleApplyFilters}
            className={style.PrimaryColorButton}
          >
            Apply
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
  
  return output;
};

export default FilterDrawer;
