import React from "react";
import { IconButton, Select, MenuItem } from "@material-ui/core";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";

const Pagination = (props) => {
  const {
    rowsperpage,
    pageNo,
    totalElements,
    totalPages,
    handlePageChange,
    handlePerRowsChange,
  } = props;
  const pages = [];
  for (let i = 0; i < totalPages; i++)
    pages.push(
      <MenuItem key={`pages-${i + 1}`} value={i + 1}>
        {i + 1}
      </MenuItem>
    );

  return (
    <div className="pagination">
      <IconButton onClick={() => handlePageChange(1)} disabled={pageNo === 1}>
        <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={() => handlePageChange(pageNo - 1)}
        disabled={pageNo === 1}
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={() => handlePageChange(pageNo + 1)}
        disabled={pageNo === totalPages}
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={() => handlePageChange(totalPages)}
        disabled={pageNo === totalPages}
      >
        <LastPageIcon />
      </IconButton>
      <span>
        Page{" "}
        <strong>
          {pageNo} of {totalPages}
        </strong>{" "}
      </span>
      <span>
        | Total record:
        <strong>{totalElements}</strong>{" "}
      </span>
      <span>
        | Go to page:{" "}
        <Select
          id="pageNo"
          value={pageNo}
          key={"pagination-pageNo"}
          variant="outlined"
          onChange={(e) => handlePageChange(e.target.value)}
          className="selectFields"
        >
          {pages}
        </Select>
      </span>{" "}
      <span>Rows per page:</span>{" "}
      <Select
        id="rows"
        value={rowsperpage}
        variant="outlined"
        key={"pagination-rows"}
        onChange={(e) => handlePerRowsChange(e.target.value)}
        className="selectFields"
      >
        {[5, 10, 50, 100, 250, 500].map((size) => (
          <MenuItem key={`pages-${size}`} value={size}>
            {size}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};
export default Pagination;
