import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Axios from "axios";
import Button from "@mui/material/Button";
import urlLinks from "../../UrlLinks";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import "./ConfirmTrigger.css";
import { numberFormat } from "../../Utils/CommonFunctions";
import { Stack } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  padding: "20px !important",
  pt: 2,
  px: 4,
  pb: 3,
};

export default function NestedModal(props) {
  const {
    open,
    setOpen,
    updateMyData,
    userIds,
    showToast,
    totalAmountToBePaid,
    retrigger,
  } = props;
  const [dataBalnce, setDataBalance] = useState();
  const [valid, setValid] = useState(false);
  const [mode, setMode] = useState("IMPS");

  const handleClose = () => {
    setOpen([false, false]);
  };

  useEffect(() => {
    Axios.get(`${urlLinks.baseUrl}${"advance/getAccountBalance"}`)
      .then((response) => {
        setDataBalance(numberFormat(response.data.response));
        if (response.data.response > totalAmountToBePaid) {
          setValid(true);
        } else {
          setValid(false);
        }
      })
      .catch((error) => {});
  }, []);

  const handlePayments = () => {
    Axios.post(
      `${urlLinks.baseUrl}${
        urlLinks.paymentUrls.triggerPayment
      }?requested_by=${localStorage.getItem("userID")}&invoice_ids=${userIds}`
    )
      .then((response) => {
        if (response.data.code === 1000) {
          showToast("success", "Payments triggered successfully");
          updateMyData();
          handleClose();
        } else {
          showToast("error", "Payments triggering failed");
          handleClose();
        }
      })
      .catch((error) => {
        showToast("error", error.message);
      });
  };

  const handlePerUserIdPayment = (userId) => {
    return new Promise((resolve, reject) => {
      Axios.post(
        `${urlLinks.baseUrl}${urlLinks.paymentUrls.updateRetriggerPaymentStage}?invoiceId=${userId}&mode=${mode}`
      )
        .then((response) => {
          if (response.data.code === 1000) {
            resolve("success");
          } else {
            reject("error");
            showToast("error", "Payments re-triggered failed");
            handleClose();
          }
        })
        .catch((error) => {
          reject("error");
          showToast("error", error.message);
        });
    });
  };

  const handleRetriggerPayment = () => {
    let userIdArr = userIds.split(",");

    Promise.all(
      userIdArr.map(async (userId) => {
        await handlePerUserIdPayment(userId);
      })
    ).then(() => {
      showToast("success", "Payments re-triggered successfully");
      updateMyData();
      handleClose();
    });
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box
          sx={{
            ...style,
            width: 400,
          }}
        >
          <div style={{ display: "inline-flex" }}>
            <div
              style={{
                fontSize: "25px",
                color: "#1C75BC",
                fontWeight: "400",
              }}
            >
              Balance
            </div>
            <div className="crossIcon">
              <IconButton component="label" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
          <div className="availableBal">
            Available Balance: <span className="bal">{dataBalnce}</span>
          </div>
          <div className="normal">
            Amount to be Pay :{" "}
            <span className={valid ? "green" : "red"}>
              {numberFormat(totalAmountToBePaid)}
            </span>
          </div>
          <br></br>
          {retrigger && (
            <div style={{ marginLeft: "7px" }}>
              <FormControl>
                <FormLabel id="demo-radio-buttons-group-label">Mode:</FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="IMPS"
                  name="radio-buttons-group"
                  onChange={(e) => {
                    setMode(e.target.value);
                  }}
                >
                  <FormControlLabel
                    value="IMPS"
                    control={<Radio color="primary" />}
                    label="IMPS"
                  />
                  <FormControlLabel
                    value="NEFT"
                    control={<Radio color="primary" />}
                    label="NEFT"
                  />
                </RadioGroup>
              </FormControl>
            </div>
          )}
          <div className="normal2">Are you sure to trigger the Payment? </div>
          <Stack spacing={2} direction="row" style={{ marginTop: "20px" }}>
            <Button fullWidth variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              fullWidth
              variant="contained"
              onClick={() => {
                if (!retrigger) {
                  handlePayments();
                } else {
                  handleRetriggerPayment();
                }
              }}
              disabled={!valid}
            >
              Confirm
            </Button>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
}
