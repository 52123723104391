import React from "react";
import styles from "./Functions.module.scss";
import SearchIcon from "@material-ui/icons/Search";
import CloseSharpIcon from "@material-ui/icons/CloseSharp";
import WarningIcon from "@mui/icons-material/Warning";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  FormControl,
  IconButton,
  InputAdornment,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-root": {
      marginLeft: "0",
      padding: "0",
      "&.Mui-focused fieldset": {
        border: "1px solid grey",
      },
    },
  },
  inputLead: {
    '& [class*="MuiOutlinedInput-input"]': {
      padding: "8px 75px 8px 8px",
      borderRadius: "0px",
    },
    "& .MuiInputAdornment-positionStart": {
      margin: "0",
    },
  },
}));

const LeadSearchBar = (props) => {
  const {
    uniqueId,
    searchBy,
    setSearchBy,
    searchError,
    searchActions,
    handleSearch,
    handleActions,
    searchBarList,
    handleSearchClose,
    placeholder,
    searchInstructions,
    warningMessage,
  } = props;
  const classes = useStyles();

  return (
    <div className={`${styles.searchBar}`}>
      <div className={`${styles.leadSearchBar}`}>
        <TextField
          className={`${classes.root}`}
          placeholder={placeholder}
          fullWidth
          value={searchBy}
          onChange={(event) => setSearchBy(event.target.value)}
          variant="outlined"
          onKeyPress={(e) => {
            if (e.key === "Enter" && !searchError) {
              handleSearch();
            }
          }}
          disabled={searchActions === "Search by" ? true : false}
          InputProps={{
            className: classes.inputLead,
            startAdornment: (
              <InputAdornment position="start">
                {uniqueId === "ApplicationDb" || uniqueId === "LeadData" ? (
                  <FormControl variant="outlined">
                    <Select
                      id="actions"
                      value={searchActions}
                      onChange={handleActions}
                      style={{ height: "35px", borderRadius: "4px 0 0 4px" }}
                      classes={{ outlined: styles.actionSelect }}
                      IconComponent={ExpandMoreIcon}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                      renderValue={() => <div>{searchActions}</div>}
                    >
                      {searchBarList.map((option) => (
                        <MenuItem
                          key={option}
                          value={option}
                          disableGutters={true}
                          style={{ padding: "5px 10px" }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "7px",
                            }}
                          >
                            <input
                              type="radio"
                              checked={searchActions === option}
                              readOnly
                            />
                            {option}
                          </div>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : (
                  <SearchIcon />
                )}
              </InputAdornment>
            ),
          }}
        />
        <div className={styles.searchButtonDiv}>
          <IconButton
            className={`${styles.closeButton} ${
              searchBy ? "" : styles.noVisibility
            }`}
            onClick={handleSearchClose}
          >
            <CloseSharpIcon />
          </IconButton>
          <button
            onClick={!searchError ? handleSearch : ""}
            className={
              searchActions === "Search by" || searchError
                ? styles.searchButtonDisable
                : styles.searchButton
            }
            disabled={
              searchActions === "Search by" || searchError ? true : false
            }
            style={{ width: "50px" }}
          >
            {<SearchIcon />}
          </button>
        </div>
      </div>

      {!searchError ? (
        <div className={`searchInstruction ${styles.searchInstruction}`}>
          {searchInstructions}
        </div>
      ) : (
        <div
          className={`searchInstructionError ${styles.searchInstructionError}`}
        >
          <WarningIcon />
          &nbsp;{warningMessage}
        </div>
      )}
    </div>
  );
};

export default LeadSearchBar;
