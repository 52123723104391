import "./Questions.css";
import { useEffect, useState } from "react";
import emptyScreening from "./emptyScreening.png";
import QuestionForm from "./QuestionForm";
import { useForm } from "react-hook-form";
import { useParams, useHistory } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import UrlLinks from "../../../../UrlLinks";

export default function Questions() {
  const history = useHistory();
  const { jobId } = useParams();
  const [questionArray, setQuestionArray] = useState([]);
  const [reapply, setReapply] = useState(false);
  const [errArray, setErrArray] = useState([]);
  const [totalMarks, setTotalMarks] = useState(0);
  const [passingMarks, setPassingMarks] = useState(0);
  const [totalMarkErr, setTotalMarkErr] = useState("");
  const [passingMarkErr, setPassingMarkErr] = useState("");
  const { handleSubmit } = useForm();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${UrlLinks.baseUrl}/task-job/getJobQuestions?jobId=${jobId}`
        );
        if (response.data && response.data.questionList) {
          setReapply(response.data.reapply === 1);
          setQuestionArray(response.data.questionList);
          if (response.data.questionList.length === 0) {
            setTotalMarks("");
            setPassingMarks("");
          } else {
            setTotalMarks(response.data.totalMarks || "");
            setPassingMarks(response.data.passingMarks || "");
          }
        }
      } catch (error) {
        console.log("Error fetching questions:", error);
      }
    };
    fetchData();
  }, [jobId]);

  async function handleDelete(questionId, questionMarks) {
    const isTemporary =
      typeof questionId === "string" && questionId.startsWith("temp");

    if (!isTemporary) {
      const userId = localStorage.getItem("userID");
      try {
        await axios.post(
          `${UrlLinks.baseUrl}task-job/deleteScreeningQuestion?insertedBy=${userId}&questionId=${questionId}`
        );
      } catch (error) {
        console.log("Error deleting question:", error);
      }
    }

    setQuestionArray((prevQuestionArray) =>
      prevQuestionArray.filter((question) => question.questionId !== questionId)
    );
    setTotalMarks((prev) => prev - questionMarks);
  }

  function handleAddQuestion() {
    setQuestionArray((prev) => [
      ...prev,
      {
        answer: "",
        answerId: 0,
        answerList: [{ option_id: "temp-00", option_text: "" }],
        isMandatory: 0,
        marks: 0,
        questionId: `temp-${(prev.length + 1).toString().padStart(2, "0")}`,
        questionText: "",
      },
    ]);
  }

  function handleCopy(question) {
    const newQuestion = {
      ...question,
      questionId: `temp-${Date.now()}`, // Unique temporary ID
    };
    setQuestionArray((prev) => [...prev, newQuestion]);
    setTotalMarks((prev) => prev + question.marks);
  }

  async function handleQuestionsSubmit(data) {
    setTotalMarkErr("");
    setPassingMarkErr("");
    const formDataToSend = questionArray.map((question) => ({
      ...question,
      questionId:
        typeof question.questionId === "string" &&
        question.questionId.startsWith("temp")
          ? 0
          : question.questionId,
      totalMarks,
      passingMarks,
    }));

    let isValid = true;
    let errors = [];

    for (let i = 0; i < formDataToSend.length; i++) {
      const question = formDataToSend[i];
      let errObj = { questionIndex: i };

      if (totalMarks === "") {
        setTotalMarkErr("This Field is Required");
        isValid = false;
      }

      if (passingMarks === "") {
        setPassingMarkErr("This Field is Required");
        isValid = false;
      }

      if (passingMarks !== "" && totalMarks !== "") {
        if (Number(passingMarks) > Number(totalMarks)) {
          setPassingMarkErr("Passing Marks should be less than Total Marks");
          isValid = false;
        } else if (Number(passingMarks) < 1) {
          setPassingMarkErr("Invalid Entry");
          isValid = false;
        }
      }

      if (passingMarkErr !== "" && totalMarkErr !== "") isValid = false;

      if (question.questionText === "") {
        errObj.questionErr = "This Field is required";
        isValid = false;
      }

      if (question.answer === "") {
        errObj.answerErr = "This Field is required";
        isValid = false;
      }

      if (question.marks === "" || question.marks === 0) {
        errObj.marksErr = "This Field is required";
        isValid = false;
      }

      if (!isValid) {
        errors.push(errObj);
      }
    }

    if (!isValid) {
      setErrArray(errors);
      return;
    }

    const strBody = {
      ...data,
      questionList: formDataToSend,
      reapply: reapply ? 1 : 0,
      jobId,
      totalMarks,
      passingMarks,
    };

    const userId = localStorage.getItem("userID");

    const response = await axios.post(
      `${UrlLinks.baseUrl}task-job/addEditScreeningQuestion?insertedBy=${userId}`,
      strBody
    );
    if (response.status === 200 && response.data.code !== 1001) {
      swal("Job saved successfully", "", "success");
      history.push("/jobsdb");
    } else {
      swal("Duplicate Options", "", "error");
    }
  }

  return (
    <section className="screeningQuestionView">
      <section className="screeningQuestionContainer">
        <div className="screeningSubNav">
          <div className="screeningSubNavLeft">
            <a
              className="screeningBackBtn"
              href={`/jobsdb/eligibilityCriteria/${jobId}`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="size-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
                />
              </svg>
            </a>
            <div>Add Screening Questions 3/3</div>
          </div>
          <div className="screeningSubNavRight">
            <div className="reapplyToggle">
              Re-apply
              <div
                onClick={() => setReapply((prev) => !prev)}
                className={`reapplyToggleSwitch ${
                  reapply ? "reapplyToggleSwitchOn" : ""
                }`}
              >
                <div
                  className={`reapplyToggleBtn ${
                    reapply ? "reapplyToggleBtnOn" : ""
                  }`}
                ></div>
              </div>
            </div>
            <div
              onClick={handleAddQuestion}
              className="screeningSubNavRightBtn"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="size-6"
              >
                <path
                  fillRule="evenodd"
                  d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25ZM12.75 9a.75.75 0 0 0-1.5 0v2.25H9a.75.75 0 0 0 0 1.5h2.25V15a.75.75 0 0 0 1.5 0v-2.25H15a.75.75 0 0 0 0-1.5h-2.25V9Z"
                  clipRule="evenodd"
                />
              </svg>
              <div>Add Question</div>
            </div>
          </div>
        </div>
        {questionArray.length === 0 ? (
          <div className="screeningEmptyBody">
            <div className="emptyScreeningImage">
              <img src={emptyScreening} alt="" />
            </div>
            <div className="emptyScreeningText">
              Add Your Preference Question to get the good leads
            </div>
          </div>
        ) : (
          <div className="screeningQuestionBody">
            {questionArray.map((question, index) => (
              <QuestionForm
                index={index}
                key={question.questionId}
                id={question.questionId}
                questionText={question.questionText}
                marks={question.marks}
                isMandatory={question.isMandatory}
                answer={question.answer}
                answerId={question.answerId}
                answerList={question.answerList}
                setQuestionArray={setQuestionArray}
                handleDelete={handleDelete} // Pass handleDelete function
                handleCopy={handleCopy} // Pass handleCopy function
                errArray={errArray}
                setErrArray={setErrArray}
                setTotalMarks={setTotalMarks}
              />
            ))}
          </div>
        )}
        <form
          className="payloadSaveForm"
          onSubmit={handleSubmit(handleQuestionsSubmit)}
        >
          <div
            className={`actionBtnScreening ${
              questionArray.length > 0 ? "actionBtnScreeningBetween" : ""
            }`}
          >
            {questionArray.length > 0 && (
              <div className="payloadInputs">
                <div className="payloadInputsHead">Pass Marks</div>
                <input
                  type="number"
                  placeholder="Type passing marks"
                  className="passingMarks"
                  value={passingMarks || ""}
                  onChange={(e) => setPassingMarks(e.target.value)}
                  style={{ MozAppearance: "textfield" }}
                />

                {/* {errors.passingMarks && <span className="error">{errors.passingMarks.message}</span>} */}
                {passingMarkErr && (
                  <span className="error">{passingMarkErr}</span>
                )}
                <div className="payloadInputsHead">Out of</div>
                <input
                  type="text"
                  placeholder="Total Marks"
                  className="totalMarks"
                  value={totalMarks || ""}
                  readOnly
                />
                {totalMarkErr && <span className="error">{totalMarkErr}</span>}
              </div>
            )}
            <div className="actionBtnsMAin">
              <a href={`/jobsdb`}>
                <button type="button" className="payloadCancel">
                  CANCEL
                </button>
              </a>
              <button type="submit" className="payloadSave">
                {questionArray.length === 0 ? "Done" : "Save"}
              </button>
            </div>
          </div>
        </form>
      </section>
    </section>
  );
}
