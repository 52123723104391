import { Grid } from '@material-ui/core';
import React from 'react'
import style from './index.module.scss'

const TimeLineHeader =() =>{
    const list=[]
    for( var i=0;i<=24;i++){
        if(i>6){

            list.push(
                <div className={style.timeContainer} key={i}>
                    <span className={i%2!==0?style.timeTextLarge:style.timeTextSmall}>{`${(i+12)%12===0?12:(i+12)%12} ${((i<12 || i>23) && i%2!==0)?"AM":(i>11 && i%2!==0)?"PM":""}`}</span>
                    <div className={style.timeStick}></div>
                </div>
            )
        }
    }
    return (
        <Grid container>
            <div className={style.timeLineContainer}>
                {list}
            </div>
        </Grid>
    )
}
export default TimeLineHeader;