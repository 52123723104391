import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
  FormControl,
  IconButton,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import Modal from "@mui/material/Modal";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import urlLinks from "../../../UrlLinks";
import Axios from "axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  padding: "15px 25px",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.15)",
  p: 4,
  borderRadius: "5px",
};

export default function RejectModal(props) {
  const { open, setOpen, handleOnHold, text } = props;

  const [remark, setRemark] = useState(0);
  const [options, setOptions] = useState(0);
  const [onHoldList, setOnHoldList] = useState([]);
  const [otherValue, setOtherValue] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setRemark(0);
    setOptions(0);
    setOtherValue(false);
  };

  const makeonHoldList = (data) => {
    let list = [];
    data.map((el) => {
      list.push({
        typeKey: el.typeKey,
        typeValue: el.typeValue,
      });
    });
    return list;
  };

  useEffect(() => {
    Axios.get(`${urlLinks.baseUrl}${urlLinks.onHoldList}`).then((response) => {
      setOnHoldList(makeonHoldList(response.data.response.content));
    });
  }, []);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              style={{
                color: "#1C75BC",
                fontSize: "24px",
                fontWeight: "normal",
                margin: "0",
              }}
            >
              {text}
            </Typography>
            <IconButton
              component="label"
              onClick={handleClose}
              fontSize="large"
              style={{ marginRight: "-15px" }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <Box sx={{ margin: "0 0 25px 0" }}>
            <FormControl variant="outlined" fullWidth margin="dense">
              <Select
                id="sortBy"
                IconComponent={ExpandMoreIcon}
                style={{ color: remark === 0 ? "grey" : "" }}
                value={options}
                onChange={(event) => {
                  let action = event.target.value;
                  setRemark(action);
                  setOptions(action);
                  if (action == "Other") {
                    setOtherValue(true);
                  } else {
                    setOtherValue(false);
                  }
                }}
              >
                <MenuItem key={0} value={0} disabled={true}>
                  Select your reason
                </MenuItem>
                {onHoldList.map((option) => (
                  <MenuItem key={option.typeKey} value={option.typeValue}>
                    {option.typeValue}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          {otherValue ? (
            <Box
              sx={{ margin: "25px 0" }}
              component="form"
              noValidate
              autoComplete="off"
            >
              <TextField
                id="outlined-multiline-flexible"
                fullWidth
                multiline
                onChange={(e) => {
                  setRemark(e.target.value);
                }}
                rows={3}
                placeholder="Enter reason here"
                variant="outlined"
              />
            </Box>
          ) : (
            ""
          )}
          <Stack spacing={2} direction="row">
            <Button fullWidth variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              fullWidth
              variant="contained"
              disabled={remark !== null && remark.length > 0 ? false : true}
              onClick={() => {
                handleOnHold(false, remark);
                handleClose();
                setOpen(false);
              }}
            >
              Submit
            </Button>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
}
