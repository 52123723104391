import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { CSVLink } from 'react-csv';
import Download from '../Group 3238.svg'
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import Styles from '../index.module.scss'
import UrlLinks from "../../../UrlLinks";
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import { saveAs } from 'file-saver';
import Axios from 'axios'
import { ScaleLoader } from "react-spinners";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    border: "unset",

  }
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    display: "flex",
    flexDirection: "row-reverse",
    justifyContent: "space-between"
  }
}))(MuiDialogActions);

export default function CustomizedDialogs(props) {
  const { openResponseModal, setOpenResponseModal, setOpenOnboardingModal, responseData, type, invalidData, updateMyData } = props
  const handleClose = () => {
    setOpenResponseModal(false);
  };
  const [downloadLoading, setDownloadLoading] = useState(false)
  const [agreementLoading, setAgreementLoading] = useState(false)
  const [zipData, setZipData] = useState(null)
  // console.log({invalidData})
  // console.log({responseData})
  const zipDownload = () => {
    setDownloadLoading(true)
    var zipFilename = "Agreements.zip";
    zipData.generateAsync({ type: 'blob' }).then(function (content) {
      saveAs(content, zipFilename);
      setDownloadLoading(false)
    });
  }
  // const createZipForDocuments = (urls) => {
  //   var zip = new JSZip();
  //   var count = 0;
  //   urls.forEach(async function (url, index) {
  //     const record = Object.entries(url)
  //     console.log({ record })
  //     const filename = `${record[0][0]}`;
  //     const path = `${record[0][1]}`
  //     try {
  //       await JSZipUtils.getBinaryContent(path, function (err, data) {
  //         if (err) {
  //           throw err; // or handle the error
  //         }
  //         zip.file(filename, data, { binary: true });
  //         count++;
  //         console.log({ zip })
  //         if (count === urls.length) {
  //           setZipData(zip)
  //           setAgreementLoading(false)

  //         }

  //       })
  //     }
  //     catch (err) {
  //       console.log(err);
  //     }
  //   });
  // }


  // const downloadAgreementFiles = () => {
  //   Axios.post(`${UrlLinks.baseUrl}${UrlLinks.workerUrls.downloadAgreementOnResponse}${responseData.BatchNo}`)
  //     .then(response => {
  //       if (response.data.response.length > 0) {
  //         setAgreementLoading(true)
  //         createZipForDocuments(response.data.response)
  //         updateMyData();
  //       }
  //       else {
  //         props.showToast("error", "No agreement to download")
  //       }
  //     })
  // }
  console.log({invalidData})
  // const handleAgreementDownload = () => {
  //   downloadAgreementFiles()
  // }
  const loader = <div style={{ margin: "40px auto", width: "fit-content" }}>
    <ScaleLoader
      height={70}
      width={10}
      color={"#1c75bc"}
    />
  </div>
  let dialogData =
    agreementLoading ?
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={openResponseModal}
        className={Styles.onboardingDialog}
        classes={{ paper: Styles.paperResponse }}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        </DialogTitle>
        <DialogContent dividers>
          <Grid item xs={12} className={Styles.waitText}>
            <span>Please wait for few minutes, the files are downloading</span>
          </Grid>
          <Grid item xs={12} >
            {loader}
          </Grid>
        </DialogContent>
        <DialogActions>
        </DialogActions>
      </Dialog>
      : zipData ?
        <Dialog
          aria-labelledby="customized-dialog-title"
          open={openResponseModal}
          className={Styles.onboardingDialog}
          classes={{ paper: Styles.paperResponse }}
        >
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          </DialogTitle>
          <DialogContent dividers>
            <Grid item xs={12} className={Styles.waitText}>
              <span>Files are ready for download</span>
            </Grid>
            <Grid item xs={12} className={Styles.DownloadButtonContainer}>
              <Button
                variant="contained"
                onClick={zipDownload}
                className={"blueButtonContained"}
                disabled={downloadLoading}
              >
                DOWNLOAD NOW
                  </Button>
            </Grid>
          </DialogContent>
          <DialogActions>
          </DialogActions>
        </Dialog>

        : <Dialog
          aria-labelledby="customized-dialog-title"
          open={openResponseModal}
          className={Styles.onboardingDialog}
          classes={{ paper: Styles.paperResponse }}
        >
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            <Grid item xs={12} >
              <span className={Styles.summaryText}>Summary of uploaded data</span>
            </Grid>
          </DialogTitle>
          <DialogContent dividers>
            <Grid item xs={12} className="padding0">
              <span className={Styles.mainRecord}>{`# Worker records attempted to upload : ${responseData["total"]}`}</span>
            </Grid>
            {responseData && <Grid item xs={12} className="padding0 marginTop16">
              <Grid container className="padding0 marginTop16">
                <table className="tableContainer">
                    <tr>
                      <td># Workers uploaded</td>
                      <td>{responseData["uploaded"]}</td>
                    </tr>
                    <tr>
                      <td># Invalid data records</td>
                      <td>{responseData["rejected"]}</td>
                    </tr>

                </table>
              </Grid>
              {/* Note: For any invalid records not uploaded, please update the re-upload the records along with GroupFlex ID */}
              <div > 
                <div>
                  {invalidData && <Grid item xs={12} style={{ marginTop: "20px" }}>
                    <CSVLink
                      data={invalidData}
                      filename={type==="Offboarding"?"Invalid Swiggy offboarding data.csv":"Invalid Swiggy ID data.csv"}
                      className='btn btn-primary blueColor downloadExcelButton'
                      target='_blank'
                      style={{
                        color: 'inherit',
                        textDecoration: 'none',
                        marginTop: "20px",
                        fontWeight: '500'
                      }}
                    >
                      <span style={{ marginRight: "10px" }}>Download invalid data</span><img src={Download} alt="download" />
                    </CSVLink>
                  </Grid>}
                </div>
              </div>
            </Grid>}
          </DialogContent>

          <DialogActions>
            
            {responseData && <Button variant="contained" onClick={() => { setOpenOnboardingModal(false); setOpenResponseModal(false); }} className={"blueButtonContained"}>
              Done
              </Button>}
            {type === "Onboarding" && <span><b>Note:</b> It may take upto 20 min for agreements to get generated for the uploaded data.</span>}
            {/* props.agreement && responseData["Workers onboarded"]>0 && <button onClick={handleAgreementDownload} className={Styles.agreementDownloadButton} >{`Proceed to generate agreements >`}</button> */}
            {type==="groupflex" && <div style={{marginBottom:'5px'}}><b>Note:</b> For any invalid records not uploaded, please update and re-upload the records along with GroupFlex ID</div>}

                   </DialogActions>

        </Dialog>



  return dialogData;

}
