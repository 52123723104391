import styles from "../index.module.scss";

const LocationComponent = ({ getData, setShowLocationList }) => {
  const maxDisplayedLocations = 10;

  const locations = getData?.locationArray || [];
  const locationNames = locations.map((obj) => obj.name);

  const displayLocations =
    locations.length <= maxDisplayedLocations
      ? locationNames.join(", ")
      : locationNames.slice(0, maxDisplayedLocations).join(", ");

  const showMoreLocations =
    locations.length > maxDisplayedLocations ? (
      <button
        className={styles.showMoreBtn}
        onClick={() => handleShowMoreClick()}
      >
        +{locations.length - maxDisplayedLocations} more
      </button>
    ) : null;

  const handleShowMoreClick = () => {
    // Handle your logic when the "+10 more" button is clicked
    // This can include showing a modal, expanding the list, etc.
    // For now, let's just console.log a message
    setShowLocationList(true);
  };

  return (
    <div>
      <p>
        {displayLocations} {showMoreLocations}
      </p>
    </div>
  );
};

export default LocationComponent;
