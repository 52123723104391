import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Axios from "axios";
import document from "../document.svg";
import ResponseModal from "./ResponseModalAJ";
import Typography from "@material-ui/core/Typography";
import * as moment from 'moment'
import { FormControlLabel, Grid, Radio, RadioGroup } from "@material-ui/core";
import Styles from '../index.module.scss'
import UrlLinks from "../../../UrlLinks";
import Download from "../Group 3238.svg";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    borderBottom: "unset",
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function CustomizedDialogs(props) {
  const {
    openOnboardingModal,
    setOpenOnboardingModal,
    showToast,
    updateMyData,
  } = props;
  const [excel, setExcel] = useState(null);
  const [openResponseModal, setOpenResponseModal] = useState(false);
  const [name, setName] = useState("");
  const [excelError, setExcelError] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const [invalidData, setInvalidData] = useState()
  // const [fileLink, setFileLink] = useState(null)
  const [responseData, setResponseData] = useState()
  const [closeFile, setCloseFile] = useState(true)

  const handleClose = () => {
    setOpenOnboardingModal(false);
  };

  const handleCloseFile = () => {
    setName("");
    setCloseFile(true);
  };

  const handleUpload = (event) => {
    const fileExtension = event.target.files[0].name.split(".").slice(-1)[0];
    const fileName = event.target.files[0].name;

    if (
      fileExtension.toLowerCase() === "xlsx" ||
      fileExtension.toLowerCase() === "xls"
    ) {
      setName(fileName);
      setExcel(event.target.files[0]);
      setExcelError(false);
      setCloseFile(false);
    } else {
      setExcelError(true);
      setExcel(null);
      setName("");
    }
    setResponseData();
    event.target.value = null;
  };

  let newOnboardingExcel =
    "https://www.okaygo.in/Upload_AJ_Workers_Template.xlsx";
  const createInvalidData = (records) => {
    if (records.length > 1) {
      let list = [];
      records.map((record) => {
        if (record.hasOwnProperty("WorkerDetail")) {
          if (record.WorkerDetail.isWorkerCreated === 0) {
            let firstName = "";
            let lastName = "";
            if (record.WorkerDetail.workerName) {
              var i = record.WorkerDetail.workerName.indexOf(" ");
              if (i > 0) {
                firstName = record.WorkerDetail.workerName.slice(0, i).trim();
                lastName = record.WorkerDetail.workerName
                  .slice(i + 1, record.WorkerDetail.workerName.length)
                  .trim();
              } else {
                firstName = record.WorkerDetail.workerName;
              }
            }
            list.push({
              "CTS ID*": record.WorkerDetail.cts_id || "",
              "First Name*": firstName || "",
              "Last Name": lastName || "",
              "Father's Name*": record.WorkerDetail.fatherName || "",
              "DOB*": record.WorkerDetail.dateOfBirth
                ? moment(record.WorkerDetail.dateOfBirth).format("DD/MM/YYYY")
                : "",
              "Contact No*": record.WorkerDetail.mobileNo || "",
              "Designation*": record.WorkerDetail.designation || "",
              "TDS %*": record.WorkerDetail.tds || "",
              "Offer Letter Creation Date*": record.WorkerDetail
                .offerLetterCreationDate
                ? moment(record.WorkerDetail.offerLetterCreationDate).format(
                    "DD/MM/YYYY"
                  )
                : "",
              "Branch/City*": record.WorkerDetail.hubName || "",
              "Joining Date*": record.WorkerDetail.dateOfJoining
                ? moment(record.WorkerDetail.dateOfJoining).format("DD/MM/YYYY")
                : "",
              "Aadhaar No*": record.WorkerDetail.adhaarNo || "",
              "PAN No": record.WorkerDetail.panNumber || "",
              "Is PAN verified":
                record.WorkerDetail.isPanVerified === 1
                  ? "Yes"
                  : record.WorkerDetail.isPanVerified === 0
                  ? "No"
                  : "",
              "Address*": record.WorkerDetail.currentAddress || "",
              "City*": record.WorkerDetail.currentCity || "",
              "State*": record.WorkerDetail.currentState || "",
              "Beneficiary Name /Account Name*":
                record.WorkerDetail.accountName || "",
              "Account Number*": record.WorkerDetail.accountNumber || "",
              "Bank Name*": record.WorkerDetail.bankName || "",
              "IFSC Code*": record.WorkerDetail.ifscCode || "",
              "Client Emp ID": record.WorkerDetail.clientEmpId || "",
              "Offer Letter link*": record.WorkerDetail.offerLetterLink || "",

              "Reason for failure": record.WorkerDetail.uploadStatus || "",
            });
          }
        }
        return true;
      });
      return list.length > 0 ? list : null;
    }
  };
  const handleExcel = () => {
    setIsLoading(true);
    let formData = new FormData();
    formData.append("file", excel);

    Axios.post(
      `${UrlLinks.baseUrl}${
        UrlLinks.workerUrls.uploadVacoOnboardingExcel
      }${localStorage.getItem("userID")}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
      .then((response) => {
        if (response.data) {
          setInvalidData(createInvalidData(response.data));
          const summary = response.data.slice(-1)[0];
          setResponseData(summary);
          updateMyData();
          setIsLoading(false);
        } else {
          setResponseData([]);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        showToast("error", error.message);
      });
  };

  useEffect(() => {
    if (responseData) {
      setOpenResponseModal(true);
    }
  }, [responseData]);
  let dialogData = (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={openOnboardingModal}
      classes={{ paper: Styles.paper }}
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        <span>Upload AJ Workers</span>{" "}
        <a
          href={newOnboardingExcel}
          download
          target="_blank"
          className={Styles.downloadTemplate}
        >
          <span>Download File Template</span>
          <img src={Download} alt="download" />
        </a>
      </DialogTitle>
      <DialogContent dividers>
        <Grid item xs={12} className="padding0">
          <input
            style={{ display: "none" }}
            id="contained-button-file"
            type="file"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            onChange={handleUpload}
          />
          <label htmlFor="contained-button-file">
            <Button
              variant="outlined"
              className={Styles.uploadButton}
              component="span"
              startIcon={
                <img src={document} alt={"upload"} style={{ color: "white" }} />
              }
            >
              Browse File
            </Button>
          </label>
        </Grid>
        <Grid item xs={12} className="padding0">
          <span className={`fontSize13 ${excelError ? "redColor" : ""}`}>
            only .xls and .xlsx are allowed
          </span>
        </Grid>
        <Grid item xs={12} className="padding0" style={{ marginTop: "10px" }}>
          {name && (
            <span className={Styles.fileName}>
              {name.length > 70 ? `${name.substring(0, 69)}...` : `${name}`}
              <span>
                <IconButton
                  aria-label="close"
                  style={{ color: "white", paddingRight: "0" }}
                  onClick={handleCloseFile}
                >
                  <CloseIcon />
                </IconButton>
              </span>
            </span>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        {isLoading ? (
          <span>Please wait... it may take few minutes</span>
        ) : (
          <Button
            variant="contained"
            onClick={handleExcel}
            className={`${!excel ? "disabledButton" : "blueButtonContained"}`}
            disabled={!excel}
          >
            Upload
          </Button>
        )}
      </DialogActions>
      {openResponseModal && (
        <ResponseModal
          openResponseModal={openResponseModal}
          setOpenResponseModal={setOpenResponseModal}
          responseData={responseData}
          setOpenOnboardingModal={setOpenOnboardingModal}
          showToast={showToast}
          invalidData={invalidData}
          updateMyData={updateMyData}
          agreement={true}
        />
      )}
    </Dialog>
  );

  return dialogData;
}
