import React, { useEffect, useState, useContext } from "react";
import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import styles from "./index.module.scss";
import { AuthContext } from "../../../services/AuthProvider";
import { useParams } from "react-router-dom";
import * as Axios from "axios";
import UrlLinks from "../../../UrlLinks";
import OkaygoLogo from "./okagoLogo.svg";

const ChangePassword = (props) => {
  const { username } = useParams();
  const { validateResetPasswordCode, forgotPassword, showToast } =
    useContext(AuthContext);
  const [wrongCode, setWrongCode] = useState("");
  const [formData, setFormData] = useState({
    verificationCode: "",
    password: "",
    confirmPassword: "",
    passwordError: null,
    confirmPasswordError: null,
    verificationCodeError: null,
    showPassword: false,
  });
  const [disabledButton, setDisabledButton] = useState(true);

  const handleClickShowPassword = () => {
    setFormData({ ...formData, showPassword: !formData.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleResend = async () => {
    Axios.post(`${UrlLinks.baseUrl}${UrlLinks.checkAdminUserName}`, {
      user_name: username,
    })
      .then(async () => {
        await forgotPassword(username, null, setWrongCode);
      })
      .catch((err) => {
        showToast("error", err.message);
      });
  };

  const onSubmitPassword = (event) => {
    event.preventDefault();
    validateResetPasswordCode(
      username,
      formData.password,
      formData.verificationCode,
      setWrongCode
    );
  };

  const handleCode = (event) => {
    if (
      (event.target.value.length !== 6 && event.target.value.length > 0) ||
      !/^\d*$/.test(event.target.value)
    ) {
      setFormData({
        ...formData,
        verificationCodeError: "Please enter a valid Code.",
        verificationCode: event.target.value,
      });
    } else {
      setFormData({
        ...formData,
        verificationCodeError: null,
        verificationCode: event.target.value,
      });
    }
    setWrongCode("");
  };

  const handlePassword = (event) => {
    let passwordError = "";
    if (event.target.value) {
      if (event.target.id === "confirmPassword") {
        if (event.target.value !== formData.password) {
          setFormData({
            ...formData,
            confirmPasswordError:
              "Password and confirm password does not match.",
            confirmPassword: event.target.value,
          });
        } else {
          setFormData({
            ...formData,
            confirmPassword: event.target.value,
            confirmPasswordError: null,
          });
        }
      } else {
        if (event.target.value.length < 8) {
          passwordError = passwordError + "Password must be 8 character long.";
        }
        if (!event.target.value.match(/[A-Z]/g)) {
          if (passwordError) {
            passwordError = passwordError + "\n";
          }
          passwordError =
            passwordError + "Password must contain an uppercase letter.";
        }
        if (!event.target.value.match(/[!@#$%^&*]/g)) {
          if (passwordError) {
            passwordError = passwordError + "\n";
          }
          passwordError =
            passwordError + "Password must contain a special character.";
        }
        if (!event.target.value.match(/[a-z]/g)) {
          if (passwordError) {
            passwordError = passwordError + "\n";
          }
          passwordError =
            passwordError + "Password must contain a lowercase letter.";
        }
        if (!event.target.value.match(/[0-9]/g)) {
          if (passwordError) {
            passwordError = passwordError + "\n";
          }
          passwordError = passwordError + "Password must contain a number.";
        }
        setFormData({
          ...formData,
          password: event.target.value,
          passwordError: passwordError,
        });
      }
    } else {
      setFormData({
        ...formData,
        [event.target.id]: event.target.value,
        passwordError: null,
        confirmPasswordError: null,
      });
    }
  };

  useEffect(() => {
    if (
      formData.passwordError ||
      formData.confirmPasswordError ||
      formData.verificationCodeError ||
      !formData.password ||
      !formData.confirmPassword ||
      !formData.verificationCode
    ) {
      setDisabledButton(true);
    } else {
      setDisabledButton(false);
    }
  }, [formData]);

  return (
    <Grid container className={styles.root}>
      <Grid item xs={12} className={styles.navBarStyle}>
        <a href="/">
          <img src={OkaygoLogo} alt="okaygoLogo" />
        </a>
      </Grid>
      <Grid item xs={12} className={styles.formParent}>
        <form className={styles.form} onSubmit={onSubmitPassword}>
          <span className={styles.heading}>Reset password</span>
          <Grid container className={styles.resendCodeContainer}>
            <span className={styles.content}>
              Enter verification code sent to your email to reset password
            </span>
            <div>
              <Button
                type="button"
                variant="contained"
                onClick={handleResend}
                className={styles.resendCodeButton}
              >
                Resend Code
              </Button>
            </div>
          </Grid>
          <FormControl
            variant="outlined"
            className={`${styles.marginBottom24} ${styles.TextField}  ${styles.marginTop40}`}
          >
            <OutlinedInput
              id="verificationCode"
              type="text"
              placeholder="Verification code"
              className={styles.borderColor}
              value={formData.verificationCode}
              error={formData.verificationCodeError || wrongCode}
              onChange={handleCode}
            />
          </FormControl>
          {formData.verificationCodeError && (
            <div className={`textAlignLeft ${styles.marginBottom24}`}>
              <span style={{ color: "red", whiteSpace: "pre" }}>
                {formData.verificationCodeError}
              </span>
            </div>
          )}
          {wrongCode && (
            <div className={`textAlignLeft ${styles.marginBottom24}`}>
              <span style={{ color: "red", whiteSpace: "pre" }}>
                {wrongCode}
              </span>
            </div>
          )}
          <FormControl
            variant="outlined"
            className={`${styles.marginBottom24} ${styles.TextField} `}
          >
            <OutlinedInput
              id="password"
              type={formData.showPassword ? "text" : "password"}
              value={formData.password}
              onChange={handlePassword}
              error={formData.passwordError}
              placeholder="Enter a strong password"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {formData.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          {formData.passwordError && (
            <div className={`${styles.marginBottom24} textAlignLeft`}>
              <span style={{ color: "red", whiteSpace: "pre-wrap" }}>
                {formData.passwordError}
              </span>
            </div>
          )}
          <FormControl
            variant="outlined"
            className={`${styles.marginBottom24} ${styles.TextField}`}
          >
            <OutlinedInput
              id="confirmPassword"
              type={"password"}
              value={formData.confirmPassword}
              onChange={handlePassword}
              error={formData.confirmPasswordError}
              placeholder="Confirm password"
            />
          </FormControl>
          {formData.confirmPasswordError && (
            <div className={`textAlignLeft ${styles.marginBottom24}`}>
              <span style={{ color: "red" }}>
                {formData.confirmPasswordError}
              </span>
            </div>
          )}
          <div className={styles.marginBottom24}>
            <Button
              disabled={disabledButton}
              type="submit"
              variant="contained"
              className={
                !disabledButton ? styles.changePasswordButton : "disabledButton"
              }
            >
              Submit
            </Button>
          </div>
        </form>
      </Grid>
    </Grid>
  );
};

export default ChangePassword;
