import { useState, useEffect, React } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../../store/actions/index";
import styles from "./Components.module.scss";
import Axios from "axios";
import UrlLinks from "../../../UrlLinks";
import swal from "sweetalert";
import { IconButton, TextField } from "@mui/material";
import { Close, InsertLink } from "@material-ui/icons";
import { blue } from "@material-ui/core/colors";
import Warning from "../Assets/warning.svg";
import {
  Box,
  Button,
  createTheme,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  makeStyles,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  ThemeProvider,
} from "@material-ui/core";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 626,
  overflow: "scroll",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 3,
  p: 2,
  fontStyle: "normal",
  color: "#2E2E2E",
  fontSize: "1rem",
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  fontWeight: "400",
  lineHeight: "1",
  letterSpacing: "0.00938em",
};

const usestyles = makeStyles({
  boxRoot: {
    overflow: "hidden",
  },
  header: {
    borderBottom: "2px solid rgba(0, 0, 0, 0.04)",
    "& .MuiTypography-root": {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      color: "#1C75BC",
      fontSize: "24px",
      fontWeight: "500",
    },
    "& .MuiIconButton-root": {
      padding: "0",
    },
  },
  formControl: {
    marginTop: "5%",
    width: "100%",
    "& .MuiFormLabel-root": {
      color: "#2E2E2E",
    },
    "& .MuiFormLabel-root.Mui-disabled": {
      color: "rgba(0, 0, 0, 0.38)",
    },
  },
  textField: {
    "& .MuiInputBase-input": {
      height: "12px !important",
    },
  },
  select: {
    height: "43px",
  },
  purposeSelect: {
    maxHeight: "156px",
    width: "576px",
  },
});

const theme = createTheme({
  palette: {
    primary: {
      main: blue[700],
    },
  },
});

export default function SendJobMsg(props) {
  const { open, setOpen, updateMyData, userIds, title, filters } = props;

  /** accessing states from redux store **/

  const { users } = useSelector((state) => state);
  const { modalLoader } = users;

  /** useDispatch is used to update states to redux store **/

  const dispatch = useDispatch();
  const { setModalLoader } = bindActionCreators(actionCreators, dispatch);

  /** other states **/

  const classes = usestyles();
  const [menuItem, setMenuItem] = useState([]);
  const [disableClearBtn, setDisableClearBtn] = useState(true);
  const [disableSendBtn, setDisableSendBtn] = useState(true);
  const [message, setMessage] = useState({
    title: null,
    body: null,
  });
  // const [modesAvailable, setModesAvailable] = useState([]);
  const [campaignName, setCampaignName] = useState("");
  const [additionalTags, setAdditionalTags] = useState("");
  const [linkPasted, setLinkPasted] = useState("");
  const [focusedTextbox, setFocusedTextbox] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [selectedMode, setSelectedMode] = useState(null);
  const modesAvailable = [
    /* id should be same as backend */
    { id: 1, mode: "In App" },
    { id: 2, mode: "WhatsApp" },
  ];
  const [dailyWhatsappSentLimit, setDailyWhatsappSentLimit] = useState({
    flag: false,
    count: 900,
  });

  const handleClose = () => {
    setOpen(false);
  };

  const sendMessageAPI = () => {
    const payload = {
      campaignName: campaignName,
      insertedBy: localStorage.getItem("userID"),
      jobId: filters.jobId,
      link: linkPasted,
      mode: selectedMode,
      templateId: selectedTemplate.templateId,
      user_id: userIds,
    };
    Axios.post(
      `${UrlLinks.baseUrl}${UrlLinks.leadData.startJobCampaign}`,
      payload
    )
      .then((response) => {
        swal("Message Sent", "", "success");
        updateMyData();
        setOpen(false);
      })
      .catch((error) => {
        swal("Failed!", "", "error");
        setOpen(false);
      });
  };

  const setResponseIntoTemplateList = (response) => {
    let menuList = [];
    response.map((e, id) => {
      if (e.description) {
        menuList.push(
          <MenuItem key={id} value={e}>
            {e.description}
          </MenuItem>
        );
      }
      return true;
    });
    setMenuItem(menuList);
  };

  const handleClear = (e) => {
    setCampaignName("");
    setSelectedMode(null);
    setAdditionalTags("");
    setLinkPasted("");
    setSelectedTemplate(null);
    setMessage({
      title: null,
      body: null,
    });
  };

  const getMessageBody = (template) => {
    setSelectedTemplate(template);
    setMessage({
      title: template.emailSubject,
      body: template.emailText,
    });
  };

  const handleTextboxFocus = (uniqueId) => {
    setFocusedTextbox(uniqueId);
  };

  const handleChange = (e, type) => {
    if (type === "campaignName") setCampaignName(e.target.value);
    else if (type === "modeSelect") {
      setSelectedTemplate(null);
      setMessage({
        title: null,
        body: null,
      });
      setFocusedTextbox(null);
      setSelectedMode(e.target.value);
      Axios.get(
        `${UrlLinks.baseUrl}${UrlLinks.leadData.getJobCampaignTemplates}`
      )
        .then((response) => {
          if (e.target.value == 1) {
            setResponseIntoTemplateList(response.data.in_app);
          } else if (e.target.value == 2) {
            setResponseIntoTemplateList(response.data.whatsapp);
          }
        })
        .catch((error) => {});
    } else if (type === "selectTemplate") {
      setFocusedTextbox(null);
      getMessageBody(e.target.value);
    } else if (type === "linkBox") {
      setLinkPasted(e.target.value);
      setModalLoader(true);
      Axios.get(
        `${UrlLinks.baseUrl}${UrlLinks.deeplink.validateDeepLink}?link=${e.target.value}`
      )
        .then((response) => {
          setAdditionalTags(response.data.response.tags);
          setModalLoader(false);
        })
        .catch((error) => {});
    }
  };

  useEffect(() => {
    if (campaignName || selectedMode || selectedTemplate || additionalTags) {
      setDisableClearBtn(false);
    } else {
      setDisableClearBtn(true);
    }
    if (selectedMode == 1 && campaignName && selectedTemplate) {
      setDisableSendBtn(false);
    } else if (
      selectedMode == 2 &&
      campaignName &&
      selectedTemplate &&
      additionalTags &&
      !dailyWhatsappSentLimit.flag
    ) {
      setDisableSendBtn(false);
    } else {
      setDisableSendBtn(true);
    }
  }, [campaignName, selectedMode, selectedTemplate, additionalTags]);

  useEffect(() => {
    Axios.get(
      `${UrlLinks.baseUrl}${UrlLinks.deeplink.getDailyWhatsAppSentCount}`
    )
      .then((response) => {
        let sendCount = response.data.response + userIds.length;
        if (sendCount > dailyWhatsappSentLimit.count) {
          setDailyWhatsappSentLimit({
            flag: true,
            count: dailyWhatsappSentLimit.count - response.data.response,
          });
        } else {
          setDailyWhatsappSentLimit({
            flag: false,
            count: dailyWhatsappSentLimit.count,
          });
        }
      })
      .catch((error) => {});
  }, []);

  const templateSelect = (
    <FormControl
      variant="outlined"
      className={classes.formControl}
      style={{ marginBottom: "2%" }}
      disabled={selectedMode ? false : true}
    >
      <FormLabel style={{ paddingBottom: "12px" }} component="legend">
        Add Template
      </FormLabel>
      <Select
        id="demo-simple-select-autowidth"
        autoWidth
        className={classes.select}
        value={selectedTemplate}
        onChange={(e) => handleChange(e, "selectTemplate")}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          getContentAnchorEl: null,
          classes: { paper: classes.purposeSelect },
        }}
      >
        {menuItem}
      </Select>
    </FormControl>
  );

  const modeRadioBoxes = (
    <ThemeProvider theme={theme}>
      <FormControl component="fieldset" className={classes.formControl}>
        <FormLabel component="legend">Select Mode</FormLabel>
        <RadioGroup
          aria-label="gender"
          name="controlled-radio-buttons-group"
          row
        >
          {modesAvailable.map((e, id) => {
            return (
              <FormControlLabel
                key={id}
                value={e.id}
                label={e.mode}
                checked={selectedMode == e.id}
                style={{ color: "#2E2E2E" }}
                onChange={(e) => handleChange(e, "modeSelect")}
                control={
                  <Radio
                    size="small"
                    color="primary"
                    style={{
                      "&.MuiChecked": {
                        color: theme.palette.primary.main,
                      },
                    }}
                  />
                }
              />
            );
          })}
        </RadioGroup>
      </FormControl>
    </ThemeProvider>
  );

  const TextBox = (props) => {
    const { uniqueId, title, val, placeholder, disable } = props;

    return (
      <FormControl variant="outlined" className={classes.formControl}>
        <FormLabel style={{ paddingBottom: "12px" }} component="legend">
          {title}
        </FormLabel>
        <TextField
          variant="outlined"
          disabled={disable}
          autoFocus={
            focusedTextbox === uniqueId &&
            focusedTextbox !== "" &&
            uniqueId !== ""
          }
          value={val}
          placeholder={placeholder}
          fullWidth
          onChange={(e) => handleChange(e, uniqueId)}
          onFocus={() => handleTextboxFocus(uniqueId)}
          className={classes.textField}
          InputProps={{
            endAdornment: (
              <>
                {uniqueId === "linkBox" ? (
                  <InsertLink style={{ rotate: "135deg", color: "#1C75BC" }} />
                ) : null}
              </>
            ),
          }}
        />
      </FormControl>
    );
  };

  return (
    <Modal
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      open={open}
      BackdropProps={{ open: true }}
      className={`${styles.sendJobCommunicationModal}`}
    >
      <Box
        sx={style}
        className={`${styles.sendJobCommunicationBox} ${classes.boxRoot}`}
      >
        <DialogTitle
          id="responsive-dialog-title"
          className={`${classes.header}`}
        >
          {title}
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent className={styles.dialogContentText}>
          <TextBox
            uniqueId="campaignName"
            title="Campaign Name"
            val={campaignName}
            placeholder="Type campaign name"
            disable={false}
          />
          {modeRadioBoxes}
          {selectedMode == 2 && dailyWhatsappSentLimit.flag && (
            <div
              style={{
                marginTop: "4px",
                alignItems: "center",
                display: "flex",
              }}
            >
              <img src={Warning} alt="warning" />
              <span style={{ marginLeft: "5px" }}>
                {dailyWhatsappSentLimit.count < 0
                  ? 0
                  : dailyWhatsappSentLimit.count}{" "}
                users can be sent WhatsApp message in a day
              </span>
            </div>
          )}
          {templateSelect}
          {(message.body !== null || message.title !== null) && (
            <div className={`${styles.textBox}`}>
              <p>{message.body}</p>
            </div>
          )}
          {selectedMode == 2 && (
            <TextBox
              uniqueId="linkBox"
              title="Link"
              val={linkPasted}
              placeholder="Paste link here"
              disable={false}
            />
          )}
          {modalLoader ? (
            <div style={{ marginTop: "4px" }}>...please wait</div>
          ) : selectedMode == 2 && additionalTags ? (
            <div style={{ marginTop: "5%" }}>
              <p style={{ marginBottom: "12px" }}>Additional Tags</p>
              <div className={`${styles.textBox}`}>
                <p>{additionalTags}</p>
              </div>
            </div>
          ) : (
            selectedMode == 2 &&
            linkPasted && (
              <div style={{ color: "red", marginTop: "4px" }}>Invalid link</div>
            )
          )}
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            variant="contained"
            className="dataButton"
            disabled={disableClearBtn}
            onClick={handleClear}
          >
            Clear
          </Button>
          <Button
            size="small"
            variant="contained"
            className="dataButton"
            disabled={disableSendBtn}
            onClick={sendMessageAPI}
          >
            Send
          </Button>
        </DialogActions>
      </Box>
    </Modal>
  );
}
