import React from "react";
import styles from "./Functions.module.scss";
import { FormControl, makeStyles, MenuItem, Select } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  MuiFormControlRoot: {
    verticalAlign: "baseline",
  },
  actionDropdown: {
    "& .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-hover .MuiOutlinedInput-notchedOutline":
      {
        border: "1px solid #1C75BC",
      },
    "& .MuiOutlinedInput-root, .MuiSelect-icon": {
      color: "#1C75BC",
    },
    "& .MuiInputBase-root.Mui-disabled, .MuiSelect-icon.Mui-disabled": {
      color: "rgba(0, 0, 0, 0.26) !important",
    },
    "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #cccccc",
    },
  },
}));

const ActionsDropdown = (props) => {
  const {
    uniqueKey,
    actions,
    handleActions,
    actionList,
    isRowSelected,
    disableOption,
    dropdownName,
  } = props;
  const classes = useStyles();

  return (
    <FormControl
      variant="outlined"
      disabled={isRowSelected}
      classes={{ root: classes.MuiFormControlRoot }}
      className={`${classes.actionDropdown} ${styles.actionFormControl}`}
    >
      <Select
        id="actions"
        value={actions}
        classes={{ outlined: styles.actionSelect }}
        onChange={handleActions}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          getContentAnchorEl: null,
        }}
      >
        <MenuItem value={-1} disabled={true}>
          {dropdownName}
        </MenuItem>
        {actionList.map((item, id) => {
          if (item.value) {
            return (
              <MenuItem
                key={id}
                value={item.value}
                disabled={
                  uniqueKey === "actionWorker" && item.value === "agreement"
                    ? disableOption
                    : false
                }
              >
                {item.label}
              </MenuItem>
            );
          }
          return;
        })}
      </Select>
    </FormControl>
  );
};

export default ActionsDropdown;
