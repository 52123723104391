import { useState, useEffect, React } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../../store/actions/index";
import styles from "./Components.module.scss";
import Axios from "axios";
import UrlLinks from "../../../UrlLinks";
import swal from "sweetalert";
import { IconButton, TextField } from "@mui/material";
import { Close } from "@material-ui/icons";
import { blue } from "@material-ui/core/colors";
import {
  Box,
  Button,
  createTheme,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  makeStyles,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  ThemeProvider,
} from "@material-ui/core";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  overflow: "scroll",
  maxHeight: "482px",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 3,
  p: 2,
};

const usestyles = makeStyles({
  boxRoot: {
    overflow: "hidden",
  },
  header: {
    "& .MuiTypography-root": {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
    },
    "& .MuiIconButton-root": {
      padding: "0",
    },
  },
  formControl: {
    margin: "0 !important",
  },
  select: {
    width: "372px",
  },
  purposeSelect: {
    height: "290px",
    width: "372px",
  },
});

const theme = createTheme({
  palette: {
    primary: {
      main: blue[700],
    },
  },
});

export default function SendMsg(props) {
  const { open, setOpen, updateMyData, userIds, title } = props;

  /** accessing states from redux store **/

  const { users } = useSelector((state) => state);
  const { modalLoader } = users;

  /** useDispatch is used to update states to redux store **/

  const dispatch = useDispatch();
  const { setModalLoader } = bindActionCreators(actionCreators, dispatch);

  /** other states **/

  const classes = usestyles();
  const [menuItem, setMenuItem] = useState([]);
  const [sendCustom, setSendCustom] = useState(false);
  const [customTitle, setCustomTitle] = useState(null);
  const [customBody, setCustomBody] = useState(null);
  const [disable, setDisable] = useState(true);
  const [value, setValue] = useState({ tempName: null, serviceType: null });
  const [message, setMessage] = useState({ title: null, body: null });
  const [modesAvailable, setModesAvailable] = useState([]);

  const handleClose = () => {
    setOpen(false);
  };

  const sendMessageAPI = () => {
    if (sendCustom) {
      Axios.post(
        `${UrlLinks.baseUrl}${
          UrlLinks.sendCustomMsg
        }?insertedBy=${localStorage.getItem(
          "userID"
        )}&user_ids=${userIds}&title=${customTitle}&body=${customBody}`
      )
        .then((response) => {
          swal("Custom Message Sent", "", "success");
          updateMyData();
          setOpen(false);
        })
        .catch((error) => {
          swal("Failed!", "", "error");
          setOpen(false);
        });
    } else {
      Axios.post(
        `${UrlLinks.baseUrl}${
          UrlLinks.sendMsg
        }?insertedBy=${localStorage.getItem(
          "userID"
        )}&user_ids=${userIds}&templateName=${value.tempName}&serviceTypes=${
          value.serviceType
        }`
      )
        .then((response) => {
          swal("Message Sent", "", "success");
          updateMyData();
          setOpen(false);
        })
        .catch((error) => {
          swal("Failed!", "", "error");
          setOpen(false);
        });
    }
  };

  const setResponseIntoTemplateList = (response) => {
    let menuList = [];
    let i = 0;
    response.map((e) => {
      i++;
      if (e.purpose !== "Advance Payment") {
        menuList.push(
          <MenuItem key={i} value={e}>
            {e.purpose}
          </MenuItem>
        );
      }
      return true;
    });
    menuList.push(
      <MenuItem key={i} value={"Custom"}>
        {"Custom Message"}
      </MenuItem>
    );
    setMenuItem(menuList);
  };

  const handleSelect = (e) => {
    setSendCustom(false);
    setMessage({ title: null, body: null });
    setDisable(true);
    let avModes = [];

    if (e && e.modes) {
      let givenModes = e.modes.split(",");
      let temaplateNames = [];
      temaplateNames = e.template_names.split(",");
      givenModes.map((e) => {
        switch (e) {
          case "1": {
            avModes.push("email-1");
            break;
          }
          case "2": {
            avModes.push("sms-2");
            break;
          }
          case "3": {
            avModes.push("whatsapp-3");
            break;
          }
          case "4": {
            avModes.push("notification-4");
            break;
          }
          case "5": {
            avModes.push("popup-5");
            break;
          }
          default: {
          }
        }
        return true;
      });
      for (let i = 0; i < temaplateNames.length; i++) {
        avModes[i] = avModes[i] + "#" + temaplateNames[i];
      }
    }
    setModesAvailable(avModes);
  };

  const getMessageBody = (template) => {
    setModalLoader(true);
    setDisable(false);
    let serviceType = template.substr(0, template.indexOf("-"));
    let templateType = template.substr(
      template.indexOf("-") + 1,
      template.indexOf("#")
    );
    template = template.substr(template.indexOf("#") + 1, template.length);
    let temp = template;
    if (template === "super_member_sms") {
      temp = "super_member";
    } else if (template === "gold_member_popup") {
      temp = "gold_member";
    }
    setValue({
      tempName: temp,
      serviceType: serviceType,
    });

    Axios.get(
      `${UrlLinks.baseUrl}${UrlLinks.getMessageBody}?templateName=` +
        template +
        "&templateType=" +
        templateType
    )
      .then((response) => {
        if (response.data.code === 1000)
          setMessage({
            title: response.data.response.emailSubject,
            body: response.data.response.emailText,
          });
        setModalLoader(false);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    if (
      customBody !== null &&
      customBody.trim().length > 0 &&
      customTitle !== null &&
      customTitle.trim().length > 0
    ) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [customBody, customTitle]);

  useEffect(() => {
    setModalLoader(true);
    Axios.get(`${UrlLinks.baseUrl}${UrlLinks.messageTemplates}`)
      .then((response) => {
        setResponseIntoTemplateList(response.data.response);
        setModalLoader(false);
      })
      .catch((error) => {});
  }, []);

  const customMessage = (
    <>
      <FormLabel
        style={{ paddingBottom: "12px", color: "black", fontWeight: "500" }}
        component="legend"
      >
        Title
      </FormLabel>
      <TextField
        inputProps={{ maxLength: 100 }}
        placeholder="Please write a title (Char limit 100)"
        multiline
        rows={1}
        fullWidth
        onChange={(event) => {
          setCustomTitle(event.target.value);
        }}
        style={{ paddingBottom: "10px" }}
      />
      <FormLabel
        style={{ paddingBottom: "12px", color: "black", fontWeight: "500" }}
        component="legend"
      >
        Message Body
      </FormLabel>
      <TextField
        inputProps={{ maxLength: 200 }}
        placeholder="Please write a Message (Char limit 200)"
        multiline
        onChange={(event) => {
          setCustomBody(event.target.value);
        }}
        rows={4}
        fullWidth
      />
    </>
  );

  const deliciousRadioBoxes = (
    <ThemeProvider theme={theme}>
      {modesAvailable.length > 0 && (
        <FormControl component="fieldset">
          <FormLabel component="legend">Available Modes</FormLabel>
          <RadioGroup
            aria-label="gender"
            name="controlled-radio-buttons-group"
            row
            onChange={(event) => {
              getMessageBody(event.target.value);
            }}
          >
            {modesAvailable.map((e, id) => {
              return (
                <FormControlLabel
                  key={id}
                  value={e}
                  control={
                    <Radio
                      size="small"
                      color="primary"
                      style={{
                        "&.Mui-checked": {
                          color: theme.palette.primary.main,
                        },
                      }}
                    />
                  }
                  label={e.substr(0, e.indexOf("-"))}
                />
              );
            })}
          </RadioGroup>
        </FormControl>
      )}
    </ThemeProvider>
  );

  const templateSelect = (
    <FormControl
      sx={{
        m: 1,
        minWidth: 300,
      }}
      variant="outlined"
      className={classes.formControl}
    >
      <FormLabel style={{ paddingBottom: "12px" }} component="legend">
        Please choose a purpose
      </FormLabel>
      <Select
        id="demo-simple-select-autowidth"
        autoWidth
        className={classes.select}
        onChange={(event) => {
          if (event.target.value === "Custom") {
            setSendCustom(true);
            setDisable(true);
            setMessage({ title: null, body: null });
            setModesAvailable([]);
          } else {
            handleSelect(event.target.value);
          }
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          getContentAnchorEl: null,
          classes: { paper: classes.purposeSelect },
        }}
      >
        {menuItem}
      </Select>
    </FormControl>
  );

  return (
    <div>
      <Modal
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        open={open}
        BackdropProps={false}
        className={`${styles.sendCommunicationModal}`}
      >
        <Box
          sx={style}
          className={`${styles.sendCommunicationBox} ${classes.boxRoot}`}
        >
          <DialogTitle
            id="responsive-dialog-title"
            className={`${classes.header}`}
          >
            {title}
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {menuItem.length > 0 && templateSelect}
            </DialogContentText>
            <div style={{ padding: "9px 0" }}>{deliciousRadioBoxes}</div>
            {(message.body !== null || message.title !== null) && (
              <div className={`${styles.textBox}`}>
                <p>{message.title}</p>
                <p>{message.body}</p>
              </div>
            )}
            {modalLoader ? " ...please wait" : ""}
            {sendCustom && <div>{customMessage}</div>}
          </DialogContent>
          <DialogActions>
            <Button
              size="small"
              variant="contained"
              className="dataButton"
              disabled={disable}
              onClick={sendMessageAPI}
            >
              Send
            </Button>
          </DialogActions>
        </Box>
      </Modal>
    </div>
  );
}
