import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import * as moment from "moment";
import Axios from "axios";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { Button } from "@material-ui/core";
import style from "./index.module.scss";
import AutoSelectFilter from "./FilterComponents/AutoSelectFilter";
import ScaleLoader from "react-spinners/ScaleLoader";
import urlLinks from "../../UrlLinks";

const FilterDrawer = (props) => {
  const { handleClose, resetFilters, applyFilters, filters, setFilters } =
    props;
  const [zonalManager, setZonalManager] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [roleTypeList, setRoleTypeList] = useState([]);

  const handleCancelFilters = () => {
    handleClose();
  };

  const handleApplyFilters = () => {
    applyFilters();
    handleClose();
  };

  const yesNo = [
    {
      id: 1,
      name: "Yes",
    },
    {
      id: 0,
      name: "No",
    },
  ];

  const loader = (
    <div style={{ margin: "130px auto" }}>
      <ScaleLoader height={70} width={10} color={"#1c75bc"} />
    </div>
  );

  const makeZonalManager = (data) => {
    let list = [
      {
        id: -1,
        name: "Not mapped",
      },
    ];
    data.map((el) => {
      if (el.zm_user_id !== null) {
        list.push({
          id: el.zm_user_id,
          name: `${el.firstName ? el.firstName : ""} ${
            el.lastName ? el.lastName : ""
          }`,
        });
      }
      return true;
    });
    return list;
  };

  const makeRoleTypeData = (data) => {
    let list = [];
    data.map((el) => {
      list.push({
        id: el.typeKey,
        name: el.typeValue,
      });
      return true;
    });
    return list;
  };

  useEffect(() => {
    if (zonalManager.length > 0) {
      setIsLoading(false);
    }
  }, [zonalManager]);

  useEffect(() => {
    setFilters(props.filters);
    let url = "";
    if (filters["billDate"][0] && !filters["billDate"][1])
      url = `${url}date1=${moment(filters["billDate"][1]).format(
        "YYYY-MM-DD"
      )}&date2=${moment(new Date()).format("YYYY-MM-DD")} 23:59:59`;
    if (!filters["billDate"][1] && filters["billDate"][0])
      url = `${url}date1=2019-01-01&date2=${moment(
        filters["billDate"][1]
      ).format("YYYY-MM-DD")} 23:59:59`;
    if (filters["billDate"][1] && filters["billDate"][0])
      url = `${url}date1=${moment(filters["billDate"][1]).format(
        "YYYY-MM-DD"
      )}&date2=${moment(filters["billDate"][0]).format("YYYY-MM-DD")} 23:59:59`;

    Axios.get(`${urlLinks.baseUrl}${urlLinks.getZonalManager}`).then(
      (response) => {
        setZonalManager(makeZonalManager(response.data.response));
      }
    );

    Axios.get(`${urlLinks.baseUrl}${urlLinks.roleType.recruiterCategory}`).then(
      (response) => {
        setRoleTypeList(makeRoleTypeData(response.data.response.content));
      }
    );
  }, []);

  const output = isLoading ? (
    <Grid container style={{ width: "520px", marginTop: "20px" }}>
      {loader}
    </Grid>
  ) : (
    <Grid container style={{ width: "520px", marginTop: "20px" }}>
      <Grid container className={`${style.FilterHeadingContainer}`}>
        <div>
          <Button
            onClick={handleCancelFilters}
            className={style.filterCloseArrow}
          >
            <ArrowForwardIcon />
          </Button>
          <span className={style.filterHeading}>Filters</span>
        </div>
        <div>
          <Button onClick={resetFilters} className={style.resetFiltersButton}>
            Reset
          </Button>
        </div>
      </Grid>
      <div className={style.filterHeadingDivider}></div>
      <Grid container className={style.filtersContainer}>
        <Grid item xs={12} className={`${style.singleFilterContainer}`}>
          <span className={style.filterTitle}>Zonal Manager</span>
          <AutoSelectFilter
            filters={filters}
            setFilters={setFilters}
            multipleFlag={false}
            id="zm_user_id"
            options={zonalManager}
          />
        </Grid>
        <Grid item xs={12} className={`${style.singleFilterContainer}`}>
          <span className={style.filterTitle}>AJ Recruiter</span>
          <AutoSelectFilter
            filters={filters}
            setFilters={setFilters}
            multipleFlag={false}
            id="isAj"
            options={yesNo}
          />
        </Grid>
        <Grid item xs={12} className={`${style.singleFilterContainer}`}>
          <span className={style.filterTitle}>Role Type</span>
          <AutoSelectFilter
            filters={filters}
            setFilters={setFilters}
            multipleFlag={false}
            id="roleType"
            options={roleTypeList}
          />
        </Grid>
      </Grid>
      <Grid container className={style.filterButtonDividerContainer}>
        <div className={style.filterHeadingDivider}></div>
        <Grid container style={{ marginTop: "30px" }} className="padding0">
          <div
            style={{
              paddingRight: "16px",
              paddingLeft: "300px",
            }}
          >
            <Button
              variant="outlined"
              onClick={handleCancelFilters}
              size="medium"
              style={{ color: "#1C75BC", borderColor: "#1C75BC" }}
            >
              Cancel
            </Button>
          </div>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            onClick={handleApplyFilters}
            style={{ backgroundColor: "#1C75BC" }}
          >
            Apply
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
  return output;
};

export default FilterDrawer;
