import React, { useState, useEffect } from 'react'
import { Select, MenuItem, IconButton } from '@material-ui/core';
import DoneSharpIcon from '@material-ui/icons/DoneSharp';
import CloseSharpIcon from '@material-ui/icons/CloseSharp';
import urlLinks from '../../../UrlLinks';
import Axios from 'axios';

const EditableDropdownSubComponent =( props) =>{
    const {row, columnId, initialValue, updateMyData, showToast, setLoading} = props
    const [value, setValue] = useState(initialValue);
    const [wasYes, setWasYes] = useState(initialValue);
    const [showButtons, setShowButtons] = useState(false)

    const onChange = e => {
        setShowButtons(true)
        setValue(e.target.value)
    }
    console.log(initialValue)
    const updateStatus = () => {
        setLoading(true)
        console.log("entered", value)
        // if (columnId === "groupflex") {
        //     const postData = {
        //         field: "groupflex",
        //         // userId:row.userId,
        //         // referralId:row.referralId

        //     }


            Axios.post(`${urlLinks.baseUrl}${urlLinks.workerUrls.updateLeader}?userid=${row.userId}&groupflexid=${row.groupflex}`)
                .then(response => {
                    if (response.data.code === 1000) {
                        if(wasYes==="NO")showToast("success","Leader Updated");
                        updateMyData()
                        setLoading(false);

                    } else {
                        showToast(response.data.message);
                        setLoading(false);
                    }
                })
        // }

    }
    const handleConfirm =() =>{
        setLoading(true)
        updateStatus();
    }
    const handleCancel =() =>{
        setValue(initialValue)
        setShowButtons(false)
    }
    
    useEffect(() => {
        setValue(initialValue)
    }, [initialValue])
    console.log(columnId)
    let dropdown=<div></div>
    if(columnId==="leader"){
        dropdown=<div className="index">
            <Select
                id="groupflex"
                value={value}
                disableUnderline
                onChange={onChange}
                inputProps={{
                    classes: {
                        icon: "selectIcon",
                    },
                }}
                >
                <MenuItem value="-" disabled>
                    <em>Leader</em>
                </MenuItem>
                <MenuItem value='YES'>YES</MenuItem>
                <MenuItem value='NO'>NO</MenuItem>
         
            </Select>
            
            {showButtons &&
            <div>
                <IconButton color="primary" onClick={handleConfirm}>
                    <DoneSharpIcon />
                </IconButton>
                <IconButton color="secondary" onClick={handleCancel} >
                    <CloseSharpIcon />
                </IconButton>
            </div>}
            {/* {row.callStatusDate && 
                <div>
                    <span>{row.callStatusDate}</span>
            </div>} */}
            
        </div>
    }
    console.log(value)
    if(columnId==="Status"){
        dropdown=<div className="index">
            <Select
                id="Status"
                value={value}
                disableUnderline
                onChange={onChange}
                inputProps={{
                    classes: {
                        icon: "selectIcon",
                    },
                }}
                >
                <MenuItem value="-" disabled>
                    <em>Status</em>
                </MenuItem>
                <MenuItem value={0}>Not Onboarded</MenuItem>
                <MenuItem value={1}>Active</MenuItem>
                <MenuItem value={2}>Partially Onboarded</MenuItem>
                <MenuItem value={3}>Inactive</MenuItem>
                <MenuItem value={4}>Offboarding Initiated</MenuItem>
                <MenuItem value={5}>Onboarding Completed</MenuItem>
                <MenuItem value={6}>Temporarily Inactive</MenuItem>
                <MenuItem value={7}>Temporary Inactivation Initiated</MenuItem>
                <MenuItem value={8}>Reactivation Initiated</MenuItem>
         
            </Select>
            
            {showButtons &&
            <div>
                <IconButton color="primary" onClick={handleConfirm}>
                    <DoneSharpIcon />
                </IconButton>
                <IconButton color="secondary" onClick={handleCancel} >
                    <CloseSharpIcon />
                </IconButton>
            </div>}
            {/* {row.callStatusDate && 
                <div>
                    <span>{row.callStatusDate}</span>
            </div>} */}
            
        </div>
    }
    
    return (
        <React.Fragment>
            { initialValue!=='-'? dropdown:"-"}
            
        </React.Fragment>
    )
}

export default EditableDropdownSubComponent;



